import { useQuery } from "react-query";
import { listAllMessages, listMessageProviderPhoneNumbers } from "./services";

export const useMessages = () => {
  const {
    data: response,
    isError,
    isLoading,
    ...rest
  } = useQuery(`UserMessages`, listAllMessages);

  const messages =
    !isLoading && !isError
      ? JSON.parse(response.data.listAllUserMessages.result)
      : null;

  return { data: messages, isError, isLoading, ...rest };
};
export const useMessageProviders = () => {
  const {
    data: response,
    isError,
    isLoading,
    ...rest
  } = useQuery(`MessageProviderNumbers`, listMessageProviderPhoneNumbers);

  const phoneNumbers =
    !isLoading && !isError
      ? JSON.parse(response.data.listMessageProviderPhoneNumbers.result)
      : null;

  return { data: phoneNumbers, isError, isLoading, ...rest };
};
