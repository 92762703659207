import { useQuery } from "react-query";

/** local imports */
import { client } from "utils/awsConfig";
import { GET_USERJOURNEYRECORDS_BY_USERID } from "graphql/queries";

export default function useUserJourneyRecords(
  config,
  deps = [],
  queryConfig = {}
) {
  const {
    data: res,
    isError,
    isLoading,
    ...rest
  } = useQuery({
    queryKey: ["userJourneyRecords", ...deps],
    queryFn: () => getUserJourneyRecords(config),
    ...queryConfig,
  });

  const journeyRecords =
    !isError && !isLoading && res ? res.data.getUser.journies.items : [];

  return { data: journeyRecords, isError, isLoading, ...rest };
}

const getUserJourneyRecords = (config) =>
  client.query({
    query: GET_USERJOURNEYRECORDS_BY_USERID,
    ...config,
  });
