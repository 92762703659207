import { ROLES } from "./authHelpers";

function getRoleRedirectPath(role) {
  switch (role) {
    case ROLES.ADMIN:
      return `/admin`;

    case ROLES.COACH:
      return `/coach`;

    default:
      return `/`;
  }
}

export default getRoleRedirectPath;
