import React, { useMemo, useRef } from "react";
import { useHistory } from "react-router";
import { get } from "lodash";
import { HStack, VStack, Badge, Text, Heading, Box } from "@chakra-ui/layout";
import { Button, ButtonGroup } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { IconButton } from "@chakra-ui/button";
import { Tooltip } from "@chakra-ui/tooltip";
import { useToast } from "@chakra-ui/toast";
import { ErrorBoundary } from "react-error-boundary";
import { compareDesc, format, isFuture, isPast, parseISO } from "date-fns/esm";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";

/* icon imports */
import { FiPlus, FiUploadCloud } from "react-icons/fi";
import { IoMdRefresh } from "react-icons/io";
import { MdAssignment, MdLink } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";

/* local imports */
import { getEvents } from "../services";
import {
  EVENT_TYPES,
  PERMISSION_TIERS,
  getStatusColor,
  getStatusTitle,
} from "../constants";
// import CreateShipmentModal from "./CreateShipmentModal";

import DataGrid from "common/DataGrid";
import ErrorFallback from "common/ErrorFallback";
import { PORTAL_ROOT } from "../../../Routes";
import CreateEventModal from "./CreateEventModal";
import ImportEventsModal from "./ImportEventsModal";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import DebouncedInput from "common/DebouncedInput";

const columnHelper = createColumnHelper();

const EventsListWidget = () => {
  const history = useHistory();

  const toBeDeleted = useRef();

  const {
    isOpen: deleteDialogIsOpen,
    onOpen: onClickDeleteEvent,
    onClose: handleCloseDeleteEventDialog,
  } = useDisclosure({ id: toBeDeleted.current });

  const handleRowClick = (rowData) =>
    history.push({
      pathname: `${PORTAL_ROOT}/admin/events/${rowData.original.id}`,
      state: rowData.original,
    });

  const {
    data: result,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery("eventsList", getEvents);

  const events = useMemo(() => {
    let data =
      !isError && !isLoading
        ? result.data.listEvents.items.sort((a, b) =>
            compareDesc(
              parseISO(get(a, "startDate")),
              parseISO(get(b, "startDate"))
            )
          )
        : [];
    return data;
  }, [isLoading, isError, result?.data?.listEvents?.items]);
  const toast = useToast();
  const eventColumns = useMemo(
    () => [
      {
        header: "Date",
        accessorKey: "startDate",
        cell: ({ row }) => {
          const parsedDate = parseISO(row.getValue("startDate"));
          return (
            <VStack
              bg="white"
              maxW="5rem"
              rounded="md"
              overflow="hidden"
              spacing="0"
              alignItems="center"
              justifyContent="center"
              color="blackAlpha.800"
              border="1px solid"
              borderColor="gray.200"
            >
              <Box px="4" bg="red.400" w="full" py="1" textAlign="center">
                <Text fontSize="xs" color="white" as="b">
                  {format(parsedDate, "MMM")}
                </Text>
              </Box>
              <Box h="full" textAlign="center" py="2">
                <Text fontSize="xs">{format(parsedDate, "EEE")}</Text>
                <Text fontWeight="bold" fontSize="larger">
                  {format(parsedDate, "d")}
                </Text>
              </Box>
            </VStack>
          );
        },
        filterFn: (row, id, value) => {
          if (value === "past") {
            return isPast(parseISO(row.getValue(id)));
          } else {
            return !isPast(parseISO(row.getValue(id)));
          }
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        header: "Permission Tier",
        accessorKey: "permissionTier",
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        header: "Title",
        accessorKey: "title",
        cell: ({ row }) => (
          <VStack align="flex-start" justify="flex-start" minW="md">
            <HStack>
              <Badge colorScheme="gray" ml={0}>
                {get(row.original, "type")}
              </Badge>
              <Badge
                colorScheme={getStatusColor(
                  get(row.original, "permissionTier")
                )}
              >
                {getStatusTitle(get(row.original, "permissionTier"))}
              </Badge>
            </HStack>
            <Heading size="md" fontWeight="normal">
              {get(row.original, "title") ?? "—"}
            </Heading>
            <Text size="sm" color="gray" maxW="2xl">
              {get(row.original, "description") ?? "—"}
            </Text>
          </VStack>
        ),
        enableSorting: false,
      },
      {
        header: "Time",
        accessorFn: (row) => format(parseISO(row.startDate), "hh:mm aa (zz)"),
      },
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => (
          <HStack spacing={1}>
            <Tooltip label={get(row.original, "signupLink")}>
              <IconButton
                onClick={async (evt) => {
                  evt.stopPropagation();
                  await navigator.clipboard.writeText(
                    get(row.original, "signupLink")
                  );
                  toast({
                    title: "Link copied to clipboard.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                }}
                disabled={!get(row.original, "signupLink")}
                aria-label="Sign up link"
                icon={<MdAssignment />}
                colorScheme="gray"
                variant="outline"
                size="sm"
              />
            </Tooltip>
            <Tooltip label={get(row.original, "joinLink")}>
              <IconButton
                onClick={async (evt) => {
                  evt.stopPropagation();
                  await navigator.clipboard.writeText(
                    get(row.original, "joinLink")
                  );
                  toast({
                    title: "Link copied to clipboard.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                }}
                disabled={!get(row.original, "joinLink")}
                aria-label="Join link"
                icon={<MdLink />}
                colorScheme="gray"
                variant="outline"
                size="sm"
              />
            </Tooltip>
          </HStack>
        ),
      }),

      columnHelper.display({
        id: "destructive-actions",
        cell: ({ row }) => (
          <Tooltip label="Delete Event">
            <IconButton
              onClick={async (evt) => {
                evt.stopPropagation();
                toBeDeleted.current = row.original.id;
                onClickDeleteEvent();
              }}
              aria-label="Delete event"
              icon={<HiOutlineTrash />}
              colorScheme="gray"
              variant="outline"
              size="sm"
            />
          </Tooltip>
        ),
      }),
    ],
    []
  );

  const { table: tableInstance, ...dataGridProps } = DataGrid.useDataGrid({
    columns: eventColumns,
    data: events,
    initialState: {
      columnVisibility: { type: false, permissionTier: false },
    },
    enablePagination: true,
    enableRowSelection: true,
  });
  return (
    <VStack
      width="full"
      spacing={3}
      bg="white"
      rounded="xl"
      border="1px solid"
      borderColor="gray.200"
      shadow="md"
      overflow="hidden"
    >
      <DataGrid.Composer table={tableInstance}>
        <Header loading={isFetching} refetch={refetch} {...dataGridProps} />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Box
            w="full"
            borderTop="1px solid"
            borderColor="gray.100"
            overflowX="scroll"
          >
            <DataGrid.Table
              onRowClick={handleRowClick}
              loading={isLoading}
              enableRowSelection
            />
            <DataGrid.Footer pagination />
          </Box>
        </ErrorBoundary>
      </DataGrid.Composer>

      <ConfirmDeleteDialog
        isOpen={deleteDialogIsOpen}
        onClose={handleCloseDeleteEventDialog}
        id={toBeDeleted.current}
        refetch={refetch}
      />
    </VStack>
  );
};

export default EventsListWidget;

const Header = ({ loading, refetch, globalFilter, setGlobalFilter }) => {
  const { getColumn } = DataGrid.useDataGridContext();

  const createEvtDisclosureProps = useDisclosure();
  const bulkImportEvtsDisclosureProps = useDisclosure();

  React.useEffect(() => {
    getColumn("startDate").setFilterValue("upcoming");
  }, []);
  return (
    <HStack width="full" justifyContent="space-between" p={3}>
      <HStack alignItems="center" spacing={2} w="full">
        <DebouncedInput
          bg="white"
          initialValue={globalFilter}
          onChange={setGlobalFilter}
          placeholder="Find event"
        />
        {getColumn("startDate") && (
          <DataGrid.TabFilter
            column={getColumn("startDate")}
            title="Type"
            options={[
              { label: "Upcoming", value: "upcoming" },
              { label: "Past", value: "past" },
            ]}
          />
        )}
        {getColumn("type") && (
          <DataGrid.DropdownFilter
            column={getColumn("type")}
            title="Type"
            options={EVENT_TYPES}
          />
        )}
        {getColumn("permissionTier") && (
          <DataGrid.DropdownFilter
            column={getColumn("permissionTier")}
            title="Tier"
            options={PERMISSION_TIERS}
          />
        )}
      </HStack>
      <HStack alignItems="center" spacing={2}>
        <IconButton
          size="sm"
          onClick={refetch}
          aria-label="Refetch events"
          icon={<IoMdRefresh />}
          isLoading={loading}
        />
        <ButtonGroup colorScheme="linkedin" isAttached size="sm">
          <Button
            onClick={createEvtDisclosureProps.onOpen}
            leftIcon={<FiPlus />}
          >
            New Event
          </Button>
          <Tooltip label="Bulk import events">
            <IconButton
              borderLeftWidth="1px"
              borderLeftColor="linkedin.600"
              onClick={bulkImportEvtsDisclosureProps.onOpen}
              aria-label="Bulk import events"
              icon={<FiUploadCloud />}
            />
          </Tooltip>
        </ButtonGroup>
        <CreateEventModal {...createEvtDisclosureProps} />
        <ImportEventsModal {...bulkImportEvtsDisclosureProps} />
      </HStack>
    </HStack>
  );
};
