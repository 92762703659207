import {
  Box,
  Button,
  Flex,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Textarea,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns/esm";
import { omit } from "lodash";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

/** icon imports */
import { IoAttachOutline, IoSend } from "react-icons/io5";

import { getDisplayName } from "pages/Messages/utils";

import ChatBubble from "modules/messages/application/ChatBubble";
import ChatComposer from "modules/messages/application/ChatComposer";
import LeftPanel from "modules/messages/application/LeftPanel";
import {
  mapApiToConversation,
  sendMessage,
} from "modules/messages/infrastructure";

import {
  createConversation,
  createMessage,
  createParticipant,
} from "modules/messages/domain";
import { useAuth } from "utils/AuthContext";
import { getCoachLinkedPatients } from "./services";

const CoachMessagePortal = () => {
  const [{ user }] = useAuth();
  const currentUserId = user?.id;
  console.log("user", user);
  const me = createParticipant({ id: currentUserId, name: user?.name });

  const [activeConversationId, setActiveConversationId] = React.useState(null);
  const [drafts, setDrafts] = React.useState({});

  const [newConversation, setNewConversation] = React.useState(null);

  const { data: conversations, isLoading, isError, refetch } = useMessages();

  console.log("conversations", conversations);
  const handleChangeConversation = (id) => {
    if (newConversation) {
      setNewConversation(null);
      setDrafts((prev) => ({ ...prev, "__new-chat__": "" }));
    }
    setActiveConversationId(id);
  };
  const isActiveChat = (id) => id === activeConversationId;

  React.useEffect(() => {
    if (activeConversationId === null && conversations) {
      handleChangeConversation(conversations[0]?.id);
    }
  }, [activeConversationId, conversations]);

  const activeConversationDetails = newConversation
    ? newConversation
    : conversations?.find(({ id }) => id === activeConversationId) ?? null;
  const activeConversationMessages = activeConversationDetails?.messages ?? [];

  // const activeConversationId = activeConversationDetails?.id ?? null;
  // const defaultMessageProvider = messageProviders?.[0]?.phoneNumber ?? null;
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ recipient, payload }) => sendMessage(recipient, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["CoachUserMessages"],
      });

      refetch();
      setDrafts((prev) => {
        const modified = omit(prev, activeConversationId);
        return modified;
      });
      setNewConversation(null);
      // handleChangeConversation(conversations[0]?.id);
    },
  });

  const handleSendMessage = async (evt) => {
    evt.preventDefault();
    const toBeSent = drafts[activeConversationId];

    const payload = createMessage({
      authorId: me.id,
      conversationId: newConversation?.id || activeConversationId,
      body: toBeSent,
    });

    mutate({
      recipient: getRecipient(newConversation || activeConversationDetails),
      payload,
    });

    // sendMessage(
    //   getRecipient(newConversation || activeConversationDetails),
    //   payload
    // );

    // setDrafts((prev) => ({ ...prev, [activeConversationId]: "" }));
    // refetch();
    // handleChangeConversation(conversations[0]?.id);
  };

  console.log("newConversation", newConversation);
  console.log("drafts", drafts);

  const getRecipient = (conversation) =>
    conversation.participants.filter(
      (participant) => participant.id !== me.id
    )[0];

  const handleNewConversation = (user) => {
    const { id: userId, conversationId } = user;
    //takes a user with a coachUserLink ID added as prop "conversationId"
    const hasActiveConversation = conversations.find(
      (chat) => chat.id === conversationId
    );
    if (hasActiveConversation) {
      return handleChangeConversation(hasActiveConversation.id);
    }

    setActiveConversationId(conversationId);
    return setNewConversation(
      createConversation(conversationId)
        .addParticipant(me)
        .addParticipant(
          createParticipant({ id: userId, name: getDisplayName(user) })
        )
        .build()
    );
  };

  if (
    [
      isLoading,
      // providersLoading,
      // !messageProviders,
      // !conversations,
      isError,
    ].includes(true)
  ) {
    return (
      <Flex
        align="center"
        justify="center"
        rounded="3xl"
        bg="white"
        shadow="lg"
        overflow="hidden"
        h="2xl"
      >
        <Box textAlign="center">
          <Spinner />
          {isError ? (
            <Text>
              We seem to be having some trouble reaching your messages, please
              wait while we try again...
            </Text>
          ) : (
            <Text>Fetching your messages...</Text>
          )}
        </Box>
      </Flex>
    );
  }
  return (
    <ChatComposer
      rounded="xl"
      border="1px solid"
      borderColor="gray.200"
      shadow="md"
      h="calc(100svh - 10rem)"
    >
      <LeftPanel
        conversations={
          newConversation ? [newConversation, ...conversations] : conversations
        }
        activeConversationId={activeConversationId}
        handleSwitchChat={handleChangeConversation}
        isActiveChat={isActiveChat}
        draftMessages={drafts}
        handleNewConversation={handleNewConversation}
        // disableHighlightedChats={!!newConversation}
      />
      <GridItem
        rounded="3xl"
        roundedLeft="none"
        shadow="xs"
        w="full"
        colSpan={{ lg: 4 }}
        display="flex"
        flexDirection="column"
        bg="blackAlpha.50"
        position="relative"
      >
        {/* Message Header */}
        <HStack
          w="full"
          h={16}
          bg="white"
          p={4}
          shadow="xs"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          {newConversation ? (
            <VStack align="flex-start" w="full">
              <Text fontSize="lg" fontWeight="bold">
                {getRecipient(newConversation).name}
                {/* New message with {getDisplayName(newChat)}, no registered number
                available */}
              </Text>
              {/* <InputGroup>
                <InputLeftAddon>{"To:"}</InputLeftAddon>
                <Input
                  w="full"
                  bg="white"
                  name="recipientNumber"
                  autoComplete="off"
                  value={newConversation?.recipient ?? ""}
                  onChange={(evt) =>
                    setNewConversation((prev) => ({
                      ...prev,
                      recipient: evt.target.value,
                    }))
                  }
                  onBlur={() => setActiveConversation(newConversation?.recipient)}
                />
              </InputGroup> */}
            </VStack>
          ) : activeConversationDetails ? (
            <Text fontWeight="bold">
              {getRecipient(activeConversationDetails).name}
              {/* {getDisplayName(activeConversationDetails?.user)} */}
            </Text>
          ) : null}
        </HStack>

        {/* Message List */}
        <VStack
          spacing={2}
          flex="1 1 0"
          // flexShrink={1}
          // bg="#f5f5f5"
          bg="#ECE5DD"
          w="full"
          minH={0}
          overflowY="scroll"
          // minH={0}
          // maxH="100%"
          p={3}
          pb={56}
        >
          {!newConversation // will probably want to fetch some message history with newConversation
            ? activeConversationMessages.map(
                ({ id, body, authorId, createdAt }) => {
                  const isInbound = authorId !== currentUserId;
                  return (
                    <ChatBubble
                      key={id}
                      p={1}
                      px={2}
                      // display="flex"
                      isInbound={isInbound}
                    >
                      <Flex flexDirection="column" align="baseline">
                        <Text
                          // color={isInbound ? "gray.700" : "white"}
                          color="gray.800"
                        >
                          {body}
                        </Text>
                        <Text
                          alignSelf="flex-end"
                          color="blackAlpha.700"
                          // color={isInbound ? "blackAlpha.600" : "blue.100"}
                          fontSize="x-small"
                        >
                          {format(parseISO(createdAt), "hh:mm aa")}
                        </Text>
                      </Flex>
                    </ChatBubble>
                  );
                }
              )
            : null}
        </VStack>

        <HStack
          bg="white"
          p={3}
          position="absolute"
          bottom="0"
          w="full"
          shadow="xs"
        >
          <chakra.form
            w="full"
            onSubmit={handleSendMessage}
            display="relative"
            // overflow="hidden"
            rounded="lg"
            // border="1px"
            // borderColor="gray.100"
            // className="relative overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring"
          >
            <VStack w="full" p={0} alignItems="flex-start">
              {/* <Label htmlFor="message" className="sr-only">
                Message
              </Label> */}
              <Textarea
                id="message"
                placeholder="Type your message here..."
                noOfLines={3}
                // minH={12}
                p={3}
                onKeyDownCapture={(evt) => {
                  console.log("keydown", evt.key);
                  if (evt.key === "Enter" && (evt.ctrlKey || evt.metaKey)) {
                    return handleSendMessage(evt);
                  }
                }}
                // border="none"

                // className="min-h-12 resize-none border-0 p-3 shadow-none focus-visible:ring-0"

                key={activeConversationId}
                name="messageContent"
                value={drafts[activeConversationId] ?? ""}
                onChange={(evt) => {
                  setDrafts((prev) => ({
                    ...prev,
                    [activeConversationId]: evt.target.value,
                  }));
                }}
              />
              <Flex
                alignItems="center"
                // p={3}
                pt={0}
                w="full"
                //  className="flex items-center p-3 pt-0"
              >
                <IconButton
                  variant="ghost"
                  size="sm"
                  icon={
                    <Icon color="gray.700" w={5} h={5} as={IoAttachOutline} />
                  }
                />
                {/* <IconButton
                  variant="ghost"
                  size="sm"
                  icon={<Icon as={IoSend} />}
                /> */}
                {/* <Tooltip>
                  <TooltipTrigger asChild>
                    <IconButton variant="ghost" size="icon"
                    // icon={}
                    >
                      <Paperclip className="size-4" />
                      <span className="sr-only">Attach file</span>
                    </IconButton>
                  </TooltipTrigger>
                  <TooltipContent side="top">Attach File</TooltipContent>
                </Tooltip> */}
                {/* <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" size="icon">
                      <Mic className="size-4" />
                      <span className="sr-only">Use Microphone</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="top">Use Microphone</TooltipContent>
                </Tooltip> */}
                <Button
                  type="submit"
                  size="sm"
                  rightIcon={<Icon as={IoSend} />}
                  ml="auto"
                  colorScheme="linkedin"
                  onClick={handleSendMessage}
                  //  className="ml-auto gap-1.5"
                >
                  Send Message
                  {/* <CornerDownLeft className="size-3.5" /> */}
                </Button>
              </Flex>
            </VStack>
          </chakra.form>
          {/* <form onSubmit={handleSendMessage} style={{ width: "100%" }}>
            <InputGroup>
              <Input
                w="full"
                bg="white"
                key={activeConversationId}
                name="messageContent"
                autoComplete="off"
                value={drafts[activeConversationId] ?? ""}
                onChange={(evt) => {
                  setDrafts((prev) => ({
                    ...prev,
                    [activeConversationId]: evt.target.value,
                  }));
                }}
              />
              <InputRightElement>
                <IconButton
                  type="submit"
                  icon={<Icon as={IoSend} />}
                  colorScheme="linkedin"
                  borderLeftRadius="none"
                />
              </InputRightElement>
            </InputGroup>
          </form> */}
        </HStack>
      </GridItem>
    </ChatComposer>
  );
};

export default CoachMessagePortal;

const useMessages = () => {
  const [{ user }] = useAuth();
  const {
    data: response,
    isError,
    isLoading,
    ...rest
  } = useQuery({
    queryKey: [`CoachUserMessages`],
    queryFn: () =>
      getCoachLinkedPatients({
        variables: {
          coachId: user.id,
        },
      }),
  });

  const headshotKey = response?.data?.getCoach?.headshotKey;
  const messages =
    !isLoading && !isError
      ? response?.data?.getCoach?.patients?.items
          ?.map((patient) =>
            mapApiToConversation({
              ...patient,
              coachHeadshotKey: headshotKey,
            })
          )
          .filter(({ messages }) => messages.length !== 0)
      : null;

  return { data: messages, isError, isLoading, ...rest };
};

const mockApiResponse = [
  {
    id: "coach-user-link-with-bishoy",
    userId: "bishoy.maher-toptal.com_2023-12-08t23:30:59.156z",
    coachId: "julien+dev-tamade-co_2021-06-18T23:02:01.821Z",
    user: {
      firstName: "Bishoy",
      lastName: "Maher",
    },
    coach: {
      headshotKey: "some_url",
      user: {
        firstName: "Julien",
        lastName: "Soros",
      },
    },
    messages: {
      items: [
        {
          id: "message-1",
          userId: "julien+dev-tamade-co_2021-06-18T23:02:01.821Z",
          recipientId: "bishoy.maher-toptal.com_2023-12-08t23:30:59.156z",
          conversationId: "coach-user-link-with-bishoy",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          readAt: new Date().toISOString(),
          body: "This is a test",
        },
        {
          id: "message-2",
          userId: "julien+dev-tamade-co_2021-06-18T23:02:01.821Z",
          recipientId: "bishoy.maher-toptal.com_2023-12-08t23:30:59.156z",
          conversationId: "coach-user-link-with-bishoy",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          readAt: new Date().toISOString(),
          body: "This is a followup",
        },
        {
          id: "message-3",
          userId: "bishoy.maher-toptal.com_2023-12-08t23:30:59.156z",
          recipientId: "julien+dev-tamade-co_2021-06-18T23:02:01.821Z",
          conversationId: "coach-user-link-with-bishoy",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          readAt: new Date().toISOString(),
          body: "This is a response",
        },
        {
          id: "message-4",
          userId: "bishoy.maher-toptal.com_2023-12-08t23:30:59.156z",
          recipientId: "julien+dev-tamade-co_2021-06-18T23:02:01.821Z",
          conversationId: "coach-user-link-with-bishoy",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          readAt: new Date().toISOString(),
          body: "This is another response very long message lol",
        },
      ],
    },
  },
];

// const mockConvo = mockApiResponse.map(mapApiToConversation);

// console.log("mockConvo", mockConvo);

// const MessageInterface = {
//   id: "message-1",
//   authorId: "xxxxx",
//   conversationId: "conversation-1",
//   // userId: "julien+dev-tamade-co_2021-06-18T23:02:01.821Z",
//   // recipientId: "bishoy.maher-toptal.com_2023-12-08t23:30:59.156z",
//   // direction: "outbound",
//   createdAt: new Date().toISOString(),
//   updatedAt: new Date().toISOString(),
//   readAt: new Date().toISOString(),
//   body: "This is a test",
// };

// const ParticipantInterface = {
//   id: "bishoy.maher-toptal.com_2023-12-08t23:30:59.156z",
//   firstName: "Bishoy",
//   lastName: "Maher",
//   lastReadMessageId: "message-X",
//   readAt: new Date().toISOString(),
//   headshotKey: "someURL",
// };
