import { Box, Container, Heading } from "@chakra-ui/react";
import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";

/* local imports */
import { PORTAL_ROOT, ProtectedRoute } from "Routes";
import { ROLES } from "utils/authHelpers";
import LinkedPatiants from "./LinkedPatients";
import MessagePortal from "./MessagePortal";

const CoachRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      {/* <ProtectedRoute path={`${url}/sessions/:id`} component={<></>} />
		<ProtectedRoute path={`${url}/sessions`} component={<></>} />
  */}
      <ProtectedRoute
        // authorizationLevel={ROLES.COACH}
        path={`${url}/messages`}
        component={CoachMessagePatients}
      />
      <ProtectedRoute
        // authorizationLevel={ROLES.COACH}
        path={`${url}/patients`}
        component={CoachManagePatients}
      />
      {/* <ProtectedRoute path={`${url}/dashboard`} component={CoachDashboard} /> */}
      <Redirect
        from={`${PORTAL_ROOT}/coach`}
        exact
        to={`${PORTAL_ROOT}/coach/patients`}
      />
    </Switch>
  );
};

export default CoachRoutes;

const CoachManagePatients = () => {
  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      minHeight="calc(100vh - 4rem)"
    >
      <Container maxW="container.md" pt="8" px={{ base: 4, md: 0 }} pb={12}>
        <Heading pb={6}>Patients</Heading>
        <Box p={0} bg="white" rounded="xl" shadow="md">
          <LinkedPatiants />
        </Box>
      </Container>
    </Box>
  );
};
const CoachMessagePatients = () => {
  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      minHeight="calc(100vh - 4rem)"
    >
      <Container maxW="container.md" pt="8" px={{ base: 4, md: 0 }} pb={12}>
        <MessagePortal />
      </Container>
    </Box>
  );
};
