import React, { useRef, useState } from "react";
import {
  Box,
  Link as ChakraLink,
  VStack,
  Grid,
  GridItem,
  Text,
  HStack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import Icon from "@chakra-ui/icon";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import { STEPS } from "../UserInvitation";

import { client } from "utils/awsConfig.js";
import { getFormattedUsername } from "utils/authHelpers";
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";
import { ACCEPT_INVITATION } from "graphql/mutations";

const InviteRegister = ({ navigate, handleChange, formState }) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const linkAccount = async () => {
    setLoading(true);
    try {
      const invitationStatus = await client.mutate({
        mutation: ACCEPT_INVITATION,
        variables: {
          invitationId: formState.invitationId,
        },
      });

      if (invitationStatus.data.acceptGuideInvitation.result === "success")
        console.log("Invite accepted successfully!");
    } catch (err) {
      alert("Something went wrong");
      console.log("[Accept Invitation] Error occured: ", err);
    }
  };

  const next = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      const formattedUsername = getFormattedUsername(formState.email);

      await Auth.signUp({
        username: formattedUsername,
        password: formState.password,
        attributes: {
          email: formState.email.toLowerCase(),
          given_name: formState.name,
          family_name: "",
          birthdate: "00/00/0000",
        },
      });
      window.analytics.identify(formattedUsername, {
        email: formState.email.toLowerCase(),
        name: formState.name,
      });
      const date = new Date();
      const params = {
        year: date.getFullYear(),
        day: Math.floor(
          (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
        ),
      };
      window.analytics.track("account_created", params);
      firebase.analytics().logEvent("account_created", params);

      await Auth.signIn(formattedUsername, formState.password);
      setLoading(false);

      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        window.analytics.identify(user.username, {
          email: user.attributes.email,
          name: user.attributes.given_name,
        });
      }

      linkAccount();
      navigate(STEPS.subscribe);
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert(e.message);
    }
  };
  const goBack = () => navigate(STEPS.landing);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };
  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={next}>
          <VStack mt="20" alignItems="flex-start" spacing={4}>
            <H1>
              Welcome to Flowly{" "}
              {/* <span role="img" aria-label="hand wave emoji">
                👋
              </span> */}
            </H1>
            <Text fontSize="lg" color="gray.400">
              {/* Replace bold items with invitee & organisationName */}
              Please register to get started with{" "}
              <strong>
                {formState.guideName}@{formState.guideOrganization}
              </strong>{" "}
              on Flowly
            </Text>
            <FormControl id="fullName">
              <FormLabel fontWeight="semibold">Full Name</FormLabel>
              <Input
                name="fullName"
                type="text"
                value={formState.name}
                onChange={handleChange("name")}
                required
                placeholder="Full Name"
                size="lg"
              />
            </FormControl>
            {/* <FormControl id="org_name">
              <FormLabel fontWeight="semibold">Organization Name</FormLabel>
              <Input
                name="org_name"
                type="text"
                value={formState.org_name}
                onChange={handleChange("org_name")}
                required
                placeholder="Organization Name"
                size="lg"
              />
            </FormControl> */}
            <FormControl id="password">
              <FormLabel fontWeight="semibold">Password</FormLabel>
              <InputGroup>
                <Input
                  name="password"
                  type={isOpen ? "text" : "password"}
                  autoComplete="current-password"
                  placeholder="Password"
                  value={formState.password}
                  onChange={handleChange("password")}
                  size="lg"
                  required
                />
                <InputRightElement top="1">
                  <IconButton
                    bg="transparent !important"
                    variant="ghost"
                    aria-label={isOpen ? "Mask password" : "Reveal password"}
                    icon={isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />}
                    onClick={onClickReveal}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <HStack alignItems="center" spacing={8}>
              <Button
                type="submit"
                onClick={next}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText={"Creating your account..."}
                py="1rem"
                px="110px"
              >
                Next
              </Button>
              <ChakraLink onClick={goBack} color="gray.400">
                Use a different email
              </ChakraLink>
            </HStack>
            <Text pt="6" color="gray.500">
              By continuing, you agree to Flowly&apos;s{" "}
              <ChakraLink
                color="blue.500"
                href="https://www.flowly.world/terms-of-service"
                isExternal
              >
                Terms of Service
              </ChakraLink>{" "}
              and{" "}
              <ChakraLink
                color="blue.500"
                href="https://www.flowly.world/privacy-policy"
                isExternal
              >
                Privacy Policy
              </ChakraLink>
            </Text>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        pt="16"
        pl="5vw"
        pr="10vw"
      >
        <Box display="grid" placeItems="center">
          <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default InviteRegister;
