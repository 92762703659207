/* local imports */
import { SUBSCRIBE_WITH_ORG } from "graphql/mutations";
import { GET_GIFTCARD_BY_CODE } from "graphql/queries";
import { client, publicClient } from "utils/awsConfig";

export const validateVoucher = async (voucherCode, orgId) => {
  try {
    const response = await publicClient.query({
      query: GET_GIFTCARD_BY_CODE,
      variables: {
        id: voucherCode,
      },
    });

    const voucherData = response.data.getGiftCard;

    if (!voucherData) {
      throw Error("Invalid voucher entered");
    }

    if (voucherData.redeemed) {
      throw Error("Voucher has already been redeemed");
    }

    if (voucherData.organizationId !== orgId) {
      throw Error("Sorry, you are not eligible for this promotion");
    }

    if (voucherData.errors) {
      throw Error(voucherData.errors[0].message);
    }

    return { data: voucherData, error: null };
  } catch (err) {
    console.log("[Voucher Code Check] Error occured:", err.message);
    throw Error(err.message);
  }
};

export const redeemLicense = async (
  orgId,
  options = {
    address: null,
    mobile: null,
    orderReferenceNumber: null,
    voucherCode: null,
  }
) => {
  try {
    const subscriptionStatus = await client.mutate({
      mutation: SUBSCRIBE_WITH_ORG,
      variables: {
        organizationId: orgId,
        options: {
          ...(options.address ? { shippingAddress: options.address } : {}),
          ...(options.mobile ? { mobile: options.mobile } : {}),
          ...(options.voucherCode ? { voucherCode: options.voucherCode } : {}),
          ...(options.orderReferenceNumber
            ? { orderReferenceNumber: options.orderReferenceNumber }
            : {}),
        },
      },
    });

    const response = subscriptionStatus.data.subscribeWithOrganization;
    if (response.result) {
      return onRedeemSuccess(response.result, orgId);
    } else if (response.error) {
      alert(response.error);
      return null;
    }
  } catch (err) {
    alert(
      "Something went wrong while attempting to redeem your license. \n" +
        err.message
    );
    console.log("[Redeem Org License] Error occured: ", err);
  }
};

export const onRedeemSuccess = (successMsg, orgId) => {
  window.analytics.track("Organization Voucher Redeemed Successfully", {
    orgId,
  });
  console.log("Organization voucher redeemed successfully!");

  return successMsg;
};
