import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";

/* local imports */
import Loading from "common/Loading";
import Button from "common/Button";
import { getFormattedUsername } from "utils/authHelpers";

const Link = styled.div`
  display: inline-block;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #5072ec;
  margin-left: 1.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

function Account({ onNext }) {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [user, setUser] = useState(null);
  const [signUp, setSignUp] = useState(true);

  const [loading, setLoading] = useState(false);

  const loadCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        window.analytics.identify(user.username, {
          email: user.attributes.email,
          name: user.attributes.given_name,
        });
        setUser(user);
        setFormState((acc) => ({
          ...acc,
          name: user.attributes.given_name,
          email: user.attributes.email,
        }));

        return user;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputChange = (property) => (e) => {
    setFormState((acc) => ({ ...acc, [property]: e.target.value }));
  };

  const login = async () => {
    setLoading(true);
    try {
      await Auth.signIn(formState.email.toLowerCase(), formState.password);

      const cognitoUser = await loadCurrentUser();
      window.analytics.track("logged_in");
      firebase.analytics().logEvent("login");

      onNext(cognitoUser);
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const registerAccount = async () => {
    if (formState.password !== formState.confirmPassword) {
      alert("Password and Confirm password should be the same.");
      return;
    }
    setLoading(true);
    try {
      await Auth.signIn(
        formState.email.toLowerCase(),
        "aabbccdd134ranggwahfealfeuiabglaewifhafd=========aa=a=a"
      );
    } catch (e) {
      console.log(e);
      if (e.code === "NotAuthorizedException") {
        setLoading(false);
        alert(
          "Email already registered. Please use a different email or log in with the current email."
        );
        return;
      }
    }

    try {
      const formattedUsername = getFormattedUsername(formState.email);

      await Auth.signUp({
        username: formattedUsername,
        password: formState.password,
        attributes: {
          email: formState.email.toLowerCase(),
          given_name: formState.name,
          family_name: "",
          birthdate: "00/00/0000",
        },
      });
      window.analytics.identify(formattedUsername, {
        email: formState.email.toLowerCase(),
        name: formState.name,
      });
      const date = new Date();
      const params = {
        year: date.getFullYear(),
        day: Math.floor(
          (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
        ),
      };
      window.analytics.track("account_created", params);
      firebase.analytics().logEvent("account_created", params);

      await Auth.signIn(formattedUsername, formState.password);
      setLoading(false);

      const cognitoUser = await loadCurrentUser();
      onNext(cognitoUser);
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert(e.message);
    }
  };

  const next = () => {
    onNext(user);
  };

  const switchToLogin = () => {
    setSignUp(false);
  };

  const switchToCreateAccount = () => {
    setSignUp(true);
  };

  const logout = async () => {
    try {
      setLoading(true);
      await Auth.signOut();
      window.analytics.track("logged_out");
      window.analytics.reset();

      setSignUp(true);
      setUser(null);
      setFormState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderCreateAccount = () => {
    return (
      <>
        {loading ? <Loading /> : <></>}
        <input
          autoComplete="off"
          type="text"
          placeholder="Full name"
          value={formState.name}
          onChange={inputChange("name")}
        />
        <input
          type="text"
          placeholder="Your Email Address"
          value={formState.email}
          onChange={inputChange("email")}
        />
        <input
          type="password"
          placeholder="Password"
          value={formState.password}
          onChange={inputChange("password")}
        />
        <input
          type="password"
          placeholder="Confirm password"
          value={formState.confirmPassword}
          onChange={inputChange("confirmPassword")}
        />
        <div>
          <Button onClick={registerAccount} outline={true} inline={true}>
            Next
          </Button>
          <Link onClick={switchToLogin}>Login</Link>
        </div>
        <div>
          <p
            style={{
              color: "#718096",
            }}
          >
            By continuing, you agree to Flowly&apos;s{" "}
            <a
              style={{
                color: "#5072ec",
              }}
              href="https://www.flowly.world/terms-of-service"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              style={{
                color: "#5072ec",
              }}
              href="https://www.flowly.world/privacy-policy"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </>
    );
  };

  const renderLogin = () => {
    return (
      <>
        {loading ? <Loading /> : <></>}
        <input
          type="text"
          placeholder="Username or Email"
          value={formState.email}
          onChange={inputChange("email")}
        />
        <input
          type="password"
          placeholder="Password"
          value={formState.password}
          onChange={inputChange("password")}
        />
        <div>
          <Button onClick={login} outline={true} inline={true}>
            Login
          </Button>
          <Link onClick={switchToCreateAccount}>Sign Up</Link>
        </div>
      </>
    );
  };

  const renderUser = () => {
    return (
      <>
        {loading ? <Loading /> : <></>}
        <input type="text" disabled={true} value={formState.name} />
        <input type="text" disabled={true} value={formState.email} />
        <div>
          <Button onClick={next} outline={true} inline={true}>
            Next
          </Button>
          <Link onClick={logout}>Logout</Link>
        </div>
      </>
    );
  };

  return user ? renderUser() : signUp ? renderCreateAccount() : renderLogin();
}

export default Account;
