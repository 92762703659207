import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";

/** icon imports */
import { FiSearch } from "react-icons/fi";

const DebouncedInput = ({
  placeholder,
  onChange,
  debounceDuration = 750,
  initialValue,
  isLoading,
  inputProps,
  ...rest
}) => {
  const [state, setState] = useState(initialValue);

  const debouncedChange = useCallback(
    debounce((query) => onChange(query), debounceDuration),
    []
  );
  const handleInputChange = (evt) => {
    const searchStr = evt.target.value;
    setState(searchStr);
    debouncedChange(searchStr);
  };

  return (
    <InputGroup size="sm" maxW="max-content" {...rest}>
      <Input
        rounded="md"
        placeholder={placeholder}
        fontWeight="medium"
        value={state}
        onChange={handleInputChange}
        {...inputProps}
      />
      <InputRightElement>
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <Icon as={FiSearch} color="gray.500" />
        )}
      </InputRightElement>
    </InputGroup>
  );
};

export default DebouncedInput;
