import React from "react";
import {
  Box,
  VStack,
  Grid,
  GridItem,
  Text,
  Link as ChakraLink,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Link, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/button";

/* local imports */
import H1 from "common/H1";
import landscapeBg from "assets/gift/relaxation-landscape-bg.jpg";
import ebbiRelax from "assets/gift/EbbiRelax@300.png";

const noShowList = ["anthem"];
const ANTHEM_CODE = "ANTH2022";
const ANTHEM_RESOURCES =
  "https://uploads-ssl.webflow.com/61f85640fd264954b1b31f0f/6261f3baf2d9daf42be6f7d1_Anthem%20BH%20resources%20041922_1323.pdf";

const CustomOrgError = ({ formState }) => {
  const { orgCode } = useParams();

  const blackListed =
    noShowList.some((r) => (formState?.specialGroup ?? "").includes(r)) ||
    orgCode === ANTHEM_CODE;

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <VStack mt="20" alignItems="flex-start" spacing={6}>
          <H1>Uh oh!</H1>
          <VStack align="flex-start" spacing={0}>
            <Text fontSize="lg" color="gray.500">
              We seem to have encountered a bit of an issue.
            </Text>
            {!!formState?.errorMessage && (
              <Text fontSize="lg" color="gray.500">
                <b>{formState.errorMessage}</b>
                <br />
              </Text>
            )}
          </VStack>
          <Text fontSize="lg" color="gray.500">
            No worries, here&apos;s what you can do.
            <UnorderedList>
              {!blackListed && (
                <ListItem>
                  Got another flowly code?{" "}
                  <ChakraLink as={Link} to="../redeem" color="blue.500">
                    Try redeeming your license manually
                  </ChakraLink>
                </ListItem>
              )}
              <ListItem>
                Think you shouldn&apos;t be seeing this error?{" "}
                <ChakraLink
                  href={`mailto:contact@flowly.world?subject=Unable to redeem my Organization license&body=I tried to redeem license code ${orgCode} on ${new Date().toISOString()} and received the following error: ${
                    formState.errorMessage
                  }`}
                  color="blue.500"
                >
                  Get in touch with Flowly Support
                </ChakraLink>
              </ListItem>
            </UnorderedList>
          </Text>
          <ErrorFooter />
        </VStack>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
        >
          <Image src={ebbiRelax} maxW="16rem" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default CustomOrgError;

const ErrorFooter = () => {
  const { orgCode } = useParams();

  if (orgCode === ANTHEM_CODE) {
    return (
      <VStack pt={12} spacing={2} align="flex-start" color="gray.500">
        <Text>
          We&apos;ve also compiled a list of useful resources you have access
          to.
        </Text>
        <Button
          variant="outline"
          colorScheme="linkedin"
          as={ChakraLink}
          isExternal
          href={ANTHEM_RESOURCES}
        >
          View Resources
        </Button>
      </VStack>
    );
  }

  return (
    <VStack pt={12} spacing={2} align="flex-start" color="gray.500">
      <Text>Don&apos;t like any of the suggestions above?</Text>
      <Button as={Link} to="/" variant="outline" colorScheme="blue">
        Back to Homepage
      </Button>
    </VStack>
  );
};
