import axios from "axios";

const DEFAULT_CONFIG = {
  ADDRESS: {
    fromPostalCode: "91104",
    toCountry: "US",
    residential: false,
  },
  CARRIER: {
    carrierCode: "stamps_com",
    serviceCode: "usps_priority_mail",
    packageCode: "package",
  },
  PACKAGE: {
    weight: { value: 34, units: "ounces" },
    dimensions: { units: "inches", length: 8, width: 6, height: 4 },
    confirmation: "delivery",
  },
};
const DEFAULT_SHIPMENT_CONFIG = {
  ...DEFAULT_CONFIG.ADDRESS,
  ...DEFAULT_CONFIG.CARRIER,
  ...DEFAULT_CONFIG.PACKAGE,
};

const REQUEST_METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
};

const Orders = {
  baseURL: "/orders",
  createOrUpdate: async (data) =>
    ShipStation.request({
      method: REQUEST_METHOD.POST,
      resourcePath: Orders.baseURL + "/createorder",
      data,
    }),
  createLabelForOrder: async (data) =>
    ShipStation.request({
      method: REQUEST_METHOD.POST,
      resourcePath: Orders.baseURL + "/createlabelfororder",
      data: {
        ...data,
        testLabel: true,
      },
    }),
};
const Stores = {
  baseURL: "/stores",
  getAll: async (params) =>
    ShipStation.request({
      method: REQUEST_METHOD.GET,
      resourcePath: Stores.baseURL,
      params,
    }),
};
const Products = {
  baseURL: "/products",
  getAll: async (params) =>
    ShipStation.request({
      method: REQUEST_METHOD.GET,
      resourcePath: Products.baseURL,
      params,
    }),
};
const Carriers = {
  baseURL: "/carriers",
  get: async (carrierCode) =>
    ShipStation.request({
      method: REQUEST_METHOD.GET,
      resourcePath: Carriers.baseURL + "/getcarrier",
      params: { carrierCode },
    }),
  getAll: async (params) =>
    ShipStation.request({
      method: REQUEST_METHOD.GET,
      resourcePath: Carriers.baseURL,
      params,
    }),
  getServices: async (carrierCode = DEFAULT_CONFIG.CARRIER.carrierCode) =>
    ShipStation.request({
      method: REQUEST_METHOD.GET,
      resourcePath: Carriers.baseURL + "/listservices",
      params: { carrierCode },
    }),
  getPackages: async (carrierCode = DEFAULT_CONFIG.CARRIER.carrierCode) =>
    ShipStation.request({
      method: REQUEST_METHOD.GET,
      resourcePath: Carriers.baseURL + "/listpackages",
      params: { carrierCode },
    }),
};
const Shipments = {
  baseURL: "/shipments",
  getRates: async (data) =>
    ShipStation.request({
      method: REQUEST_METHOD.POST,
      resourcePath: Shipments.baseURL + "/getrates",
      data: {
        ...DEFAULT_SHIPMENT_CONFIG,
        ...data,
      },
    }),
  createLabel: async (data) =>
    ShipStation.request({
      method: REQUEST_METHOD.POST,
      resourcePath: Shipments.baseURL + "/createlabel",
      data: {
        ...data,
        testLabel: true,
      },
    }),
};

const ShipStation = {
  request: ({ headers = {}, method, resourcePath, data, params }) => {
    const options = {
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_SHIPSTATION_KEY}`,
        ...headers,
      },
      baseURL: "https://ssapi.shipstation.com/",
      url: resourcePath,
      method,
      params,
    };
    if (data) options.data = data;

    return axios.request(options);
  },
  /*   --- models & their services --- */
  orders: { ...Orders },
  stores: { ...Stores },
  products: { ...Products },
  shipments: { ...Shipments },
  carriers: { ...Carriers },
};

export default ShipStation;
