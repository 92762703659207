import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Grid,
  GridItem,
  Text,
  Link,
  Flex,
  Stack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import QRCode from "react-qr-code";

/* asset imports */
import landscapeBg from "assets/gift/relaxation-landscape-bg.jpg";
import ebbiRelax from "assets/gift/EbbiRelax@300.png";
import appstore from "assets/confirmation/appstore.png";
import check from "assets/home/check.svg";
/* local imports */
import H1 from "common/H1";
import H4 from "common/H4";
import { getDeepLink } from "utils/firebase";
import { APPSTORE_LINK } from "constants/downloadApp";

const RedeemVoucherComplete = ({ formState }) => {
  const [links, setLinks] = useState({
    shortLink: APPSTORE_LINK,
    previewLink: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDeepLink(formState?.email ?? "")
      .then((res) => {
        setLinks(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [formState]);

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <VStack mt="20" alignItems="flex-start" spacing={4}>
          <H1>Welcome, Flowly Hero!</H1>
          {!!formState?.redeemSuccess && (
            <Flex>
              <Image mr="2" src={check} />
              <Text fontSize="lg" color="gray.500">
                <b>{formState.redeemSuccess}</b>
                <br />
              </Text>
            </Flex>
          )}
          <Text fontSize="lg" color="gray.500">
            Your Kit (including your VR headset and HR Sensor) is on its way.
          </Text>
          <Text fontSize="lg" color="gray.500">
            Meanwhile...
          </Text>
          <H4>1. You can call or text us for an Orientation Call</H4>
          <Text fontSize="lg" color="gray.500">
            a Flowly Guide will onboard you and help answer questions on how
            Flowly can help meet your wellness goals.
          </Text>
          <Text fontSize="lg" color="gray.500" style={{ fontWeight: "bold" }}>
            #323-545-3659
          </Text>
        </VStack>
        <Stack
          mt={20}
          direction={{ base: "column", lg: "row" }}
          alignItems="flex-start"
        >
          <VStack alignItems="flex-start">
            <H4>2. Download the Flowly app</H4>
            <Text fontSize="lg" color="gray.500">
              Scan the QR code on your iPhone or download Flowly: relaxation
              training on the Apple App Store (login with the same email and
              password you just created).
            </Text>
          </VStack>
          <VStack align="center" w="full" py={{ base: 12, md: 4 }}>
            <QRCode
              value={links.shortLink || APPSTORE_LINK}
              fgColor="#6282c7"
              bgColor="transparent"
              level="M"
              size={128}
            />
            <Link href={APPSTORE_LINK} isExternal>
              <Image
                display="unset"
                src={appstore}
                maxW="10rem"
                alt="appstore-link"
              />
            </Link>
          </VStack>
        </Stack>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
        >
          <Image src={ebbiRelax} maxW="16rem" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemVoucherComplete;
