import React from "react";
import styled from "styled-components";

const H4Style = styled.h4`
  font-family: "Tommy Soft";
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #5072ec;
`;
function H4({ children }) {
  return <H4Style>{children}</H4Style>;
}

export default H4;
