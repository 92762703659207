import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { Widget } from "@typeform/embed-react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Heading,
  Text,
  Box,
  Link,
  Grid,
  Spinner,
  Flex,
  Image,
  Button,
} from "@chakra-ui/react";

/* icon imports */

/* asset imports */
import ConfirmationBg from "assets/confirmation/mountain_landscape_withEbbi.svg";
import appstore from "assets/confirmation/appstore.png";

/* local imports */
import { getDeepLink } from "utils/firebase";
import { removeItemLocalStorage } from "utils/localStorage";
import { APPSTORE_LINK, PLAYSTORE_LINK } from "constants/downloadApp";

function deviceType() {
  const ua = navigator.userAgent;

  if (/iP(hone|od)/.test(ua)) return "apple";
  else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
    return "tablet";
  else if (
    /Mobile|Android|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  )
    return "mobile";

  return "desktop";
}

function JourneyConfirmation() {
  const [links, setLinks] = useState({
    shortLink: APPSTORE_LINK,
    previewLink: null,
  });
  const [loading, setLoading] = useState(false);
  const [platform] = useState(() => deviceType());
  const { state: formState } = useLocation();

  const { isOpen, onOpen: surveyOpen, onClose: surveyClose } = useDisclosure();

  useEffect(() => {
    surveyOpen();
    removeItemLocalStorage(["promo", "referrer"]);
  }, []);

  useEffect(() => {
    setLoading(true);
    getDeepLink(formState?.email ?? "")
      .then((res) => {
        setLinks(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [formState]);

  return (
    <>
      <VStack
        w="full"
        h={{ base: "80vh", xl: "70vh" }}
        spacing={0}
        bg={`linear-gradient(180deg, rgba(216, 229, 245, 0.19) -2.75%, #d5e9f8 42%, #fcf0f3 85%)`}
      >
        <VStack
          flexGrow={1}
          p="4rem 8vw 1rem 8vw"
          textAlign="center"
          spacing={12}
        >
          <VStack spacing={4}>
            <Text
              fontSize="3xl"
              bg="-webkit-linear-gradient(90deg, #66bde4 -0.35%, #627ac4 99.5%)"
              backgroundClip="text"
              textColor="transparent"
            >
              Congratulations!
            </Text>
            <Heading
              color="hsla(216, 39%, 37%, 1)"
              fontSize={{ base: "5xl", md: "5rem" }}
              lineHeight="1em"
              fontWeight="bold"
            >
              your journey starts now
            </Heading>
            <Text
              w="full"
              fontWeight="semibold"
              fontSize="xl"
              color="rgb(58, 87, 131)"
            >
              Your Flowly Kit will arrive in 3 - 5 business days. Meanwhile,
              please…
            </Text>
          </VStack>

          <Grid
            maxW="2xl"
            columnGap="1.875rem"
            rowGap="2rem"
            templateColumns="repeat(1, 1fr)"
          >
            <Flex>
              {/* Tablet->Desktop views */}
              <VStack
                id="deepLink"
                display={{ base: "none", md: "flex" }}
                px="8"
                spacing={0}
              >
                {loading ? (
                  <Spinner my="8" />
                ) : (
                  <Link href={links.shortLink}>
                    <QRCode
                      value={links.shortLink || APPSTORE_LINK}
                      fgColor="#6282c7"
                      bgColor="transparent"
                      level="M"
                      size={132}
                    />
                  </Link>
                )}
                <Link href={links.shortLink} pt="2">
                  <Image width={232} src={appstore} alt="App Store" />
                </Link>
                <Link href={PLAYSTORE_LINK}>
                  <Image
                    alt="Get it on Google Play"
                    width={232}
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </Link>
              </VStack>

              {/* Mobile view */}
              <VStack
                spacing={6}
                align={{ base: "center", md: "start" }}
                id="step-downloadApp"
                m={{ base: "1rem auto", md: 0 }}
                textAlign={{ base: "center", md: "left" }}
                w="full"
                color="rgb(58, 87, 131)"
                fontSize="xl"
              >
                <Heading
                  color="hsla(229, 72%, 62%, 1);"
                  fontSize={{ base: "2.5rem", md: "3rem" }}
                  textAlign={{ base: "center", md: "left" }}
                  fontWeight="medium"
                  marginBlockEnd={{ md: "-1.25rem" }}
                  lineHeight="none"
                >
                  Download the app
                </Heading>
                {platform === "apple" ? (
                  <Button
                    display={{ md: "none" }}
                    colorScheme="purple"
                    bg="hsla(232, 89%, 66%, 1);"
                    _hover={{
                      bg: "hsla(232, 89%, 56%, 1);",
                    }}
                    px="12"
                    h="16"
                    fontSize="2xl"
                    rounded="full"
                    onClick={() => window.open(links.shortLink, "_blank")}
                  >
                    Download Flowly
                  </Button>
                ) : (
                  <Box display={{ base: "block", md: "none" }}>
                    {loading ? (
                      <Spinner my="8" />
                    ) : (
                      <Link href={links.shortLink}>
                        <QRCode
                          value={links.shortLink || APPSTORE_LINK}
                          fgColor="#6282c7"
                          bgColor="transparent"
                          level="M"
                          size={132}
                        />
                      </Link>
                    )}
                  </Box>
                )}
                <Box display={{ base: "block", md: "none" }}>
                  {platform !== "apple" ? (
                    <Text>
                      Scan the QR code above on your iPhone or Android to
                      download the Flowly: Relaxation training app on the App
                      Store
                    </Text>
                  ) : (
                    <Text>
                      Tap the button to download the Flowly: Relaxation training
                      app on the App Store
                    </Text>
                  )}
                </Box>
                <Text
                  display={{ base: "none", md: "block" }}
                  fontSize="2xl"
                  fontWeight="semibold"
                >
                  Scan the QR code on your iPhone or Android
                </Text>
                <Text display={{ base: "none", md: "inherit" }}>
                  to download the Flowly: Relaxation training app. Get started
                  today!
                </Text>
              </VStack>
            </Flex>
          </Grid>
          <Link
            color="rgb(80, 114, 236)"
            fontSize="xl"
            href="https://www.flowly.world/"
          >
            Back to home page
          </Link>
        </VStack>

        <Modal isOpen={isOpen} onClose={surveyClose} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Please take a moment to fill out this survey
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody id="form">
              <Widget
                id="qp1zCnyW"
                tracking={{ user_id: formState?.username }}
                onSubmit={() => {
                  setTimeout(() => surveyClose(), 600);
                }}
                lazy={false}
                hideFooter={true}
                hideHeaders={true}
                style={{ width: "100%", height: "90vh" }}
                onClose={surveyClose}
                //eslint-disable-next-line
                autoFocus={true}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
      <Image src={ConfirmationBg} w="full" mb="-20px" />
    </>
  );
}

export default JourneyConfirmation;
