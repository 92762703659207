import { FirebaseDynamicLinks } from "firebase-dynamic-links";

const firebaseDynamicLinks = new FirebaseDynamicLinks(
  process.env.REACT_APP_FIREBASE_KEY
);

export const getDeepLink = async (email, password) => {
  return await firebaseDynamicLinks.createLink({
    dynamicLinkInfo: {
      domainUriPrefix: process.env.REACT_APP_DYNAMIC_LINK_URL,
      link: `https://www.flowly.world/?link-type=login&email=${email ?? ""}`,
      iosInfo: {
        iosBundleId: "com.tamade.flowly",
        iosAppStoreId: "1485955236",
      },
      androidInfo: {
        androidPackageName: "com.tamade.flowly",
      },
    },
  });
};
