import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

/** local imports */
import useLocalStorageSync from "./useLocalStorageSync";

const useFetchOnce = (
  asyncFn,
  deps = [],
  initialState = { loading: false, error: null, value: null },
  opts = {
    getPath: null,
    fetchCondition: true,
    syncToStorage: false,
    syncKey: null,
  }
) => {
  const [state, setState] = useState(initialState);
  const { value: storedVal, setItem: setStorage } = useLocalStorageSync(
    opts.syncKey ? `query/${opts.syncKey}` : null
  );

  const shouldFetch =
    opts.fetchCondition !== undefined ? opts.fetchCondition : true;
  const callback = useCallback((...args) => {
    if (!state.loading) setState((prev) => ({ ...prev, loading: true }));

    return asyncFn(...args).then(
      (val) => {
        const parsedVal = opts.getPath ? _.get(val, opts.getPath) : val;
        setState((prev) => ({ ...prev, loading: false, value: parsedVal }));
        if (opts.syncToStorage) setStorage(parsedVal);
        return val;
      },
      (err) => {
        console.log("[Async fn failed]: An error occured", err);
        setState((prev) => ({ ...prev, loading: false, error: err }));
        return err;
      }
    );
  }, deps);

  useEffect(() => {
    if (shouldFetch && !storedVal) {
      callback();
    }

    if (opts.syncToStorage) {
      if (!opts.syncKey)
        console.error(
          "Cannot use syncToStorage without also defining a valid syncKey"
        );
      setState((prev) => ({ ...prev, value: storedVal }));
    }
  }, [...deps, storedVal]);

  return [state, callback];
};

export default useFetchOnce;
