import { useState } from "react";
import { mapValues } from "lodash";
import { sub } from "date-fns/esm";

const DEFAULT_DURATION = {
  years: 0,
  months: 0,
  weeks: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const useTimeWindow = ({
  years = 0,
  months = 0,
  weeks = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}) => {
  const duration = Object.assign(DEFAULT_DURATION, {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  });
  const today = new Date();
  const [windowShift, setWindowShift] = useState(0);
  const [windowEnd, setWindowEnd] = useState(
    () => today
    // subWeeks(new Date(), windowShift)
  );

  const recalculateWindow = (shift) => {
    setWindowEnd(
      sub(
        today,
        mapValues(duration, (val) => shift * val)
      )
    );
  };

  const shiftLeft = () => {
    setWindowShift((s) => {
      const shift = s + 1;
      recalculateWindow(shift);

      return shift;
    });
  };
  const shiftRight = () => {
    setWindowShift((s) => {
      const shift = s ? s - 1 : s;
      recalculateWindow(shift);

      return shift;
    });
  };
  const windowStart = sub(windowEnd, duration);

  return {
    windowShift,
    windowStart,
    windowEnd,
    shiftLeft,
    shiftRight,
  };
};

export default useTimeWindow;
