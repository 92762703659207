import React from "react";
import { Link as ChakraLink, HStack, Text } from "@chakra-ui/layout";
import { IconButton, Button } from "@chakra-ui/button";
import { NavLink } from "react-router-dom";
import { format, parseISO } from "date-fns/esm";
import Auth from "@aws-amplify/auth";
import { Spinner } from "@chakra-ui/spinner";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { createColumnHelper } from "@tanstack/react-table";

/* icon imports */
import { HiDotsHorizontal, HiOutlineTrash } from "react-icons/hi";
import { MdOutlinePerson } from "react-icons/md";

/* local imports */
import { PORTAL_ROOT } from "../../Routes";
import { client } from "utils/awsConfig.js";
import { GET_GUIDE } from "graphql/queries";
import { INVITE_USER, REMOVE_USER } from "graphql/mutations";

// let loading = null;

export const getGuide = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const guide = await client.query({
    query: GET_GUIDE,
    variables: {
      id: user.username,
    },
    fetchPolicy: "network-only",
  });

  if (guide.data?.getGuide) {
    // console.log("getGuide", guide.data.getGuide);
    const { invitations, linkedUsers } = guide.data.getGuide;
    return [invitations, linkedUsers];
  }

  return null;
};

const sendInvitation = async (id, email, setLoading) => {
  setLoading(id);
  try {
    const userInvitation = await client.mutate({
      mutation: INVITE_USER,
      variables: {
        input: {
          userEmail: email,
          invitationId: id,
          action: "RESEND",
        },
      },
    });

    alert("Invitation sent successfully");
    setLoading(false);
    console.log({ userInvitation });
  } catch (error) {
    setLoading(false);
    alert("An error occurred: Invitation not sent");
    console.log("[Resend Invitation Failed] Error occurred", error);
  }
};
const removeUser = async (linkId) => {
  try {
    const userGuideLink = await client.mutate({
      mutation: REMOVE_USER,
      variables: {
        input: {
          id: linkId,
        },
      },
    });

    alert("Hero unlinked successfully");
    console.log({ userGuideLink });
  } catch (error) {
    alert("An error occurred: Unlink operation unsuccessful");
    console.log("[Unlink Operation Failed] Error occurred", error);
  }
};

const columnHelper = createColumnHelper();

export const userColumns = (cb) => [
  columnHelper.accessor((row) => `${row.firstName} ${row.lastName ?? ""}`, {
    id: "fullName",
    header: "Name",
    cell: ({ row }) => (
      <Text fontWeight="semibold">{row.getValue("fullName")}</Text>
    ),
  }),
  {
    header: "Email",
    accessorKey: "email",
    cell: ({ row }) => <Text color="gray.500">{row.getValue("email")}</Text>,
  },
  {
    header: "Sessions Completed",
    accessorKey: "sessionsCompleted",
    meta: {
      isNumeric: true,
    },
  },
  columnHelper.display({
    id: "actions",
    cell: ({ row }) => (
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          icon={<HiDotsHorizontal />}
          variant="ghost"
        />
        <MenuList>
          <MenuItem
            icon={<MdOutlinePerson />}
            as={NavLink}
            to={`${PORTAL_ROOT}/users/${row.original.id}`}
          >
            View Profile
          </MenuItem>
          <MenuDivider />
          <MenuItem
            color="red.400"
            icon={<HiOutlineTrash />}
            onClick={async (evt) => {
              evt.stopPropagation();
              await removeUser(row.original.linkId);
              cb();
            }}
          >
            Remove Hero
          </MenuItem>
        </MenuList>
      </Menu>
    ),
  }),
];

export const invitationColumns = (cb, [loading, setLoading]) => [
  {
    header: "Name",
    accessorKey: "userFirstName",
    cell: ({ row }) => (
      <Text fontWeight="semibold">{row.getValue("userFirstName")}</Text>
    ),
  },
  {
    header: "Email",
    accessorKey: "userEmail",
    cell: ({ row }) => (
      <Text color="gray.500">{row.getValue("userEmail")}</Text>
    ),
  },
  {
    header: "Sent On",
    accessorKey: "lastSentDate",
    cell: ({ row }) =>
      format(parseISO(row.getValue("lastSentDate")), "MMM do, yyyy"),
  },
  columnHelper.display({
    id: "actions",
    cell: ({ row }) => (
      <Button
        size="sm"
        variant="ghost"
        color="linkedin.500"
        display="flex"
        alignItems="center"
        isLoading={loading === row.original.id}
        onClick={async () => {
          await sendInvitation(
            row.original.id,
            row.original.userEmail,
            setLoading
          );
          cb();
        }}
      >
        Resend
      </Button>
    ),
  }),
  // {
  //   header: "",
  //   accessorKey: "id",
  //   cell: ({ row }) => {
  //     return loading === row.getValue("id") ? (
  //       <Spinner size="sm" />
  //     ) : (
  //       <ChakraLink
  //         color="linkedin.500"
  //         display="flex"
  //         alignItems="center"
  //         onClick={async () => {
  //           await sendInvitation(
  //             row.getValue("id"),
  //             row.getValue("userEmail"),
  //             setLoading
  //           );
  //           cb();
  //         }}
  //       >
  //         Resend
  //       </ChakraLink>
  //     );
  //   },
  // },
];
