import React from "react";
import styled from "styled-components";

const ProductStyle = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;

  .image {
    width: 110px;
    img {
      width: 100%;
    }
  }
  .content {
    flex: 1;
    padding-left: 14px;
    padding-right: 8px;
    .name {
      font-family: "Tommy Soft";
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 26px;
      color: #464e64;
    }
    .description {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }
    .description p {
      margin: 0;
    }
  }
  .pricing {
    text-align: right;
    .price {
      font-family: Nunito;
      font-weight: bold;
      font-size: 18px;
      line-height: 1.5rem;
    }
    .quantity {
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

function Product({ name, price, description, image, qty = 1 }) {
  return (
    <ProductStyle>
      <div className="image">
        <img src={image} alt={name} />
      </div>
      <div className="content">
        <div className="name">{name}</div>
        <div className="description">{description}</div>
      </div>
      <div className="pricing">
        <div className="price">{price}</div>
        <div className="quantity">Qty: {qty ? qty : 1}</div>
      </div>
    </ProductStyle>
  );
}

export default Product;
