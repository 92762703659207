import React, { useRef, useState } from "react";
import { Auth } from "aws-amplify";
import "firebase/analytics";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link as ChakraLink,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { useToast } from "@chakra-ui/toast";
import Icon from "@chakra-ui/icon";
import { Link, Redirect } from "react-router-dom";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";
import { PORTAL_ROOT } from "../../Routes";

const STEPS = {
  landing: "landing",
  captureCode: "captureCode",
  thankyou: "thankyou",
};
const ForgotPassword = () => {
  const [step, setStep] = useState(STEPS.landing);

  const [formState, setFormState] = useState({
    email: "",
    resetCode: "",
    newPass: "",
  });

  const handleInputChange = (property) => (e) =>
    setFormState((prev) => ({ ...prev, [property]: e.target.value }));

  const navigate = (selectedStep) => setStep(STEPS[selectedStep]);

  return (
    <Box
      height="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <Grid gridTemplateColumns="repeat(12, 1fr)" height="full">
        {/* Left */}
        <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
          <CurrentStep
            step={step}
            navigate={navigate}
            handleChange={handleInputChange}
            formState={formState}
          />
        </GridItem>

        {/* Right */}
        <GridItem
          colSpan={6}
          display={{ base: "none", lg: "block" }}
          width="full"
          pt="16"
          pl="5vw"
          pr="10vw"
        >
          <Box display="grid" placeItems="center">
            <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;

const CurrentStep = ({ step, ...controls }) => {
  switch (step) {
    case STEPS.captureCode:
      return <ForgotPassCodeReset {...controls} />;
    case STEPS.landing:
    default:
      return <ForgotPassLanding {...controls} />;
  }
};

const ForgotPassLanding = ({ navigate, handleChange, formState }) => {
  const [loading, setLoading] = useState(false);

  const submitForgotPass = async () => {
    setLoading(true);
    try {
      await Auth.forgotPassword(formState.email.toLowerCase());
      navigate(STEPS.captureCode);
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <form onSubmit={submitForgotPass}>
      <VStack mt="20" alignItems="flex-start" spacing={12}>
        <VStack alignItems="flex-start" spacing={1}>
          <H1>Forgot Password</H1>
          <Text fontSize="xl" color="gray.400">
            Enter the email address associated with your account and we&apos;ll
            send you a link to reset your password.
          </Text>
        </VStack>
        <VStack alignItems="flex-start" w="full" spacing={4}>
          <FormControl id="email">
            <FormLabel fontWeight="semibold">Email address</FormLabel>
            <Input
              name="email"
              type="email"
              autoComplete="email"
              value={formState.email}
              onChange={handleChange("email")}
              required
              placeholder="john.doe@gmail.com"
              size="lg"
            />
          </FormControl>
          <Flex alignItems="center">
            <Button
              type="submit"
              onClick={submitForgotPass}
              variant="solid"
              bg="#5072ec"
              colorScheme="blue"
              rounded="xl"
              height="100%"
              isLoading={loading}
              loadingText={"Checking credentials..."}
              py="1rem"
              px="110px"
            >
              Continue
            </Button>
          </Flex>
        </VStack>
        <HStack spacing={1}>
          <Text>Return to </Text>
          <ChakraLink as={Link} to="./login" color="blue.500">
            Login
          </ChakraLink>
        </HStack>
      </VStack>
    </form>
  );
};

const ForgotPassCodeReset = ({ navigate, handleChange, formState }) => {
  const [r2login, setR2Login] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();
  const inputRef = useRef(null);

  const submitForgotPass = async () => {
    setLoading(true);
    try {
      const msg = await Auth.forgotPasswordSubmit(
        formState.email.toLowerCase(),
        formState.resetCode,
        formState.newPass
      );

      if (msg === "SUCCESS") {
        toast({
          title: "Password Reset Successful.",
          description:
            "We've reset your password for you. You will be redirected back to login shortly.",
          status: "success",
          duration: 7000,
          isClosable: true,
        });

        setTimeout(() => {
          setR2Login(true);
        }, 7000);
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  if (r2login) {
    return <Redirect to={`${PORTAL_ROOT}/login`} />;
  }
  return (
    <form onSubmit={submitForgotPass}>
      <VStack mt="20" alignItems="flex-start" spacing={12}>
        <VStack alignItems="flex-start" spacing={1}>
          <H1>Enter recovery code</H1>
          <Text fontSize="xl" color="gray.400">
            A recovery code was sent to your email or phone, please enter the
            code below.
          </Text>
        </VStack>
        <VStack alignItems="flex-start" w="full" spacing={4}>
          <FormControl id="resetCode">
            <FormLabel fontWeight="semibold">Reset Code</FormLabel>
            <Input
              name="resetCode"
              type="text"
              value={formState.resetCode}
              onChange={handleChange("resetCode")}
              required
              placeholder="1234567890"
              size="lg"
            />
          </FormControl>
          <FormControl id="newPass">
            <FormLabel fontWeight="semibold">New Password</FormLabel>
            <InputGroup>
              <Input
                name="newPass"
                type={isOpen ? "text" : "password"}
                placeholder="Password"
                value={formState.newPass}
                onChange={handleChange("newPass")}
                size="lg"
                required
              />
              <InputRightElement top="1">
                <IconButton
                  bg="transparent !important"
                  variant="ghost"
                  aria-label={isOpen ? "Mask password" : "Reveal password"}
                  icon={isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />}
                  onClick={onClickReveal}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Flex alignItems="center">
            <Button
              type="submit"
              onClick={submitForgotPass}
              variant="solid"
              bg="#5072ec"
              colorScheme="blue"
              rounded="xl"
              height="100%"
              isLoading={loading}
              loadingText={"Verifying code..."}
              py="1rem"
              px="110px"
            >
              Submit
            </Button>
          </Flex>
        </VStack>
        <HStack spacing={1}>
          <Text>Return to </Text>
          <ChakraLink as={Link} to="./login" color="blue.500">
            Login
          </ChakraLink>
        </HStack>
      </VStack>
    </form>
  );
};
