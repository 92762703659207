import React, { useEffect } from "react";

const useLocalStorageSync = (key) => {
  const [value, setValue] = React.useState(loadFromLocalStorage);

  function loadFromLocalStorage() {
    try {
      const serializedValue = localStorage.getItem(key);
      if (serializedValue === null) {
        return undefined;
      }
      return JSON.parse(serializedValue);
    } catch (err) {
      return undefined;
    }
  }

  function setItem(value) {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
      setValue(value);
    } catch (err) {
      console.log("LocalStorage set item failed.", err);
    }
  }

  useEffect(() => {
    if (key) {
      setValue(loadFromLocalStorage);
    }
  }, [key]);

  return {
    value,
    setItem,
  };
};

export default useLocalStorageSync;
