import React from "react";
import {
  Box,
  VStack,
  Grid,
  GridItem,
  Text,
  Link,
  Flex,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

/* local imports */
import H1 from "common/H1";

/* asset imports */
import landscapeBg from "assets/gift/relaxation-landscape-bg.jpg";
import ebbiRelax from "assets/gift/EbbiRelax@300.png";
import appstore from "assets/confirmation/appstore.png";
import check from "assets/home/check.svg";

const RedeemComplete = ({ formState }) => {
  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <VStack mt="20" alignItems="flex-start" spacing={4}>
          <H1>Welcome, Flowly Hero!</H1>
          {!!formState?.redeemSuccess && (
            <Flex alignItems="flex-start">
              <Image mt="1" mr="2" src={check} />
              <Text fontSize="lg" color="gray.400">
                <b>{formState.redeemSuccess}</b>
                <br />
              </Text>
            </Flex>
          )}
          <Text fontSize="lg" color="gray.400">
            {/* <b>Congratulations on becoming a Flowly Member!</b>
            <br />
            <br /> */}
            You can start exploring Flowly now! Your Intro Worlds can be
            experienced in VR or in 360 mode on your phone.
          </Text>
        </VStack>
        <Link
          href="https://apps.apple.com/us/app/flowly-relaxation-training/id1485955236"
          isExternal
        >
          <Image
            display="unset"
            src={appstore}
            mt="32"
            ml="-4"
            maxW="12rem"
            alt="appstore-link"
          />
        </Link>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
        // pt="16"
        // pl="5vw"
        // pr="10vw"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
          // display="grid"
          // placeItems="center"
          // bottom="0"
          // left="38%"
          // pb="48"
        >
          <Image src={ebbiRelax} maxW="16rem" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemComplete;
