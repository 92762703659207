import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { groupBy } from "lodash";
import firebase from "firebase/app";
import "firebase/analytics";

/* asset imports */
import vr from "assets/home/vr.png";
import support from "assets/home/support.png";
import analytics from "assets/home/analytics.png";
import screenshots from "assets/home/screenshots.png";
import check from "assets/home/hi_check-badge.svg";
import xcircle from "assets/home/x-circle.svg";
import coach from "assets/home/coach.png";
import community from "assets/home/community.png";

/* local imports */
import Button from "common/Button";
import Card from "common/Card";
import H2 from "common/H2";
import H3 from "common/H3";
import Spinner from "common/Spinner";
import { device } from "utils/device";
import useSubscriptionProducts from "hooks/useSubscriptionProducts";
import {
  SUBSCRIPTION_PLAN_TYPES,
  SUBSCRIPTION_PRODUCT_TYPES,
} from "./constants";
import {
  CardContent,
  Center,
  Feature,
  Features,
  HomeStyle,
  Tag,
  WhiteContainer,
} from "./components";
import { get } from "lodash";

function Pricing() {
  const [billing, setBilling] = useState("MONTHLY");

  const { data: products, isLoading } = useSubscriptionProducts({
    variables: {
      filter: {
        and: [
          {
            active: {
              eq: true,
            },
            subscriptionMethod: {
              eq: "STRIPE",
            },
            duration: {
              eq: 1,
            },
            subscriptionProductType: {
              eq: SUBSCRIPTION_PRODUCT_TYPES.STANDARD,
            },
            or: [
              {
                subscriptionPlan: {
                  eq: SUBSCRIPTION_PLAN_TYPES.PRO,
                },
              },
              {
                subscriptionPlan: {
                  eq: SUBSCRIPTION_PLAN_TYPES.COMMUNITY,
                },
              },
            ],
          },
        ],
      },
    },
  });

  const productsToDisplay = useMemo(() => {
    const activeOffers = products.sort((a, b) => a.price - b.price);

    if (activeOffers.length === 0) {
      return [];
    }

    return {
      GROUP_PLANS: activeOffers.filter((plan) => plan.numDependents > 0),
      COMMUNITY: groupBy(
        groupBy(activeOffers, "subscriptionPlan")[
          SUBSCRIPTION_PLAN_TYPES.COMMUNITY
        ],
        "subscriptionType"
      ),
      PRO: groupBy(
        groupBy(activeOffers, "subscriptionPlan")[
          SUBSCRIPTION_PLAN_TYPES.PRO
        ].filter((plan) => plan.numDependents === null),
        "subscriptionType"
      ),
    };
  }, [products]);

  useEffect(() => {
    firebase.analytics().logEvent("home_visited");
  }, []);

  const currentCommunityPlan = get(productsToDisplay, [
    "COMMUNITY",
    billing,
    0,
  ]);
  const currentProPlan = get(productsToDisplay, ["PRO", billing, 0]);

  return (
    <>
      <HomeStyle>
        <HeroStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 className="title">
              Change your relationship to your pain, anxiety, and sleep
            </h1>
          </div>
          {/* Switch */}
          <Sticky>
            <Container maxWidth="2xl">
              <SwitchStyle>
                <div className="switch-wrapper">
                  <input
                    id="monthly"
                    type="radio"
                    name="switch"
                    checked={billing === "MONTHLY"}
                    onChange={() => setBilling("MONTHLY")}
                  />
                  <input
                    id="yearly"
                    type="radio"
                    name="switch"
                    checked={billing === "YEARLY"}
                    onChange={() => setBilling("YEARLY")}
                  />
                  <label htmlFor="monthly">Monthly</label>
                  <label htmlFor="yearly">Yearly</label>
                  <span className="highlighter"></span>
                </div>
              </SwitchStyle>
            </Container>
          </Sticky>
          {isLoading ? (
            <Container maxWidth="2xl">
              <Spinner />
            </Container>
          ) : (
            <Container
              maxWidth={currentCommunityPlan && currentProPlan ? "2xl" : "md"}
            >
              <Grid
                templateColumns={`${(currentCommunityPlan && "1fr") ?? ""} ${
                  (currentProPlan && "1.4fr") ?? ""
                }`}
                templateAreas={
                  currentCommunityPlan && currentProPlan && "'community pro'"
                }
              >
                {!!currentCommunityPlan && (
                  <GridItem area="community">
                    <FeatureCard
                      borderRadius="1rem"
                      mt="2rem"
                      pt="2rem"
                      pb="1.5rem"
                      pl="1.5rem"
                      pr="3.5rem"
                      mr="0.2rem"
                      ml="0.2rem"
                    >
                      <CardContent>
                        <h1 className="header">Join the Community</h1>

                        <span className="priceContainer">
                          {!!currentCommunityPlan.initialPrice && (
                            <h2 className="prev">
                              ${currentCommunityPlan.initialPrice}
                            </h2>
                          )}
                          <h2 style={{ marginTop: 0, marginBottom: 0 }}>
                            ${currentCommunityPlan.price || 0}
                          </h2>
                          <p style={{ color: "#9CA3AF", marginLeft: "4px" }}>
                            /
                            {currentCommunityPlan.timeUnits.toLowerCase() || ""}
                          </p>
                        </span>
                        <div className="checkmarks">
                          <h3 className="subheader">Included Hardware</h3>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            Member Necklace
                          </div>
                          <div className="unchecked">
                            <img src={xcircle} alt="X Icon" />
                            VR + Biofeedback Pro Kit
                          </div>
                          <hr className="lightDivider" />
                          <div className="checkmarks">
                            <h3 className="subheader">Access to Events</h3>
                            <div className="check">
                              <img src={check} alt="Checkmark" />
                              Health Seminars
                            </div>
                            <div className="unchecked">
                              <img src={xcircle} alt="X Icon" />
                              Expert-led workshops
                            </div>
                          </div>
                          <hr className="lightDivider" />
                          <div className="checkmarks">
                            <h3 className="subheader">
                              Tools for your nervous system
                            </h3>
                            <div className="check">
                              <img src={check} alt="Checkmark" />
                              Breathers
                            </div>
                            <div className="unchecked">
                              <img src={xcircle} alt="X Icon" />
                              VR Biofeedback Sessions
                            </div>
                          </div>
                          <hr className="lightDivider" />
                          <div className="checkmarks">
                            <h3 className="subheader">Flowly Goods</h3>
                            <div className="check">
                              <img src={check} alt="Checkmark" />
                              Exclusive 15% of all goods
                            </div>
                          </div>
                        </div>
                      </CardContent>
                      <div style={{ marginTop: "2rem" }}>
                        <Button
                          outline
                          to={{
                            pathname: `/checkout/${currentCommunityPlan.subscriptionType}/${currentCommunityPlan.id}`,
                            state: currentCommunityPlan,
                          }}
                        >
                          Continue to Checkout
                        </Button>
                      </div>
                    </FeatureCard>
                  </GridItem>
                )}
                {!!currentProPlan && (
                  <GridItem area="pro" ml="-10px" mr="-10px">
                    <Card>
                      <CardContent>
                        <div style={{ marginBottom: "1rem" }}>
                          <Tag>RECOMMENDED</Tag>
                        </div>
                        <h1 className="header">
                          {currentProPlan.title} Membership
                        </h1>
                        <span className="priceContainer">
                          {!!currentProPlan.initialPrice && (
                            <h2 className="prev">
                              ${currentProPlan.initialPrice}
                            </h2>
                          )}
                          <h2 style={{ marginTop: 0, marginBottom: 0 }}>
                            ${currentProPlan.price || 0}
                          </h2>
                          <p style={{ color: "#9CA3AF", marginLeft: "4px" }}>
                            /{currentProPlan.timeUnits.toLowerCase() || ""}
                          </p>
                        </span>
                        <div className="checkmarks">
                          <h3 className="subheader">Included Hardware</h3>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            Member Necklace
                          </div>
                          <p className="featureDescription">
                            A classic stainless steel tag necklace that serves
                            as your membership badge
                          </p>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            VR + Biofeedback Pro Kit
                          </div>
                          <p className="featureDescription">
                            Includes a Virtual Reality headset and Heart Reate
                            Sensor that enables relaxation experiences that help you
                            balance your nervous system
                          </p>
                          <hr className="lightDivider" />
                        </div>
                        <div className="checkmarks">
                          <h3 className="subheader">Access to Events</h3>
                          <p className="featureDescription">
                            Daily support group meetings focused on different
                            topics
                          </p>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            Health Seminars
                          </div>
                          <p className="featureDescription">
                            Focused on science-backed health techniques,
                            learnings, and tools
                          </p>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            Expert-led workshops
                          </div>
                          <p className="featureDescription">
                            Physician and therapist-led workshops where you can
                            directly engage with the expert
                          </p>
                        </div>
                        <hr className="lightDivider" />
                        <div className="checkmarks">
                          <h3 className="subheader">
                            Tools for your nervous system
                          </h3>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            Breathers
                          </div>
                          <p className="featureDescription">
                            Short 2D breathing exercises
                          </p>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            VR Biofeedback Sessions
                          </div>
                          <p className="featureDescription">
                            Using your Pro Kit, enter relaxing VR worlds wher
                            eyou will learn to control your heart rate and
                            nervous system to feel better each day
                          </p>
                        </div>
                        <hr className="lightDivider" />
                        <div className="checkmarks">
                          <h3 className="subheader">Flowly Goods</h3>
                          <div className="check">
                            <img src={check} alt="Checkmark" />
                            Exclusive 30% of all goods
                          </div>
                          <p className="featureDescription">
                            Including opportunities to earn free swag
                          </p>
                        </div>
                      </CardContent>
                      <div style={{ marginTop: "2rem" }}>
                        <Button
                          to={{
                            pathname: `/checkout/${currentProPlan.subscriptionType}/${currentProPlan.id}`,
                            state: currentProPlan,
                          }}
                        >
                          Continue to Checkout
                        </Button>
                      </div>
                    </Card>
                  </GridItem>
                )}
              </Grid>
            </Container>
          )}
        </HeroStyle>

        <WhiteContainer>
          <Features>
            <Feature>
              <img src={vr} alt="Virtual Reality" />
              <p>
                designed to relax and help you better manage your nervous system
              </p>
            </Feature>
            <Feature className="center">
              <img src={analytics} alt="Analytics" />
              <p>actually see how your body is doing in your data portal</p>
            </Feature>
            <Feature>
              <img src={support} alt="Support" />
              <p>in our in-app community and from our health coach</p>
            </Feature>
          </Features>

          <Center>
            <div className="image">
              <img src={screenshots} alt="App Screenshots" />
            </div>

            <div className="content">
              <H2>flowly app</H2>
              <p>
                {/* copy here <br /> */}
                <br />
              </p>
              <div className="item">
                <H3>Experience relaxation</H3>
                <p>
                  in immersive Virtual Reality worlds designed and tested for
                  optimal comfort and relaxation
                </p>
              </div>
              <div className="item">
                <H3>Balance your nervous system</H3>
                <p>
                  by re-learning stress responses through biofeedback methods to influence various body metrics including HR, HRV, and respiration in every session.
                </p>
              </div>
              <div className="item">
                <H3>Track your progress</H3>
                <p>
                  in a data portal that tracks your biometrics, streaks, and
                  journal notes.
                </p>
              </div>
            </div>
          </Center>

          <div className="support-message">
            <H2>We Support You</H2>
            <p>
              Our Flowly community is growing every day and we want to give you the access to tools, processes, methods, and support you need to feel supported on your health journey
            </p>
          </div>

          <div className="app-features">
            <div className="left">
              <H3>Access our Health Coach</H3>
              <p>
                Call or Text our Flowly Health coach to get help setting up,
                receive advice about how to best integrate Flowly into a ritual,
                and more.
              </p>
              <div className="image">
                <img src={coach} alt="Coach"></img>
              </div>
            </div>
            <div className="right">
              <H3>Share in our Community</H3>
              <p>Become part of our safe, restricted in-app community.</p>
              <div className="image">
                <img src={community} alt="Community"></img>
              </div>
            </div>
          </div>
        </WhiteContainer>
      </HomeStyle>
    </>
  );
}

export default Pricing;

const Sticky = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 1rem;
  z-index: 1000;
`;

const SwitchStyle = styled.div`
  .switch-wrapper {
    position: sticky;
    top: 2rem;
    display: inline-flex;
    padding: 4px;
    border: 1px solid #dde1e9;
    margin-bottom: 40px;
    border-radius: 30px;
    background: white;
  }

  .switch-wrapper [type="radio"] {
    position: absolute;
    left: -9999px;
  }

  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"],
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"] {
    font-weight: 700;
    color: white;
  }

  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"]:hover,
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"]:hover {
    background: transparent;
  }

  .switch-wrapper
    [type="radio"]:checked#monthly
    + label[for="yearly"]
    ~ .highlighter {
    transform: none;
  }

  .switch-wrapper
    [type="radio"]:checked#yearly
    + label[for="monthly"]
    ~ .highlighter {
    transform: translateX(100%);
  }

  .switch-wrapper label {
    font-weight: 700;
    font-size: 18px;
    z-index: 1;
    min-width: 128px;
    line-height: 32px;
    cursor: pointer;
    border-radius: 30px;
    transition: color 0.25s ease-in-out;
    text-align: center;
  }

  .switch-wrapper label:hover {
    background: #f3f4f6;
  }

  .switch-wrapper .highlighter {
    position: absolute;
    top: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 30px;
    background: linear-gradient(96.72deg, #8adbff -38.81%, #8ea4e8 119.98%);
    box-shadow: 0px 2px 10px 0.2px rgba(0, 44, 129, 0.2);

    transition: transform 0.25s ease-in-out;
  }
`;
const HeroStyle = styled.header`
  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 10px;
  padding: 0 3vw;
  @media ${device.tablet} {
    padding: 0 10vw;
  }
  margin-bottom: 180px;
  .image {
    grid-column: span 12;
    img {
      width: 100%;
    }

    @media ${device.laptopL} {
      grid-column: span 6;
    }
  }
  .card {
    grid-column: span 12;

    @media ${device.laptopL} {
      // padding-left: 5vw;
      grid-column: span 6;
    }
  }

  .title {
    font-size: 2.5rem;
    line-height: 120%;
    font-family: Tommy Soft;
    font-weight: 700;
    color: #5072ec;
    text-align: center;
    max-width: 1024px;

    @media ${device.laptopL} {
      font-size: 3.2rem;
      line-height: 3.5rem;
    }
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) =>
    breakpoints[props.maxWidth] ? breakpoints[props.maxWidth] : breakpoints.md};
`;

const FeatureCard = styled.div`
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  min-width: ${(props) => (props.minW ? props.minW : 0)};
  max-width: ${(props) => (props.maxW ? props.maxW : "100%")};
  
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 0)};
  
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};
  
  padding-top: ${(props) => (props.pt ? props.pt : 0)};
  padding-bottom: ${(props) => (props.pb ? props.pb : 0)};
  padding-left: ${(props) => (props.pl ? props.pl : 0)};
  padding-right: ${(props) => (props.pr ? props.pr : 0)};

  
  @media ${device.laptopL} {
    margin-right: -2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-areas: ${(props) =>
    props.templateAreas ? props.templateAreas : ""};
  grid-template-columns: ${(props) =>
    props.templateColumns ? props.templateColumns : ""};
  grid-gap: 2em;

  @media ${device.laptopL} {
    grid-gap: 0;
  }
`;

const GridItem = styled.div`
  grid-column: span 2;
  grid-column-start: ${(props) => (props.colStart ? props.colStart : 0)};
  grid-column-end: ${(props) => (props.colEnd ? props.colEnd : 0)};
  grid-row-start: ${(props) => (props.area === "pro" ? 1 : "auto")};
  grid-row-end: ${(props) => (props.rowEnd ? props.rowEnd : 0)};

  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};

  @media ${device.laptopL} {
    grid-column: auto;
    grid-row-start: auto;
  }
`;

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};
