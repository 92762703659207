import React, { useEffect, useRef, useState } from "react";
import { omit, get, pick } from "lodash";
import { HStack, VStack, Divider, Text, Box } from "@chakra-ui/layout";
import { Button, IconButton } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/number-input";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Checkbox } from "@chakra-ui/checkbox";
import { Select } from "@chakra-ui/select";
import { Spinner } from "@chakra-ui/spinner";
import Icon from "@chakra-ui/icon";
import { useFieldArray, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

/* icon imports */
// import { HiOutlineSelector } from "react-icons/hi";
import { BiChevronDown } from "react-icons/bi";
import {
  MdOutlinePhone,
  MdEdit,
  MdOutlineClose,
  MdAddCircleOutline,
} from "react-icons/md";
import { BsPatchCheckFill } from "react-icons/bs";

/* local imports */
import { USER_TYPES } from "../constants";
import {
  createOrder,
  createShipment,
  getProducts,
  getStores,
  getUserDetails,
  updateUser,
} from "../services";

import UserSearch from "common/UserSearch";
import useFetchOnce from "hooks/useFetchOnce";

const CreateShipmentModal = (props) => {
  const { isOpen, onClose } = props;

  const userRef = useRef();
  const toast = useToast();

  const [selectedUser, setSelectedUser] = useState(null);
  const [userType, setUserType] = useState(USER_TYPES.EXISTING);
  const [customFields, setCustomFields] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      bill: { products: [{ quantity: 1, sku: "0000000000" }] },
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bill.products",
  });

  const [{ value: products, error: productsErr, loading: productsLoading }] =
    useFetchOnce(getProducts, [], [], { getPath: "data.products" });
  const [{ value: stores, error: storesErr, loading: storesLoading }] =
    useFetchOnce(getStores, [], [], { getPath: "data" });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (data) =>
        (userRef.current = {
          ...data.attributes,
          username: data.username,
        })
    );
  }, []);

  const [
    { value: userDetails, error: userDetailsErr, loading: userDetailsLoading },
  ] = useFetchOnce(
    () => getUserDetails(selectedUser?.id),
    [selectedUser],
    {},
    {
      getPath: "data.getUser",
      fetchCondition: !!selectedUser?.id,
    }
  );

  const handleChangeUserType = (type) => {
    setUserType(type);
    handleResetForm({ user: true, cf: true, clearAddress: true });
  };

  const handleResetForm = (elements) => {
    let el = elements;
    if (!el)
      el = { user: true, cf: true, clearAddress: true, formDefaults: true };

    const { user, cf, formDefaults, clearAddress } = el;

    if (user) setSelectedUser(null);
    if (cf) setCustomFields([]);
    if (clearAddress)
      reset({
        ...getValues(),
        address1: "",
        address2: "",
        city: "",
        zip: "",
        state: "",
        country: "United States",
      });
    if (formDefaults)
      reset({ bill: { products: [{ quantity: 1, sku: "0000000000" }] } });
  };
  const handleCustomAddress = () => {
    setCustomFields((cf) => [...cf, "ADDRESS"]);
    reset({ ...getValues(), ...userDetails?.address });
  };

  const handleClose = () => {
    handleResetForm();
    onClose();
  };

  const onSubmit = async (data) => {
    clearErrors("userId");

    // Handle selecting the Store
    let store = null;
    if (stores.length) {
      if (data.bill.products.length === 1) {
        if (data.bill.products[0].sku === "0000000000") {
          // If sending out a Flowly Kit ("0000000000") -> use Flowly Store
          store = stores.find(
            (s) =>
              s.storeName.includes("Flowly") &&
              s.marketplaceName.includes("ShipStation")
          );
        } else {
          // If sending out anything else -> use Flowly Ad Hoc Store
          store = stores.find((s) => s.storeName.includes("Ad Hoc"));
        }
      } else {
        // If sending out multiple items, user gets to make this decision
        store = stores.find((s) => s.storeId === +data.store);
      }
    } else {
      store = { storeName: "Flowly Ad Hoc", storeId: 463176 }; // hardcoded the Flowly Ad Hoc StoreId as a default fallback. Not optimal, but better than failing due to an API fetch failing.
    }

    // Handle sent data by selected userType
    if (userType === USER_TYPES.EXISTING) {
      if (!selectedUser)
        return setError("userId", {
          type: "manual",
          message:
            "Search Hero by Email or Username, or create custom Receipient",
        });

      if (!userDetails?.address && !customFields.includes("ADDRESS"))
        return setError("userId", {
          type: "manual",
          message:
            "This user doesn't have an address on record. Please update their profile and try again, or create the order with a custom recipient.",
        });

      try {
        const address = customFields.includes("ADDRESS")
          ? { ...data }
          : { ...userDetails.address };

        // const order = await createOrder({
        //   ...address,
        //   ...data.bill,
        //   name: `${selectedUser.firstName} ${
        //     selectedUser.lastName ?? ""
        //   }`.trim(),
        //   phone: null,
        //   products: data.bill.products.map((prod) => ({
        //     ...prod,
        //     ...products.find((p) => p.sku === prod.sku),
        //   })),
        //   storeId: store.storeId,
        //   orderNumber: store.storeName.includes("Ad Hoc")
        //   ? `FL2-${userRef.current?.username}`
        //     : userRef.current?.username,
        //   });
        console.log({ ...data.bill, ...address });

        const shipment = await createShipment({
          userId: selectedUser.id,
          createdBy: userRef.current?.username,
          recipientAddress: omit(
            {
              ...address,
              name: data.name,
            },
            "__typename"
          ),
          recipientEmail: selectedUser.email,
          items: data.bill.products.map((prod) => {
            const { name, sku, weightOz } = products.find(
              (p) => p.sku === prod.sku
            );
            return {
              // ...prod,
              ...{ name, sku, weight: weightOz ?? 0 },
            };
          }),
          storeId: store.storeId,
          // shipstationOrderNumber: store.storeName.includes("Ad Hoc")
          //   ? `FL2-${userRef.current?.username}`
          //   : userRef.current?.username,
          status: "WAITING",
          // there may be a need to move this within the Shipment view, like a "staging" shipment and then a "confirmed" shipment
          shouldCreateOrder: true,
        });

        console.log(shipment.data);

        if (data.saveToProfile) {
          updateUser(selectedUser.id, {
            address: pick(address, [
              "address1",
              "address2",
              "city",
              "state",
              "zip",
              "country",
            ]),
          });
        }

        toast({
          title: "Shipment created.",
          description: `We've created a shipment for ${
            selectedUser.firstName
          } ${selectedUser.lastName ?? ""}.`,
          status: "success",
          duration: 7500,
          isClosable: true,
        });
        handleClose();
        return;
      } catch (err) {
        console.log("Something went wrong during order creation:", err);
        alert("Something went wrong during order creation:", err);
        return;
      }
    }

    try {
      //   const order = await createOrder({
      //     ...data,
      //     products: data.bill.products.map((prod) => ({
      //       ...prod,
      //       ...products.find((p) => p.sku === prod.sku),
      //     })),
      //     storeId: store.storeId,
      //     orderNumber: store.storeName.includes("Ad Hoc")
      //       ? `FL2-${userRef.current?.username}`
      //       : userRef.current?.username,
      //   });
      //   console.log(order.data);
      console.log(data);

      const shipment = await createShipment({
        // userId: selectedUser.id,
        createdBy: userRef.current?.username,
        recipientAddress: omit(
          {
            ...pick(data, [
              "address1",
              "address2",
              "city",
              "state",
              "zip",
              "country",
            ]),
            name: data.name,
          },
          "__typename"
        ),
        // recipientEmail: selectedUser.email, TODO: Add email field
        items: data.bill.products.map((prod) => {
          const { name, sku, weightOz } = products.find(
            (p) => p.sku === prod.sku
          );
          return {
            ...{ name, sku, weight: weightOz ?? 0 },
          };
        }),
        storeId: store.storeId,
        shipstationOrderNumber: store.storeName.includes("Ad Hoc")
          ? `FL2-${userRef.current?.username}`
          : userRef.current?.username,
        status: "WAITING",
        // there may be a need to move this within the Shipment view, like a "staging" shipment and then a "confirmed" shipment
        shouldCreateOrder: true,
      });

      // const shipment = await createShipment({
      //   id: order.data.orderId,
      //   createdBy: userRef.current?.username,
      //   status: "WAITING",
      // });

      console.log(shipment.data);

      toast({
        title: "Shipment created.",
        description: `We've created a shipment for ${data.name}.`,
        status: "success",
        duration: 7500,
        isClosable: true,
      });
      handleClose();
      return;
    } catch (err) {
      console.log(err);
      alert("Something went wrong during order creation:", err);
      return;
    }
  };

  // console.log(`fields`, fields);
  // console.log(`userDetails`, userDetails);
  // console.log(`selectedUser`, selectedUser);
  // console.log(`stores`, stores);
  // console.log(`selectedProduct`, selectedProduct);
  // console.log(`products`, products);
  // console.log(`currentUser`, userRef);
  // console.log({ selectedUser, userDetails });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="3xl"
      blockScrollOnMount={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Shipment</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <ModalBody>
            <VStack alignItems="flex-start" spacing={8} my={4}>
              <VStack alignItems="flex-start" spacing={4} w="full">
                <HStack justifyContent="space-between" w="full">
                  <Text fontSize="xl" fontWeight="bold">
                    Personal Information
                  </Text>
                  <RadioGroup value={userType} onChange={handleChangeUserType}>
                    <HStack spacing={4}>
                      <Radio isInvalid={false} value={USER_TYPES.EXISTING}>
                        Existing Hero
                      </Radio>
                      <Radio isInvalid={false} value={USER_TYPES.CUSTOM}>
                        Custom Recipient
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </HStack>
                {userType === USER_TYPES.EXISTING && (
                  <>
                    <FormControl
                      id="userId"
                      isInvalid={errors?.userId}
                      isRequired
                    >
                      <HStack justifyContent="space-between">
                        <FormLabel mb={1} fontWeight="semibold">
                          Hero
                        </FormLabel>
                      </HStack>
                      <UserSearch
                        setSelectedUser={(user) => {
                          handleResetForm({ user: true, cf: true });
                          setSelectedUser(user);
                          clearErrors("userId");
                        }}
                        size="full"
                      />
                      {errors?.userId ? (
                        <FormErrorMessage>
                          {errors.userId.message}
                        </FormErrorMessage>
                      ) : !selectedUser ? (
                        <FormHelperText>
                          We&apos;ll automatically fill in all the details from
                          the user&apos;s account
                        </FormHelperText>
                      ) : userDetailsLoading || !userRef.current?.username ? (
                        <FormHelperText>
                          Fetching user details...
                        </FormHelperText>
                      ) : userDetails ? (
                        <FormHelperText display="flex" alignItems="center">
                          <Icon
                            as={BsPatchCheckFill}
                            color="green.400"
                            mb="px"
                            mr="1"
                          />
                          All done, ready to order!
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    {!!selectedUser &&
                      !!userDetails?.id &&
                      !customFields.includes("ADDRESS") && (
                        <UserAddressSummary
                          address={userDetails?.address}
                          handleCustomAddress={handleCustomAddress}
                        />
                      )}
                    {!!selectedUser && customFields.includes("ADDRESS") && (
                      <AddressFields
                        register={register}
                        errors={errors}
                        currentView={userType}
                      />
                    )}
                  </>
                )}
                {userType === USER_TYPES.CUSTOM && (
                  <>
                    <HStack spacing={4} alignItems="flex-start">
                      <FormControl
                        id="name"
                        isInvalid={errors?.name}
                        isRequired
                      >
                        <FormLabel mb={1} fontWeight="semibold">
                          Name
                        </FormLabel>
                        <Input
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Name cannot be left empty",
                            },
                          })}
                          type="text"
                          placeholder="John Doe"
                        />
                        {errors?.name && (
                          <FormErrorMessage>
                            {errors.name.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl id="phone">
                        <FormLabel mb={1} fontWeight="semibold">
                          Phone Number
                        </FormLabel>
                        <InputGroup>
                          <Input
                            type="tel"
                            {...register("phone")}
                            placeholder="(050) 123 4567"
                          />
                          <InputLeftElement>
                            <Icon
                              as={MdOutlinePhone}
                              color="gray.400"
                              fontSize="lg"
                            />
                          </InputLeftElement>
                        </InputGroup>
                      </FormControl>
                    </HStack>
                    <AddressFields register={register} errors={errors} />
                  </>
                )}
              </VStack>

              <VStack alignItems="flex-start" spacing={4} w="full">
                <Text fontSize="xl" fontWeight="bold">
                  Bill Details
                </Text>
                <VStack alignItems="flex-start" spacing={2} w="full">
                  {fields.map((prod, idx) => (
                    <ProductFields
                      key={prod.id}
                      idx={idx}
                      name={`bill.products[${idx}]`}
                      removeItem={() => remove(idx)}
                      register={register}
                      products={products}
                      productsLoading={productsLoading}
                      productsErr={productsErr}
                    />
                  ))}
                  <Button
                    leftIcon={<MdAddCircleOutline />}
                    pr={4}
                    size="sm"
                    variant="ghost"
                    onClick={() => append({ quantity: 1, sku: "0000000000" })}
                  >
                    Add Product
                  </Button>
                </VStack>
                {fields.length > 1 && (
                  <Box>
                    <FormControl id="store">
                      <FormLabel mb={1} fontWeight="semibold">
                        Store
                      </FormLabel>
                      <Select
                        {...register("store")}
                        icon={storesLoading ? <Spinner /> : <BiChevronDown />}
                        required
                        rounded="md"
                        defaultValue={
                          stores.length
                            ? stores.find((s) => s.storeName.includes("Ad Hoc"))
                                .storeId
                            : 463176
                        }
                      >
                        {!storesLoading && !storesErr && Array.isArray(stores)
                          ? stores.map((st) => (
                              <option key={st.storeId} value={st.storeId}>
                                {st.storeName} — {st.marketplaceName}
                              </option>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </VStack>
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="linkedin"
              rounded="md"
              type="submit"
              loadingText="Creating your order..."
            >
              Create Order
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default CreateShipmentModal;

const ProductFields = ({
  idx,
  name,
  register,
  errors,
  products,
  productsLoading,
  productsErr,
  removeItem,
}) => (
  <HStack w="full" spacing={6} align="flex-start">
    <FormControl id={`${name}.sku`}>
      {idx === 0 && (
        <FormLabel mb={1} fontWeight="semibold">
          Product
        </FormLabel>
      )}
      <Select
        {...register(`${name}.sku`)}
        icon={productsLoading ? <Spinner /> : <BiChevronDown />}
        required
        rounded="md"
      >
        {!productsLoading && !productsErr && Array.isArray(products)
          ? products.map((prod) => (
              <option key={prod.sku} value={prod.sku}>
                {prod.sku} — {prod.name}
              </option>
            ))
          : null}
      </Select>
    </FormControl>
    <FormControl
      id={`${name}.quantity`}
      isInvalid={errors?.bill?.quantity}
      isRequired
      flexShrink={2}
    >
      {idx === 0 && (
        <FormLabel mb={1} fontWeight="semibold">
          Quantity
        </FormLabel>
      )}
      <NumberInput min={1}>
        <NumberInputField
          {...register(`${name}.quantity`, {
            required: {
              value: true,
              message: "This field is required",
            },
            valueAsNumber: true,
          })}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {get(errors, `${name}.quantity`) && (
        <FormErrorMessage>
          {get(errors, `${name}.quantity.message`)}
        </FormErrorMessage>
      )}
    </FormControl>
    <IconButton
      variant="ghost"
      alignSelf="flex-end"
      icon={<MdOutlineClose />}
      onClick={removeItem}
      isDisabled={idx === 0}
    />
  </HStack>
);

const AddressFields = ({ register, errors, currentView }) => (
  <>
    <FormControl id="address">
      <FormLabel mb={1} fontWeight="semibold">
        Address
      </FormLabel>
      <Input
        {...register("address1")}
        borderBottomRadius="none"
        placeholder="Address Line 1"
      />
      <Input
        {...register("address2")}
        mt="-px"
        borderTopRadius="none"
        placeholder="Address Line 2"
      />
    </FormControl>
    <HStack spacing={4} alignItems="flex-start">
      <FormControl id="city" isInvalid={errors?.city} isRequired>
        <FormLabel mb={1} fontWeight="semibold">
          City
        </FormLabel>
        <Input
          {...register("city", {
            required: {
              value: true,
              message: "City cannot be left empty",
            },
          })}
          placeholder="City"
        />
        {errors?.city && (
          <FormErrorMessage>{errors.city.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl id="state" isInvalid={errors?.state} isRequired>
        <FormLabel mb={1} fontWeight="semibold">
          State
        </FormLabel>
        <Input
          {...register("state", {
            required: {
              value: true,
              message: "State cannot be left empty",
            },
          })}
          placeholder="State"
        />
        {errors?.state && (
          <FormErrorMessage>{errors.state.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl id="zip" isInvalid={errors?.zip} isRequired>
        <FormLabel mb={1} fontWeight="semibold">
          Zip Code
        </FormLabel>
        <Input
          {...register("zip", {
            required: {
              value: true,
              message: "Zip code cannot be left empty",
            },
            minLength: {
              value: 5,
              message: "Zip code must be at least 5 digits long",
            },
          })}
          placeholder="XXXXX"
        />
        {errors?.zip && (
          <FormErrorMessage>{errors.zip.message}</FormErrorMessage>
        )}
      </FormControl>
    </HStack>
    <HStack
      w={currentView === USER_TYPES.EXISTING ? "75%" : "50%"}
      spacing={4}
      align="flex-end"
    >
      <FormControl id="country">
        <FormLabel mb={1} fontWeight="semibold">
          Country
        </FormLabel>
        <Select
          {...register("country", { required: true })}
          defaultValue="United States"
        >
          <option value="United States">United States</option>
        </Select>
      </FormControl>
      {currentView === USER_TYPES.EXISTING && (
        <FormControl id="saveToProfile" pb={2}>
          <Checkbox {...register("saveToProfile")}>Update Profile?</Checkbox>
        </FormControl>
      )}
    </HStack>
  </>
);

const UserAddressSummary = ({ address = null, handleCustomAddress }) => {
  if (!address)
    return (
      <Box p={4} bg="gray.100" rounded="md">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Address</Text>
          <IconButton
            size="xs"
            icon={<MdEdit />}
            onClick={handleCustomAddress}
          />
        </HStack>
        <Text>No address found</Text>
      </Box>
    );

  const { address1, address2, city, zip, state, country } = address ?? {
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
    country: "United States",
  };
  return (
    <Box p={4} bg="gray.100" rounded="md">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Address</Text>
        <IconButton size="xs" icon={<MdEdit />} onClick={handleCustomAddress} />
      </HStack>
      <Text>{address1}</Text>
      {address2 && <Text>{address2}</Text>}
      <Text>
        {city}, {state} {zip}
      </Text>
      <Text>{country}</Text>
    </Box>
  );
};
