export function createConversation(id) {
  const conversation = {
    id,
    state: "active", // active | archived
    provider: "FLOWLY", // SMS | FLOWLY
    participants: [],
    messages: [],
  };

  return {
    getId: function () {
      return conversation.id;
    },
    addParticipant: function (participant) {
      conversation.participants = conversation.participants.concat(participant);
      return this;
    },
    addMessage: function (message) {
      conversation.messages = conversation.messages.concat(message);
      return this;
    },
    build: function () {
      return conversation;
    },
  };
}

export function createParticipant({
  id,
  name,
  lastReadMessageId,
  lastReadMessageTimestampInMs,
  headshotKey,
}) {
  return {
    id,
    name,
    lastReadMessageId,
    lastReadMessageTimestampInMs,
    headshotKey,
  };
}

export function createMessage({
  id,
  authorId,
  conversationId,
  body,
  createdAt,
  updatedAt,
}) {
  return {
    id,
    authorId,
    conversationId,
    body,
    createdAt,
    updatedAt,
  };
}

export const getDisplayName = (userObj) => {
  if (userObj.firstName || userObj.lastName)
    return `${userObj.firstName} ${userObj.lastName ?? ""}`.trim();

  return userObj.mobile;
};
