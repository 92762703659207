import React from "react";
import { Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <VStack p="2rem" bg="white" width="full" height="full" spacing={5}>
    <Text variant="h5">Hmm... Looks like something went wrong.</Text>
    <Text variant="caption">Error: {error.message}</Text>
    <Button
      colorScheme="linkedin"
      variant="outline"
      onClick={resetErrorBoundary}
    >
      Try again
    </Button>
  </VStack>
);

export default ErrorFallback;
