import { client } from "utils/awsConfig";
import {
  LIST_ALL_MESSAGE_PROVIDER_NUMBERS,
  LIST_ALL_USER_MESSAGES,
} from "graphql/queries";
import { SEND_TEXT_MESSAGE } from "graphql/mutations";

export const listAllMessages = () =>
  client.query({
    query: LIST_ALL_USER_MESSAGES,
    variables: { payload: { limit: 20 } },
  });
export const listMessageProviderPhoneNumbers = () =>
  client.query({
    query: LIST_ALL_MESSAGE_PROVIDER_NUMBERS,
    variables: { payload: { limit: 20 } },
  });

export const sendMessage = (payload) =>
  client.mutate({
    mutation: SEND_TEXT_MESSAGE,
    variables: {
      payload,
    },
  });
