export const SUBSCRIPTION_PRODUCT_TYPES = {
  STANDARD: "STANDARD",
  GIFT: "GIFT",
  JOURNEY: "JOURNEY",
};
export const SUBSCRIPTION_PLAN_TYPES = {
  PRO: "PRO",
  COMMUNITY: "COMMUNITY",
};
export const SUBSCRIPTION_TIME_UNITS = {
  YEAR: "YEAR",
  MONTH: "MONTH",
  LIFETIME: "LIFETIME",
};
