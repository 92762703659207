import React from "react";
import { Flex } from "@chakra-ui/react";

const IconBox = ({ children, ...rest }) => (
  <Flex alignItems="center" justifyContent="center" rounded="md" {...rest}>
    {children}
  </Flex>
);

export default IconBox;
