import React from "react";
import {
  Link,
  useLocation,
  useHistory,
  useRouteMatch,
  NavLink,
} from "react-router-dom";
import {
  Box,
  Container,
  Image,
  Avatar as ChakraAvatar,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  ButtonGroup,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";

/* icon imports */
import { HiChevronDown } from "react-icons/hi";
import { RiMessage3Fill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { BsCalendar2EventFill } from "react-icons/bs";

/* asset imports */
import logo from "assets/logo.svg";

/* local imports */
import { PORTAL_ROOT } from "../Routes";
import IconBox from "common/IconBox";

import { client } from "utils/awsConfig.js";
import { useAuth } from "utils/AuthContext";
import getRoleRedirectPath from "utils/getRoleRedirectPath";

const noShowList = [
  "/login",
  "/register",
  "/invitation",
  "/confirm-account",
  "/forgot-password",
  "/completeProfile",
];

const getRoutes = (basename) => [
  //   { to: `${basename}/insights`, title: "Dashboard", icon: RiHomeHeartFill },
  { to: `${basename}/patients`, title: "Patients", icon: FaUsers },
  { to: `${basename}/messages`, title: "Messages", icon: RiMessage3Fill },
  // {
  //   to: `${basename}/sessions`,
  //   title: "Sessions",
  //   icon: BsCalendar2EventFill,
  // },
];

const CoachNav = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { pathname, state } = useLocation();
  const [{ user }, authDispatch] = useAuth();
  const [activeIdx, setActiveIdx] = React.useState(null);

  const coachRoutes = React.useMemo(() => getRoutes(url), []);

  const logout = async () => {
    try {
      await Auth.signOut();

      // clean up artifacts & log evt
      window.analytics.track("logged_out");
      window.analytics.reset();
      await client.resetStore();
      localStorage.clear();

      authDispatch({ type: "LOGOUT" });

      history.push(`${PORTAL_ROOT}/login`, { state });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Flex
      height="16"
      p="2"
      bg="whiteAlpha.200"
      alignItems="center"
      // justifyContent="space-between"
      // shadow="md"
    >
      <Link to={`${PORTAL_ROOT}${getRoleRedirectPath(user?.role)}`}>
        <Image
          src={logo}
          alt="Flowly Logo"
          width={{ base: "24", md: "32" }}
          my="auto"
        />
      </Link>
      {/* <Box>
      </Box> */}
      <Flex
        ml={{
          base: 2,
          lg: "calc(calc(calc(100% - var(--chakra-sizes-container-md)) / 2) - var(--chakra-sizes-36))",
        }}
        flex={1}
        justifyContent="space-between"
        px={0}
      >
        <ButtonGroup size="sm" variant="ghost" isAttached>
          {coachRoutes.map(({ to, title, icon: NavItemIcon }, idx) => (
            <NavLink
              to={to}
              key={title}
              className={(isActive) => (isActive ? setActiveIdx(idx) : null)}
            >
              <Button
                fontSize="sm"
                ms="0px"
                ml="0"
                // pl="0"
                // px={{base: "0", md: "inherit"}}
                // px="0px"
                // me={{ sm: "2px", md: "16px" }}
                // variant="ghost"
                leftIcon={
                  <IconBox
                    bg={`${idx === activeIdx ? "linkedin.300" : "transparent"}`}
                    color={`${idx === activeIdx ? "white" : "gray.700"}`}
                    me="-2px"
                    transition="0.2s linear"
                    p="1.5"
                  >
                    <Icon as={NavItemIcon} w="4" h="4" me="0px" />
                  </IconBox>
                }
              >
                <Text
                  display={{ base: "none", md: "block" }}
                  color={idx === activeIdx ? "linkedin.500" : "gray.700"}
                >
                  {title}
                </Text>
              </Button>
            </NavLink>
          ))}
        </ButtonGroup>
        {!noShowList.some((r) => pathname.includes(r)) ? (
          <Box>
            <DropdownMenu
              size="sm"
              id={user?.id}
              name={user?.name ?? "User Name"}
              logout={logout}
            />
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default CoachNav;

const DropdownMenu = ({ id, name, logout, ...rest }) => (
  <Menu>
    <MenuButton as={Button} rightIcon={<HiChevronDown />} variant="ghost">
      <HStack alignItems="center">
        <ChakraAvatar
          name={name}
          alt={name}
          size="sm"
          mx="0"
          mr="1"
          {...rest}
        />
        <Text display={{ base: "none", sm: "flex" }} variant="button">
          {name}
        </Text>
      </HStack>
    </MenuButton>
    <MenuList>
      <MenuItem as={NavLink} to={`${PORTAL_ROOT}/users/${id}`}>
        View Profile
      </MenuItem>
      <MenuItem onClick={logout}>Sign out</MenuItem>
    </MenuList>
  </Menu>
);
