import React, { useMemo, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link as ChakraLink,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import Icon from "@chakra-ui/icon";
import { Link, useLocation, Redirect } from "react-router-dom";
import qs from "qs";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";
import { PORTAL_ROOT } from "../../Routes";
import { useAuth } from "utils/AuthContext";
import { client } from "utils/awsConfig.js";
import { parseRole, ROLES } from "utils/authHelpers";

import { GET_COACH, GET_GUIDE } from "graphql/queries";

const LoginGuide = () => {
  const { search, state } = useLocation();

  const [, authDispatch] = useAuth();
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const [shouldRedirect, setRedirect] = useState(false);

  const [accountState, setAccountState] = useState({
    name: "",
    email: "",
    password: "",
    org_name: "",
  });
  const [loading, setLoading] = useState(false);

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );
  const handleChange = (property) => (e) =>
    setAccountState((prev) => ({ ...prev, [property]: e.target.value }));

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  const loadCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const userObj = {
          id: user.username,
          name: user.attributes.given_name,
          email: user.attributes.email,
          role: parseRole(
            user.signInUserSession.accessToken.payload["cognito:groups"]
          ),
        };
        window.analytics.identify(user.username, userObj);
        setAccountState((prev) => ({ ...prev, ...userObj }));
        authDispatch({
          type: "LOGIN",
          payload: { isAuthenticated: true, user: userObj },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const login = async () => {
    setLoading(true);
    try {
      await Auth.signIn(
        accountState.email.toLowerCase(),
        accountState.password
      );
      await loadCurrentUser();
      window.analytics.track("logged_in");
      firebase.analytics().logEvent("login");

      const { role, homePath } = await getUserArchetype();

      const triedToAccess = state?.from?.pathname;
      if (triedToAccess) {
        setRedirect(triedToAccess);
      }

      if (role === ROLES.USER) {
        setRedirect(getRedirectURL(currentParams?.redirectURL ?? ""));
      }

      setRedirect(`${PORTAL_ROOT}${homePath}`);
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log("@LOGIN", accountState, auth);
  const getRedirectURL = (to = "") => {
    switch (to) {
      case "becomeGuide":
        return `${PORTAL_ROOT}/completeProfile`;

      case "billing":
        return `${PORTAL_ROOT}/users/billing`;

      default:
        return `${PORTAL_ROOT}/`;
    }
  };

  return (
    <Box
      height="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      {shouldRedirect ? (
        <Redirect
          to={{
            pathname: shouldRedirect,
            state: accountState,
          }}
        />
      ) : null}

      <Grid gridTemplateColumns="repeat(12, 1fr)" height="full">
        {/* Left */}
        <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
          <form onSubmit={login}>
            <VStack mt="20" alignItems="flex-start" spacing={4}>
              <H1>Login</H1>
              <FormControl id="email">
                <FormLabel fontWeight="semibold">Email address</FormLabel>
                <Input
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={accountState.email}
                  onChange={handleChange("email")}
                  required
                  placeholder="Username or Email"
                  size="lg"
                />
              </FormControl>
              <FormControl id="password">
                <Flex justify="space-between" w="full">
                  <FormLabel fontWeight="semibold">Password</FormLabel>
                  <ChakraLink as={Link} to="./forgot-password" color="blue.500">
                    Forgot password?
                  </ChakraLink>
                </Flex>
                <InputGroup>
                  <Input
                    name="password"
                    type={isOpen ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder="Password"
                    value={accountState.password}
                    onChange={handleChange("password")}
                    size="lg"
                    required
                  />
                  <InputRightElement top="1">
                    <IconButton
                      bg="transparent !important"
                      variant="ghost"
                      aria-label={isOpen ? "Mask password" : "Reveal password"}
                      icon={
                        isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />
                      }
                      onClick={onClickReveal}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Flex alignItems="center">
                <Button
                  type="submit"
                  onClick={login}
                  variant="solid"
                  bg="#5072ec"
                  colorScheme="blue"
                  rounded="xl"
                  height="100%"
                  isLoading={loading}
                  loadingText={"Signing in..."}
                  py="1rem"
                  px="110px"
                >
                  Login
                </Button>
              </Flex>
              <HStack spacing={1}>
                <Text>Don&apos;t have an account? </Text>
                <ChakraLink as={Link} to="./register" color="blue.500">
                  Sign up for free!
                </ChakraLink>
              </HStack>
            </VStack>
          </form>
        </GridItem>

        {/* Right */}
        <GridItem
          colSpan={6}
          display={{ base: "none", lg: "block" }}
          width="full"
          pt="16"
          pl="5vw"
          pr="10vw"
        >
          <Box display="grid" placeItems="center">
            <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default LoginGuide;

const getUserArchetype = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const cognitoRole = parseRole(
    user.signInUserSession.accessToken.payload["cognito:groups"]
  );

  switch (cognitoRole) {
    case ROLES.ADMIN:
      return {
        role: ROLES.ADMIN,
        homePath: `/admin`,
      };

    case ROLES.COACH: {
      const response = await client.query({
        query: GET_COACH,
        variables: {
          id: user.username,
        },
      });

      const isCoach = !!response.data.getCoach?.id;

      if (isCoach) {
        return {
          role: ROLES.COACH,
          homePath: `/coach`,
        };
      }

      return {
        role: ROLES.USER,
        homePath: `/`,
      };
    }

    case ROLES.PILOT_CLINICIAN:
    case ROLES.GUIDE: {
      const response = await client.query({
        query: GET_GUIDE,
        variables: {
          id: user.username,
        },
      });

      const isGuide = !!response.data.getGuide?.id;
      if (isGuide) {
        return {
          role: ROLES.GUIDE,
          homePath: `/guide`,
        };
      }

      return {
        role: ROLES.USER,
        homePath: `/`,
      };
    }

    default:
      return {
        role: ROLES.USER,
        homePath: `/`,
      };
  }
};
