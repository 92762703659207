import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button } from "@chakra-ui/button";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { get } from "lodash";

/* icon imports */
import { TiArrowForward } from "react-icons/ti";

/* local imports */
import { client } from "utils/awsConfig";
import { GET_USER_SETTINGS } from "graphql/queries";
import { GEN_STRIPE_PORTAL_LINK } from "graphql/mutations";

const ManageSubscription = () => {
  const [loading, setLoading] = useState(false);

  const loadCustomerPortalSesion = async () => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userSettings = await client.query({
        query: GET_USER_SETTINGS,
        variables: {
          id: user.username,
        },
      });

      const stripeCustomerId = get(
        userSettings,
        "data.getUser.subscriptionInfo.stripeSubscriptionInfo.stripeCustomerId",
        null
      );

      if (!stripeCustomerId) {
        throw new Error("User has no associated stripe customer ID");
      }

      const generateLink = await client.mutate({
        mutation: GEN_STRIPE_PORTAL_LINK,
        variables: {
          customerId: stripeCustomerId,
        },
      });

      const parsedLink = get(
        generateLink,
        "data.getStripePortalLink.result",
        null
      );

      if (parsedLink) {
        window.location.replace(parsedLink);
      }
    } catch (e) {
      setLoading(false);
      console.log("ERROR", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      p="8"
      bg="white"
      rounded="3xl"
      shadow="md"
      height="xs"
      width="full"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
    >
      <Box w="full">
        <Heading color="linkedin.500" fontSize="2xl">
          Manage your subscription
        </Heading>
        <Text fontSize="md" color="gray.400" maxW="85%">
          Update or pause your subscription, download previous invoices, or
          update your billing information all from one place.
        </Text>
      </Box>
      <Box>
        <Button
          mt="4"
          colorScheme="linkedin"
          onClick={loadCustomerPortalSesion}
          isLoading={loading}
          loadingText="Loading your data..."
          rightIcon={<TiArrowForward />}
        >
          To Billing
        </Button>
      </Box>
    </Box>
  );
};

export default ManageSubscription;
