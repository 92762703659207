export const beautify = (key) =>
  key
    ? key
        .split("-")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(" ")
    : key;

export const toSentenceCase = (str = "") =>
  str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
// str.charAt(0) + str.substring(1).toLowerCase();

export const getParsedJSON = (JSONString) => {
  try {
    return JSON.parse(JSONString);
  } catch (err) {
    console.log("Failed to parse provided JSON", err);
    return {};
  }
};
