import { useQuery } from "react-query";

/** local imports */
import { client } from "utils/awsConfig";
import { LIST_JOURNEYS } from "graphql/queries";

export default function useJourneys(config, deps = [], queryConfig = {}) {
  const {
    data: res,
    isError,
    isLoading,
    ...rest
  } = useQuery({
    queryKey: ["journeys", ...deps],
    queryFn: () =>
      client.query({
        query: LIST_JOURNEYS,
        ...config,
      }),
    ...queryConfig,
  });

  const journeyRecords =
    !isError && !isLoading && res ? res.data.listJourneys.items : [];

  return { data: journeyRecords, isError, isLoading, ...rest };
}
