/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "1c7169b28c574ece876d19e6478b7b29",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "1c7169b28c574ece876d19e6478b7b29",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "1c7169b28c574ece876d19e6478b7b29",
                "region": "us-east-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "flowlyRestAPI",
            "endpoint": "https://jwf6adgm8j.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2lhuilod7bdopeumeo4ci3rlaq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-n6hlpyjnrvgdpbdfeoiitgygvq",
    "aws_cognito_identity_pool_id": "us-east-2:17a4cc2b-08c6-4945-9cee-a1f4ebdd6e3d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_VKKMNdocJ",
    "aws_user_pools_web_client_id": "36q58aanh86b3nuosbh0va40b7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "flowly-storage223227-prod",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "fundraiserTable-prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
