function Downloader(props) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const link = params.get("link");
  if (link) {
    window.location = link;
  } else {
    window.location =
      "https://apps.apple.com/us/app/flowly-relaxation-training/id1485955236";
  }
  return null;
}
export default Downloader;
