import React, { useState } from "react";
import { Box, Flex, VStack, Grid, GridItem, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Auth } from "aws-amplify";

/* local imports */
import { STEPS } from "../RedeemGift";
import H1 from "common/H1";
import landscapeBg from "assets/gift/relaxation-northernlights-bg.jpg";
import ebbiVR from "assets/gift/EbbiVR.png";

const RedeemLanding = ({ navigate, handleChange, formState }) => {
  const [loading, setLoading] = useState(false);

  const next = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      await Auth.signIn(
        formState.email.toLowerCase(),
        "aabbccdd134ranggwahfealfeuiabglaewifhafd=========aa=a=a"
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e.code === "NotAuthorizedException") {
        console.log("Email already registered. Redirecting to login.");
        navigate(STEPS.login);
      }
      if (e.code === "UserNotFoundException") {
        console.log("No matching email found. Redirecting to registration.");
        navigate(STEPS.register);
      }
    }
  };

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={next} noValidate>
          <VStack mt="20" alignItems="flex-start" spacing={4}>
            <H1>Redeem your gift of relaxation</H1>
            <Text fontSize="lg" color="gray.400">
              Congratulations! You received the gift of Flowly. You are only a
              few steps away from taking immediate control of your pain,
              anxiety, and sleep.
            </Text>
            <FormControl id="email">
              <FormLabel fontWeight="semibold">
                Enter your email to get started!
              </FormLabel>
              <Input
                name="email"
                type="email"
                autoComplete="email"
                value={formState.email}
                onChange={handleChange("email")}
                required
                placeholder="john.doe@gmail.com"
                size="lg"
              />
            </FormControl>
            <Flex alignItems="center">
              <Button
                type="submit"
                onClick={next}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText={"Signing in..."}
                py="1rem"
                px="110px"
              >
                Next
              </Button>
            </Flex>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
        // pt="16"
        // pl="5vw"
        // pr="10vw"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
          // display="grid"
          // placeItems="center"
          // bottom="0"
          // left="38%"
          // pb="48"
        >
          <Image src={ebbiVR} maxW="16rem" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemLanding;
