import React, { useState } from "react";
import { Box, Flex, VStack, Grid, GridItem, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Auth } from "aws-amplify";
/* local imports */
import { STEPS } from "../constants";
import H1 from "common/H1";
import { logout } from "utils/authHelpers";
import landscapeBg from "assets/gift/relaxation-northernlights-bg.jpg";
import ebbiVR from "assets/gift/EbbiVR.png";
import { useForm } from "react-hook-form";

const RedeemVoucherAuth = ({ navigate, formState, updateFormState }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data) => {
    const { email } = data;
    updateFormState("email", email);

    setLoading(true);
    try {
      await Auth.signIn(
        email.toLowerCase(),
        "aabbccdd134ranggwahfealfeuiabglaewifhafd=========aa=a=a"
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e.code === "NotAuthorizedException") {
        console.log("Email already registered. Redirecting to login.");
        navigate(STEPS.login);
      }
      if (e.code === "UserNotFoundException") {
        console.log("No matching email found. Redirecting to registration.");
        navigate(STEPS.register);
      }
    }
  };

  React.useEffect(() => {
    const isLoggedIn = async () => {
      const user = await Auth.currentAuthenticatedUser();
      return !!user;
    };
    if (isLoggedIn()) {
      logout();
    }
  }, []);

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <VStack mt="20" alignItems="flex-start" spacing={6}>
            <VStack alignItems="flex-start" spacing={1}>
              <H1>Redeem your license</H1>
              <Text fontSize="lg" color="gray.500">
                {formState.subscription
                  ? formState.subscription
                  : `Congratulations! ${formState.orgName} is offering you free access to Flowly. You are only a few steps
                away from taking immediate control of your pain, anxiety, and
                sleep.`}
              </Text>
            </VStack>
            <FormControl id="email" isInvalid={!!errors["email"]} isRequired>
              <FormLabel fontWeight="semibold">
                Enter your email to get started!
              </FormLabel>
              <Input
                {...register("email", {
                  required: {
                    value: true,
                    message: "An email address is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter a valid email",
                  },
                })}
                type="email"
                isRequired
                placeholder="john.doe@gmail.com"
                size="md"
              />
              {!!errors["email"] && (
                <FormErrorMessage>{errors["email"].message}</FormErrorMessage>
              )}
            </FormControl>
            <Flex alignItems="center" w="full">
              <Button
                type="submit"
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isDisabled={!!errors["email"]}
                isLoading={loading}
                loadingText={"Signing in..."}
                py="1rem"
                w={[
                  "full",
                  "fit-content",
                  "fit-content",
                  "full",
                  "fit-content",
                ]}
                px="20"
              >
                Next
              </Button>
            </Flex>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
        >
          <Image src={ebbiVR} maxW="16rem" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemVoucherAuth;
