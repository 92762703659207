import React, { useEffect, useMemo, useState } from "react";
import _, { groupBy, mapValues, uniqBy } from "lodash";
import {
  format,
  isAfter,
  isSameDay,
  isValid,
  subWeeks,
  isBefore,
  getTime,
  parseISO,
  getWeek,
  getMonth,
} from "date-fns/esm";
import { useDisclosure } from "@chakra-ui/hooks";
import { Flex, Text, Box, HStack } from "@chakra-ui/layout";
import {
  Stat,
  StatArrow,
  StatHelpText,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/stat";
import {
  ComposedChart,
  Scatter,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
} from "recharts";
import { ButtonGroup, IconButton } from "@chakra-ui/button";
import { useHistory, useRouteMatch } from "react-router";
import Icon from "@chakra-ui/icon";

/* icon imports */
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

/* local imports */
import NoDataAvailable from "common/NoDataAvailable";
import useTimeWindow from "../hooks/useTimeWindow";
import {
  binMillisecondDateToStdTimeUnits,
  fillTicksData,
  getGroupByLabel,
  getPeriodTicks,
  getPeriodVal,
  getTicks,
  timeUnit,
  withinBounds,
} from "../utils/temporal";
import SessionListModal from "./SessionListModal";

const ScoreTrajectory = ({ data, filterWeeks }) => {
  const filledData = fillTicksData(data);
  const chartUnit = timeUnit(filterWeeks);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPoint, setSelectedPoint] = useState(null);

  const { windowShift, windowStart, windowEnd, shiftLeft, shiftRight } =
    useTimeWindow({
      weeks: filterWeeks,
    });

  const sessionData = useMemo(() => {
    if (!filledData) return { data: [{ value: null }] };

    let cleaned = filledData.map(binMillisecondDateToStdTimeUnits);

    if (filterWeeks) {
      cleaned = cleaned.filter(withinBounds(windowStart, windowEnd));
    }

    const getGroupedByTimeUnit = groupBy(cleaned, chartUnit);

    // Calculate avg and set avg to all instances on same timeUnit
    mapValues(getGroupedByTimeUnit, (sessions) => {
      const calculatedAverage = Math.round(
        sessions.reduce((acc, current) => (acc += current.value), 0) /
          sessions.filter((s) => s.value !== null).length
      );

      sessions.forEach(
        (session) => (session.average = calculatedAverage || null)
      );
    });

    return {
      raw: cleaned,
      data: uniqBy(cleaned, chartUnit),
    };
  }, [filledData, filterWeeks, chartUnit, windowStart, windowEnd]);

  const metrics = useMemo(() => {
    if (!sessionData.data.length) return 0;

    return {
      avg: (
        sessionData.data.reduce((acc, current) => acc + current.value, 0) /
        sessionData.data.length
      ).toFixed(2),
    };
  }, [sessionData]);

  const handlePointClick = (evt) => {
    if (!evt) return;
    const { activePayload } = evt;
    const [{ payload }] = activePayload;

    setSelectedPoint(payload);
    onOpen();
  };

  // console.log(`@Survey Score Widget`, sessionData);
  return (
    <>
      <StatGroup px="8">
        <Stat>
          <StatNumber fontSize="4xl">{metrics.avg ?? 0}</StatNumber>
          <StatLabel mt="-3">Avg. Rating</StatLabel>
        </Stat>
      </StatGroup>
      <Box height="sm" width="full" mt="4">
        <Chart
          data={sessionData.data}
          filterWeeks={filterWeeks}
          windowStart={windowStart}
          windowEnd={windowEnd}
          metrics={metrics}
          handlePointClick={handlePointClick}
        />
      </Box>
      {filterWeeks ? (
        <HStack mt="4" justifyContent="center" spacing={2}>
          <ButtonGroup>
            <IconButton
              onClick={shiftLeft}
              aria-label="Week before"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowLeft} />}
            />
            <IconButton
              onClick={shiftRight}
              disabled={!windowShift}
              aria-label="Week after"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowRight} />}
            />
          </ButtonGroup>
        </HStack>
      ) : null}
      <SessionListModal
        title={`${getGroupByLabel(filterWeeks)} — ${getPeriodVal(
          filterWeeks,
          new Date(selectedPoint?.fullDate ?? null)
        )}`}
        isOpen={isOpen}
        onClose={onClose}
        sessions={
          selectedPoint
            ? sessionData.raw.filter(
                (point) =>
                  point[chartUnit] === selectedPoint[chartUnit] &&
                  point.value !== null
              )
            : []
        }
      />
    </>
  );
};

export default ScoreTrajectory;

const Chart = ({
  data,
  filterWeeks,
  windowStart,
  windowEnd,
  metrics,
  handlePointClick,
}) => {
  const ticks = getTicks(
    filterWeeks ? windowStart : new Date(data[0]?.fullDate ?? null),
    windowEnd,
    getPeriodTicks(filterWeeks)
  );
  const domain = [(dataMin) => dataMin, (dataMax) => windowEnd.getTime()];

  if (!data.length)
    return (
      <NoDataAvailable>
        {!!filterWeeks && (
          <Text color="gray.600" fontWeight="bold">
            {format(windowStart, "MMM do, yyyy")} —{" "}
            {format(windowEnd, "MMM do, yyyy")}
          </Text>
        )}
      </NoDataAvailable>
    );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        onClick={handlePointClick}
        data={data}
        margin={{
          top: 20,
          right: 80,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <Tooltip
          labelStyle={{ color: "#1a202c", fontWeight: 600 }}
          cursor={{ strokeDasharray: "2,2", color: "#4A5568" }}
          formatter={(val) => val.toFixed(2)}
          labelFormatter={(val) =>
            val && isValid(new Date(val))
              ? `${getPeriodVal(filterWeeks, new Date(val))}`
              : format(new Date(), "MMM do, yyyy ")
          }
        />
        <XAxis
          allowDuplicatedCategory={false}
          //   type="date"
          dataKey="fullDate"
          tickFormatter={(val) =>
            val && isValid(new Date(val))
              ? format(new Date(val), "MMM do, yyyy")
              : format(new Date(), "MMM do, yyyy")
          }
          type="number"
          ticks={ticks}
          domain={domain}
          // domain={[getTime(windowStart), getTime(windowEnd)]}
          // label={{ value: "Index", position: "insideBottomRight", offset: 0 }}
        />
        <YAxis
          // unit="ms"
          type="number"
          domain={[0, 10]}
          label={{ value: "Rating", angle: -90, position: "insideLeft" }}
        />
        {filterWeeks && filterWeeks < 12 ? (
          <Scatter
            name="Session Rating"
            dataKey="average"
            fill="#00a0dc"
            isAnimationActive={false}
          />
        ) : null}
        <Legend wrapperStyle={{ position: "relative" }} />
        <ReferenceLine
          y={metrics.avg}
          position="end"
          stroke="red"
          strokeDasharray="6 3"
        />
        <Line
          animationDuration={500}
          type="basis"
          stroke="#00a0dc"
          strokeWidth={2}
          name={`${getGroupByLabel(filterWeeks)}'s Average`}
          dataKey="average"
          dot={false}
          // activeDot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
