import { useEffect } from "react";
import useLocalStorageState from "use-local-storage-state";
import { StringParam, useQueryParams } from "use-query-params";
import { useCookies } from "react-cookie";
import { isEmpty } from "lodash";

const utmKeys = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_id",
];

const domain = "flowly.world";

const QueryParamsController = () => {
  const [query] = useQueryParams({
    promo: StringParam,
    referrer: StringParam,
    ...utmKeys.reduce(
      (acc, current) => ((acc[current] = StringParam), acc),
      {}
    ),
  });

  const [, persistPromo] = useLocalStorageState("promo");
  const [, persistReferrer] = useLocalStorageState("referrer");

  const [cookies, setCookie] = useCookies([]);

  useEffect(() => {
    const { promo, referrer, ...utmParams } = query;
    if (promo) {
      persistPromo(promo);
      window.analytics.track("promo_code_visit", {
        code: promo,
      });
    }
    if (referrer) {
      persistReferrer(referrer);
      window.analytics.track("referred_visit", {
        referrer,
      });
    }

    setCookie("referring_url", JSON.stringify(document.referrer), {
      path: "/",
    });

    if (utmParams) {
      const validUTMParams = Object.entries(utmParams).reduce(
        (acc, [k, v]) => (v ? (acc[k] = v) : null, acc),
        {}
      );
      if (!isEmpty(validUTMParams)) {
        setCookie("utm", JSON.stringify(validUTMParams), {
          domain,
          path: "/",
        });

        if (isEmpty(cookies.initial_utm))
          setCookie("initial_utm", JSON.stringify(validUTMParams), {
            domain,
            path: "/",
          });
      }
    }
  }, [query, persistPromo, setCookie, persistReferrer]);

  return null;
};

export default QueryParamsController;
