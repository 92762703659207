import React, { useState, useEffect, useMemo } from "react";
import firebase from "firebase/app";
import "firebase/analytics";

/* asset imports */
import vr from "assets/home/vr.png";
import support from "assets/home/support.png";
import analytics from "assets/home/analytics.png";
import screenshots from "assets/home/screenshots.png";
import check from "assets/home/check.svg";
import coach from "assets/home/coach.png";
import community from "assets/home/community.png";

/* local imports */
import Button from "common/Button";
import Card from "common/Card";
import H2 from "common/H2";
import H3 from "common/H3";
import Spinner from "common/Spinner";
import { toSentenceCase } from "utils/string";
import { SUBSCRIPTION_PRODUCT_TYPES } from "./constants";
import {
  CardContent,
  Center,
  Feature,
  Features,
  Hero,
  HomeStyle,
  LoadingContainer,
  PlanSelector,
  WhiteContainer,
} from "./components";
import useSubscriptionProducts from "hooks/useSubscriptionProducts";

function PatientCheckout() {
  const [selected, setSelectedPlan] = useState({ subscriptionType: "YEARLY" });
  const { data: products, isLoading } = useSubscriptionProducts({
    variables: {
      filter: {
        and: [
          {
            duration: {
              eq: 1,
            },
          },
          {
            subscriptionMethod: {
              eq: "STRIPE",
            },
          },
          {
            subscriptionProductType: {
              eq: SUBSCRIPTION_PRODUCT_TYPES.STANDARD,
            },
          },
          {
            kitPrice: { gt: 0 },
          },
        ],
      },
    },
  });

  const productsToDisplay = useMemo(
    () => products.sort((a, b) => a.price - b.price),
    [products]
  );
  useEffect(() => {
    if (productsToDisplay.length && !selected.id) {
      setSelectedPlan(
        () =>
          productsToDisplay.find(
            (offering) => offering.subscriptionType === "YEARLY"
          ) ?? { subscriptionType: "YEARLY" }
      );
    }
  }, [productsToDisplay, selected]);

  useEffect(() => {
    firebase.analytics().logEvent("home_visited");
  }, []);

  const calculateDiscount = (initialPrice, current) =>
    (((initialPrice - current) / initialPrice) * 100).toFixed(0);

  return (
    <HomeStyle>
      <Hero>
        <div className="image">
          <div className="iframe-container">
            <iframe
              src="https://www.youtube-nocookie.com/embed/Wi6jNrlKxcs?modestbranding=1&autoplay=0&rel=0&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="card">
          <Card>
            <CardContent>
              <h1 className="header">
                Physician Recommended: Flowly Patient Membership
              </h1>
              <h3 className="subheader">What&apos;s Included</h3>
              <div className="checkmarks">
                <div className="check">
                  <img src={check} alt="Checkmark" />
                  Mobile Virtual Reality Headset
                </div>
                <div className="check">
                  <img src={check} alt="Checkmark" />
                  Bluetooth Realtime Heart Rate Biosensor
                </div>
                <div className="check">
                  <img src={check} alt="Checkmark" />
                  Access to all Flowly app experiences and features
                </div>
              </div>
              <div className="mt-1">
                <h3 className="subheader">Subscriptions</h3>
                <PlanSelector>
                  {isLoading ? (
                    <LoadingContainer>
                      <Spinner />
                    </LoadingContainer>
                  ) : (
                    productsToDisplay.map(
                      ({
                        id,
                        title = "",
                        description = "",
                        subscriptionType,
                        price,
                        timeUnits,
                        initialPrice = 396.99,
                        ...rest
                      }) => (
                        <button
                          key={id}
                          onClick={() =>
                            setSelectedPlan({
                              id,
                              subscriptionType,
                              price,
                              timeUnits,
                              initialPrice,
                              ...rest,
                            })
                          }
                          className={`card ${
                            id === selected.id ? "selected" : null
                          }`}
                        >
                          {!!initialPrice && (
                            <div
                              className={`badge ${
                                id === selected.id ? "selected" : null
                              }`}
                            >
                              Save {calculateDiscount(initialPrice, price)}%
                            </div>
                          )}
                          <div id="cardBody">
                            <div>
                              <h4>
                                {toSentenceCase(title ?? subscriptionType)}
                              </h4>
                              <p className="productDescription">
                                {description}
                              </p>
                            </div>
                            <span className="prices">
                              {!!initialPrice && (
                                <p className="prev">${initialPrice}</p>
                              )}
                              <p>
                                ${price}/{timeUnits.toLowerCase()} + one-time
                                kit fee
                              </p>
                            </span>
                          </div>
                        </button>
                      )
                    )
                  )}
                </PlanSelector>
              </div>
              <p>
                This discount is exclusively for patients recommended through
                physicians and providers.
              </p>
            </CardContent>
            <Button
              to={{
                pathname: `/checkout/${selected.subscriptionType}/${selected.id}`,
                state: { from: "PATIENT_CHECKOUT", selected },
              }}
            >
              Proceed to Checkout
            </Button>
          </Card>
        </div>
      </Hero>
      <WhiteContainer>
        <Features>
          <Feature>
            <img src={vr} alt="Virtual Reality" />
            <p>
              designed to relax and help you better manage your nervous system
            </p>
          </Feature>
          <Feature className="center">
            <img src={analytics} alt="Analytics" />
            <p>actually see how your body is doing in your data portal</p>
          </Feature>
          <Feature>
            <img src={support} alt="Support" />
            <p>in our in-app community and from our health coach</p>
          </Feature>
        </Features>

        <Center>
          <div className="image">
            <img src={screenshots} alt="App Screenshots" />
          </div>

          <div className="content">
            <H2>flowly app</H2>
            <p>
              {/* copy here <br /> */}
              <br />
            </p>
            <div className="item">
              <H3>Experience relaxation</H3>
              <p>
                in immersive Virtual Reality worlds designed and tested for
                optimal comfort and relaxation
              </p>
            </div>
            <div className="item">
              <H3>Balance your nervous system</H3>
              <p>
                by re-learning stress responses through biofeedback methods to
                influence various body metrics including HR, HRV, and
                respiration in every session.
              </p>
            </div>
            <div className="item">
              <H3>Track your progress</H3>
              <p>
                in a data portal that tracks your biometrics, streaks, and
                journal notes.
              </p>
            </div>
          </div>
        </Center>

        <div className="support-message">
          <H2>We Support You</H2>
          <p>
            Our Flowly community is growing every day and we want to give you
            the access to tools, processes, methods, and support you need to
            feel supported on your health journey
          </p>
        </div>

        <div className="app-features">
          <div className="left">
            <H3>Access our Health Coach</H3>
            <p>
              Call or Text our Flowly Health coach to get help setting up,
              receive advice about how to best integrate Flowly into a ritual,
              and more.
            </p>
            <div className="image">
              <img src={coach} alt="Coach"></img>
            </div>
          </div>
          <div className="right">
            <H3>Share in our Community</H3>
            <p>Become part of our safe, restricted in-app community.</p>
            <div className="image">
              <img src={community} alt="Community"></img>
            </div>
          </div>
        </div>
      </WhiteContainer>
    </HomeStyle>
  );
}

export default PatientCheckout;
