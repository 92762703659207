import React from "react";
import { useRadio } from "@chakra-ui/radio";
import { Box } from "@chakra-ui/react";

function EventTypeRadio(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        // mb="2"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        border="none"
        // boxShadow="base"
        _checked={{
          bg: "teal.500",
          color: "white",
          boxShadow: "md",
        }}
        _hover={{
          boxShadow: "base",
        }}
        px={4}
        py={2}
        fontSize="sm"
        fontWeight="bold"
        letterSpacing="wider"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default EventTypeRadio;
