import React, { useState } from "react";
import { HStack, VStack, Divider } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import { useRadioGroup } from "@chakra-ui/radio";
import { Select } from "@chakra-ui/select";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/input";
import { Textarea } from "@chakra-ui/textarea";
import { Controller, useForm } from "react-hook-form";
import { add, format, parseISO, subDays } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

/* icon imports */

/* local imports */
import timezones from "utils/timezones";
import EventTypeRadio from "./EventTypeRadio";
import { EVENT_TYPES } from "../constants";
import { createEvent } from "../services";

const CreateEventModal = (props) => {
  const { isOpen, onClose } = props;

  const toast = useToast();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      description: "",
      startDate: format(new Date(), "yyyy-MM-dd"),
      startTime: format(new Date(), "HH:mm"),
      durationHours: 1,
      durationMinutes: 0,
    },
  });

  const [customTimezone, setCustomTimezone] = useState(null);
  const displayTimezonePicker = () => {
    setCustomTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  };
  const [eventType, setEventType] = useState("VIRTUAL");

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "eventType",
    defaultValue: "VIRTUAL",
    onChange: setEventType,
  });

  const handleResetForm = () => {
    reset({
      title: "",
      description: "",
      startDate: format(new Date(), "yyyy-MM-dd"),
      startTime: format(new Date(), "HH:mm"),
      durationHours: 1,
      durationMinutes: 0,
    });
  };

  const handleClose = () => {
    handleResetForm();
    onClose();
  };

  const onSubmit = async (data) => {
    const {
      title,
      description,
      startDate,
      startTime,
      durationHours,
      durationMinutes,
    } = data;

    const payload = {
      title,
      description,
      duration: +durationHours * 60 + +durationMinutes,
      type: eventType,
      permissionTier: "FREE",
      startDate: zonedTimeToUtc(
        `${startDate} ${startTime}`,
        customTimezone
          ? data.timeZone
          : Intl.DateTimeFormat().resolvedOptions().timeZone
      ).toISOString(),
      signupLink: data.signupLink.length ? data.signupLink : null,
      joinLink: data.joinLink.length ? data.joinLink : null,
    };

    try {
      await createEvent(payload);
      toast({
        title: "Event created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      handleClose();
    } catch (error) {
      console.log("[Create Event Failed] Error occurred", error);
      alert("An error occurred: Event not created");
    }
  };

  const getEventSummaryString = () => {
    const evtDate =
      parseISO(`${getValues()?.startDate}T${getValues()?.startTime}`) ??
      new Date();

    const endDate = add(evtDate, {
      hours: +getValues()?.durationHours ?? 0,
      minutes: +getValues()?.durationMinutes ?? 0,
    });
    return `Event ends on ${format(evtDate, "eeee do")} at ${format(
      endDate,
      "hh:mm aa"
    )}`;
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="2xl"
      blockScrollOnMount={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Event</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <ModalBody>
            <VStack alignItems="flex-start" spacing="6" my={2}>
              <VStack alignItems="flex-start" spacing={4} w="80%">
                <FormControl id="type" isInvalid={errors?.type} isRequired>
                  <FormLabel mb={1} fontWeight="semibold">
                    Where is the event taking place?
                  </FormLabel>
                  <HStack
                    {...getRootProps()}
                    spacing="0"
                    borderColor="gray.200"
                    borderWidth="1px"
                    p="0.5"
                    rounded="lg"
                    w="fit-content"
                  >
                    {EVENT_TYPES.map(({ label, value }) => {
                      const radio = getRadioProps({ value });
                      return (
                        <EventTypeRadio key={value} {...radio}>
                          {label}
                        </EventTypeRadio>
                      );
                    })}
                  </HStack>
                </FormControl>
                <FormControl id="title" isInvalid={errors?.title} isRequired>
                  <FormLabel mb={1} fontWeight="semibold">
                    Event title
                  </FormLabel>
                  <Input
                    {...register("title", {
                      required: {
                        value: true,
                        message: "Event title cannot be left empty",
                      },
                    })}
                    type="text"
                    placeholder="Breathe along with Flowly"
                  />
                  {errors?.title && (
                    <FormErrorMessage>{errors.title.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl id="description" isInvalid={errors?.description}>
                  <FormLabel mb={1} fontWeight="semibold">
                    Description
                  </FormLabel>
                  <Textarea
                    {...register("description")}
                    type="text"
                    size="sm"
                    rounded="md"
                    placeholder="Who should come? What's the event about?"
                  />
                </FormControl>
                <FormControl
                  id="startDate"
                  isInvalid={errors?.startDate}
                  isRequired
                >
                  <FormLabel mb={1} fontWeight="semibold">
                    Date
                  </FormLabel>
                  <HStack w="80%" spacing={4} alignItems="flex-start">
                    <Input
                      {...register("startDate", {
                        required: {
                          value: true,
                          message: "Date cannot be left empty",
                        },
                        min: {
                          value: format(subDays(new Date(), 1), "yyyy-MM-dd"),
                          message: "Event date cannot be in the past",
                        },
                      })}
                      w="150%"
                      isRequired={true}
                      type="date"
                      min={format(new Date(), "yyyy-MM-dd")}
                    />
                    <Input
                      {...register("startTime", {
                        required: {
                          value: true,
                          message: "Time cannot be left empty",
                        },
                      })}
                      type="time"
                    />
                  </HStack>
                  {!customTimezone ? (
                    <FormHelperText>
                      {format(new Date(), "OOOO")} —{" "}
                      {new Date()
                        .toString()
                        .match(/\((.*)\)/)
                        .pop()}{" "}
                      Zone •{" "}
                      <Button
                        variant="link"
                        colorScheme="linkedin"
                        size="sm"
                        onClick={displayTimezonePicker}
                      >
                        Change
                      </Button>
                    </FormHelperText>
                  ) : (
                    <Controller
                      control={control}
                      name="timezone"
                      // rules={{ required: "Please enter at least one food group." }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <FormControl py={4} isInvalid={!!error} id="timezone">
                          <FormLabel mb={1} fontWeight="semibold">
                            Timezone
                          </FormLabel>
                          <Select
                            name={name}
                            ref={ref}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder={
                              timezones.find(
                                (tz) =>
                                  tz.code ===
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone
                              ).city
                            }
                          >
                            {timezones.map((tz) => (
                              <option key={tz.code} value={tz.code}>
                                {tz.offset} {tz.name} — {tz.city}
                              </option>
                            ))}{" "}
                          </Select>
                          <FormErrorMessage>
                            {error && error.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  )}
                </FormControl>
                <FormControl
                  id="duration"
                  isInvalid={errors?.durationHours || errors?.durationMinutes}
                  isRequired
                >
                  <FormLabel mb={1} fontWeight="semibold">
                    Duration
                  </FormLabel>
                  <HStack w="80%" spacing={4} alignItems="flex-start">
                    <VStack alignItems="flex-start">
                      <InputGroup>
                        <Input
                          {...register("durationHours", {
                            required: {
                              value: true,
                              message: "Duration cannot be left empty",
                            },
                            max: {
                              value: 23,
                              message: "Hours cannot exceed 23",
                            },
                          })}
                          required="required"
                          type="number"
                          defaultValue="1"
                        />
                        <InputRightAddon>hour</InputRightAddon>
                      </InputGroup>
                      {errors?.durationHours && (
                        <FormErrorMessage>
                          {errors.durationHours.message}
                        </FormErrorMessage>
                      )}
                    </VStack>
                    <VStack alignItems="flex-start">
                      <InputGroup>
                        <Input
                          {...register("durationMinutes", {
                            max: {
                              value: 59,
                              message: "Minutes cannot exceed 59",
                            },
                          })}
                          type="number"
                          defaultValue="0"
                        />
                        <InputRightAddon>minute</InputRightAddon>
                      </InputGroup>
                      {errors?.durationMinutes && (
                        <FormErrorMessage>
                          {errors.durationMinutes.message}
                        </FormErrorMessage>
                      )}
                    </VStack>
                  </HStack>
                  <FormHelperText>{getEventSummaryString()}</FormHelperText>
                </FormControl>
              </VStack>
              <Divider />
              <VStack alignItems="flex-start" spacing={4} w="85%">
                <FormControl id="signupLink" isInvalid={errors?.signupLink}>
                  <FormLabel mb={1} fontWeight="semibold">
                    Sign up link
                  </FormLabel>
                  <Input
                    {...register("signupLink")}
                    type="text"
                    placeholder="https://zoom.us/webinar/register/XXXXXX_XXXXXXXX"
                  />
                  {errors?.signupLink && (
                    <FormErrorMessage>
                      {errors.signupLink.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl id="joinLink" isInvalid={errors?.joinLink}>
                  <FormLabel mb={1} fontWeight="semibold">
                    Join link
                  </FormLabel>
                  <Input
                    {...register("joinLink")}
                    type="text"
                    placeholder="https://zoom.us/j/XXXXXXXXXX"
                  />
                  {errors?.joinLink && (
                    <FormErrorMessage>
                      {errors.joinLink.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </VStack>
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="linkedin"
              rounded="md"
              type="submit"
              loadingText="Creating your order..."
            >
              Create Event
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default CreateEventModal;
