export const FLOWLY_PRO_FEATURES = [
  {
    title: "Included Hardware",
    features: [
      {
        title: "Member Necklace",
        description:
          "A classic stainless steel tag necklace that serves as your membership badge",
        locked: false,
      },
      {
        title: "VR + Biofeedback Pro Kit",
        description:
          "Includes a Virtual Reality headset and Heart Reate Sensor that enables relaxation experiences that help you balance your nervous system",
        locked: false,
      },
    ],
  },
  {
    title: "Access to Events",
    features: [
      {
        title: "Health Seminars",
        description:
          "Focused on science-backed health techniques, learnings, and tools",
        locked: false,
      },

      {
        title: "Expert-led workshops",
        description:
          "Physician and therapist-led workshops where you can directly engage with the expert",
        locked: false,
      },
    ],
  },
  {
    title: "Tools for your nervous system",
    features: [
      {
        title: "Breathers",
        description: "Short 2D breathing exercises",
        locked: false,
      },
      {
        title: "VR Biofeedback Sessions",
        description:
          "Using your Pro Kit, enter relaxing VR worlds where you will learn to control your heart rate and nervous system to feel better each day",
        locked: false,
      },
    ],
  },
  {
    title: "Flowly Goods",
    features: [
      {
        title: "Exclusive 30% of all goods",
        description: "Including opportunities to earn free swag",
        locked: false,
      },
    ],
  },
];
export const FLOWLY_COMMUNITY_FEATURES = [
  {
    title: "Included Hardware",
    features: [
      {
        title: "Member Necklace",
        //   description:
        // 	"A classic stainless steel tag necklace that serves as your membership badge",
        locked: false,
      },
      {
        title: "VR + Biofeedback Pro Kit",
        //   description:
        // 	"Includes a Virtual Reality headset and Heart Reate Sensor that enables relaxation experiences that help you balance your nervous system",
        locked: true,
      },
    ],
  },
  {
    title: "Access to Events",
    features: [
      {
        title: "Health Seminars",
        //   description:
        // 	"Focused on science-backed health techniques, learnings, and tools",
        locked: false,
      },

      {
        title: "Expert-led workshops",
        //   description:
        // 	"Physician and therapist-led workshops where you can directly engage with the expert",
        locked: true,
      },
    ],
  },
  {
    title: "Tools for your nervous system",
    features: [
      {
        title: "Breathers",
        //   description: "Short 2D breathing exercises",
        locked: false,
      },
      {
        title: "VR Biofeedback Sessions",
        //   description:
        // 	"Using your Pro Kit, enter relaxing VR worlds where you will learn to control your heart rate and nervous system to feel better each day",
        locked: true,
      },
    ],
  },
  {
    title: "Flowly Goods",
    features: [
      {
        title: "Exclusive 15% of all goods",
        //   description: "Including opportunities to earn free swag",
        locked: false,
      },
    ],
  },
];
