import { Box } from "@chakra-ui/react";
import React from "react";

const ChatBubble = ({ isInbound, children, ...rest }) => {
  return (
    <Box
      position="relative"
      maxW="64"
      alignSelf={isInbound ? "flex-start" : "flex-end"}
      rounded="lg"
      borderBottomRightRadius={isInbound ? "lg" : 0}
      borderBottomLeftRadius={isInbound ? 0 : "lg"}
      // border={isInbound ? "1px solid" : "none"}
      // borderColor="#f3f4f6"
      // borderColor={
      //   isInbound ? "#e7e7e7" : "blue.200"
      //   // "#00a2ff"
      // }
      // shadow={isInbound ? "sm" : "none"}
      shadow="lg"
      bg={
        isInbound ? "white" : "#DCF8C6"
        // "#f6f7ff"
        // "#00a2ff"
      }
      p={3}
      px={6}
      flexShrink={0}
      // _before={{
      //   content: `""`,
      //   w: "0",
      //   h: "0",
      //   borderColor: `transparent ${
      //     isInbound ? "var(--chakra-colors-white)" : "#C2E6FF"
      //   } transparent`,
      //   borderStyle: "solid",
      //   borderWidth: "0px 10px 10px 0",
      //   position: "absolute",
      //   // left: isInbound ? "-8px" : "0px",
      //   // right: isInbound ? "0px" : "12px",
      //   top: "0px",
      //   display: "block",
      //   ...(isInbound
      //     ? { left: "-8px", borderWidth: "0px 10px 10px 0" }
      //     : { right: "-8px", borderWidth: "0px 0px 10px 10px" }),
      // }}
      {...rest}
    >
      {children}
    </Box>
  );
};
export default ChatBubble;
