import React, { useMemo, useState } from "react";
import { HStack, VStack, Box } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { IconButton } from "@chakra-ui/button";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";

/* icon imports */
import { IoMdRefresh } from "react-icons/io";

/* local imports */
import { PORTAL_ROOT } from "Routes";
import { invitationColumns, userColumns } from "../constants";
import DataGrid from "common/DataGrid";
import ErrorFallback from "common/ErrorFallback";
import DebouncedInput from "common/DebouncedInput";

const HeroListWidget = ({ userList, invitations, loading, onSuccess }) => {
  const history = useHistory();
  const handleRowClick = (rowData) =>
    history.push(`${PORTAL_ROOT}/users/${rowData.original.id}`);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const [rowLoading, setRowLoading] = useState(false);

  const tableData = useMemo(
    () => ({
      users: userList,
      invitations,
    }),
    [invitations, userList]
  );

  const { table: userTableInstance, ...userTableGridProps } =
    DataGrid.useDataGrid({
      columns: userColumns(onSuccess, [rowLoading, setRowLoading]),
      data: tableData.users,
      // initialState: {
      //   columnVisibility: { type: false, permissionTier: false },
      // },
      enablePagination: true,
      // enableRowSelection: true,
    });

  const { table: invitationsTableInstance, ...invitationTableGridProps } =
    DataGrid.useDataGrid({
      columns: invitationColumns(onSuccess, [rowLoading, setRowLoading]),
      data: tableData.invitations,
      // initialState: {
      //   columnVisibility: { type: false, permissionTier: false },
      // },
      enablePagination: true,
      enableRowSelection: true,
    });

  return (
    <VStack width="full" spacing={3} bg="white" rounded="xl" shadow="md">
      <Tabs
        index={tabIndex}
        onChange={handleTabsChange}
        width="full"
        variant="solid-rounded"
        size="sm"
        isLazy
      >
        <VStack width="full" align="flex-start" spacing={3}>
          <HStack width="full" justifyContent="space-between" p={4}>
            <TabList flexShrink={0} pt={2}>
              <Tab
                fontSize="xs"
                _selected={{
                  bg: "linkedin.500",
                  color: "white",
                }}
              >
                Active Users
              </Tab>
              <Tab
                fontSize="xs"
                _selected={{
                  bg: "linkedin.500",
                  color: "white",
                }}
              >
                Pending Invitations
              </Tab>
            </TabList>
            <HStack alignItems="center">
              {tabIndex === 0 ? (
                <SearchBar key="user-search" {...userTableGridProps} />
              ) : (
                <SearchBar
                  key="invitation-search"
                  {...invitationTableGridProps}
                />
              )}
              <IconButton
                size="sm"
                onClick={onSuccess}
                aria-label="Refetch guide data"
                icon={<IoMdRefresh />}
                isLoading={loading}
              />
            </HStack>
          </HStack>
          <TabPanels overflowX="hidden">
            <TabPanel p={0}>
              <DataGrid.Composer table={userTableInstance}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Box
                    borderTop="1px solid"
                    borderColor="gray.100"
                    overflowX="scroll"
                  >
                    <DataGrid.Table
                      key="user-table"
                      // columns={}
                      // data={tableData.users}
                      loading={loading}
                      onRowClick={handleRowClick}
                    />
                    <DataGrid.Footer pagination />
                  </Box>
                </ErrorBoundary>
              </DataGrid.Composer>
            </TabPanel>
            <TabPanel p={0}>
              <DataGrid.Composer table={invitationsTableInstance}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Box
                    borderTop="1px solid"
                    borderColor="gray.100"
                    overflowX="scroll"
                  >
                    <DataGrid.Table
                      key="invitation-table"
                      loading={loading}
                      enableRowSelection
                    />
                    <DataGrid.Footer pagination />
                  </Box>
                </ErrorBoundary>
              </DataGrid.Composer>
            </TabPanel>
          </TabPanels>
        </VStack>
      </Tabs>
    </VStack>
  );
};

export default HeroListWidget;

const SearchBar = ({ globalFilter, setGlobalFilter }) => {
  // const { globalFilter, setGlobalFilter } = DataGrid.useDataGridContext();

  return (
    <DebouncedInput
      initialValue={globalFilter}
      onChange={setGlobalFilter}
      placeholder="Find hero"
    />
  );
};
