import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/modal";
import { Input } from "@chakra-ui/input";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import {
  Link as ChakraLink,
  VStack,
  Text,
  Heading,
  UnorderedList,
  ListItem,
} from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";

const ConsentFormModal = ({
  isOpen,
  onClose,
  onSave,
  onCancel,
  consentState,
  handleConsentChange,
  consentDoesNotMatch,
  signaturePlaceholder,
}) => {
  const { consentGiven } = consentState;

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader maxW="75%">
          Flowly Wellness App Use Case Evaluation Opportunity
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody bgColor="gray.50" pb={6}>
          <VStack spacing={8} align="flex-start" px={6} color="gray.500">
            <VStack>
              <Heading as="h1" size="lg" alignSelf="center" color="gray.700">
                CONSENT
              </Heading>
              <Text>
                You are being asked for your consent to take part in a use case
                of the Flowly wellness mobile app. This document describes the
                app and what participants will need to do in the use case. It
                provides all of the information we believe people need to decide
                whether they want to participate in the use case.
                <br />
                <br />
                In this consent form “you” generally refers to the participant,
                i.e., the person who will use the mobile app.
              </Text>
            </VStack>

            <VStack>
              <Heading size="lg" color="gray.700">
                What should I know about this opportunity?
              </Heading>
              <UnorderedList pl={5}>
                <ListItem>This form sums up that explanation.</ListItem>
                <ListItem>
                  Taking part in use case is voluntary. Whether you take part is
                  up to you.
                </ListItem>
                <ListItem>
                  You can choose not to take part. There will be no penalty or
                  loss of benefits for choosing not to participate.
                </ListItem>
                <ListItem>
                  Your health plan, health care providers and employer will not
                  be notified of your participation.
                </ListItem>
                <ListItem>
                  You can agree to take part and later change your mind. There
                  will be no penalty or loss of benefits for changing your mind.
                </ListItem>
                <ListItem>
                  This use case is not an emergency service. If you are having
                  an emergency, please call 911 or go to the nearest emergency
                  room.
                </ListItem>
                <ListItem>
                  This use case is not an emergency service. If you are having
                  an emergency, please call 911 or go to the nearest emergency
                  room.
                </ListItem>
              </UnorderedList>
            </VStack>

            <VStack align="flex-start">
              <Heading size="lg" color="gray.700">
                Why is this use case being done?
              </Heading>
              <Text>
                This use case is being done to evaluate the feasibility and user
                satisfaction of the Flowly app as a wellness solution.
              </Text>
            </VStack>

            <VStack align="flex-start">
              <Heading size="lg" color="gray.700">
                How long will I be participating in the use case?
              </Heading>

              <Text>
                You will participate in the use case for 8 weeks actually using
                the app and then some additional time for receiving equipment
                and participating in an offboarding survey. The entire use case
                will be no longer than 12 weeks maximum depending on when you
                sign up.
              </Text>
            </VStack>

            <VStack>
              <Heading size="lg" color="gray.700">
                What happens to me if I agree to use Flowly?
              </Heading>

              <Text>
                If you agree to participate in the Flowly use case, you will be
                asked to electronically sign this consent form. You will also
                receive a copy that you can download and keep for your records.
                <br />
                <br />
                It is important that you know that using Flowly is not a
                treatment for any diagnosis and is not meant to replace existing
                treatments. It is also not an emergency service. If you are
                having an emergency, please call 911 or go to the nearest
                emergency room. You should follow the advice of your doctor for
                all healthcare decisions.
                <br />
                <br />
                This use case will be conducted virtually. All interactions with
                Flowly will happen in the app, or on email/phone with the Flowly
                customer support.
                <br />
                <br />
                Here is what will happen in the use case:
              </Text>
            </VStack>
            <VStack align="flex-start">
              <Heading as="h3" size="sm" color="gray.700">
                App Usage Period:
              </Heading>

              <Text>
                After signing up, logging into the Flowly app on your phone, and
                tapping “I Received My Kit” in the app (after receiving your
                kit), an 8 week usage period will begin. The kit consists of a
                Virtual Reality headset and a heart rate monitor you will wear
                on your ear. You will use your Apple iPhone mounted into the
                headset along with the ear mounted heart rate monitor to conduct
                a virtual reality experience. After the 8 week period, you will
                lose access to your Flowly account. You will be asked to
                complete a survey after you sign up, after 4 weeks of using
                Flowly and after 8 weeks of using Flowly post-program.
              </Text>
            </VStack>
            <VStack align="flex-start">
              <Heading as="h3" size="sm" color="gray.700">
                Post-Program:
              </Heading>
              <Text>
                After the program ends, you will be asked to complete one final
                survey as a final step.
              </Text>
            </VStack>
            <VStack align="flex-start">
              <Heading as="h3" size="sm" color="gray.700">
                Use case Reminders & Mobile App Notifications:
              </Heading>
              <Text>
                You agree to allow Flowly to send you email and text
                notifications to the email and phone number provided during sign
                up. Through the Flowly app, you may opt-out to receive push
                notifications to your phone. These notifications include
                reminders to complete sessions, check-ins, and tips.
              </Text>
            </VStack>

            <VStack align="flex-start">
              <Heading size="lg" color="gray.700">
                What are my responsibilities if I take part in this use case?
              </Heading>

              <Text>
                If you take part in this use case, you will be responsible to:
                Complete the online surveys sent to you via email or text and
                utilize the Flowly app as desired over the 8 week period.
                Recommended use is 10-15 minutes daily.
              </Text>
            </VStack>

            <VStack align="flex-start">
              <Heading size="lg" color="gray.700">
                Could being a participant in this use case hurt me?
              </Heading>
              <Text>
                There are minimal risks associated with this use case. Possible
                risks include:
              </Text>
              <UnorderedList pl={5}>
                <ListItem>
                  Nausea due to the virtual reality experience
                </ListItem>
                <ListItem>
                  Facial pain due to the weight of the VR headset
                </ListItem>
              </UnorderedList>
            </VStack>

            <VStack align="flex-start">
              <Heading size="lg" color="gray.700">
                Will it cost me money to take part in this use case opportunity?
              </Heading>

              <Text>
                This use case is provided for your evaluation at no cost. The VR
                headset and heart rate monitor are yours to keep and do not need
                to be returned.
              </Text>
            </VStack>

            <VStack>
              <Heading size="lg" color="gray.700">
                What happens to the information collected during this use case?
              </Heading>
              <Text>
                All information collected will be saved by Flowly and no
                identifiable information will be shared with your employer.
                Information may be shared with your employer but only once
                de-identified and aggregated. Information collected in this use
                case will not be linked to your health plan or be sent to your
                healthcare provider.
              </Text>
            </VStack>

            <VStack>
              <Heading size="lg" color="gray.700">
                Who can answer my questions about this use case?
              </Heading>
              <Text>
                Questions about this use case can be raised with the Flowly
                team. You can reach the Flowly team by calling 323-545-3659 or
                by emailing contact@flowly.world.
              </Text>
            </VStack>

            <VStack align="flex-start">
              <Heading size="lg" color="gray.700">
                What happens if I agree to be in this use case, but I change my
                mind later?
              </Heading>
              <Text>
                You can change your mind at any point. Changing your mind will
                not affect your benefits in any way. Depending on what you would
                like to do once your mind has changed, you can reach out to the
                Flowly team by calling 323-545-3659 or by emailing
                contact@flowly.world. The Flowly team will do their best to
                accommodate your requests, however there is no guarantee that
                they will be able to fulfill your requests.
              </Text>
            </VStack>

            <VStack align="flex-start">
              <Text fontWeight="semibold" color="gray.700">
                By signing this consent form, you acknowledge and agree to the
                following:
              </Text>

              <UnorderedList pl={5}>
                <ListItem>
                  You have read it in its entirety and accept what is stated
                  within it.
                </ListItem>
                <ListItem>
                  You understand that signup for this use case is completely
                  voluntary and optional.
                </ListItem>
                <ListItem>
                  You acknowledge that you have completed the screening survey
                  truthfully.
                </ListItem>
                <ListItem>
                  You acknowledge that you have read and agree to Flowly&apos;s{" "}
                  <ChakraLink
                    color="blue.500"
                    href="https://www.flowly.world/terms-of-service"
                    isExternal
                  >
                    Terms of Service
                  </ChakraLink>{" "}
                  and{" "}
                  <ChakraLink
                    color="blue.500"
                    href="https://www.flowly.world/privacy-policy"
                    isExternal
                  >
                    Privacy Policy
                  </ChakraLink>
                </ListItem>
                <ListItem>You are over 18 years of age.</ListItem>
                <ListItem>
                  You will not share your Flowly access with anybody else.
                </ListItem>
              </UnorderedList>
            </VStack>

            <Text>
              To Sign the Consent Form, please provide your eSignature below.
            </Text>

            <FormControl
              color="black"
              isRequired
              isInvalid={consentDoesNotMatch}
            >
              <FormLabel fontWeight="semibold" color="gray.700">
                Signature
              </FormLabel>
              <Input
                variant="filled"
                placeholder={signaturePlaceholder || "John Doe"}
                value={consentGiven}
                onChange={handleConsentChange("consentGiven")}
              />
              {consentDoesNotMatch && (
                <FormErrorMessage>{consentDoesNotMatch}</FormErrorMessage>
              )}
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => onSave(consentGiven)}
            disabled={!consentGiven.length}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConsentFormModal;
