import { GiSprout, GiOakLeaf, GiFruitTree, GiRoundStar } from "react-icons/gi";
import { FaStripe, FaApplePay } from "react-icons/fa";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";

const SubscriptionIcons = {
  FREE: GiSprout,
  BASIC: GiOakLeaf,
  COMMUNITY: GiFruitTree,
  PRO: GiRoundStar,
};
const SubscriptionPlanNames = {
  FREE: "Free Trial",
  BASIC: "Basic Membership",
  COMMUNITY: "Community Membership",
  PRO: "Change My Life Membership",
};
const SubscriptionMethodIcons = {
  STRIPE: FaStripe,
  APPLE: FaApplePay,
  OTHER: BsFillCreditCard2FrontFill,
};

export { SubscriptionIcons, SubscriptionPlanNames, SubscriptionMethodIcons };
