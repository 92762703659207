import React, { useRef, useState } from "react";
import {
  Box,
  Link as ChakraLink,
  VStack,
  Grid,
  GridItem,
  Text,
  Stack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import Icon from "@chakra-ui/icon";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import { STEPS } from "../constants";
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";
import { redeemLicense } from "../services";

const RedeemVoucherLogin = ({
  navigate,
  handleChange,
  formState,
  updateFormState,
}) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const login = async () => {
    setLoading(true);
    try {
      await Auth.signIn(formState.email.toLowerCase(), formState.password);
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        window.analytics.identify(user.username, {
          email: user.attributes.email,
          name: user.attributes.given_name,
        });

        window.analytics.track("logged_in");
        firebase.analytics().logEvent("login");
      }

      await handleRedeemLicense();
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };
  const next = () => login();

  const goBack = () => navigate(STEPS.authDirect);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  const handleRedeemLicense = async () => {
    try {
      setLoading(true);
      const response = await redeemLicense(formState.orgId, {
        voucherCode: formState?.voucherCode,
        orderReferenceNumber: formState?.orderReferenceNumber,
      });
      if (response) {
        updateFormState("redeemSuccess", response);
        navigate(STEPS.thankyou);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={next}>
          <VStack mt="20" alignItems="flex-start" spacing={6}>
            <VStack alignItems="flex-start" spacing={1}>
              <H1>Welcome back!</H1>
              <Text fontSize="lg" color="gray.500">
                Login to get redeem your Flowly subscription.
              </Text>
            </VStack>
            <FormControl id="email">
              <FormLabel fontWeight="semibold">Email</FormLabel>
              <Text fontSize="lg" color="gray.700" mt="-1">
                {formState.email}
              </Text>
            </FormControl>
            <FormControl id="password">
              <FormLabel fontWeight="semibold">Password</FormLabel>
              <InputGroup>
                <Input
                  name="password"
                  type={isOpen ? "text" : "password"}
                  autoComplete="current-password"
                  placeholder="Password"
                  value={formState.password}
                  onChange={handleChange("password")}
                  size="lg"
                  required
                />
                <InputRightElement top="1">
                  <IconButton
                    bg="transparent !important"
                    variant="ghost"
                    aria-label={isOpen ? "Mask password" : "Reveal password"}
                    icon={isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />}
                    onClick={onClickReveal}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack
              direction={["column", "row", "row", "column", "row"]}
              alignItems="center"
              spacing={8}
              w="full"
            >
              <Button
                type="submit"
                onClick={next}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText={"Signing in..."}
                py="1rem"
                w={[
                  "full",
                  "fit-content",
                  "fit-content",
                  "full",
                  "fit-content",
                ]}
                px="20"
              >
                Sign in
              </Button>
              <ChakraLink onClick={goBack} color="gray.500">
                Use a different email
              </ChakraLink>
            </Stack>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        pt="16"
        pl="5vw"
        pr="10vw"
      >
        <Box display="grid" placeItems="center">
          <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemVoucherLogin;
