import React, { createContext, useContext, useMemo, useState } from "react";
import { Flex, Text, HStack, Box } from "@chakra-ui/layout";
import {
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/table";
import { Tooltip } from "@chakra-ui/tooltip";
import { IconButton } from "@chakra-ui/button";
import { useRadio, useRadioGroup } from "@chakra-ui/radio";
import {
  chakra,
  Input,
  InputGroup,
  InputRightElement,
  Checkbox,
  Divider,
  Badge,
  Button,
} from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  MenuGroup,
  MenuItemOption,
  MenuOptionGroup,
} from "@chakra-ui/menu";
import Icon from "@chakra-ui/icon";
import { Spinner } from "@chakra-ui/spinner";
import { Select } from "@chakra-ui/select";
import {
  useReactTable,
  getPaginationRowModel,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  flexRender,
} from "@tanstack/react-table";

/* icon imports */
import {
  FiChevronRight,
  FiChevronsRight,
  FiChevronLeft,
  FiChevronsLeft,
  FiPlusCircle,
} from "react-icons/fi";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { RiArrowUpDownLine } from "react-icons/ri";

/* local imports */

const getRowSelectColumnDef = (tableOptions) => ({
  id: "select",
  header: ({ table }) => (
    <Checkbox
      colorScheme="linkedin"
      isIndeterminate={table.getIsSomePageRowsSelected()}
      isChecked={table.getIsAllPageRowsSelected()}
      onChange={(evt) => table.toggleAllPageRowsSelected(!!evt.target.checked)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      colorScheme="linkedin"
      isChecked={row.getIsSelected()}
      onClick={(evt) => {
        evt.nativeEvent.stopImmediatePropagation();
        evt.stopPropagation(); // not working for some reason
      }}
      onChange={(evt) => {
        row.toggleSelected(!!evt.target.checked);
      }}
      aria-label="Select row"
    />
  ),
  enableSorting: false,
  enableHiding: false,
});

const DataGridContext = createContext({});
const useDataGridContext = () => useContext(DataGridContext);

const useDataGrid = (
  config = {
    enablePagination: true,
    enableRowSelection: false,
    columns: [],
    data: [],
  }
) => {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});

  const { enablePagination, enableRowSelection, columns, ...rest } = config;

  const stableColumns = useMemo(
    () => [
      ...[enableRowSelection && getRowSelectColumnDef()].filter(Boolean),
      ...columns,
    ],
    [enableRowSelection, columns]
  );

  console.log("rest", rest);

  const tableInstance = useReactTable({
    columns: stableColumns,
    // data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: enablePagination && getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      sorting,
      columnFilters,
      globalFilter,
      rowSelection,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    ...rest,
  });

  return {
    table: tableInstance,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    rowSelection,
    setRowSelection,
  };
};

const DataGridComposer = ({ children, table }) => {
  return (
    <DataGridContext.Provider value={{ ...table }}>
      {children}
    </DataGridContext.Provider>
  );
};

const Table = ({
  // table: tableInstance,
  isLoading = false,
  onRowClick = null,
  // columns,
  // data,
  // enablePagination = true,
  enableRowSelection = undefined,
  // loading,
}) => {
  // const {
  //   table: tableInstance,
  //   sorting,
  //   setSorting,
  //   columnFilters,
  //   setColumnFilters,
  //   globalFilter,
  //   setGlobalFilter,
  //   rowSelection,
  //   setRowSelection,
  // } = useDataGrid();

  // const tableInstance = useReactTable({
  //   columns: stableColumns,
  //   data,
  //   getCoreRowModel: getCoreRowModel(),
  //   getPaginationRowModel: enablePagination && getPaginationRowModel(),
  //   getFilteredRowModel: getFilteredRowModel(),
  //   getSortedRowModel: getSortedRowModel(),
  //   state: {
  //     sorting,
  //     columnFilters,
  //     globalFilter,
  //     rowSelection,
  //   },
  //   // defaultColumn: {
  //   //   minSize: -1,
  //   //   size: -1,
  //   //   maxSize: Number.MAX_SAFE_INTEGER,
  //   // },
  //   onSortingChange: setSorting,
  //   onColumnFiltersChange: setColumnFilters,
  //   onGlobalFilterChange: setGlobalFilter,
  //   onRowSelectionChange: setRowSelection,
  // });

  const tableInstance = useDataGridContext();
  // console.log("tableInstance", tableInstance);
  const { getHeaderGroups, getRowModel, getTotalSize, getAllColumns } =
    tableInstance;
  return (
    <ChakraTable
      // maxW="full"
      minW={getTotalSize()}
    >
      <Thead>
        {getHeaderGroups().map((headerGroup, i) => (
          <Tr
            key={headerGroup.id}
            // overflow="hidden"
            _hover={{
              bg: "gray.50",
              cursor: "pointer",
            }}
          >
            {headerGroup.headers.map((header, idx) => {
              return (
                <Th
                  key={header.id}
                  // w={header.getSize() === -1 ? header.getSize() : "auto"}
                  // w={
                  //   header.column.columnDef.meta?.size
                  //     ? header.column.columnDef.meta?.size
                  //     : "auto"
                  // }
                  isNumeric={header.column.columnDef.meta?.isNumeric}
                  onClick={header.column.getToggleSortingHandler()}
                  pl={enableRowSelection && idx === 0 ? 3 : 4}
                  pr={enableRowSelection && idx === 0 ? 0 : 4}
                  whiteSpace="nowrap"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}

                  {header.column.getCanSort() ? (
                    <chakra.span pl="4" display="inline">
                      {header.column.getIsSorted() === "desc" ? (
                        <Icon
                          as={HiOutlineArrowSmDown}
                          aria-label="sorted descending"
                        />
                      ) : header.column.getIsSorted() === "asc" ? (
                        <Icon
                          as={HiOutlineArrowSmUp}
                          aria-label="sorted ascending"
                        />
                      ) : (
                        <Icon as={RiArrowUpDownLine} aria-label="not sorted" />
                      )}
                    </chakra.span>
                  ) : null}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isLoading ? (
          <Tr>
            <Td
              colSpan={getAllColumns().length}
              h="24"
              w="full"
              textAlign="center"
            >
              <Spinner />
            </Td>
          </Tr>
        ) : getRowModel().rows?.length ? (
          getRowModel().rows.map((row, i) => {
            return (
              <Tr
                key={row.id}
                _hover={{
                  bg: "gray.50",
                  cursor: onRowClick ? "pointer" : "default",
                }}
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (onRowClick && evt.target.tagName === "TD") {
                    onRowClick(row);
                  }
                }}
              >
                {row.getVisibleCells().map((cell, idx) => {
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={cell.column.columnDef.meta?.isNumeric}
                      // px={enableRowSelection && idx === 0 ? 0 : 6}
                      pl={enableRowSelection && idx === 0 ? 3 : 4}
                      pr={enableRowSelection && idx === 0 ? 0 : 4}

                      // width={
                      //   cell.column.columnDef.meta?.size
                      //     ? cell.column.columnDef.meta?.size + "px"
                      //     : "auto"
                      // }
                      // w={
                      //   cell.column.getSize() === -1
                      //     ? cell.column.getSize()
                      //     : "auto"
                      // }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })
        ) : (
          <Tr>
            <Td
              colSpan={getAllColumns().length}
              h="24"
              w="full"
              textAlign="center"
            >
              <Text>No results.</Text>
            </Td>
          </Tr>
        )}
      </Tbody>
    </ChakraTable>
  );
};

const ColumnHeader = ({ column, title }) => {
  if (!column.getCanSort()) {
    return <chakra.div>{title}</chakra.div>;
  }

  return (
    <chakra.span pl="4" display="inline">
      {title}
      {column.getIsSorted() === "desc" ? (
        <Icon as={FaSortDown} aria-label="sorted descending" />
      ) : column.getIsSorted() === "asc" ? (
        <Icon as={FaSortUp} aria-label="sorted ascending" />
      ) : (
        <Icon as={FaSort} aria-label="not sorted" />
      )}
    </chakra.span>
  );
};

const Footer = ({ rowSelectionCount = undefined, pagination = undefined }) => {
  return (
    <HStack justify="space-between" p="4" w="full">
      {rowSelectionCount && <RowSelectionCount />}
      {pagination && <Pagination />}
    </HStack>
  );
};

const Pagination = () => {
  const {
    getCanNextPage,
    getPageOptions,
    getPageCount,
    getCanPreviousPage,
    previousPage,
    nextPage,
    setPageIndex,
    setPageSize,
    getState,
  } = useDataGridContext();

  return (
    <HStack w="full" justify="flex-end" spacing={12}>
      <Select
        w={32}
        size="sm"
        rounded="md"
        value={getState().pagination.pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>

      <HStack alignItems="center" spacing={1}>
        <Text flexShrink="0">Page</Text>
        <Text fontWeight="bold" as="span">
          {getState().pagination.pageIndex + 1}
        </Text>
        <Text>of</Text>
        <Text fontWeight="bold" as="span">
          {getPageOptions().length}
        </Text>
      </HStack>

      <HStack>
        <Tooltip label="First Page">
          <IconButton
            size="sm"
            onClick={() => setPageIndex(0)}
            isDisabled={!getCanPreviousPage()}
            icon={<Icon as={FiChevronsLeft} h={5} w={5} />}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            size="sm"
            onClick={previousPage}
            isDisabled={!getCanPreviousPage()}
            icon={<Icon as={FiChevronLeft} h={5} w={5} />}
          />
        </Tooltip>
        <Tooltip label="Next Page">
          <IconButton
            size="sm"
            onClick={nextPage}
            isDisabled={!getCanNextPage()}
            icon={<Icon as={FiChevronRight} h={5} w={5} />}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            size="sm"
            onClick={() => setPageIndex(getPageCount() - 1)}
            isDisabled={!getCanNextPage()}
            icon={<Icon as={FiChevronsRight} h={5} w={5} />}
          />
        </Tooltip>
      </HStack>
    </HStack>
  );
};

const RowSelectionCount = () => {
  const table = useDataGridContext();

  const { getFilteredRowModel, getFilteredSelectedRowModel } = table;

  return (
    <Text as="span" color="gray.500" w="full">
      {getFilteredSelectedRowModel().rows.length} of{" "}
      {getFilteredRowModel().rows.length} row(s) selected.
    </Text>
  );
};

const FacetedFilter = ({ column, title, options = [] }) => {
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = new Set(column?.getFilterValue());
  console.log("facets", facets);
  console.log("selectedValues", selectedValues);
  // console.log("options", options);

  const [findOption, setFindOption] = useState("");

  const handleSelectFilterValue = (option) => {
    const isSelected = selectedValues.has(option.value);
    if (isSelected) {
      selectedValues.delete(option.value);
    } else {
      selectedValues.add(option.value);
    }
    const filterValues = Array.from(selectedValues);
    console.log("filterValues", filterValues);
    column?.setFilterValue(filterValues.length ? filterValues : undefined);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        size="sm"
        border="1px dashed"
        color="gray.600"
        leftIcon={<Icon as={FiPlusCircle} />}
      >
        <HStack spacing={2}>
          <Text>{title}</Text>
          {selectedValues?.size > 0 && (
            <>
              <Divider orientation="vertical" h={4} />
              <Badge variant="solid" display={{ lg: "none" }}>
                {selectedValues.size}
              </Badge>
              <HStack
                spacing={1}
                display={{ base: "none", lg: "flex" }}
                className="hidden space-x-1 lg:flex"
              >
                {selectedValues.size > 2 ? (
                  <Badge variant="solid">{selectedValues.size} selected</Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge key={option.value} variant="solid">
                        {option.label}
                      </Badge>
                    ))
                )}
              </HStack>
            </>
          )}
        </HStack>
      </MenuButton>
      <MenuList pt={0} align="start">
        {/* <Box p={2}>
          <Input
            size="sm"
            placeholder="Filter options..."
            value={findOption}
            onChange={(evt) => setFindOption(evt.target.value)}
          />
        </Box>
        {findOption &&
        options.filter((option) => option.label.includes(findOption)).length ===
          0 ? (
          <Text>No results found.</Text>
        ) : null} */}
        {options
          // .filter((option) => option.label.includes(findOption))
          .map((option) => {
            const isSelected = selectedValues.has(option.value);
            return (
              <MenuItem
                key={option.value}
                onClick={() => handleSelectFilterValue(option)}
                command={facets?.get(option.value)}
                icon={option?.icon}
              >
                <Checkbox
                  isChecked={isSelected}
                  onChange={() => handleSelectFilterValue(option)}
                >
                  <span>{option.label}</span>
                </Checkbox>
                {/* <div
                // className={cn(
                //   "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                //   isSelected
                //     ? "bg-primary text-primary-foreground"
                //     : "opacity-50 [&_svg]:invisible"
                // )}
                >
                  <Icon as={CheckIcon} 
                    // className={cn("h-4 w-4")} 
                    />
                </div> */}
                {/* {option.icon && (
                  <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                )} */}
              </MenuItem>
            );
          })}
        {selectedValues.size > 0 && (
          <>
            <MenuDivider />
            <MenuItem
              onClick={() => column?.setFilterValue(undefined)}
              className="justify-center text-center"
            >
              Clear filters
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        // mb="2"
        cursor="pointer"
        borderWidth="1px"
        rounded="md"
        border="1px solid"
        borderColor="transparent"
        // boxShadow="base"
        _checked={{
          bg: "linkedin.500",
          color: "white",
          boxShadow: "md",
        }}
        _hover={{
          boxShadow: "base",
          border: "1px solid",
          borderColor: "gray.200",
        }}
        px="2"
        py="0.5"
        fontSize="sm"
        fontWeight="bold"
        // letterSpacing=""
      >
        {props.children}
      </Box>
    </Box>
  );
}

const FacetedTabFilter = ({ column, title, options = [] }) => {
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: `${title}-tab-filter`,
    defaultValue: selectedValue,
    onChange: (option) => {
      setSelectedValue(option);
      column?.setFilterValue(option);
    },
  });

  return (
    <HStack
      {...getRootProps()}
      spacing="0"
      borderColor="gray.200"
      borderWidth="1px"
      p="0.5"
      rounded="md"
      w="fit-content"
    >
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {label}
          </RadioCard>
        );
      })}
    </HStack>
  );
};

const DataGrid = {
  DropdownFilter: FacetedFilter,
  TabFilter: FacetedTabFilter,
  Footer,
  Composer: DataGridComposer,
  useDataGrid,
  useDataGridContext,
  Table,
};
// DataGrid.DropdownFilter = FacetedFilter;
// DataGrid.Footer = Footer;
// DataGrid.ColumnHeader = ColumnHeader;
// DataGrid.Composer = DataGridComposer;
// DataGrid.useDataGrid = useDataGrid;
// DataGrid.Table = DataGrid;

export default DataGrid;
