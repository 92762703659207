import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import styled from "styled-components";
import { get } from "lodash";
import { Box } from "@chakra-ui/react";

/* asset imports */
import vr from "assets/home/vr.png";
import support from "assets/home/support.png";
import analytics from "assets/home/analytics.png";
import screenshots from "assets/home/screenshots.png";
import check from "assets/home/hi_check-badge.svg";
import coach from "assets/home/coach.png";
import community from "assets/home/community.png";

/** local imports */
import { GET_JOURNEY_BY_ID } from "graphql/queries";
import { publicClient } from "utils/awsConfig";
import {
  CardContent,
  Center,
  Feature,
  Features,
  HomeStyle,
  // PlanSelector,
  WhiteContainer,
} from "pages/Checkout/components";

import H2 from "common/H2";
import H3 from "common/H3";
import Spinner from "common/Spinner";
// import Card from "common/Card";
import Button from "common/Button";

import { device } from "utils/device";
import { toSentenceCase } from "utils/string";

const calculateDiscount = (initialPrice, current) =>
  (((initialPrice - current) / initialPrice) * 100).toFixed(0);

const JourneyLanding = () => {
  const { journeyId } = useParams();

  const [selected, setSelectedPlan] = useState({ subscriptionType: "MONTHLY" });

  const { data: journey, isLoading: journeyLoading } = useJourney(journeyId, {
    variables: {
      id: journeyId,
    },
  });

  const productsToDisplay = useMemo(() => {
    if (journeyLoading) {
      return [];
    }

    if (
      !!journey.featuresConfiguration &&
      typeof journey.featuresConfiguration === "string"
    ) {
      journey.featuresConfiguration = JSON.parse(journey.featuresConfiguration);
    }

    const offeredProducts = get(journey, ["subscriptionProducts", "items"]);

    const activeOffers = offeredProducts
      .filter((prod) => prod.subscriptionMethod === "STRIPE")
      .sort((a, b) => a.price - b.price);

    if (activeOffers.length === 0) {
      return [];
    }

    return activeOffers;
  }, [journey, journeyLoading]);

  const currentActivePlan = get(productsToDisplay, [0]);
  const maintenanceProduct = get(productsToDisplay, [
    0,
    "journeySubscriptionProduct",
  ]);

  useEffect(() => {
    if (productsToDisplay.length && !selected.id) {
      setSelectedPlan(() => maintenanceProduct);
    }
  }, [productsToDisplay, selected, maintenanceProduct]);

  const { title } = journey || {
    title: "",
    description: "",
  };
  return (
    <>
      <HomeStyle>
        <HeroStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 className="title">{title}</h1>
            <p className="description">
              a curated 3-month program designed by psychologists and pain
              specialists with new lessons, trainings, and experiences daily.
              Access to a Flowly coach included.
              {/* {description} */}
            </p>
          </div>

          {journeyLoading ? (
            <Container maxWidth="2xl">
              <Spinner />
            </Container>
          ) : (
            <Container maxWidth="md">
              <Card>
                <Box
                  style={{
                    backgroundColor: "#304fa7",
                    padding: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "2em",
                  }}
                  bg="#304fa7"
                  pl="2rem"
                  pr="2rem"
                >
                  <h3
                    style={{
                      color: "#EFF6FF",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                      letterSpacing: "-0.025rem",
                      margin: 0,
                    }}
                    // className="subheader"
                  >
                    12-Week Program Fee
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {!!currentActivePlan?.initialPrice && (
                      <p
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: 400,
                          color: "#EFF6FF",
                          textDecoration: "line-through",
                          letterSpacing: "-0.0375rem",
                          marginBlock: "0.2em",
                        }}
                        className="prev"
                      >
                        ${currentActivePlan?.initialPrice}
                      </p>
                    )}
                    <h2
                      style={{
                        fontSize: "3.5rem",
                        lineWeight: 400,
                        color: "white",
                        marginBlock: "0.4em",
                        marginBlockEnd: "0.6em",
                        // marginTop: "0.4rem",
                        // marginBottom: "1.45rem",
                      }}
                    >
                      ${currentActivePlan?.price || 0}
                    </h2>
                    {!!currentActivePlan?.initialPrice && (
                      <div
                        style={{
                          display: "flex",
                          padding: "0.25rem 0.5rem",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "0.5rem",
                          border: "1px solid #F9FAFB",
                          color: "#F9FAFB",
                          fontSize: "1rem",
                          fontWeight: 500,
                        }}
                      >
                        Save $
                        {Math.round(currentActivePlan?.initialPrice || 0) -
                          Math.round(currentActivePlan?.price || 0)}{" "}
                        (
                        {calculateDiscount(
                          currentActivePlan?.initialPrice,
                          currentActivePlan?.price
                        )}
                        % off)
                      </div>
                    )}
                  </div>
                </Box>
                <div
                  style={{
                    paddingTop: "2rem",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    paddingBottom: "22px",
                  }}
                >
                  <CardContent>
                    <h3 className="subheader">Includes</h3>
                    <PlanSelector>
                      {[maintenanceProduct].map(
                        ({
                          id,
                          title = "",
                          description = "",
                          subscriptionType,
                          price,
                          timeUnits,
                          initialPrice = 396.99,
                          trialMonths,
                          ...rest
                        }) => (
                          <button
                            key={id}
                            onClick={() =>
                              setSelectedPlan({
                                id,
                                subscriptionType,
                                price,
                                timeUnits,
                                initialPrice,
                                trialMonths,
                                ...rest,
                              })
                            }
                            className={`card ${
                              id === selected.id ? "selected" : null
                            }`}
                          >
                            {/* {!!trialMonths && (
                              <div
                                className={`badge ${
                                  id === selected.id ? "selected" : null
                                }`}
                              >
                                {`First ${
                                  trialMonths > 1 ? trialMonths : ``
                                } ${timeUnits.toLowerCase()}${
                                  trialMonths > 1 ? `s` : ``
                                } free`}
                              </div>
                            )} */}
                            <input
                              type="radio"
                              name="membership"
                              value={id}
                              checked={id === selected.id}
                            />
                            <div id="cardBody">
                              <div>
                                <h4 style={{ color: "#43435E" }}>
                                  {title
                                    ? toSentenceCase(title)
                                    : toSentenceCase(subscriptionType) +
                                      " Membership"}
                                </h4>
                                <p className="productDescription">
                                  {description}
                                </p>
                              </div>
                              <span className="prices">
                                {!!initialPrice && !trialMonths ? (
                                  <>
                                    <p className="prev">${initialPrice}</p>
                                    <p style={{ color: "#43435E" }}>
                                      ${price}/{timeUnits.toLowerCase()}
                                    </p>
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      marginLeft: "0",
                                    }}
                                  >
                                    <p className="prev">
                                      ${price}/{timeUnits.toLowerCase()}
                                    </p>
                                    <p
                                      style={{
                                        color: "#43435E",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Free for {trialMonths}{" "}
                                      {timeUnits.toLowerCase()}s
                                    </p>
                                  </span>
                                )}
                              </span>
                            </div>
                          </button>
                        )
                      )}
                    </PlanSelector>
                    <hr className="lightDivider" />

                    {journey.featuresConfiguration.map(
                      ({ title, features }, idx) => (
                        <div key={title} className="checkmarks">
                          <h3 className="subheader">{title}</h3>
                          {features.map(
                            ({
                              title: featureTitle,
                              subTitle,
                              imageUrl = null,
                            }) => (
                              <div
                                key={featureTitle}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ width: "100%" }}>
                                  <div className="check">
                                    <img src={check} alt="Checkmark" />
                                    {featureTitle}
                                  </div>
                                  <p className="featureDescription">
                                    {subTitle}
                                  </p>
                                </div>
                                {!!imageUrl && (
                                  <img
                                    className="featurePhoto"
                                    src={imageUrl}
                                    alt={featureTitle}
                                  />
                                )}
                              </div>
                            )
                          )}
                          {idx !== journey.featuresConfiguration.length - 1 && (
                            <hr className="lightDivider" />
                          )}
                        </div>
                      )
                    )}
                  </CardContent>
                  <div style={{ marginTop: "2rem" }}>
                    <Button
                      to={{
                        pathname: `/checkout/${currentActivePlan?.subscriptionType}/${currentActivePlan?.id}`,
                        state: {
                          from: "JOURNEY_CHECKOUT",
                          selected: currentActivePlan,
                        },
                      }}
                    >
                      Continue to Checkout
                    </Button>
                  </div>
                </div>
              </Card>
            </Container>
          )}
        </HeroStyle>

        <WhiteContainer>
          <Features>
            <Feature>
              <img src={vr} alt="Virtual Reality" />
              <p>
                designed to relax and help you better manage your nervous system
              </p>
            </Feature>
            <Feature className="center">
              <img src={analytics} alt="Analytics" />
              <p>actually see how your body is doing in your data portal</p>
            </Feature>
            <Feature>
              <img src={support} alt="Support" />
              <p>in our in-app community and from our health coach</p>
            </Feature>
          </Features>

          <Center>
            <div className="image">
              <img src={screenshots} alt="App Screenshots" />
            </div>

            <div className="content">
              <H2>flowly app</H2>
              <p>
                {/* copy here <br /> */}
                <br />
              </p>
              <div className="item">
                <H3>Experience relaxation</H3>
                <p>
                  in immersive Virtual Reality worlds designed and tested for
                  optimal comfort and relaxation
                </p>
              </div>
              <div className="item">
                <H3>Balance your nervous system</H3>
                <p>
                  by re-learning stress responses through biofeedback methods to
                  influence various body metrics including HR, HRV, and
                  respiration in every session.
                </p>
              </div>
              <div className="item">
                <H3>Track your progress</H3>
                <p>
                  in a data portal that tracks your biometrics, streaks, and
                  journal notes.
                </p>
              </div>
            </div>
          </Center>

          <div className="support-message">
            <H2>We Support You</H2>
            <p>
              Our Flowly community is growing every day and we want to give you
              the access to tools, processes, methods, and support you need to
              feel supported on your health journey
            </p>
          </div>

          <div className="app-features">
            <div className="left">
              <H3>Access our Flowly Therapists</H3>
              <p>
                Call or Text our Flowly Therapists to get help setting up,
                receive advice about how to best integrate Flowly into a ritual,
                and more.
              </p>
              <div className="image">
                <img src={coach} alt="Coach"></img>
              </div>
            </div>
            <div className="right">
              <H3>Share in our Community</H3>
              <p>Become part of our safe, restricted in-app community.</p>
              <div className="image">
                <img src={community} alt="Community"></img>
              </div>
            </div>
          </div>
        </WhiteContainer>
      </HomeStyle>
    </>
  );
};

export default JourneyLanding;

function useJourney(id, config) {
  const {
    data: res,
    isError,
    isLoading,
    ...rest
  } = useQuery(`journey-${id}`, () => getJourney(config));

  const journey = !isError && !isLoading ? res.data.getJourney : {};

  return { data: journey, isError, isLoading, ...rest };
}

const getJourney = (config) =>
  publicClient.query({
    query: GET_JOURNEY_BY_ID,
    ...config,
  });

const HeroStyle = styled.header`
  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 10px;
  padding: 0 3vw;
  @media ${device.tablet} {
    padding: 0 10vw;
  }
  margin-bottom: 180px;
  .image {
    grid-column: span 12;
    img {
      width: 100%;
    }

    @media ${device.laptopL} {
      grid-column: span 6;
    }
  }
  .card {
    grid-column: span 12;

    @media ${device.laptopL} {
      // padding-left: 5vw;
      grid-column: span 6;
    }
  }

  .title {
    font-size: 2.5rem;
    line-height: 120%;
    font-family: Tommy Soft;
    font-weight: 700;
    color: #5072ec;
    text-align: center;
    max-width: 1024px;

    @media ${device.laptopL} {
      font-size: 3.2rem;
      line-height: 3.5rem;
    }
  }

  .description {
    font-family: "Tommy Soft";
    font-size: 1rem;
    font-weight: normal;
    color: rgba(75, 85, 99, 0.85);
    text-align: left;
    margin: -1.7rem 0 2rem 0;
    max-width: 375px;

    @media ${device.tablet} {
      font-size: 1.28rem;
      line-height: 1.2em;
      max-width: 768px;
    }
  }
`;

const Card = styled.div`
  filter: drop-shadow(0px 2px 1.25rem rgba(0, 44, 129, 0.2));
  background: #ffffff;
  border-radius: 1.5rem;
  flex: 1;
  margin: 0px 10px;
  // padding-top: 2rem;
  // padding-left: 1.5rem;
  // padding-right: 1.5rem;
  // padding-bottom: 22px;
  position: relative;
  overflow: hidden;
`;

const PlanSelector = styled.div`
  width: 100%;

  // reset button styling
  button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .card {
    min-height: 6.5rem;
    position: relative;
    padding: 0.75rem;
    border: 3px solid rgba(243, 244, 246, 1);
    border-radius: 8px;
    box-shadow: 0px 2px 5px 0.2px rgba(0, 44, 129, 0.2);
    margin-top: 1rem;
    :not(:first-child) {
      margin-top: 1.5rem;
    }

    .badge {
      position: absolute;
      right: 18px;
      top: 0;
      transform: translateY(-50%);
      padding: 0.25rem 2rem;
      border-radius: 1rem;
      height: 25px;
      display: flex;
      align-items: center;
      background-color: rgba(243, 244, 246, 1);
      color: rgba(156, 163, 175, 1);
    }
    .badge.selected {
      background-color: rgba(248, 194, 110, 1);
      color: white;
    }

    #cardBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }
  }
  .card.selected {
    border-color: #93c5fd;
    background-color: #eff6ff;
    // box-shadow: 0px 2px 20px 0.2px rgba(0, 44, 129, 0.2);
  }
  .card h4 {
    margin: 0 0;
    font-family: Nunito;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .prices {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: 0;
  }

  .prices p {
    margin: 0;
    font-family: Nunito;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;

    &.prev {
      text-decoration: line-through;
      color: rgba(0, 0, 0, 0.3);
      margin-right: 0.25em;
    }
  }

  .productDescription {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: #000a;
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) =>
    breakpoints[props.maxWidth] ? breakpoints[props.maxWidth] : breakpoints.md};
`;

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};
