import React from "react";
import { Grid } from "@chakra-ui/react";

const ChatComposer = ({ children, ...rest }) => {
  return (
    <Grid
      templateColumns={{ lg: "repeat(6, 1fr)" }}
      rounded="3xl"
      bg="white"
      shadow="lg"
      // overflow="hidden"
      height="2xl"
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default ChatComposer;
