import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase/app";
import "firebase/analytics";
import "utils/gtm";

require("firebase/analytics");
var firebaseConfig = {
  apiKey: "AIzaSyBOspQ-dh-KYdY9yptGw0sL9LoSA7kxA5E",
  authDomain: "flowly-e87a0.firebaseapp.com",
  databaseURL: "https://flowly-e87a0.firebaseio.com",
  projectId: "flowly-e87a0",
  storageBucket: "flowly-e87a0.appspot.com",
  messagingSenderId: "218552334918",
  appId: "1:218552334918:web:2d0d38577aab6518626847",
  measurementId: "G-HSZ7NPT35X",
};

// firebase.initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
