import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import QRCode from "react-qr-code";

/* icon imports */
import { FaPhoneAlt } from "react-icons/fa";

/* asset imports */
import { ReactComponent as ConfirmationBg } from "assets/confirmation/mountain_landscape_withEbbi.svg";
import appstore from "assets/confirmation/appstore.png";

/* local imports */
import Spinner from "common/Spinner";
import { device } from "utils/device";
import { getDeepLink } from "utils/firebase";
import { removeItemLocalStorage } from "utils/localStorage";
import { APPSTORE_LINK, PLAYSTORE_LINK } from "constants/downloadApp";

const ConfirmationStyle = styled.div`
  min-height: 100%;
  align-items: stretch;
  background: linear-gradient(
    180deg,
    rgba(216, 229, 245, 0.19) -2.75%,
    #d5e9f8 42%,
    #fcf0f3 85%
  );

  padding: 0 8vw;

  padding-top: 4rem;
  padding-bottom: 1rem;

  .congrats {
    text-align: center;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 1.5em;
    background: -webkit-linear-gradient(90deg, #66bde4 -0.35%, #627ac4 99.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .semibold {
    font-weight: 600;
  }
  .centered {
    margin: 1rem auto;
    text-align: center;

    width: 100%;
  }

  .deepLink {
    display: none;

    @media ${device.tablet} {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      margin-top: 1rem;
      align-items: center;
    }
  }

  h2 {
    color: hsla(229, 72%, 62%, 1);
    font-size: 2.5rem;
    text-align: center;

    @media ${device.tablet} {
      font-size: 3rem;
      text-align: left;
      line-height: 1em;
      margin-block-end: -1.25rem;
    }
  }

  .subtitle {
    display: none;

    @media ${device.tablet} {
      display: block;
      font-size: 1.75rem;
      line-height: 1.2em;
    }
  }

  .step-downloadApp {
    margin: 1rem auto;
    text-align: center;
    width: 100%;

    @media ${device.tablet} {
      margin: 0;
      text-align: left;
    }
  }
  .step-checkin {
    margin-right: 2rem;
    text-align: center;
    width: 100%;

    @media ${device.tablet} {
      text-align: left;
    }
  }
  .step-orientation {
    text-align: center;

    @media ${device.tablet} {
      text-align: left;
    }
  }

  .app-screenshot {
    display: none;

    @media ${device.tablet} {
      display: block;
      padding: 2rem;
    }
  }

  .flex {
    display: flex;
  }

  a {
    display: block;
    font-family: Nunito;
    font-size: 18px;
    line-height: 1.5rem;
    color: #5072ec;
    text-decoration: none;
    margin-bottom: -0.4rem;
  }
  .appstore {
    width: 100%;

    @media ${device.mobileL} {
      max-width: 250px;
    }
  }
  p {
    font-family: Nunito;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: hsla(216, 39%, 37%, 1);

    @media ${device.laptop} {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
  .icon-container {
    display: flex;
    aspect-ratio: 1 / 1;
    background-color: rgba(190, 227, 248, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
  }
  .call-actions {
    width: 216px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  .desktop {
    display: none;

    @media ${device.tablet} {
      display: inherit;
    }
  }
`;

const ThankYou = styled.div`
  font-family: "Tommy Soft";
  font-weight: bold;
  color: hsla(216, 39%, 37%, 1);
  text-align: center;
  font-size: 3rem;
  line-height: 1em;

  @media ${device.laptop} {
    font-size: 5rem;
    line-height: 1em;
  }
`;

const StyledConfirmationBg = styled(ConfirmationBg)`
  width: 100%;
  height: 100%;
  margin-bottom: -20px;
`;

const Grid = styled.div`
  max-width: 100%;
  margin-top: 3rem;
  display: grid;
  column-gap: 1.875rem;
  row-gap: 2rem;

  @media ${device.laptop} {
    padding: 0 2vw;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.laptopL} {
    padding: 0 10vw;
  }
`;

const PrimaryButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: hsla(232, 89%, 66%, 1);
  padding: ${({ size }) => {
    if (size === "md") return "0.5rem 2rem";
    return "1rem 3rem";
  }};
  font-size: ${({ size }) => {
    if (size === "md") return "0.875rem";
    return "1.5rem";
  }};
  font-family: "Nunito";
  font-weight: 600;
  color: white;
  border-radius: ${({ borderRadius }) => {
    if (borderRadius === "sm") return "0.5rem";
    return "50px";
  }};

  :hover {
    background-color: hsla(232, 89%, 56%, 1);
  }
`;
const DownloadButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: hsla(232, 89%, 66%, 1);
  padding: 1rem 3rem;
  font-size: 1.5rem;
  font-family: "Nunito";
  font-weight: 600;
  color: white;
  border-radius: 50px;

  :hover {
    background-color: hsla(232, 89%, 56%, 1);
  }

  @media ${device.tablet} {
    display: none;
  }
`;

const IsMobile = styled.div`
  display: block;
  text-align: center;

  @media ${device.tablet} {
    display: none;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function deviceType() {
  const ua = navigator.userAgent;

  if (/iP(hone|od)/.test(ua)) return "apple";
  else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
    return "tablet";
  else if (
    /Mobile|Android|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  )
    return "mobile";

  return "desktop";
}

const CALENDLY_LINK =
  "https://calendly.com/flowlyworld/flowly-guide-orientation-call";

function Confirmation() {
  const [links, setLinks] = useState({
    shortLink: APPSTORE_LINK,
    previewLink: null,
  });
  const [loading, setLoading] = useState(false);
  const [platform] = useState(() => deviceType());
  const { state: formState } = useLocation();

  useEffect(() => {
    removeItemLocalStorage(["promo", "referrer"]);
  }, []);

  useEffect(() => {
    setLoading(true);
    getDeepLink(formState?.email ?? "")
      .then((res) => {
        setLinks(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [formState]);

  return (
    <PageContainer>
      <ConfirmationStyle>
        <p className="congrats">Congratulations!</p>
        <ThankYou>your journey starts now</ThankYou>
        <p className="centered semibold">
          Your Flowly Kit will arrive in 3 - 5 business days. Meanwhile, please…
        </p>
        <Grid>
          <div className="flex">
            <div className="desktop call-actions">
              <div className="icon-container">
                <FaPhoneAlt
                  color="#2B6CB0"
                  size="2.5rem"
                  style={{ padding: "2rem" }}
                />
              </div>
              <PrimaryButton
                className="desktop centered"
                size="md"
                borderRadius="sm"
                onClick={() => window.open(CALENDLY_LINK, "_blank")}
              >
                Schedule a call
              </PrimaryButton>
            </div>
            <div className="step-orientation">
              <h2>Step 1</h2>
              <IsMobile>
                <PrimaryButton
                  onClick={() => window.open(CALENDLY_LINK, "_blank")}
                  className="centered"
                >
                  Schedule a call
                </PrimaryButton>
              </IsMobile>
              <p className="subtitle semibold">Book your Orientation call</p>
              {/* <IsMobile>
                <img height="254px" src={appScreenshot} alt="" />
              </IsMobile> */}
              <p>
                A Flowly Guide will onboard you and help answer questions on how
                Flowly can help meet your goals.
              </p>
            </div>
          </div>
          {/* <div className="flex">
            <img
              width="194px"
              height="254px"
              src={appScreenshot}
              alt=""
              className="app-screenshot"
            />
            <div className="step-checkin">
              <h2>Step 1</h2>
              <IsMobile>
                <img height="254px" src={appScreenshot} alt="" />
              </IsMobile>
              <p>
                Complete your daily check-in. (This will take less than a
                minute!)
              </p>
            </div>
          </div> */}
          <div className="flex">
            <div className="deepLink">
              {loading ? (
                <Spinner className="centered" />
              ) : (
                <a href={links.shortLink}>
                  <QRCode
                    value={links.shortLink || APPSTORE_LINK}
                    fgColor="#6282c7"
                    bgColor="transparent"
                    level="M"
                    size={132}
                  />
                </a>
              )}
              <a href={links.shortLink}>
                <img width="152px" src={appstore} alt="App Store" />
              </a>
              <a href={PLAYSTORE_LINK}>
                <img
                  alt="Get it on Google Play"
                  width="152px"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
            <div className="step-downloadApp">
              <h2>Step 2</h2>
              {platform === "apple" && (
                <DownloadButton
                  onClick={() => window.open(links.shortLink, "_blank")}
                  className="centered"
                >
                  Download Flowly
                </DownloadButton>
              )}
              {platform !== "apple" ? (
                <IsMobile>
                  {loading ? (
                    <Spinner className="centered" />
                  ) : (
                    <a href={links.shortLink}>
                      <QRCode
                        value={links.shortLink || APPSTORE_LINK}
                        fgColor="#6282c7"
                        bgColor="transparent"
                        level="M"
                        size="132"
                      />
                    </a>
                  )}
                </IsMobile>
              ) : null}
              <p className="subtitle semibold">
                Scan the QR code on your iPhone
              </p>
              <IsMobile>
                {platform !== "apple" ? (
                  <p>
                    Scan the QR code above on your iPhone to download the
                    Flowly: Relaxation training app on the App Store
                  </p>
                ) : (
                  <p>
                    Tap the button to download the Flowly: Relaxation training
                    app on the App Store
                  </p>
                )}
              </IsMobile>
              <p className="desktop">
                to download the Flowly: Relaxation training app on the App Store
              </p>
            </div>
          </div>
        </Grid>
        <a href="https://www.flowly.world/" className="centered">
          Back to home page
        </a>
      </ConfirmationStyle>
      <StyledConfirmationBg />
    </PageContainer>
  );
}

export default Confirmation;
