import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import {
  Box,
  Link as ChakraLink,
  Grid,
  GridItem,
  HStack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { Spinner } from "@chakra-ui/spinner";
import styled from "styled-components";
import qs from "qs";

/* asset imports */
import check from "assets/home/check.svg";
import FlowlyProKit from "assets/home/hero.png";

/* local imports */
import { publicClient } from "utils/awsConfig";
import { device } from "utils/device";
import { toSentenceCase } from "utils/string";
import {
  SUBSCRIPTION_PRODUCT_TYPES,
  SUBSCRIPTION_PLAN_TYPES,
} from "pages/Checkout/constants";
import { GET_SUBSCRIPTION_PRODUCTS } from "graphql/queries";
import { STEPS } from "../UserInvitation";

// TODO: Subscription plan, type, etc. constants need to be hoisted to @constants root dir
export const SUBSCRIPTIONS = {
  monthly: "MONTHLY",
  yearly: "YEARLY",
};

// TODO: to replace current implementation once react-query branch is merged
// const getSubscriptionProducts = () =>  publicClient.query({
//   query: GET_SUBSCRIPTION_PRODUCTS,
// });

const InviteSubscription = ({ navigate, updateFormState, formState }) => {
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const history = useHistory();

  const [products, setProducts] = useState([]);
  const [selected, setSelectedPlan] = useState({ subscriptionType: "YEARLY" });
  const [loading, setLoading] = useState(false);

  const toShipping = () => {
    updateFormState("selectedPlan", selected);
    navigate(STEPS.shipping);
  };
  const toThankYou = () => navigate(STEPS.thankyou);

  const select = (subType) => {
    history.replace({
      pathname: url,
      search: qs.stringify({
        ...qs.parse(search, { ignoreQueryPrefix: true }),
        plan: subType,
      }),
    });
    setSelectedPlan(subType);
  };

  const calculateDiscount = (initialPrice, current) =>
    (((initialPrice - current) / initialPrice) * 100).toFixed(0);

  useEffect(() => {
    // TODO: replace with react-query implementation
    const fetchProductList = async () => {
      setLoading(true);
      try {
        console.log("getting list of products");
        const subscriptionProds = await publicClient.query({
          query: GET_SUBSCRIPTION_PRODUCTS,
        });

        const activeOfferings =
          subscriptionProds.data.listSubscriptionProducts.items
            .filter(
              (item) =>
                !!item.active &&
                item.subscriptionMethod === "STRIPE" &&
                item.duration === 1 &&
                item.subscriptionProductType ===
                  SUBSCRIPTION_PRODUCT_TYPES.STANDARD &&
                item.subscriptionPlan === SUBSCRIPTION_PLAN_TYPES.PRO
            )
            .sort((a, b) => a.price - b.price);

        setSelectedPlan(
          () =>
            activeOfferings.find(
              (offering) => offering.subscriptionType === "YEARLY"
            ) ?? { subscriptionType: "YEARLY", id: 1 }
        );
        console.log("list of products", activeOfferings);
        setProducts(activeOfferings);
      } catch (error) {
        console.error("[SubscriptionProducts]: Fetch list failed", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProductList();
  }, []);

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem
        colSpan={[12, 12, 12, 6, 6]}
        pr="5vw"
        pl="10vw"
        bg="white"
        pt="20"
      >
        <div className="card">
          {/* <Card> */}
          <CardContent>
            <h1 className="header">
              Get the best experience with the Flowly Pro Kit
            </h1>
            <h3 className="subheader">What&apos;s Included</h3>
            <div className="checkmarks">
              <div className="check">
                <img src={check} alt="Checkmark" />
                Mobile Virtual Reality Headset
              </div>
              <div className="check">
                <img src={check} alt="Checkmark" />
                Bluetooth Realtime Heart Rate Biosensor
              </div>
              <div className="check">
                <img src={check} alt="Checkmark" />
                Access to all Flowly app experiences and features
              </div>
            </div>
            <div className="mt-1">
              <h3 className="subheader">Subscriptions</h3>
              <PlanSelector>
                {loading ? (
                  <Box
                    w="full"
                    flex
                    justify="center"
                    align="center"
                    bg="gray.50"
                    borderWidth="1px"
                    borderColor="gray.200"
                    py="5rem"
                    rounded="md"
                    my="2"
                  >
                    <Spinner />
                  </Box>
                ) : (
                  products.map(
                    ({
                      id,
                      title = "",
                      description = "",
                      subscriptionType,
                      price,
                      timeUnits,
                      initialPrice = 396.99,
                      ...rest
                    }) => (
                      <button
                        key={id}
                        onClick={() =>
                          setSelectedPlan({
                            id,
                            subscriptionType,
                            price,
                            timeUnits,
                            initialPrice,
                            ...rest,
                          })
                        }
                        className={`card ${
                          id === selected.id ? "selected" : null
                        }`}
                      >
                        {!!initialPrice && (
                          <div
                            className={`badge ${
                              id === selected.id ? "selected" : null
                            }`}
                          >
                            Save {calculateDiscount(initialPrice, price)}%
                          </div>
                        )}
                        <div id="cardBody">
                          <div>
                            <h4>{toSentenceCase(title ?? subscriptionType)}</h4>
                            <p className="productDescription">{description}</p>
                          </div>
                          <span className="prices">
                            {!!initialPrice && (
                              <p className="prev">${initialPrice}</p>
                            )}
                            <p>
                              ${price}/{timeUnits.toLowerCase()}
                            </p>
                          </span>
                        </div>
                      </button>
                    )
                  )
                )}
              </PlanSelector>
            </div>
            <p>
              Current Flowly memberships all include the Flowly Pro Kit free of
              charge (worth $85!).
            </p>
          </CardContent>
          <HStack alignItems="center" spacing={8} mb={16}>
            <Button
              type="submit"
              onClick={toShipping}
              variant="solid"
              bg="#5072ec"
              colorScheme="blue"
              rounded="xl"
              height="100%"
              py="1rem"
              px="110px"
            >
              Proceed to Checkout
            </Button>
            <ChakraLink onClick={toThankYou} color="gray.400">
              Skip for now
            </ChakraLink>
          </HStack>
          {/* </Card> */}
        </div>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        pt="16"
        pl="5vw"
        pr="10vw"
      >
        <Box display="grid" placeItems="center">
          <Image src={FlowlyProKit} maxW="30vw" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default InviteSubscription;

const CardContent = styled.div`
    position: relative;
    padding: 0 0.865rem;
    @media ${device.mobileL} {
      padding: 0 1.25rem;
    }


    .header {
      font-family: "Tommy Soft";
      font-size: 2.75rem;
      line-height: 2.8rem;
      font-weight: bold;
      color: #5072ec;
      text-align: left;
      margin: 0.25rem 0 1rem 0;
      
      @media ${device.mobileL} {
        font-size: 2.26rem;
        line-height: 44.96px;
      }

    }
    .subheader {
      margin: 0 0;
      font-family: "Tommy Soft";
      font-weight: 700;
      font-size: 1rem;
      line-height: 19.36px;
      color: rgba(156, 163, 175, 0.76);
      text-transform: uppercase;
      letter-spacing: 0.12em;
      text-align: left;
    }

    .strike {
      display: inline-block;
      margin-left: 4px;
      margin-right: 4px;
      position: relative;
      ::before {
        content: "";
        border-bottom: 2px solid #5072ec;
        position: absolute;
        left: -2px;
        right -2px;
        margin-top: 0.7em;
      }
    }
    .free {
      font-family: "Tommy Soft";
      font-weight: 500;
      line-height: 26px;
      display: inline-block;
      align-items: center;
      color: #ffffff;
      background: #5072ec;
      border-radius: 2px;
      padding: 2px 9px;
      font-size: 1rem;
      @media ${device.mobileL} {
        font-size: 1.25rem;
      }


    }
    .checkmarks{
      margin: 0.75rem 0 0 0;
    }
    .mt-1 {
      margin-top: 28px;
    }
    .mb-1 {
      margin-bottom: 28px;
    }
    .check {
        padding: 0.5rem 0px;
        display: flex;
        align-items: flex-start;
        font-size: 1.25rem;
        line-height: 1.5rem;
        img {
          margin-top: 2px;
          margin-right: 12px;
        }
        
        @media ${device.mobileL} {
          font-size: 1.125rem;
        }
    }
    p{
      font-size: 1rem;
      line-height: 1.5rem;
      
      margin-bottom:24px;
      
      @media ${device.mobileL} {
        font-size: 1.125rem;
      }
    }
  `;

const PlanSelector = styled.div`
  width: 100%;

  // reset button styling
  button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    background-color: white;
    width: 100%;
  }

  .card {
    position: relative;
    padding: 0.75rem;
    border: 3px solid rgba(243, 244, 246, 1);
    border-radius: 8px;
    box-shadow: 0px 2px 5px 0.2px rgba(0, 44, 129, 0.2);
    margin-top: 1rem;
    :not(:first-child) {
      margin-top: 1.5rem;
    }

    .badge {
      position: absolute;
      right: 18px;
      top: 0;
      transform: translateY(-50%);
      padding: 0.25rem 2rem;
      border-radius: 1rem;
      height: 25px;
      display: flex;
      align-items: center;
      background-color: rgba(243, 244, 246, 1);
      color: rgba(156, 163, 175, 1);
    }
    .badge.selected {
      background-color: rgba(248, 194, 110, 1);
      color: white;
    }
  }
  .card.selected {
    border-color: rgba(248, 194, 110, 1);
    box-shadow: 0px 2px 20px 0.2px rgba(0, 44, 129, 0.2);
  }
  .card h4 {
    margin: 0 0;
    font-family: Nunito;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .prev {
    text-decoration: line-through;
    margin: 0;
  }

  .prices {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    margin-left: 0;
  }

  .prices p {
    margin: 0;
    font-family: Nunito;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .productDescription {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: #000a;
  }
`;
