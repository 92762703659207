import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar } from "@chakra-ui/avatar";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { useClipboard, useDisclosure } from "@chakra-ui/hooks";
import Icon from "@chakra-ui/icon";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Badge,
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/stat";
import { createColumnHelper } from "@tanstack/react-table";
import {
  add,
  compareDesc,
  format,
  formatDistanceToNow,
  parseISO,
} from "date-fns/esm";
import _, { get } from "lodash";
import { ErrorBoundary } from "react-error-boundary";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Link,
  NavLink,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

/* icon imports */
import { BsPatchCheckFill } from "react-icons/bs";
import { MdArrowForward, MdCopyAll } from "react-icons/md";

/* local imports */
import { AddressEdit, AddressView } from "./components_settings/AddressForm.js";
import {
  ResonantFreqEdit,
  ResonantFreqView,
} from "./components_settings/AppConfigForm.js";
import {
  BirthdateEdit,
  BirthdateView,
} from "./components_settings/BirthdateForm.js";
import {
  CoinDeductEdit,
  CoinDeductView,
  CoinEdit,
  CoinView,
} from "./components_settings/CoinForm.js";
import { EmailEdit, EmailView } from "./components_settings/EmailForm.js";
import InlineEditRow from "./components_settings/InlineEditRow.jsx";
import {
  PasswordEdit,
  PasswordView,
} from "./components_settings/PasswordForm.js";
import {
  PreferredUsernameEdit,
  PreferredUsernameView,
} from "./components_settings/PreferredUsernameForm.js";
import {
  SecurityGroupEdit,
  SecurityGroupView,
} from "./components_settings/SecurityGroupForm.js";
import {
  ListSubscriptionProducts,
  SubscriptionEdit,
  SubscriptionPlanEdit,
  SubscriptionPlanView,
  SubscriptionProviderEdit,
  SubscriptionProviderView,
  SubscriptionView,
} from "./components_settings/SubscriptionForm.js";
import {
  UserNameEdit,
  UserNameView,
} from "./components_settings/UserNameForm.js";

import MapProgressEditor from "./components_settings/JourneyConfigForm.js";

import DataGrid from "common/DataGrid.jsx";
import ErrorFallback from "common/ErrorFallback.jsx";
import Loading from "common/Loading.js";
import UserSearch from "common/UserSearch.jsx";
import Can from "common/Can.js";

import { client } from "utils/awsConfig.js";
import { getParsedJSON, toSentenceCase } from "utils/string.js";
import { ROLES } from "utils/authHelpers.js";

import CreateShipmentSimpleModal from "../Shipments/components/CreateShipmentSimpleModal.jsx";
import { getShipments } from "../Shipments/services";

import { CREATE_COACH_USER_LINK, UPDATE_USER } from "graphql/mutations.js";
import {
  ADMIN_GET_USER_ATTRIBUTES,
  ADMIN_LIST_USER_ROLES,
  GET_COACH,
  GET_USER,
  GET_USER_SETTINGS,
} from "graphql/queries";

import { getStatusColor, getStatusTitle } from "pages/Shipments/constants.js";

import { getCoachLinkedPatients } from "pages/Coach/services.js";

import { PORTAL_ROOT } from "Routes.js";
import useFetchOnce from "hooks/useFetchOnce.js";
import { SubscriptionIcons, SubscriptionPlanNames } from "./constants.js";

const UserSettings = () => {
  // const [{ user: authUser }] = useAuth();

  const { userId } = useParams();
  const shipmentsDisclosureProps = useDisclosure();
  const linkPatientsDisclosureProps = useDisclosure();
  const billingShowPlansDisclosure = useDisclosure();
  const billingManagePlanDisclosure = useDisclosure();

  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    roles: [ROLES.USER],
    subscriptionInfo: {
      subscriptionPlan: "",
      subscriptionDate: new Date().toISOString(),
      subscriptionMethod: "",
    },
  });

  const mutateLocalUser = (updatedState) =>
    setUser((u) => ({ ...u, ...updatedState }));

  const [loading, setLoading] = useState(false);

  const fetchUser = useCallback(async () => {
    setLoading(true);

    const fetchedData = await Promise.allSettled([
      client
        .mutate({
          mutation: ADMIN_GET_USER_ATTRIBUTES,
          variables: {
            userId,
          },
        })
        .then((response) => {
          const cognitoUser = response.data.adminGetUser;

          const domainUserObject = Object.entries(cognitoUser).reduce(
            (acc, [key, currentValue]) => {
              const snakeToCamelCase = (str) =>
                str
                  .split("_")
                  .map((w, idx) =>
                    idx === 0 ? w : w.charAt(0).toUpperCase() + w.slice(1)
                  )
                  .join("");

              if (key === "birthdate" && currentValue === "--/--/----") {
                acc[snakeToCamelCase(key)] = null;
                return acc;
              }

              acc[snakeToCamelCase(key)] = currentValue;

              return acc;
            },
            {}
          );

          return domainUserObject;
        }),
      client
        .query({
          query: ADMIN_LIST_USER_ROLES,
          variables: {
            userId,
          },
        })
        .then((response) => {
          const roleOrder = Object.values(ROLES);
          return {
            roles: response.data.adminListUserGroups.roles.sort(
              (a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)
            ),
          };
        }),
      client
        .query({
          query: GET_USER,
          variables: {
            id: userId,
          },
        })
        .then((response) => response.data.getUser),
      client
        .query({
          query: GET_USER_SETTINGS,
          variables: {
            id: userId,
          },
        })
        .then((response) => response.data.getUser),
      client
        .query({
          query: GET_COACH,
          variables: {
            id: userId,
          },
        })
        .then((response) => ({ isCoach: !!response.data.getCoach.id })),
    ]);

    setUser({
      ...fetchedData
        .filter((result) => result.status === "fulfilled")
        .reduce((acc, result) => {
          acc = { ...acc, ...result.value };
          return acc;
        }, {}),
    });

    setLoading(false);
  }, [userId]);

  useEffect(() => {
    fetchUser();
  }, [userId, fetchUser]);
  console.log("@USER", user);

  const isSubscriptionProduct = () => {
    const {
      subscriptionMethod: userSubMethod,
      subscriptionPlan: userSubPlan,
      subscriptionType: userSubType,
    } = user.subscriptionInfo;
    const {
      subscriptionMethod: productSubMethod,
      subscriptionPlan: productSubPlan,
      subscriptionType: productSubType,
    } = user.subscriptionInfo?.subscriptionProduct ?? {
      subscriptionMethod: "",
      subscriptionPlan: "",
      subscriptionType: "",
    };
    return _.isEqual(
      {
        subscriptionMethod: userSubMethod,
        subscriptionPlan: userSubPlan,
        subscriptionType: userSubType,
      },
      {
        subscriptionMethod: productSubMethod,
        subscriptionPlan: productSubPlan,
        subscriptionType: productSubType,
      }
    );
  };

  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      height="100%"
    >
      {loading && <Loading />}
      <Box overflow="hidden" shadow="sm" borderBottomRadius="3xl">
        <PageHeader user={user} />
      </Box>
      <Container
        maxW="container.md"
        minHeight="calc(100vh - 4rem)"
        justifyContent="center"
        alignItems="center"
        py="10"
      >
        <VStack alignItems="flex-start" spacing={8}>
          {/* Account Information */}
          <Card>
            <CardTitle>Account Information</CardTitle>
            <Divider my="4" mx="-8" pl="16" />
            <VStack spacing={5}>
              <InlineEditRow
                title="Username"
                belongsToModel="ADMIN_UPDATE_USER"
                mutateLocalUser={mutateLocalUser}
                content={{
                  preferredUsername: user?.preferredUsername ?? "—",
                }}
                ViewComponent={PreferredUsernameView}
                EditComponent={PreferredUsernameEdit}
              />
              <InlineEditRow
                title="Given Name"
                belongsToModel="ADMIN_UPDATE_USER"
                mutateLocalUser={mutateLocalUser}
                content={{
                  firstName: user.firstName,
                  lastName: user?.lastName ?? "",
                }}
                ViewComponent={UserNameView}
                EditComponent={UserNameEdit}
              />
              <InlineEditRow
                title="Email"
                belongsToModel="ADMIN_UPDATE_USER"
                mutateLocalUser={mutateLocalUser}
                content={{ email: user.email }}
                ViewComponent={EmailView}
                EditComponent={EmailEdit}
              />
              <InlineEditRow
                title="Address"
                belongsToModel="USER"
                mutateLocalUser={mutateLocalUser}
                content={{ address: _.omit(user.address, "__typename") }}
                ViewComponent={AddressView}
                EditComponent={AddressEdit}
              />
              <InlineEditRow
                title="Birthday"
                belongsToModel="ADMIN_UPDATE_USER"
                mutateLocalUser={mutateLocalUser}
                content={{ birthdate: user?.birthdate }}
                ViewComponent={BirthdateView}
                EditComponent={BirthdateEdit}
              />
            </VStack>
          </Card>

          <Can role={ROLES.CX_TEAM}>
            <Card>
              <CardTitle>Security</CardTitle>
              <Divider my="4" mx="-8" pl="16" />
              <VStack spacing={5}>
                <InlineEditRow
                  title="Password"
                  belongsToModel="ADMIN_SET_USER_PASSWORD"
                  mutateLocalUser={mutateLocalUser}
                  content={{ password: null }}
                  ViewComponent={PasswordView}
                  EditComponent={PasswordEdit}
                />
                <Can role={ROLES.ADMIN}>
                  <InlineEditRow
                    title="Permissions Group"
                    belongsToModel="ADMIN_UPDATE_USER_GROUP"
                    mutateLocalUser={mutateLocalUser}
                    content={{
                      roles: user.roles ?? [ROLES.USER],
                      id: user?.id,
                      isCoach: user?.isCoach ?? false,
                    }}
                    ViewComponent={SecurityGroupView}
                    EditComponent={SecurityGroupEdit}
                  />
                </Can>
              </VStack>
            </Card>
          </Can>

          {/* Subscriptions */}
          <Card>
            <CardTitle>Billing</CardTitle>

            <Divider my="4" mx="-8" pl="16" />
            <Flex>
              <VStack
                alignItems="flex-start"
                flex
                flexGrow={1}
                flexShrink={1}
                flexBasis={"0px"}
                spacing={4}
              >
                <VStack align="flex-start" spacing={1}>
                  <HStack align="flex-start">
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="12px"
                      borderBottomRightRadius="4px"
                      bg="linkedin.100"
                      color="linkedin.800"
                      h="24px"
                      w="24px"
                      me="0px"
                    >
                      <Icon
                        w="14px"
                        h="14px"
                        me="0px"
                        as={
                          SubscriptionIcons[
                            user.subscriptionInfo?.subscriptionPlan
                          ]
                        }
                      />
                    </Flex>
                    <VStack align="flex-start" spacing={0}>
                      <HStack>
                        <Text fontWeight="bold" fontSize="md">
                          {
                            SubscriptionPlanNames[
                              user.subscriptionInfo?.subscriptionPlan
                            ]
                          }
                        </Text>
                      </HStack>
                      {isSubscriptionProduct() ? (
                        <Text fontWeight="bold" color="gray.500" fontSize="sm">
                          Renews at $
                          {user.subscriptionInfo?.subscriptionProduct?.price}{" "}
                          USD /{" "}
                          {user.subscriptionInfo?.subscriptionProduct?.timeUnits.toLowerCase()}
                        </Text>
                      ) : (
                        <Text fontWeight="bold" color="gray.500" fontSize="sm">
                          Custom Plan
                        </Text>
                      )}
                    </VStack>
                  </HStack>
                </VStack>
                <HStack>
                  <Button
                    colorScheme="linkedin"
                    size="sm"
                    onClick={() => {
                      if (billingManagePlanDisclosure.isOpen)
                        billingManagePlanDisclosure.onToggle();
                      billingShowPlansDisclosure.onToggle();
                    }}
                  >
                    Explore Plans
                  </Button>
                  <Button
                    variant="ghost"
                    shadow="base"
                    size="sm"
                    onClick={() => {
                      if (billingShowPlansDisclosure.isOpen)
                        billingShowPlansDisclosure.onToggle();
                      billingManagePlanDisclosure.onToggle();
                    }}
                  >
                    Manage Plan
                  </Button>
                </HStack>
              </VStack>

              <VStack
                alignItems="flex-start"
                flex
                flexGrow={1}
                flexShrink={1}
                flexBasis={"0px"}
                spacing={0}
              >
                <Text fontWeight="bold" fontSize="md">
                  Billing Period
                </Text>
                <Flex>
                  <Text fontWeight="bold" fontSize="sm" me="1">
                    {toSentenceCase(user.subscriptionInfo?.subscriptionType)}
                  </Text>
                  <Text fontWeight="bold" color="gray.500" fontSize="sm">
                    {`(renews on ${format(
                      add(new Date(user.subscriptionInfo?.subscriptionDate), {
                        [user.subscriptionInfo?.subscriptionProduct?.timeUnits.toLowerCase() +
                        "s"]:
                          user.subscriptionInfo?.subscriptionProduct?.duration,
                      }),
                      "MMMM do, yyyy"
                    )})`}
                  </Text>
                </Flex>
              </VStack>
            </Flex>
            <Collapse
              in={billingShowPlansDisclosure.isOpen}
              animateOpacity
              unmountOnExit
            >
              {/* SubscriptionProduct Cards */}
              <ListSubscriptionProducts
                initialSelection={user.subscriptionInfo?.subscriptionProductId}
                toggleEdit={billingShowPlansDisclosure.onToggle}
                mutateLocalUser={mutateLocalUser}
                isSubscriptionProduct={isSubscriptionProduct()}
              />
            </Collapse>
            <Collapse in={billingManagePlanDisclosure.isOpen} animateOpacity>
              <VStack spacing={5} mt="4">
                <InlineEditRow
                  title="Current Plan"
                  belongsToModel="SUBSCRIPTION"
                  mutateLocalUser={mutateLocalUser}
                  content={{
                    subscriptionPlan: _.get(
                      user,
                      "subscriptionInfo.subscriptionPlan"
                    ),
                  }}
                  ViewComponent={SubscriptionPlanView}
                  EditComponent={SubscriptionPlanEdit}
                />
                <InlineEditRow
                  title="Subscription Type"
                  belongsToModel="SUBSCRIPTION"
                  mutateLocalUser={mutateLocalUser}
                  content={{
                    subscriptionType: _.get(
                      user,
                      "subscriptionInfo.subscriptionType"
                    ),
                  }}
                  ViewComponent={SubscriptionView}
                  EditComponent={SubscriptionEdit}
                />
                <InlineEditRow
                  title="Subscription Provider"
                  belongsToModel="SUBSCRIPTION"
                  mutateLocalUser={mutateLocalUser}
                  content={{
                    subscriptionMethod: _.get(
                      user,
                      "subscriptionInfo.subscriptionMethod"
                    ),
                  }}
                  ViewComponent={SubscriptionProviderView}
                  EditComponent={SubscriptionProviderEdit}
                />
              </VStack>
            </Collapse>
          </Card>

          {/* Flokens */}
          <Can role={ROLES.ADMIN}>
            <Card>
              <HStack justifyContent="space-between" alignItems="flex-start">
                <CardTitle>Flokens</CardTitle>
                <StatGroup mt="5" justifyContent="flex-start">
                  <SimpleGrid columns={3} spacing={6}>
                    <Stat>
                      <StatLabel fontSize="xs">Current Balance</StatLabel>
                      <StatNumber
                        fontSize="lg"
                        display="flex"
                        alignItems="baseline"
                      >
                        {Math.round(
                          (user?.totalRewardsAmount ?? 0) -
                            (user?.totalRedeemedAmount ?? 0)
                        )}{" "}
                        <Text
                          fontWeight="bold"
                          color="gray.400"
                          fontSize="sm"
                          ml="1"
                        >
                          ₡
                        </Text>
                      </StatNumber>
                    </Stat>
                    <Stat>
                      <StatLabel fontSize="xs">Total Rewards</StatLabel>
                      <StatNumber
                        fontSize="lg"
                        display="flex"
                        alignItems="baseline"
                      >
                        {user.totalRewardsAmount
                          ? user.totalRewardsAmount
                          : "N/A"}{" "}
                        <Text
                          fontWeight="bold"
                          color="gray.400"
                          fontSize="sm"
                          ml="1"
                        >
                          ₡
                        </Text>
                      </StatNumber>
                    </Stat>
                    <Stat>
                      <StatLabel fontSize="xs">Total Redeemed</StatLabel>
                      <StatNumber
                        fontSize="lg"
                        display="flex"
                        alignItems="baseline"
                      >
                        {user.totalRedeemedAmount
                          ? user.totalRedeemedAmount
                          : 0}
                        <Text
                          fontWeight="bold"
                          color="gray.400"
                          fontSize="sm"
                          ml="1"
                        >
                          ₡
                        </Text>
                      </StatNumber>
                    </Stat>
                  </SimpleGrid>
                </StatGroup>
              </HStack>
              <Divider my="4" mx="-8" pl="16" />
              <VStack spacing={5}>
                <InlineEditRow
                  title="Send Flokens"
                  belongsToModel="REWARDS"
                  mutateLocalUser={mutateLocalUser}
                  content={{ totalRewardsAmount: user.totalRewardsAmount }}
                  ViewComponent={CoinView}
                  EditComponent={CoinEdit}
                  ButtonProps={{
                    edit: { text: "Send Rewards", colorScheme: "linkedin" },
                    submit: { text: "Send!" },
                  }}
                />
                <InlineEditRow
                  title="Deduct Flokens"
                  belongsToModel="REWARDS"
                  mutateLocalUser={mutateLocalUser}
                  content={{ totalRewardsAmount: user.totalRewardsAmount }}
                  ViewComponent={CoinDeductView}
                  EditComponent={CoinDeductEdit}
                  ButtonProps={{
                    edit: { text: "Modify Rewards" },
                    submit: { text: "Remove Flokens", colorScheme: "red" },
                  }}
                />
              </VStack>
            </Card>
          </Can>

          {/* App Configuration */}
          <Card>
            <CardTitle>App Configuration</CardTitle>
            <Divider my="4" mx="-8" pl="16" />
            <VStack spacing={5}>
              <InlineEditRow
                title="Resonant Frequency"
                belongsToModel="USER"
                mutateLocalUser={mutateLocalUser}
                content={{ resonantFrequency: user.resonantFrequency }}
                ViewComponent={ResonantFreqView}
                EditComponent={ResonantFreqEdit}
              />
            </VStack>
          </Card>
          {/* Journey Configuration */}
          <Can role={ROLES.ADMIN}>
            <Card>
              <MapProgressEditor preload={user} />
            </Card>
          </Can>

          {/* Orders */}
          <Can role={ROLES.ADMIN}>
            <Card p={0} pt={{ base: 6, lg: 4 }}>
              <HStack
                justifyContent="space-between"
                alignItems="center"
                px={{ base: 6, lg: 4 }}
                pb={{ base: 6, lg: 4 }}
              >
                <CardTitle>Orders</CardTitle>
                <Button
                  size="sm"
                  colorScheme="linkedin"
                  onClick={shipmentsDisclosureProps.onOpen}
                >
                  New Order
                </Button>
                <CreateShipmentSimpleModal
                  preload={user}
                  mutateLocalUser={mutateLocalUser}
                  {...shipmentsDisclosureProps}
                />
              </HStack>

              <VStack spacing={5}>
                <UserShipmentsList user={user} />
              </VStack>
            </Card>
          </Can>

          {/* CoachLinkedUsers */}
          {user?.isCoach && (
            <Can role={ROLES.ADMIN}>
              <Card p={0} pt={{ base: 6, lg: 4 }}>
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  px={{ base: 6, lg: 4 }}
                  pb={{ base: 6, lg: 4 }}
                >
                  <CardTitle>Patients</CardTitle>
                  <Button
                    size="sm"
                    colorScheme="linkedin"
                    onClick={linkPatientsDisclosureProps.onOpen}
                  >
                    Link Patient
                  </Button>
                  <LinkPatientToCoachModal
                    preload={user}
                    // mutateLocalUser={mutateLocalUser}
                    {...linkPatientsDisclosureProps}
                  />
                </HStack>
                {/* <Divider my={4} mx="-8" pl="16" /> */}

                <VStack spacing={5}>
                  <CoachLinkedPatientsList user={user} />
                </VStack>
              </Card>
            </Can>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default UserSettings;

const UserShipmentsList = ({ user }) => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [
    { value: shipments, error: shipmentsErr, loading: shipmentsLoading },
    fetchShipments,
  ] = useFetchOnce(getShipments, [], [], {
    getPath: "data.listShipments.items",
    syncToStorage: true,
    syncKey: "shipments",
  });
  console.log({ shipmentsLoading });
  useEffect(() => {
    if (shipments) {
      const parsedSSInfo = shipments.map((s) => ({
        ...s,
        shipstationInfo: getParsedJSON(s.shipstationInfo),
      }));

      const userShipments = parsedSSInfo
        .filter((s) => s.userId === user.id)
        .sort((a, b) =>
          compareDesc(
            parseISO(_.get(a, "shipstationInfo.createDate")),
            parseISO(_.get(b, "shipstationInfo.createDate"))
          )
        );
      setTableData(userShipments);
    }
  }, [shipments, user]);

  const handleRowClick = (rowData) =>
    history.push({
      pathname: `${PORTAL_ROOT}/admin/shipments/${rowData.original.shipstationOrderNumber}`,
      state: rowData.original,
    });

  const shipmentColumns = useMemo(
    () => [
      {
        header: "Order #",
        accessorKey: "shipstationOrderId",
        meta: { isNumeric: true },
        enableSorting: false,
      },

      {
        header: "Purchased",
        accessorFn: (rowData) => {
          const items = get(rowData, "items");
          if (items.length <= 1) return items.map((item) => `${item.name}`);

          return (
            `${items[0].quantity}x ${items[0].name}` +
            ` (+${items.length - 1} more)`
          );
        },
        enableSorting: false,
      },

      {
        header: "Status",
        accessorKey: "status",
        enableSorting: false,
        cell: (rowData) =>
          get(rowData, "shipstationOrderId") ? (
            <Badge colorScheme={getStatusColor(get(rowData, "status"))}>
              {getStatusTitle(get(rowData, "status"))}
            </Badge>
          ) : (
            <Badge>Pending</Badge>
          ),
      },
      {
        header: "Date",
        accessorFn: (row) =>
          format(parseISO(get(row, "createdAt")), "MMM do yyyy, HH:mm"),
      },
    ],
    []
  );

  const { table: tableInstance } = DataGrid.useDataGrid({
    columns: shipmentColumns,
    data: tableData,
  });

  return (
    <Box w="full">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <DataGrid.Composer table={tableInstance}>
          <Box
            w="full"
            borderTop="1px solid"
            borderColor="gray.100"
            // overflowX="scroll"
          >
            <DataGrid.Table
              onRowClick={handleRowClick}
              loading={shipmentsLoading}
            />
          </Box>
        </DataGrid.Composer>
      </ErrorBoundary>
    </Box>
  );
};

const columnHelper = createColumnHelper();

const CoachLinkedPatientsList = ({ user }) => {
  const history = useHistory();

  // const [{ user }] = useAuth();

  const handleRowClick = (rowData) =>
    history.push(`${PORTAL_ROOT}/users/${rowData.original.user.id}`);

  const {
    data: result,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery("CoachUserLinks", () =>
    getCoachLinkedPatients({
      variables: {
        coachId: user.id,
      },
    })
  );

  const totalExpectedSessions = 84;
  const columns = useMemo(
    () => [
      {
        header: "Patient ID",
        accessorKey: "user.id",
        id: "patientId",
      },
      {
        header: "Patient First Name",
        accessorKey: "user.firstName",
        id: "patientFirstName",
      },
      {
        header: "Patient Last Name",
        accessorKey: "user.lastName",
        id: "patientLastName",
      },
      {
        header: "Patient Email",
        accessorKey: "user.email",
        id: "patientEmail",
      },
      columnHelper.display({
        id: "displayName",
        header: "Name",
        cell: ({ row }) => {
          return (
            <VStack
              as={NavLink}
              to={`${PORTAL_ROOT}/users/${row.getValue("patientId")}`}
              align="flex-start"
              spacing={0}
              maxW="xs"
            >
              <Text fontWeight="semibold" color="gray.700">
                {`${row.getValue("patientFirstName")} ${
                  row.getValue("patientLastName") ?? ""
                }`.trim()}
              </Text>
              <Text color="gray.500">{row.getValue("patientEmail")}</Text>
            </VStack>
          );
        },
      }),

      // {
      //   header: "Progression",
      //   accessorFn: (row) => row.user.sessionsCompleted,

      //   // meta: {
      //   //   size: "14rem",
      //   // },
      //   cell: (row) => (
      //     <Progress
      //       maxW="12rem"
      //       // minW={20}
      //       rounded="full"
      //       hasStripe={true}
      //       colorScheme="green"
      //       value={Math.round(
      //         (row.getValue("sessionsCompleted") / totalExpectedSessions) * 100
      //       )}
      //     />
      //   ),
      // },
      {
        header: "Last session",
        accessorKey: "user.lastSessionDate",
        id: "lastSessionDate",
        // accessorFn: (row) => row.user.lastSessionDate,
        cell: ({ row }) => {
          if (!row.getValue("lastSessionDate")) return "—";

          return (
            <>
              <Text>
                {format(
                  new Date(row.getValue("lastSessionDate")),
                  "MMM do, yyyy"
                )}{" "}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {formatDistanceToNow(new Date(row.getValue("lastSessionDate")))}{" "}
                ago
              </Text>
            </>
          );
        },
      },
      // columnHelper.display({
      //   id: "actions",
      //   cell: ({ row }) => {
      //     return <RowActionsDropdown row={row} />;
      //   },
      // }),
    ],
    []
  );

  const { table: tableInstance, ...dataGridProps } = DataGrid.useDataGrid({
    columns,
    data: result?.data?.getCoach?.patients?.items ?? [],
    initialState: {
      columnVisibility: {
        patientId: false,
        patientFirstName: false,
        patientLastName: false,
        patientEmail: false,
      },
    },
    enablePagination: true,
    // enableRowSelection: true,
  });
  return (
    <DataGrid.Composer table={tableInstance}>
      {/* <Header {...dataGridProps} /> */}
      <Box
        w="full"
        borderTop="1px solid"
        borderColor="gray.100"
        overflowX="scroll"
      >
        <DataGrid.Table
          loading={isLoading}
          onRowClick={handleRowClick}
          // enableRowSelection={false}
        />
        <DataGrid.Footer pagination />
      </Box>
    </DataGrid.Composer>
  );
};

const Card = (props) => (
  <Box
    bg="white"
    rounded="xl"
    overflow="hidden"
    shadow="md"
    p={{ base: 6, lg: 4 }}
    width="full"
    {...props}
  />
);

const CardTitle = (props) => (
  <Text fontSize="md" fontWeight="bold" {...props} />
);

const PageHeader = ({ user }) => {
  const { url } = useRouteMatch();
  const { onCopy, hasCopied } = useClipboard(
    `https://portal.flowly.world/?referrer=${user.id}`
  );
  const toast = useToast();

  useEffect(() => {
    if (hasCopied)
      toast({
        status: "success",
        title: "Copied referral link successfully!",
      });
  }, [hasCopied]);

  return (
    <VStack
      w="full"
      // divider={<StackDivider borderColor="gray.200" />}
      spacing={8}
    >
      <HStack
        width="full"
        maxW="container.md"
        justifyContent="space-between"
        alignItems="center"
        mx="auto"
        pt="16"
        px={{ base: 8, md: 0 }}
      >
        <Flex>
          <Avatar
            name={`${user.firstName} ${user?.lastName ?? ""}`}
            src=""
            alt={`${user.firstName} ${user?.lastName ?? ""}`}
            size="xl"
            shadow="md"
          />
          <VStack alignItems="flex-start" pl="5">
            <Heading>
              {`${user.firstName} ${user?.lastName ?? ""}`.trim()}
            </Heading>
            <Badge colorScheme="linkedin" variant="solid">
              {user.subscriptionInfo.subscriptionPlan}
            </Badge>
          </VStack>
        </Flex>
        <Button
          as={Link}
          to={url ? url.split("/").slice(0, -1).join("/") : "."}
          rightIcon={<Icon as={MdArrowForward} />}
          colorScheme="linkedin"
          variant="outline"
        >
          View sessions
        </Button>
        {/* <Box>
        <ChakraLink
          as={Link}
          to={url ? url.split("/").slice(0, -1).join("/") : "."}
          color="blue.500"
        >
          View sessions <Icon as={MdArrowForward} />
        </ChakraLink>
      </Box> */}
      </HStack>
      <Box bg="blue.50" w="full">
        <Stack
          align="center"
          direction={["column", "row"]}
          maxW="container.md"
          mx="auto"
          px="8"
          py="4"
          justify="center"
        >
          <Text fontWeight="bold" color="gray.700">
            Invite new Heroes to Flowly —{" "}
          </Text>
          <Box
            display="flex"
            bg="gray.100"
            px="4"
            py="2"
            border="1px solid"
            borderColor="gray.300"
            rounded="md"
            alignItems="center"
          >
            <Text
              maxW="72"
              color="gray.500"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              https://portal.flowly.world/?referrer={user.id}
            </Text>
            <IconButton
              ml="4"
              icon={<MdCopyAll />}
              size="sm"
              onClick={onCopy}
            />
          </Box>
        </Stack>
      </Box>
    </VStack>
  );
};

const LinkPatientToCoachModal = ({ isOpen, onClose, preload }) => {
  const toast = useToast();

  const [selectedUser, setSelectedUser] = useState(null);
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (input) =>
      client
        .mutate({
          mutation: CREATE_COACH_USER_LINK,
          variables: {
            input,
          },
        })
        .then(() =>
          client.mutate({
            mutation: UPDATE_USER,
            variables: {
              input: { id: input.userId, coachIds: input.coachId },
            },
          })
        ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["CoachUserLinks"],
      });

      toast({
        status: "success",
        title: "Patient linked successfully!",
      });
      onClose();
    },
  });

  const handleLinkPatient = () => {
    mutate({ coachId: preload.id, userId: selectedUser.id });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      blockScrollOnMount={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Link Patient to Coach</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <VStack alignItems="flex-start" spacing={8} my={4}>
            <FormControl
              id="userId"
              // isInvalid={errors?.userId}
              isRequired
            >
              <HStack justifyContent="space-between">
                <FormLabel mb={1} fontWeight="semibold">
                  Find Patient
                </FormLabel>
              </HStack>
              <UserSearch
                setSelectedUser={(user) => {
                  setSelectedUser(user);
                }}
                size="full"
              />
              {!selectedUser ? (
                <FormHelperText>
                  We&apos;ll automatically fill in all the details from the
                  user&apos;s account
                </FormHelperText>
              ) : (
                <FormHelperText display="flex" alignItems="center">
                  <Icon
                    as={BsPatchCheckFill}
                    color="green.400"
                    mb="px"
                    mr="1"
                  />
                  A link to {selectedUser.firstName} will be created
                </FormHelperText>
              )}
            </FormControl>
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="linkedin"
            rounded="md"
            onClick={handleLinkPatient}
            loadingText="Creating your order..."
          >
            Link Patient
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
