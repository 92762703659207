import React, { useMemo, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { VStack, Grid, GridItem, Text, HStack } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Avatar } from "@chakra-ui/avatar";
import { Elements } from "@stripe/react-stripe-js";
import qs from "qs";

/* asset imports */
import necklace from "assets/products/member-necklace.png";
import vr from "assets/products/vr.png";
import done from "assets/checkout/done.svg";
import subscription from "assets/products/subscription.svg";

/* local imports */
import H1 from "common/H1";
import stripePromise from "utils/stripeConfig";
import { getItemLocalStorage, setItemLocalStorage } from "utils/localStorage";
import { SUBSCRIPTION_TIME_UNITS } from "pages/Checkout/constants";
import { toSentenceCase } from "utils/string";

import { SUBSCRIPTIONS } from "./InviteSubscription";
import ShippingDetailsForm from "./InviteCheckout_Shipping";
import PaymentDetailsForm from "./InviteCheckout_Payment";
import { STEPS } from "../UserInvitation";

const InviteCheckout = ({
  navigate,
  handleChange,
  formState,
  updateFormState,
}) => {
  const { search } = useLocation();

  const [loading, setLoading] = useState(false);
  const {
    price: planPrice,
    kitPrice = 0,
    title: planTitle,
  } = formState?.selectedPlan ?? { price: 0, kitPrice: 0, title: "" };

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const promo = useMemo(() => {
    const urlPromo = currentParams?.promo;

    if (urlPromo) {
      setItemLocalStorage("promo", urlPromo);
      return urlPromo;
    }

    const storedPromo = getItemLocalStorage("promo");
    return storedPromo;
  }, [currentParams?.promo]);

  const referrer = useMemo(() => {
    const urlReferrer = currentParams?.referrer;

    if (urlReferrer) {
      setItemLocalStorage("referrer", urlReferrer);
      return urlReferrer.replace(" ", "+");
    }

    const storedReferrer = getItemLocalStorage("referrer");
    return storedReferrer ? storedReferrer.replace(" ", "+") : null;
  }, [currentParams?.referrer]);

  const applyPromo = async (promoArg) => {
    if (
      !(
        (promoArg && typeof promoArg === "string") ||
        formState.promoCode ||
        formState.promo
      )
    )
      return null;

    const validCode =
      promoArg && typeof promoArg === "string"
        ? promoArg
        : formState.promoCode
        ? formState.promoCode
        : formState.promo;

    setLoading(true);
    try {
      const result = await API.get("flowlyRestAPI", "/check-coupon", {
        queryStringParameters: {
          coupon: validCode,
        },
      });
      if (!result.valid) {
        alert("Invalid promo code");
      } else if (result.type === "invalid_request_error") {
        if (result.code === "resource_missing") {
          alert("Invalid promo code");
        } else if (result.code === "coupon_expired") {
          alert("Promo code is expired");
        } else {
          alert(
            "We had an error processing the promo code. Please, try again later."
          );
        }
        updateFormState("promoCode", "");
      } else {
        let discount = null;
        if (result.amount_off) {
          updateFormState("discountType", "amount_off");
          discount = result.amount_off;
        } else if (result.percent_off) {
          updateFormState("discountType", "percent");
          discount = result.percent_off;
        }
        if (result.duration === "repeating") {
          updateFormState(
            "discountDuration",
            `${result.name} for ${result.duration_in_months} months`
          );
          updateFormState("discountProps", result);
        }
        if (result.duration === "forever") {
          updateFormState(
            "discountDuration",
            `${result.percent_off}% off for life`
          );
        }

        updateFormState("discount", discount);
        updateFormState("promoApplied", true);
      }
    } catch (e) {
      alert(
        "We had an error processing the promo code. Please, try again later."
      );

      updateFormState("promoCode", "");
    } finally {
      setLoading(false);
    }
  };

  const checkReferrer = async (userId) => {
    setLoading(true);
    try {
      const result = await API.get("flowlyRestAPI", "/check-referrer-id", {
        queryStringParameters: {
          userId: userId,
          subscriptionType: formState.subscriptionType,
        },
      });
      if (result) {
        if (result.coupon) {
          updateFormState("referralCode", result.referralCode);
          updateFormState("promoCode", result.coupon);
          applyPromo(result.coupon);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Check for referrer or promo in URL/LocalStorage on mount
  useEffect(() => {
    console.log({ formState });
    if (referrer) {
      checkReferrer(referrer);
    }
    if (promo) {
      updateFormState("promoCode", promo);
      if (!formState.promoApplied) applyPromo(promo);
    }
  }, []);

  const discountAmount = () => {
    switch (formState.discountType) {
      case "amount_off":
        return formState.discount / 100.0 > planPrice
          ? planPrice
          : formState.discount / 100.0;
      case "percent":
        return (
          ((planPrice + (formState?.shippingPrice ?? 0)) * formState.discount) /
          100
        );

      default:
        return null;
    }
  };

  const calculatedTotal =
    planPrice + (formState?.shippingPrice ?? 0) - discountAmount();

  const discountValue = formState?.discountProps
    ? formState.subscriptionType !== SUBSCRIPTIONS.monthly
      ? Math.round(
          (formState.discountProps.percent_off / 100) *
            planPrice *
            Math.ceil(formState.discountProps.duration_in_months / 12)
        )
      : Math.round(
          (formState.discountProps.percent_off / 100) *
            planPrice *
            formState?.discountProps?.duration_in_months ?? 0
        )
    : null;

  const toPayment = () => navigate(STEPS.payment);
  const toShipping = () => navigate(STEPS.shipping);
  const toSubscriptionSelector = () => navigate(STEPS.subscribe);
  const toThankYou = () => navigate(STEPS.thankyou);

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem
        colSpan={[12, 12, 12, 12, 6]}
        pr="5vw"
        pl="10vw"
        pb="20"
        bg="white"
      >
        {currentParams.step === "shipping" ? (
          <ShippingDetailsForm
            handleChange={handleChange}
            formState={formState}
            next={toPayment}
            goBack={toSubscriptionSelector}
            updateFormState={updateFormState}
          />
        ) : currentParams.step === "payment" ? (
          <Elements stripe={stripePromise}>
            <PaymentDetailsForm
              handleChange={handleChange}
              formState={formState}
              next={toThankYou}
              goBack={toShipping}
              updateFormState={updateFormState}
            />
          </Elements>
        ) : null}
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={[12, 12, 12, 12, 6]}
        display={{ lg: "block" }}
        width="full"
        py="20"
        pl="5vw"
        pr="10vw"
      >
        <VStack alignItems="flex-start" spacing={4}>
          <H1>Order Details</H1>
          <Text
            fontSize="md"
            color="gray.400"
            opacity={0.76}
            textTransform="uppercase"
            fontFamily="Tommy Soft"
            fontWeight="bold"
            letterSpacing="0.12rem"
          >
            Included in your order
          </Text>

          <ProductCard
            name={
              planTitle
                ? `${planTitle} Membership`
                : `${toSentenceCase(
                    formState?.subscriptionType
                  )} Flowly Membership`
            }
            image={subscription}
            price={"$" + planPrice}
            description={
              <ProductDescription selectedPlan={formState?.selectedPlan} />
            }
            // qty={formState?.selectedPlan?.numDependents}
          />

          <ProductCard
            name="VR Headset and Biosensor"
            image={vr}
            price={kitPrice ? `$${kitPrice.toFixed(2)}` : "Free"}
            description={
              kitPrice
                ? `You will be charged a one time fee of $${kitPrice} for the Flowly kit`
                : null
            }
          />

          <ProductCard
            name="Member Necklace"
            image={necklace}
            price="Free"
            description=""
          />

          <VStack
            width="full"
            borderTop="1px solid #c0cff9"
            borderBottom="1px solid #c0cff9"
            py={8}
          >
            <HStack width="full" justifyContent="space-between">
              <Text>Subtotal</Text>
              <Text fontWeight="bold" fontSize="lg">
                ${planPrice}
              </Text>
            </HStack>
            <HStack width="full" justifyContent="space-between">
              <Text>Shipping (Priority 2-3 days)</Text>
              <Text fontWeight="bold" fontSize="lg">
                {formState?.shippingPrice
                  ? `$${formState.shippingPrice}`
                  : "..."}
              </Text>
            </HStack>
            {discountAmount() && (
              <HStack
                width="full"
                justifyContent="space-between"
                align="flex-start"
              >
                <Text>
                  Discount{" "}
                  {formState?.discountDuration
                    ? ` (${formState?.discountDuration})`
                    : ""}
                  <br />
                  <Text color="gray.500" fontSize="md" fontStyle="italic">
                    {discountValue &&
                      `Total value of $${discountValue.toFixed(2)}`}
                  </Text>
                </Text>
                <Text fontWeight="bold" fontSize="lg">
                  -${discountAmount().toFixed(2)}
                </Text>
              </HStack>
            )}
            <HStack width="full" justifyContent="space-between">
              <Text>Total Due Today</Text>
              <Text fontWeight="bold" fontSize="lg">
                ${calculatedTotal.toFixed(2)}
              </Text>
            </HStack>
          </VStack>
          {formState.promoApplied ? (
            <FormControl id="promoCode">
              <HStack alignItems="center" spacing={3}>
                <Image src={done} alt="done" />
                <FormLabel
                  fontWeight="medium"
                  fontFamily="Tommy Soft"
                  color="#5072ec"
                  fontSize="xl"
                >
                  Discount code applied
                  <span role="img" aria-label="party-emoji">
                    🎉
                  </span>
                </FormLabel>
              </HStack>
            </FormControl>
          ) : (
            <>
              <FormControl id="promoCode">
                <FormLabel
                  fontWeight="medium"
                  fontFamily="Tommy Soft"
                  color="#5072ec"
                  fontSize="xl"
                >
                  Have a promo code?
                </FormLabel>
                <Input
                  name="promoCode"
                  type="text"
                  bg="white"
                  value={formState.promoCode}
                  onChange={handleChange("promoCode")}
                  required
                  placeholder="Enter your code"
                  size="lg"
                />
              </FormControl>
              <Button
                type="submit"
                onClick={applyPromo}
                variant="outline"
                color="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText="Applying code..."
                py="1rem"
                px="110px"
              >
                Apply
              </Button>
            </>
          )}
        </VStack>
      </GridItem>
    </Grid>
  );
};

export default InviteCheckout;

const ProductCard = ({ name, price, description, image, qty = null }) => (
  <HStack
    bg="white"
    shadow="md"
    rounded="lg"
    p={4}
    width="100%"
    alignItems="flex-start"
    justifyContent="space-between"
  >
    <HStack alignItems="flex-start">
      <Avatar size="xl" ml={0} mr={2} src={image} bg="whiteAlpha.100" />
      <VStack alignItems="flex-start" spacing={0}>
        <Text fontWeight="bold" fontSize="large">
          {name}
        </Text>
        <Text color="gray.400">{description}</Text>
      </VStack>
    </HStack>
    <VStack alignItems="flex-end" spacing={0}>
      <Text fontWeight="bold" fontSize="large">
        {price}
      </Text>
      <Text color="gray.400">Qty: {qty ? qty : 1}</Text>
    </VStack>
  </HStack>
);

const durationInMonths = (duration, unit) => {
  switch (unit) {
    case SUBSCRIPTION_TIME_UNITS.LIFETIME:
      return `For life`;
    case SUBSCRIPTION_TIME_UNITS.YEAR:
      return `${duration * 12} months`;

    case SUBSCRIPTION_TIME_UNITS.MONTH:
    default:
      return `${duration} month${duration > 1 ? "s" : ""}`;
  }
};
const ProductDescription = ({ selectedPlan }) => (
  <>
    {selectedPlan?.description ? (
      <Text>{selectedPlan?.description}</Text>
    ) : null}
    <Text>
      {durationInMonths(
        selectedPlan?.duration ?? 1,
        selectedPlan?.timeUnits ?? SUBSCRIPTION_TIME_UNITS.YEAR
      )}
    </Text>
  </>
);
