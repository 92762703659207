import React from "react";
import styled from "styled-components";

const H2Style = styled.h2`
  font-family: "Tommy Soft";
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.5em;
  color: #3a5883;
`;
function H2({ children }) {
  return <H2Style>{children}</H2Style>;
}

export default H2;
