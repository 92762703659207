import React from "react";
import _ from "lodash";
import {
  FormControl,
  Flex,
  HStack,
  Text,
  Select,
  Menu,
  MenuButton,
  Button,
  Icon,
  Divider,
  Badge,
  MenuList,
  MenuItem,
  MenuDivider,
  Checkbox,
} from "@chakra-ui/react";

import { ROLES } from "utils/authHelpers";
import { toSentenceCase } from "utils/string";
import { FiPlusCircle } from "react-icons/fi";

export const SecurityGroupView = ({ content }) => (
  <Flex alignItems="baseline">
    <HStack spacing={1}>
      {content.roles.map((role) => (
        <Badge key={role} variant="solid">
          {role.split("_").map(toSentenceCase).join(" ")}
        </Badge>
      ))}
    </HStack>
  </Flex>
);
export const SecurityGroupEdit = ({ state, handleChange }) => {
  const selectedValues = new Set(state.roles);
  const handleSelectFilterValue = (option) => {
    const isSelected = selectedValues.has(option);
    if (isSelected) {
      if (ROLES[option] === ROLES.USER) return; //Cannot remove community-user role otherwise mobile app breaks
      selectedValues.delete(option);
    } else {
      selectedValues.add(option);
    }
    const filterValues = Array.from(selectedValues);
    console.log("filterValues", filterValues);

    handleChange("roles")({
      target: { value: filterValues.length ? filterValues : undefined },
    });
  };

  const options = Object.keys(ROLES);
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        size="sm"
        color="gray.600"
        leftIcon={<Icon as={FiPlusCircle} />}
      >
        <HStack spacing={2}>
          {selectedValues?.size > 0 && (
            <>
              <Divider orientation="vertical" h={4} />
              <HStack spacing={1}>
                {selectedValues.size > 3 ? (
                  <Badge variant="solid">{selectedValues.size} selected</Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(ROLES[option]))
                    .map((option) => (
                      <Badge key={option} variant="solid">
                        {option.split("_").map(toSentenceCase).join(" ")}
                      </Badge>
                    ))
                )}
              </HStack>
            </>
          )}
        </HStack>
      </MenuButton>
      <MenuList pt={0} align="start">
        {options.map((option) => {
          const isSelected = selectedValues.has(ROLES[option]);
          return (
            <MenuItem
              isDisabled={ROLES[option] === ROLES.USER}
              key={option}
              onClick={() => handleSelectFilterValue(ROLES[option])}
              icon={option?.icon}
            >
              <Checkbox
                isDisabled={ROLES[option] === ROLES.USER}
                isChecked={isSelected}
                onChange={() => handleSelectFilterValue(ROLES[option])}
              >
                <span>{option.split("_").map(toSentenceCase).join(" ")}</span>
              </Checkbox>
            </MenuItem>
          );
        })}
        {selectedValues.size > 0 && (
          <>
            <MenuDivider />
            <MenuItem
              onClick={() =>
                handleChange("roles")({ target: { value: [ROLES.USER] } })
              }
              className="justify-center text-center"
            >
              Reset to Default
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};
