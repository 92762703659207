import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  VStack,
  Checkbox,
  FormErrorMessage,
  Spinner,
  Link as ChakraLink,
} from "@chakra-ui/react";
import firebase from "firebase/app";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/** local imports */
import ConsentFormModal from "./ConsentFormModal";
import { STEPS } from "../constants";
import { getUserIPAddress, saveSignature, updateUserParams } from "../services";

import landscapeBg from "assets/gift/relaxation-northernlights-bg.jpg";
import ebbiVR from "assets/gift/EbbiVR.png";
import worldProgress from "assets/products/iphone-worldprogress.png";
import AddressElements from "common/AddressElements";
import H1 from "common/H1";
import { getFormattedUsername } from "utils/authHelpers";

const login = async (email, password) => {
  try {
    await Auth.signIn(email.toLowerCase(), password);
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      window.analytics.identify(user.username, {
        email: user.attributes.email,
        name: user.attributes.given_name,
      });

      window.analytics.track("logged_in");
      firebase.analytics().logEvent("login");

      return user;
    }
  } catch (e) {
    alert(e.message);
  }

  return null;
};

const CustomOrgRegister = ({
  formState,
  handleChange,
  navigate,
  updateFormState,
}) => {
  const [loading, setLoading] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    password: false,
    acceptedTos: false,
    acceptedPrivacyPolicy: false,
  });

  const { isOpen, onOpen: onOpenConsentForm, onClose } = useDisclosure();
  const [consentBox, setConsentBox] = useState(false);

  const { acceptedTos, acceptedPrivacyPolicy, consentGiven } = formState;
  const consentState = { acceptedTos, acceptedPrivacyPolicy, consentGiven };

  const resetConsent = () => {
    updateFormState("acceptedTos", false);
    updateFormState("acceptedPrivacyPolicy", false);
    updateFormState("consentGiven", "");
  };

  const handleChangeConsent = (evt) => {
    if (!evt.target.checked) {
      setConsentBox(evt.target.checked);
      resetConsent();
    }
    if (evt.target.checked && !consentBox) {
      onOpenConsentForm();
      updateErrors("consentGiven", false);
    }
  };

  const updateErrors = (field, message) =>
    setFormErrors((prev) => ({ ...prev, [field]: message }));

  const handleSaveConsentModal = (consentGiven) => {
    if (consentGiven === formState.name) {
      updateErrors("consentGiven", false);
      setConsentBox(true);
      updateFormState("consentGiven", consentGiven);
      onClose();
    } else {
      updateErrors(
        "consentGiven",
        "Signature must match the provided Full Name"
      );
    }
  };

  const handleCancelConsent = () => {
    setConsentBox(false);
    resetConsent();
    onClose();
  };

  const createAccount = async () => {
    try {
      const formattedUsername = getFormattedUsername(formState.email);

      await Auth.signUp({
        username: formattedUsername,
        password: formState.password,
        attributes: {
          email: formState.email.toLowerCase(),
          given_name: formState.name,
          family_name: "",
          birthdate: "00/00/0000",
        },
      });
      window.analytics.identify(formattedUsername, {
        email: formState.email.toLowerCase(),
        name: formState.name,
      });
      const date = new Date();
      const params = {
        year: date.getFullYear(),
        day: Math.floor(
          (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
        ),
      };
      window.analytics.track("account_created", params);
      firebase.analytics().logEvent("account_created", params);
    } catch (err) {
      // handle error
      console.log(err);
      alert(err.message);
    }
  };

  const createConsent = async () => {
    try {
      const userLoc = await getUserIPAddress();
      const consentRes = await saveSignature(formState.consentGiven, {
        ipAddress: userLoc,
      });
      return consentRes.data.createConsent.id;
    } catch (err) {
      // handle error
      console.log(err);
      alert(err.message);
    }
  };

  const checkErrors = () => {
    let hasErr = false;

    const validName = validateNonEmpty(formState.name, "Your name is required");
    if (validName.hasErr) hasErr = validName.hasErr;
    if (validName.hasErr) updateErrors("name", validName.message);

    const validPhone = validateNonEmpty(
      formState.phone,
      "Phone number is required"
    );
    if (validPhone.hasErr) hasErr = validPhone.hasErr;
    if (validPhone.hasErr) updateErrors("phone", validPhone.message);

    const validPassword = validatePassword(
      formState.password,
      "Password cannot be left empty"
    );
    if (validPassword.hasErr) hasErr = validPassword.hasErr;
    if (validPassword.hasErr) updateErrors("password", validPassword.message);

    if (!formState.acceptedTos) {
      hasErr = true;
      updateErrors("acceptedTos", "Please accept Flowly's Terms of Service");
    }
    if (!formState.acceptedPrivacyPolicy) {
      hasErr = true;
      updateErrors(
        "acceptedPrivacyPolicy",
        "Please accept Flowly's Privacy Policy"
      );
    }
    return hasErr;
  };

  useEffect(() => {
    const validName = validateNonEmpty(formState.name, "Your name is required");
    const validPhone = validateNonEmpty(
      formState.phone,
      "Phone number is required"
    );
    if (!validName.hasErr) {
      updateErrors("name", null);
    }
    if (!validPhone.hasErr) {
      updateErrors("phone", null);
    }
    if (formState.acceptedTos) {
      updateErrors("acceptedTos", null);
    }
    if (formState.acceptedPrivacyPolicy) {
      updateErrors("acceptedPrivacyPolicy", null);
    }
  }, [
    formState.name,
    formState.phone,
    formState.acceptedTos,
    formState.acceptedPrivacyPolicy,
  ]);

  const next = async (evt) => {
    evt.preventDefault();
    const hasErrors = checkErrors();
    if (hasErrors) return;

    setLoading(true);

    await createAccount();
    const user = await login(formState.email, formState.password);

    if (user) {
      const consentId = await createConsent();
      await updateUserParams(user, {
        consentId,
      });
      navigate(STEPS.shipping);
    } else {
      updateFormState(
        "errorMessage",
        "Account created successfully, but failed to complete setup process."
      );
      navigate(STEPS.error);
    }

    setLoading(false);
  };

  const validateAlias = async () => {
    const validEmail = validateNonEmpty(
      formState.email,
      "Your email is required"
    );
    if (validEmail.hasErr) {
      updateErrors("email", validEmail.message);
      return;
    }

    const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!formState.email.match(validEmailRegex)) {
      setFormErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email",
      }));
      return;
    }

    setCheckEmail(true);
    try {
      await Auth.signIn(
        formState.email.toLowerCase(),
        "aabbccdd134ranggwahfealfeuiabglaewifhafd=========aa=a=a"
      );
    } catch (e) {
      console.log(e);
      if (e.code === "NotAuthorizedException") {
        console.log("Email already registered.");
        // show error
        updateErrors(
          "email",
          "Account already exists, please use a different email."
        );
      }
      if (e.code === "UserNotFoundException") {
        console.log("No matching email found.");
        updateErrors("email", null);
        return;
      }
    } finally {
      setCheckEmail(false);
    }
  };
  // console.log(formErrors);

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={next}>
          <VStack my="20" alignItems="flex-start" spacing={6}>
            <VStack alignItems="flex-start" spacing={1}>
              <H1>Welcome to Flowly!</H1>
              <Text fontSize="lg" color="gray.400">
                Please register to unlock the full Flowly experience.
              </Text>
            </VStack>
            <AddressElements
              state={formState}
              handleChange={handleChange}
              elements={{ phone: true, name: true }}
              required={{ phone: true, name: true }}
              errors={formErrors}
            />

            <FormControl isRequired isInvalid={formErrors["email"]} id="email">
              <FormLabel fontWeight="semibold">Email</FormLabel>
              <InputGroup>
                <Input
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formState.email}
                  onChange={handleChange("email")}
                  onBlur={validateAlias}
                  required
                  placeholder="john.doe@gmail.com"
                  size="lg"
                />
                {checkEmail && (
                  <InputRightElement mt={1}>
                    <Spinner size="md" />
                  </InputRightElement>
                )}
              </InputGroup>
              {formErrors["email"] && (
                <FormErrorMessage>{formErrors["email"]}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isRequired
              isInvalid={formErrors["password"]}
              id="password"
            >
              <FormLabel fontWeight="semibold">Create Password</FormLabel>
              <PasswordInput
                value={formState.password}
                onChange={handleChange("password")}
                onBlur={() => {
                  const validPassword = validatePassword(formState.password);
                  if (validPassword.hasErr)
                    return updateErrors("password", validPassword.message);
                  return updateErrors("password", null);
                }}
              />
              {formErrors["password"] && (
                <FormErrorMessage>{formErrors["password"]}</FormErrorMessage>
              )}
            </FormControl>
            <VStack spacing={0} align="flex-start">
              <Checkbox
                isChecked={acceptedTos}
                onChange={handleChange("acceptedTos")}
                color="gray.700"
              >
                I accept Flowly&apos;s{" "}
                <ChakraLink
                  color="blue.500"
                  href="https://www.flowly.world/terms-of-service"
                  isExternal
                >
                  Terms of Service
                </ChakraLink>
              </Checkbox>
              {formErrors["acceptedTos"] && (
                <FormErrorMessage>{formErrors["acceptedTos"]}</FormErrorMessage>
              )}
              <Checkbox
                isChecked={acceptedPrivacyPolicy}
                onChange={handleChange("acceptedPrivacyPolicy")}
                color="gray.700"
              >
                I have read and accept Flowly&apos;s{" "}
                <ChakraLink
                  color="blue.500"
                  href="https://www.flowly.world/privacy-policy"
                  isExternal
                >
                  Privacy Policy
                </ChakraLink>
              </Checkbox>
              {formErrors["acceptedPrivacyPolicy"] && (
                <FormErrorMessage>
                  {formErrors["acceptedPrivacyPolicy"]}
                </FormErrorMessage>
              )}
              <Checkbox
                isRequired
                isChecked={consentBox}
                onChange={handleChangeConsent}
              >
                I have read and accepted the{" "}
                <Button
                  variant="link"
                  color="blue.500"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onOpenConsentForm();
                  }}
                >
                  Participant Consent Form
                </Button>
              </Checkbox>
            </VStack>

            <HStack alignItems="center" spacing={8}>
              <Button
                type="submit"
                disabled={
                  !consentBox ||
                  !formState.consentGiven ||
                  !formState.acceptedTos ||
                  !formState.acceptedPrivacyPolicy ||
                  Object.values(formErrors).filter((x) => !!x).length
                }
                onClick={next}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText={"Creating your account..."}
                py="1rem"
                px="110px"
              >
                Create Account
              </Button>
            </HStack>
          </VStack>
          <ConsentFormModal
            consentState={consentState}
            handleConsentChange={handleChange}
            consentDoesNotMatch={formErrors["consentGiven"]}
            isOpen={isOpen}
            onClose={onClose}
            onSave={handleSaveConsentModal}
            onCancel={handleCancelConsent}
            signaturePlaceholder={formState.name}
          />
        </form>
      </GridItem>
      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
          pl="10%"
        >
          <Image src={ebbiVR} maxW="16rem" alt="welcome-to-flowly" zIndex={2} />
          <Image
            position="relative"
            src={worldProgress}
            top="-15"
            left="-12"
            transform="rotateY(17deg) translate(-2rem, -2rem)"
            maxW="16rem"
            alt="Flowly world progress"
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default CustomOrgRegister;

const PasswordInput = ({ value, onChange, ...rest }) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <InputGroup>
      <Input
        name="password"
        type={isOpen ? "text" : "password"}
        autoComplete="current-password"
        placeholder="Password"
        value={value}
        onChange={onChange}
        size="lg"
        required
        {...rest}
      />
      <InputRightElement top="1">
        <IconButton
          bg="transparent !important"
          variant="ghost"
          aria-label={isOpen ? "Mask password" : "Reveal password"}
          icon={isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />}
          onClick={onClickReveal}
        />
      </InputRightElement>
    </InputGroup>
  );
};

const validateNonEmpty = (value, message) =>
  value.length > 0
    ? { message: null, hasErr: false }
    : { message, hasErr: true };

const validatePassword = (value) => {
  const nonEmptyPassword = validateNonEmpty(
    value,
    "Password cannot be left empty"
  );
  if (nonEmptyPassword.hasErr) return nonEmptyPassword;

  if (!(value.length >= 8))
    return {
      message: "Password must be at least 8 characters long",
      hasErr: true,
    };

  return { message: null, hasErr: false };
};
