import React from "react";
import _ from "lodash";
import {
  FormControl,
  FormLabel,
  Flex,
  Text,
  Input,
  HStack,
  VStack,
} from "@chakra-ui/react";

export const AddressView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content?.address && _.size(content?.address) ? (
        <>
          <Text fontWeight="bold" fontSize="md">
            {content.address?.address1}
          </Text>
          <Text fontWeight="bold" fontSize="md">
            {content.address?.address2}
          </Text>
          <Text fontWeight="bold" fontSize="md">
            {`${content.address?.city}, ${content.address?.state} ${content.address?.zip}`}
          </Text>
        </>
      ) : (
        <Text fontWeight="bold" fontSize="md">
          No address on record
        </Text>
      )}
    </Text>
  </Flex>
);
export const AddressEdit = ({ state, handleChange }) => (
  <VStack spacing="3">
    <FormControl id="addr1">
      <FormLabel mb="px" fontWeight="semibold">
        Address Line 1
      </FormLabel>
      <Input
        name="address.address1"
        type="text"
        rounded="md"
        value={_.get(state, "address.address1")}
        onChange={handleChange("address.address1")}
        placeholder={_.get(state, "address.address1")}
        required
        size="sm"
      />
    </FormControl>
    <FormControl id="addr2">
      <FormLabel mb="px" fontWeight="semibold">
        Address Line 2
      </FormLabel>
      <Input
        name="address.address2"
        type="text"
        rounded="md"
        value={_.get(state, "address.address2")}
        onChange={handleChange("address.address2")}
        placeholder={_.get(state, "address.address2")}
        required
        size="sm"
      />
    </FormControl>
    <HStack>
      <FormControl id="city">
        <FormLabel mb="px" fontWeight="semibold">
          City
        </FormLabel>
        <Input
          name="address.city"
          type="text"
          rounded="md"
          value={_.get(state, "address.city")}
          onChange={handleChange("address.city")}
          placeholder={_.get(state, "address.city")}
          required
          size="sm"
        />
      </FormControl>
      <FormControl id="state">
        <FormLabel mb="px" fontWeight="semibold">
          State
        </FormLabel>
        <Input
          name="address.state"
          type="text"
          rounded="md"
          value={_.get(state, "address.state")}
          onChange={handleChange("address.state")}
          placeholder={_.get(state, "address.state")}
          required
          size="sm"
        />
      </FormControl>
      <FormControl id="zip">
        <FormLabel mb="px" fontWeight="semibold">
          Zip Code
        </FormLabel>
        <Input
          name="address.zip"
          type="text"
          rounded="md"
          value={_.get(state, "address.zip")}
          onChange={handleChange("address.zip")}
          placeholder={_.get(state, "address.zip")}
          required
          size="sm"
        />
      </FormControl>
    </HStack>
  </VStack>
);
