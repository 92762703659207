import React, { useState, useMemo } from "react";
import _ from "lodash";
import {
  Box,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
  Link as ChakraLink,
  HStack,
} from "@chakra-ui/layout";
import Icon from "@chakra-ui/icon";
import { Button, IconButton } from "@chakra-ui/button";
import { Link, useParams } from "react-router-dom";
import ReactCalendarHeatmap from "react-calendar-heatmap";
import ReactTooltip from "react-tooltip";
import {
  compareAsc,
  compareDesc,
  format,
  isEqual,
  isAfter,
  subYears,
  sub,
  isSameDay,
  isFirstDayOfMonth,
  addMinutes,
} from "date-fns/esm";
import { ErrorBoundary } from "react-error-boundary";
import "react-calendar-heatmap/dist/styles.css";
import "./react-calendar-heatmap.css";

/* icon imports */
import { HiBadgeCheck } from "react-icons/hi";

/* local imports */
import { beautify } from "utils/string";
import { getDates } from "utils/dateUtils";
import { PORTAL_ROOT } from "../../../Routes";
import ErrorFallback from "common/ErrorFallback";

const getLevel = (count) => {
  if (count < 3) {
    return 1;
  }
  if (count < 6) {
    return 2;
  }
  if (count < 12) {
    return 3;
  }
  if (count < 15) {
    return 4;
  }
  if (count >= 24) {
    return 5;
  }
};

// TODO: Clean this up. This is the best workaround I could manage for now.

// export const toUTC = (date) => addMinutes(date, date.getTimezoneOffset());
// const toUTC = (date) =>
//   new Date(
//     Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) +
//       60 * 1000 * date.getTimezoneOffset()
//   );

// const toDate = (strDate) => {
//   // const d = new Date(strDate);
//   // const dateObj = new Date(
//   //   Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
//   // );
//   const [y, m, d] = strDate.split("-");
//   const dateObj = new Date(y, m - 1, d);
//   // console.log(dateObj);
//   return dateObj;
// };
// const toUTC = (date) =>
//   new Date(date.valueOf() + 60 * 1000 * date.getTimezoneOffset());

const dateConst = {
  get currentDay() {
    // return addDays(new Date(), 1);
    // return new Date(Date.now() + 60 * 1000 * new Date().getTimezoneOffset());
    return new Date();
  },
  get lastYear() {
    return sub(dateConst.currentDay, { years: 1, days: 1 });
  },
  get yearArr() {
    return getDates(dateConst.lastYear, dateConst.currentDay);
  },
};

const SessionHeatmapWidget = ({ user }) => {
  const sessionHistory = useMemo(() => {
    const firstDay = {
      count: 0,
      date: format(dateConst.lastYear, "yyyy-MM-dd"),
      // date: dateConst.lastYear.toISOString().split("T")[0],
      level: 0,
    };
    const today = {
      count: 0,
      date: format(dateConst.currentDay, "yyyy-MM-dd"),
      // date: dateConst.currentDay.toISOString().split("T")[0],
      level: 0,
    };
    const baseYear = dateConst.yearArr.map((day) => ({
      count: 0,
      date: day,
      level: 0,
    }));

    if (!user.sessions.length) return baseYear;

    console.log(
      "@Sessions",
      user.sessions.map((x) => x.createdAt)
    );
    const formatDates = user.sessions.map((s) => {
      // const [y, m, d] = s.createdAt.split("T")[0].split("-");
      return {
        id: s.id,
        createdAtDay: new Date(s.createdAt).toLocaleDateString("fr-CA"),
        // createdAtDay: toDate(new Date(s.createdAt).toISOString().split("T")[0])
        //   .toISOString()
        //   .split("T")[0],
        // createdAtDay: addDays(new Date(s.createdAt), 1)
        //   .toISOString()
        //   .split("T")[0],
      };
    });
    console.log(
      "@Sessions",
      dateConst.currentDay,
      formatDates.map((x) => x.createdAtDay)
    );
    const groupedByCreatedAt = _.groupBy(formatDates, "createdAtDay");
    console.log("@Sessions", groupedByCreatedAt);

    let formattedSessions = Object.entries(groupedByCreatedAt)
      .map(([day, sessions]) => {
        // console.log({ day });
        // const [y, m, d] = day.split("-");
        return {
          count: sessions.length,
          date: day,
          // date: toDate(day).toISOString().split("T")[0],
          // date: subDays(new Date(y, m - 1, d), 1)
          //   .toISOString()
          //   .split("T")[0],
          level: getLevel(sessions.length),
        };
      })
      .filter((day) => {
        // console.log(day);
        // const comparator = toDate(day.date);
        const comparator = new Date(day.date);
        // new Date(day.date);
        return (
          isSameDay(comparator, dateConst.lastYear) ||
          isAfter(comparator, dateConst.lastYear)
        );
      })
      .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)));

    formattedSessions = _.unionBy(formattedSessions, baseYear, "date");

    if (firstDay.date !== formattedSessions[0].date) {
      formattedSessions.unshift(firstDay);
    }

    if (today.date !== formattedSessions[formattedSessions.length - 1].date) {
      formattedSessions.push(today);
    }

    console.log({ groupedByCreatedAt, formattedSessions, baseYear });
    return formattedSessions;
  }, [user.sessions]);

  return (
    <VStack width="full" align="flex-start" spacing={3}>
      <Heading color="linkedin.500">Sessions</Heading>
      <Box p="8" bg="white" rounded="3xl" shadow="md" width="full">
        <SessionHeatmap
          sessions={user.sessions}
          sessionHistory={sessionHistory}
        />
      </Box>
    </VStack>
  );
};

export default SessionHeatmapWidget;

const SessionHeatmap = ({ sessions, sessionHistory }) => {
  const { userId } = useParams();
  const [selectedDate, setSelectedDate] = useState(null);

  const sessionsInView = useMemo(
    () =>
      sessions
        .filter((s) => {
          const comparator = new Date(s.createdAt);
          return (
            isSameDay(comparator, dateConst.lastYear) ||
            isAfter(comparator, dateConst.lastYear)
          );
        })
        .filter((s) => {
          if (!selectedDate) return s;
          // const [y, m, d] = toUTC(new Date(s.createdAt))
          //   .toISOString()
          //   .split("T")[0]
          //   .split("-");
          // const comparator = toDate(s.createdAt.split("T")[0]);
          const comparator = new Date(s.createdAt);
          // console.log({ comparator });
          return isSameDay(comparator, selectedDate);
        })
        .sort((a, b) =>
          compareDesc(new Date(a.createdAt), new Date(b.createdAt))
        ),
    [sessions, selectedDate]
  );
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ReactCalendarHeatmap
        startDate={subYears(dateConst.currentDay, 1)}
        endDate={dateConst.currentDay}
        // startDate={sessions[0].date}
        // endDate={sessions[sessions.length - 1].date}
        values={sessionHistory}
        classForValue={(value) =>
          !value || !value.level ? "color-empty" : `color-flowly-${value.level}`
        }
        tooltipDataAttrs={(value) => {
          const [y, m, d] = value?.date ? value.date.split("-") : [1970, 0, 1];
          return {
            "data-tip": `<strong>${
              value?.count ?? 0
            } sessions</strong> on ${format(
              new Date(y, m - 1, d),
              "MMM do, yyyy"
            )}`,
            rx: isFirstDayOfMonth(new Date(y, m - 1, d)) ? 2 : 8,
            ry: isFirstDayOfMonth(new Date(y, m - 1, d)) ? 2 : 8,
            visibility: value.date ? "visible" : "hidden",
          };
        }}
        gutterSize={2}
        showWeekdayLabels
        weekdayLabels={
          dateConst.currentDay.getTimezoneOffset() > 0
            ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
            : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        }
        onClick={(value) => {
          if (!value) return null;
          console.log("@SESSIONS clicked", { value });
          // const [y, m, d] = value.date.split("-");
          return setSelectedDate(new Date(value.date + "T00:00:00"));
        }}
      />
      <ReactTooltip html />
      <Box mt="-4" mb="4">
        {selectedDate ? (
          <Text>
            <strong>{sessionsInView.length} Sessions</strong> on{" "}
            {format(selectedDate, "MMM do, yyyy")}
            <Button
              ml="2"
              size="xs"
              variant="ghost"
              onClick={() => setSelectedDate(null)}
            >
              Show all
            </Button>
          </Text>
        ) : (
          <Text>
            <strong>{sessionsInView.length} Sessions</strong> in the last year
          </Text>
        )}
      </Box>
      <List spacing={2} maxHeight="sm" overflowY="auto">
        {sessionsInView.length ? (
          sessionsInView.map((session, index) => (
            <ListItem key={session.id}>
              <HStack spacing={0}>
                <ListIcon as={HiBadgeCheck} color="green.400" />
                <Box width="full" display="flex" justifyContent="space-between">
                  <ChakraLink
                    fontWeight="semibold"
                    color="linkedin.500"
                    as={Link}
                    to={`${PORTAL_ROOT}/users/${userId}/session/${session.id}`}
                  >
                    {beautify(session.worldId)} {session.sessionNumber}
                  </ChakraLink>
                  <Text fontWeight="light" color="gray.400" mr="2">
                    {format(new Date(session.createdAt), "PPp")}
                  </Text>
                </Box>
              </HStack>
            </ListItem>
          ))
        ) : (
          <Text>No sessions recorded on this day</Text>
        )}
      </List>
    </ErrorBoundary>
  );
};
