import React, { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/modal";
import { useToast } from "@chakra-ui/toast";
import { Button } from "@chakra-ui/button";

/** local imports */
import { destroyEvent } from "../services";

const ConfirmDeleteDialog = ({ isOpen, onClose, id, refetch }) => {
  const cancelRef = useRef();
  const toast = useToast();

  const handleDeleteEvent = async () => {
    try {
      await destroyEvent(id);
      toast({
        title: "Event deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetch();
      onClose();
    } catch (error) {
      toast({
        title: "Unable to delete event, please contact an administrator",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Delete Event?</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Are you sure you want to delete this event? You cannot undo this
          action afterwards.
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            No
          </Button>
          <Button colorScheme="red" ml={3} onClick={handleDeleteEvent}>
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmDeleteDialog;
