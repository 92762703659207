import { client } from "utils/awsConfig";
import { SEND_INAPP_MESSAGE } from "graphql/mutations";
import {
  createConversation,
  createParticipant,
  createMessage,
  getDisplayName,
} from "../domain";

export function mapApiToConversation(coachUserLink) {
  const { id, userId, coachId, user, coachHeadshotKey, messages } =
    coachUserLink;

  //TODO: Iterate over messages, find last message sent by each party, check the readAt date, and add that as the participant's lastReadMessageId and timestamp
  const coachParticipant = createParticipant({
    id: coachId,
    name: getDisplayName(user),
    lastReadMessageId: "message-X",
    lastReadMessageTimestampInMs: new Date().getTime(),
    headshotKey: coachHeadshotKey,
  });
  const recipientParticipant = createParticipant({
    id: userId,
    name: getDisplayName(user),
    lastReadMessageId: "message-X",
    lastReadMessageTimestampInMs: new Date().getTime(),
    headshotKey: "someURL",
  });

  const conversation = createConversation(id);
  conversation
    .addParticipant(coachParticipant)
    .addParticipant(recipientParticipant);

  messages.items.forEach(({ id, body, conversationId, userId, createdAt }) =>
    conversation.addMessage(
      createMessage({
        id,
        body,
        conversationId,
        authorId: userId,
        createdAt,
      })
    )
  );

  return conversation.build();
}

export function sendMessage(recipient, payload) {
  return client.mutate({
    mutation: SEND_INAPP_MESSAGE,
    variables: {
      input: mapMessageToApi(recipient, payload),
    },
  });
}

function mapMessageToApi(
  { id: recipientId },
  { body, authorId, conversationId }
) {
  return {
    userId: authorId,
    recipientId,
    conversationId,
    body,
  };
}
