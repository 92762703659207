import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { Box } from "@chakra-ui/layout";
import _ from "lodash";
import qs from "qs";

/* local imports */
import RedeemVoucherAuth from "./components/RedeemVoucherAuth";
import RedeemVoucherRegister from "./components/RedeemVoucherRegister";
import RedeemVoucherLogin from "./components/RedeemVoucherLogin";
import RedeemVoucherComplete from "./components/RedeemVoucherComplete";
import RedeemVoucherLanding from "./components/RedeemVoucherLanding";
import RedeemVoucherInfo from "./components/RedeemVoucherInfo";
import { STEPS } from "./constants";

const RedeemVoucherSubscription = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const [step, setStep] = useState(STEPS.landing);

  const navigate = useCallback(
    (selectedStep) => {
      history.push({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: selectedStep }),
      });
      setStep(STEPS[selectedStep]);
    },
    [url, currentParams, history]
  );

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    password: "",
    orgCode: "",
    step: "landing",
    voucherCode: "",
    orderReferenceNumber: "",
    subscription: "",
    requiresKit: null,
  });

  useEffect(() => {
    setFormState((prev) => ({ ...prev, ...currentParams }));
  }, [currentParams]);

  const handleInputChange = (property) => (e) =>
    setFormState((prev) => ({
      ...prev,
      [property]: _.get(e, "target.value") ?? e,
    }));

  const updateFormState = (property, value) => {
    if (Array.isArray(property) && Array.isArray(value)) {
      const mergeKeyVals = property.reduce((acc, current, idx) => {
        acc.push([current, value[idx]]);

        return acc;
      }, []);

      const updatedState = Object.fromEntries(mergeKeyVals);
      return setFormState((prev) => ({ ...prev, ...updatedState }));
    }

    return setFormState((prev) => _.set(prev, property, value));
  };

  const currentStep = useMemo(() => currentParams.step, [currentParams]);
  useEffect(() => {
    if (!currentStep) {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS.landing }),
      });
    } else {
      navigate(currentStep);
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS[currentStep] }),
      });
    }
  }, [currentStep]);

  console.log(formState);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <CurrentStep
        step={step}
        navigate={navigate}
        handleChange={handleInputChange}
        formState={formState}
        updateFormState={updateFormState}
      />
    </Box>
  );
};

export default RedeemVoucherSubscription;

const CurrentStep = ({ step, ...controls }) => {
  switch (step) {
    case STEPS.thankyou:
      return <RedeemVoucherComplete {...controls} />;
    // case STEPS.voucherDetails:
    //   return <RedeemVoucherInfo {...controls} />;
    case STEPS.login:
      return <RedeemVoucherLogin {...controls} />;
    case STEPS.register:
      return <RedeemVoucherRegister {...controls} />;
    case STEPS.authDirect:
      return <RedeemVoucherAuth {...controls} />;
    case STEPS.landing:
    default:
      return <RedeemVoucherLanding {...controls} />;
  }
};
