import React, { useRef, useState } from "react";
import {
  Box,
  Link as ChakraLink,
  VStack,
  Grid,
  GridItem,
  Text,
  HStack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import Icon from "@chakra-ui/icon";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import { STEPS } from "../RedeemGift";
import { client } from "utils/awsConfig.js";
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";

import { REDEEM_GIFT } from "graphql/mutations";

const RedeemLogin = ({
  navigate,
  handleChange,
  formState,
  updateFormState,
}) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const redeemGift = async () => {
    setLoading(true);
    console.log({
      redemptionCode: formState.redemptionCode,
      giftId: formState.giftId,
    });
    try {
      const redeemGiftStatus = await client.mutate({
        mutation: REDEEM_GIFT,
        variables: {
          input: {
            redemptionCode: formState.redemptionCode,
            giftId: formState.giftId,
          },
        },
      });

      console.log({ redeemGiftStatus });
      if (redeemGiftStatus.data.redeemGift.result) {
        onRedeemSuccess(redeemGiftStatus.data.redeemGift.result);
      } else if (redeemGiftStatus.data.redeemGift.error) {
        alert(redeemGiftStatus.data.redeemGift.error);
      }
    } catch (err) {
      alert(
        "Something went wrong while attempting to redeem your gift. \n" +
          err.message
      );
      console.log("[Redeem Gift] Error occured: ", err);
    }
  };

  const onRedeemSuccess = (successMsg) => {
    window.analytics.track("Gift Redeemed Successfully", {
      giftId: formState.giftId,
    });
    console.log("Gift redeemed successfully!");
    // alert(successMsg);

    updateFormState("redeemSuccess", successMsg);
    navigate(STEPS.thankyou);
  };

  const login = async () => {
    setLoading(true);
    try {
      await Auth.signIn(formState.email.toLowerCase(), formState.password);
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        window.analytics.identify(user.username, {
          email: user.attributes.email,
          name: user.attributes.given_name,
        });

        window.analytics.track("logged_in");
        firebase.analytics().logEvent("login");
      }
      await redeemGift();
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };
  const next = () => login();

  const goBack = () => navigate(STEPS.landing);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  console.log("@REDEEM", loading, formState);
  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={next}>
          <VStack mt="20" alignItems="flex-start" spacing={4}>
            <H1>Welcome back!</H1>
            <Text fontSize="lg" color="gray.400">
              {/* Replace bold items with invitee & organisationName */}
              Login to get redeem your Flowly subscription.
            </Text>
            <FormControl id="password">
              <FormLabel fontWeight="semibold">Password</FormLabel>
              <InputGroup>
                <Input
                  name="password"
                  type={isOpen ? "text" : "password"}
                  autoComplete="current-password"
                  placeholder="Password"
                  value={formState.password}
                  onChange={handleChange("password")}
                  size="lg"
                  required
                />
                <InputRightElement top="1">
                  <IconButton
                    bg="transparent !important"
                    variant="ghost"
                    aria-label={isOpen ? "Mask password" : "Reveal password"}
                    icon={isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />}
                    onClick={onClickReveal}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <HStack alignItems="center" spacing={8}>
              <Button
                type="submit"
                onClick={next}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText={"Signing in..."}
                py="1rem"
                px="110px"
              >
                Next
              </Button>
              <ChakraLink onClick={goBack} color="gray.400">
                Use a different email
              </ChakraLink>
            </HStack>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        pt="16"
        pl="5vw"
        pr="10vw"
      >
        <Box display="grid" placeItems="center">
          <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemLogin;
