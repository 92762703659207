import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  Flex,
  Text,
  Select,
  FormControl,
  Input,
  Box,
  Spinner,
  HStack,
  VStack,
  Badge,
  Button,
  Icon,
  Checkbox,
  IconButton,
  useClipboard,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { format, formatDistanceToNow } from "date-fns/esm";

/* icon imports */
import { MdCopyAll } from "react-icons/md";

/* local imports */
import { toSentenceCase } from "utils/string";
import useSubscriptionProducts from "hooks/useSubscriptionProducts";
import { SUBSCRIPTION_PRODUCT_TYPES } from "pages/Checkout/constants";
import { UPDATE_SUBSCRIPTION } from "graphql/mutations";
import { client } from "utils/awsConfig";
import { SubscriptionMethodIcons, SubscriptionPlanNames } from "../constants";

export const SubscriptionPlanView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content?.subscriptionPlan
        ? toSentenceCase(content.subscriptionPlan)
        : ""}
    </Text>
  </Flex>
);

export const SubscriptionPlanEdit = ({ state, handleChange }) => (
  <Flex alignItems="baseline">
    <Select
      name="subscriptionPlan"
      type="text"
      value={_.get(state, "subscriptionPlan")}
      onChange={handleChange("subscriptionPlan")}
      required
      rounded="md"
      size="sm"
    >
      <option value="FREE">Free</option>
      <option value="COMMUNITY">Community</option>
      <option value="BASIC">Basic</option>
      <option value="PRO">Pro</option>
    </Select>
  </Flex>
);

export const SubscriptionView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content?.subscriptionType
        ? toSentenceCase(content.subscriptionType)
        : ""}
    </Text>
  </Flex>
);

export const SubscriptionEdit = ({ state, handleChange }) => (
  <Flex alignItems="baseline">
    <Select
      name="subscriptionType"
      type="text"
      value={_.get(state, "subscriptionType")}
      onChange={handleChange("subscriptionType")}
      required
      rounded="md"
      size="sm"
    >
      <option value="FREE">Free</option>
      <option value="YEARLY">Yearly</option>
      <option value="MONTHLY">Monthly</option>
      <option value="LIFETIME">Lifetime</option>
    </Select>
  </Flex>
);

export const SubscriptionProviderView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content?.subscriptionMethod
        ? toSentenceCase(content.subscriptionMethod)
        : ""}
    </Text>
  </Flex>
);

export const SubscriptionProviderEdit = ({ state, handleChange }) => (
  <Flex alignItems="baseline">
    <Select
      name="subscriptionMethod"
      type="text"
      value={_.get(state, "subscriptionMethod")}
      onChange={handleChange("subscriptionMethod")}
      required
      rounded="md"
      size="sm"
    >
      <option value="STRIPE">Stripe</option>
      <option value="APPLE">Apple</option>
      <option value="OTHER">Other</option>
    </Select>
  </Flex>
);

export const SubscriptionExpirationView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content?.expirationDate
        ? `${format(
            new Date(+content.expirationDate),
            "MMM do, yyyy"
          )} (${formatDistanceToNow(
            new Date(+content.expirationDate)
          )} from now).
        `
        : "No expiration date set"}
    </Text>
  </Flex>
);

export const SubscriptionExpirationEdit = ({ state, handleChange }) => (
  <Flex alignItems="baseline">
    <FormControl id="addr1">
      <Input
        name="expirationDate"
        type="date"
        rounded="md"
        value={_.get(state, "expirationDate")}
        onChange={handleChange("expirationDate")}
        placeholder={_.get(state, "expirationDate")}
        required
        size="sm"
      />
    </FormControl>
  </Flex>
);

export const ListSubscriptionProducts = ({
  initialSelection,
  toggleEdit,
  mutateLocalUser,
  isSubscriptionProduct,
}) => {
  const { userId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selected, setSelectedPlan] = useState(initialSelection);
  const [provider, setPaymentProvider] = useState("STRIPE");
  const [showDisabled, setShowDisabled] = useState(false);

  const { onCopy, hasCopied } = useClipboard(selected);
  const toast = useToast();

  useEffect(() => {
    if (hasCopied)
      toast({
        status: "success",
        title: "Copied product ID successfully!",
      });
  }, [hasCopied]);

  const { data: products, isLoading } = useSubscriptionProducts({
    variables: {
      filter: {
        and: [
          // {
          //   active: {
          //     eq: true,
          //   },
          // },
          // {
          //   subscriptionMethod: {
          //     eq: "STRIPE",
          //   },
          // },
          {
            subscriptionProductType: {
              eq: SUBSCRIPTION_PRODUCT_TYPES.STANDARD,
            },
          },
          // {
          //   duration: {
          //     eq: 1,
          //   },
          // },
        ],
      },
    },
  });

  const productsToDisplay = useMemo(
    () => [
      ...products.filter((p) => p.id === initialSelection),
      ...products
        .filter(
          (p) => p.id !== initialSelection && p.subscriptionMethod === provider
        )
        .filter((p) => {
          if (!showDisabled) return p.active;
          return p.active || !p.active;
        })
        .sort((a, b) => a.price - b.price),
    ],
    [products, initialSelection, provider, showDisabled]
  );

  const updateUser = async () => {
    setIsSubmitting(true);
    try {
      const selectedProduct = products.find((prod) => prod.id === selected);
      const {
        id: subscriptionProductId,
        subscriptionMethod,
        subscriptionPlan,
        subscriptionType,
      } = selectedProduct;

      let variables = {
        input: {
          id: userId,
          subscriptionProductId,
          subscriptionMethod,
          subscriptionPlan,
          subscriptionType,
        },
      };
      console.log(variables, selectedProduct);

      const user = await client.mutate({
        mutation: UPDATE_SUBSCRIPTION,
        variables,
      });
      setIsSubmitting(false);
      toggleEdit();

      mutateLocalUser({
        subscriptionInfo: { ...user.data.updateSubscriptionInfo },
      });
    } catch (err) {
      setIsSubmitting(false);
      alert("Failed to update user subscription");
      console.log("[Update User] Error occured: ", err);
    }
  };

  if (isLoading) {
    return (
      <Box py="6" px="1">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <HStack pt="4" spacing={2}>
        {/* <Input
          name="filter"
          type="text"
          value={filterByIdValue}
          onChange={(evt) => setFilterByIdValue(evt.target.value)}
          // size="md"
          // borderBottomRadius="none"
          placeholder="Filter plans..."
        /> */}
        <Select
          onChange={(evt) => setPaymentProvider(evt.target.value)}
          minW="-moz-max-content"
          maxW="xs"
        >
          <option value="STRIPE">Stripe</option>
          <option value="APPLE">Apple</option>
        </Select>
        <Checkbox
          value={showDisabled}
          whiteSpace="nowrap"
          onChange={(evt) => setShowDisabled(evt.target.checked)}
        >
          Show disabled products?
        </Checkbox>
      </HStack>
      <HStack
        align="stretch"
        pt="6"
        pb="1"
        px="1"
        overflowX="scroll"
        spacing={3}
        // css={{
        //   "&::-webkit-scrollbar": {
        //     width: "0px",
        //   },
        //   "&::-webkit-scrollbar-track": {
        //     width: "0px",
        //   },
        // }}
      >
        {productsToDisplay.map(
          ({
            id,
            title = "",
            description = "",
            subscriptionType,
            price,
            timeUnits,
            initialPrice = 396.99,
            subscriptionPlan,
            subscriptionMethod,
            numDependents,
            active,
            ...rest
          }) => (
            <VStack
              key={id}
              shadow={selected === id ? "outline" : "md"}
              borderRadius="lg"
              align="flex-start"
              minW="xs"
              whiteSpace="nowrap"
              p="4"
              onClick={() => setSelectedPlan(id)}
              spacing={0}
            >
              <HStack mb="2">
                {isSubscriptionProduct && id === initialSelection ? (
                  <Badge
                    fontSize="xx-small"
                    variant="subtle"
                    colorScheme="purple"
                    ml="0"
                  >
                    Current Plan
                  </Badge>
                ) : null}
                {numDependents !== null ? (
                  <Badge
                    fontSize="xx-small"
                    variant="subtle"
                    colorScheme="linkedin"
                    ml="0"
                  >
                    Family Plan - {numDependents} accounts
                  </Badge>
                ) : null}

                <Badge
                  fontSize="xx-small"
                  variant="subtle"
                  colorScheme="linkedin"
                  ml="0"
                >
                  {subscriptionType}
                </Badge>
              </HStack>
              <Text fontWeight="bold" fontSize="md">
                {SubscriptionPlanNames[subscriptionPlan]}{" "}
                <Tooltip label="Copy plan ID">
                  <IconButton
                    icon={<MdCopyAll />}
                    size="xs"
                    variant="ghost"
                    onClick={onCopy}
                  />
                </Tooltip>
              </Text>
              <Text fontWeight="bold" color="gray.500" fontSize="sm">
                ${price} USD / {timeUnits.toLowerCase()}
              </Text>
              <HStack w="full" justify="space-between" spacing={4} pt="8">
                <Badge
                  rounded="full"
                  variant="subtle"
                  fontSize="xx-small"
                  colorScheme={active ? "green" : "gray"}
                  ml="0"
                  mt="4"
                  py="1"
                  px="2"
                  display="flex"
                  alignItems="center"
                  textTransform="revert"
                >
                  <Box
                    w="2"
                    h="2"
                    borderRadius="full"
                    mr="2"
                    bg={active ? "green.500" : "gray.500"}
                  />
                  {active ? "Active" : "Disabled"}
                </Badge>
                <Icon
                  as={SubscriptionMethodIcons[subscriptionMethod]}
                  w="auto"
                  pt="2"
                  h="10"
                  color="gray.500"
                />
              </HStack>
            </VStack>
          )
        )}
      </HStack>
      {!isSubscriptionProduct || selected !== initialSelection ? (
        <Button
          colorScheme="green"
          size="sm"
          onClick={updateUser}
          isLoading={isSubmitting}
        >
          Update Plan
        </Button>
      ) : null}
    </>
  );
};
