import React from "react";
import styled from "styled-components";

/* local imports */
import logo from "assets/logo.svg";
import H1 from "common/H1";
import PriceCard from "common/PriceCard";
import { device } from "utils/device";

const PlanStyle = styled.div`
  background: linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh);
  min-height: 100vh;
`;

const Header = styled.div`
  margin-top: 70px;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;

  img {
    margin-right: 1.25rem;
    height: 55px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 80vw;
  @media ${device.tablet} {
    width: 75vw;
  }
  @media ${device.laptop} {
    width: 60vw;
  }
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  .priceCard {
    margin-bottom: 1.25rem;
    grid-column: span 12;
    @media ${device.tablet} {
      grid-column: span 6;
    }
  }
`;
function Plan() {
  return (
    <PlanStyle>
      <Header>
        <img src={logo} alt="Flowly Logo" /> <H1>Membership</H1>
      </Header>
      <Content>
        <PriceCard
          className="priceCard"
          period="Monthly"
          monthlyPrice="$29.99"
          basePrice="$59.99"
          link="/checkout/MONTHLY"
          description="Billed monthly"
        />

        <PriceCard
          className="priceCard"
          period="Yearly"
          monthlyPrice="$15"
          basePrice="$33"
          mostPopular={true}
          saveMessage="Save 50%"
          link="/checkout/YEARLY"
          description="Billed $179.99 annually"
        />
      </Content>
    </PlanStyle>
  );
}

export default Plan;
