import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

/* local imports */
import logo from "assets/logo.svg";
import { device } from "utils/device";
import "./Navbar.css";

const noShowList = ["/gift", "/redeem", "/confirmation", "/org", "/patients", "/", "/checkout"];

const CheckoutNav = ({ disableBacklink = undefined }) => {
  const { pathname } = useLocation();

  return (
    <div>
      <StyledNav>
        {disableBacklink ? (
          <img src={logo} alt="Flowly Logo" />
        ) : (
          <a href="https://www.flowly.world/">
            <img src={logo} alt="Flowly Logo" />
          </a>
        )}
        {/* {!confirmation && <Stepper />} */}
      </StyledNav>
      {!noShowList.some((r) => pathname.includes(r)) && (
        <StyledBanner>
          <b>*Exclusive Holiday Discount! 50% off Monthly, 55% off Yearly*</b>
        </StyledBanner>
      )}
    </div>
  );
};

export default CheckoutNav;

// const Stepper = () => (
//   <ul>
//     <StyledListItem>
//       <NavLink
//         style={{ pointerEvents: "none" }}
//         exact
//         activeClassName="active"
//         to="/"
//       >
//         <img src={option1} alt="Get Device" />
//         Get Device
//       </NavLink>
//     </StyledListItem>
//     <StyledListItem>
//       <NavLink
//         style={{ pointerEvents: "none" }}
//         exact
//         activeClassName="active"
//         to="/plan"
//       >
//         <img src={option2} alt="Select Plan" />
//         Select Plan
//       </NavLink>
//     </StyledListItem>
//     <StyledListItem>
//       <NavLink
//         style={{ pointerEvents: "none" }}
//         activeClassName="active"
//         to="/checkout"
//       >
//         <img src={option3} alt="Checkout" />
//         Checkout
//       </NavLink>
//     </StyledListItem>
//   </ul>
// );

const StyledNav = styled.nav`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem 10vw;
  // padding-top: 25px;
  // padding-bottom: 23px;
  // padding-left: 10vw;
  // padding-right: calc(10vw + 11.25rem);
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: relative;

  img {
    width: 9.4rem;
    // width: 11.25rem;
  }

  ul {
    margin: 0 auto;
    display: none;
    grid-template-columns: repeat(12, 1fr);

    padding-inline-start: 0px;
    @media ${device.laptop} {
      display: grid;
    }
  }
`;
const StyledBanner = styled.nav`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 1rem 0;
  // padding-top: 25px;
  // padding-bottom: 23px;
  text-align: center;
  b {
    // color: #3a5883;
    color: rgba(146, 64, 14, 1);
  }
  background-color: #f9dfaf;
`;
// const StyledListItem = styled.li`
//   grid-column: span 4;
//   font-family: Nunito;
//   list-style-type: none;
//   display: flex;
//   flex: 1;
//   text-align: center;
//   white-space: nowrap;
//   padding-left: 10px;
//   padding-right: 10px;
//   a {
//     margin: 0 auto;
//     color: #3a5883;
//     font-size: 18px;
//     text-decoration: none;
//     opacity: 0.5;
//     align-items: center;
//     display: flex;
//   }
//   a.active {
//     opacity: 1;
//   }
//   a img {
//     height: 1.3em;
//     width: 1.3em;
//     margin-right: 0.4em;
//   }
// `;
