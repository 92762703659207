import React, { useEffect, useRef, useState } from "react";
import _, { get, omit, pick } from "lodash";
import { HStack, VStack, Divider, Text, Box } from "@chakra-ui/layout";
import { Button, IconButton } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/number-input";
import { Select } from "@chakra-ui/select";
import { Spinner } from "@chakra-ui/spinner";
import { Checkbox } from "@chakra-ui/checkbox";
import Icon from "@chakra-ui/icon";
import { useFieldArray, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

/* icon imports */
// import { HiOutlineSelector } from "react-icons/hi";
import { BiChevronDown } from "react-icons/bi";
import {
  MdOutlinePhone,
  MdEdit,
  MdOutlineClose,
  MdAddCircleOutline,
} from "react-icons/md";

/* local imports */
import {
  createOrder,
  createShipment,
  getProducts,
  getStores,
  updateUser,
} from "../services";

import useFetchOnce from "hooks/useFetchOnce";

const CreateShipmentSimpleModal = (props) => {
  const { isOpen, onClose, preload, mutateLocalUser } = props;

  const userRef = useRef();
  const toast = useToast();

  const [customFields, setCustomFields] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      bill: { products: [{ quantity: 1, sku: "0000000000" }] },
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bill.products",
  });

  const [{ value: products, error: productsErr, loading: productsLoading }] =
    useFetchOnce(getProducts, [], [], { getPath: "data.products" });
  const [{ value: stores, error: storesErr, loading: storesLoading }] =
    useFetchOnce(getStores, [], [], { getPath: "data" });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (data) =>
        (userRef.current = {
          ...data.attributes,
          username: data.username,
        })
    );
  }, []);

  const handleResetForm = (elements) => {
    let el = elements;
    if (!el)
      el = { user: true, cf: true, clearAddress: true, formDefaults: true };

    const { user, cf, formDefaults, clearAddress } = el;

    // if (user) setSelectedUser(null);
    if (cf) setCustomFields([]);
    if (clearAddress)
      reset({
        ...getValues(),
        address1: "",
        address2: "",
        city: "",
        zip: "",
        state: "",
        country: "United States",
      });
    if (formDefaults)
      reset({ bill: { products: [{ quantity: 1, sku: "0000000000" }] } });
  };
  const handleCustomAddress = () => {
    setCustomFields((cf) => [...cf, "ADDRESS"]);
    reset({ ...getValues(), ...preload?.address });
  };
  const handleCustomDetails = () => {
    setCustomFields((cf) => [...cf, "PERSONAL"]);
    reset({
      ...getValues(),
      ...{
        name: `${preload.firstName} ${preload.lastName ?? ""}`.trim(),
        phone: preload?.phone,
      },
    });
  };

  const handleClose = () => {
    handleResetForm();
    onClose();
  };

  const onSubmit = async (data) => {
    clearErrors("userId");

    // Handle selecting the Store
    let store = null;
    if (stores.length) {
      if (data.bill.products.length === 1) {
        if (data.bill.products[0].sku === "0000000000") {
          // If sending out a Flowly Kit ("0000000000") -> use Flowly Store
          store = stores.find(
            (s) =>
              s.storeName.includes("Flowly") &&
              s.marketplaceName.includes("ShipStation")
          );
        } else {
          // If sending out anything else -> use Flowly Ad Hoc Store
          store = stores.find((s) => s.storeName.includes("Ad Hoc"));
        }
      } else {
        // If sending out multiple items, user gets to make this decision
        store = data.store;
      }
    } else {
      store = { storeId: 463176 }; // hardcoded the Flowly Ad Hoc StoreId as a default fallback. Not optimal, but better than failing due to an API fetch failing.
    }

    // Handle sent data by selected userType
    if (!preload?.address && !customFields.includes("ADDRESS"))
      return setError("userId", {
        type: "manual",
        message:
          "This user doesn't have an address on record. Please update their profile and try again, or create the order with a custom recipient.",
      });

    try {
      const address = customFields.includes("ADDRESS")
        ? { ...data }
        : { ...preload.address };
      const personalDetails = customFields.includes("PERSONAL")
        ? { ...data }
        : {
            ...{
              name: `${preload.firstName} ${preload.lastName ?? ""}`.trim(),
              phone: null,
            },
          };

      // const order = await createOrder({
      //   ...address,
      //   ...personalDetails,
      //   ...data.bill,
      //   products: data.bill.products.map((prod) => ({
      //     ...prod,
      //     ...products.find((p) => p.sku === prod.sku),
      //   })),
      //   storeId: store.storeId,
      //   orderNumber: store.storeName.includes("Ad Hoc")
      //     ? `FL2-${userRef.current?.username}`
      //     : userRef.current?.username,
      // });
      // console.log(order.data);

      // const shipment = await createShipment({
      //   id: order.data.orderId,
      //   userId: preload.id,
      //   createdBy: userRef.current?.username,
      //   status: "WAITING",
      // });

      const shipment = await createShipment({
        userId: preload.id,
        createdBy: userRef.current?.username,
        recipientAddress: pick(address, [
          "address1",
          "address2",
          "city",
          "state",
          "zip",
          "country",
        ]),
        recipientEmail: preload.email,
        items: data.bill.products.map((prod) => {
          const { name, sku, weightOz } = products.find(
            (p) => p.sku === prod.sku
          );
          return {
            name,
            sku,
            weight: weightOz ?? 0,
          };
        }),
        storeId: store.storeId,
        status: "WAITING",
        shouldCreateOrder: true,
      });

      console.log(shipment.data);

      if (data.saveToProfile) {
        const user = await updateUser(preload.id, {
          address: pick(address, [
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "country",
          ]),
          mobile: personalDetails.phone ?? null,
        });
        mutateLocalUser(user.data.updateUser);
      }

      toast({
        title: "Shipment created.",
        description: `We've created a shipment for ${preload.firstName} ${
          preload.lastName ?? ""
        }.`,
        status: "success",
        duration: 7500,
        isClosable: true,
      });
      handleClose();
      return;
    } catch (err) {
      alert("Something went wrong during order creation:", err);
    }
  };

  // console.log(`fields`, fields);
  // console.log(`preload`, preload);
  // console.log(`preload`, preload);
  // console.log(`stores`, stores);
  // console.log(`selectedProduct`, selectedProduct);
  // console.log(`products`, products);
  // console.log(`currentUser`, userRef);
  // console.log({ preload, preload });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="3xl"
      blockScrollOnMount={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Shipment</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <ModalBody>
            <VStack alignItems="flex-start" spacing={8} my={4}>
              <VStack alignItems="flex-start" spacing={4} w="full">
                <Text fontSize="xl" fontWeight="bold">
                  Personal Information
                </Text>
                <HStack spacing={6} align="flex-start">
                  {!!preload &&
                    !!preload?.id &&
                    !customFields.includes("PERSONAL") && (
                      <UserPersonalDetailsSummary
                        user={preload}
                        handleCustom={handleCustomDetails}
                      />
                    )}

                  {!!preload &&
                    !!preload?.id &&
                    !customFields.includes("ADDRESS") && (
                      <UserAddressSummary
                        address={preload?.address}
                        handleCustomAddress={handleCustomAddress}
                      />
                    )}
                </HStack>
                {!!preload && customFields.includes("PERSONAL") && (
                  <PersonalDetailsFields register={register} errors={errors} />
                )}
                {!!preload && customFields.includes("ADDRESS") && (
                  <AddressFields register={register} errors={errors} />
                )}
                {errors?.userId && (
                  <FormErrorMessage>{errors.userId.message}</FormErrorMessage>
                )}
              </VStack>

              <VStack alignItems="flex-start" spacing={4} w="full">
                <Text fontSize="xl" fontWeight="bold">
                  Bill Details
                </Text>
                <VStack alignItems="flex-start" spacing={2} w="full">
                  {fields.map((prod, idx) => (
                    <ProductFields
                      key={prod.id}
                      idx={idx}
                      name={`bill.products[${idx}]`}
                      removeItem={() => remove(idx)}
                      register={register}
                      products={products}
                      productsLoading={productsLoading}
                      productsErr={productsErr}
                    />
                  ))}
                  <Button
                    leftIcon={<MdAddCircleOutline />}
                    pr={4}
                    size="sm"
                    variant="ghost"
                    onClick={() => append({ quantity: 1, sku: "0000000000" })}
                  >
                    Add Product
                  </Button>
                </VStack>
                {fields.length > 1 && (
                  <Box>
                    <FormControl id="store">
                      <FormLabel mb={1} fontWeight="semibold">
                        Store
                      </FormLabel>
                      <Select
                        {...register("store")}
                        icon={storesLoading ? <Spinner /> : <BiChevronDown />}
                        required
                        rounded="md"
                      >
                        {!storesLoading && !storesErr && Array.isArray(stores)
                          ? stores.map((st) => (
                              <option key={st.storeId} value={st.storeId}>
                                {st.storeName} — {st.marketplaceName}
                              </option>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </VStack>
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="linkedin"
              rounded="md"
              type="submit"
              loadingText="Creating your order..."
            >
              Create Order
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default CreateShipmentSimpleModal;

const ProductFields = ({
  idx,
  name,
  register,
  errors,
  products,
  productsLoading,
  productsErr,
  removeItem,
}) => (
  <HStack w="full" spacing={6} align="flex-start">
    <FormControl id={`${name}.sku`}>
      {idx === 0 && (
        <FormLabel mb={1} fontWeight="semibold">
          Product
        </FormLabel>
      )}
      <Select
        {...register(`${name}.sku`)}
        icon={productsLoading ? <Spinner /> : <BiChevronDown />}
        required
        rounded="md"
      >
        {!productsLoading && !productsErr && Array.isArray(products)
          ? products.map((prod) => (
              <option key={prod.sku} value={prod.sku}>
                {prod.sku} — {prod.name}
              </option>
            ))
          : null}
      </Select>
    </FormControl>
    <FormControl
      id={`${name}.quantity`}
      isInvalid={errors?.bill?.quantity}
      isRequired
      flexShrink={2}
    >
      {idx === 0 && (
        <FormLabel mb={1} fontWeight="semibold">
          Quantity
        </FormLabel>
      )}
      <NumberInput min={1}>
        <NumberInputField
          {...register(`${name}.quantity`, {
            required: {
              value: true,
              message: "This field is required",
            },
            valueAsNumber: true,
          })}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {get(errors, `${name}.quantity`) && (
        <FormErrorMessage>
          {get(errors, `${name}.quantity.message`)}
        </FormErrorMessage>
      )}
    </FormControl>
    <IconButton
      variant="ghost"
      alignSelf="flex-end"
      icon={<MdOutlineClose />}
      onClick={removeItem}
      isDisabled={idx === 0}
    />
  </HStack>
);

const AddressFields = ({ register, errors }) => (
  <>
    <FormControl id="address">
      <FormLabel mb={1} fontWeight="semibold">
        Address
      </FormLabel>
      <Input
        {...register("address1")}
        borderBottomRadius="none"
        placeholder="Address Line 1"
      />
      <Input
        {...register("address2")}
        mt="-px"
        borderTopRadius="none"
        placeholder="Address Line 2"
      />
    </FormControl>
    <HStack spacing={4} alignItems="flex-start">
      <FormControl id="city" isInvalid={errors?.city} isRequired>
        <FormLabel mb={1} fontWeight="semibold">
          City
        </FormLabel>
        <Input
          {...register("city", {
            required: {
              value: true,
              message: "City cannot be left empty",
            },
          })}
          placeholder="City"
        />
        {errors?.city && (
          <FormErrorMessage>{errors.city.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl id="state" isInvalid={errors?.state} isRequired>
        <FormLabel mb={1} fontWeight="semibold">
          State
        </FormLabel>
        <Input
          {...register("state", {
            required: {
              value: true,
              message: "State cannot be left empty",
            },
          })}
          placeholder="State"
        />
        {errors?.state && (
          <FormErrorMessage>{errors.state.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl id="zip" isInvalid={errors?.zip} isRequired>
        <FormLabel mb={1} fontWeight="semibold">
          Zip Code
        </FormLabel>
        <Input
          {...register("zip", {
            required: {
              value: true,
              message: "Zip code cannot be left empty",
            },
            minLength: {
              value: 5,
              message: "Zip code must be at least 5 digits long",
            },
          })}
          placeholder="XXXXX"
        />
        {errors?.zip && (
          <FormErrorMessage>{errors.zip.message}</FormErrorMessage>
        )}
      </FormControl>
    </HStack>
    <HStack w="75%" spacing={4} align="flex-end">
      <FormControl id="country">
        <FormLabel mb={1} fontWeight="semibold">
          Country
        </FormLabel>
        <Select
          {...register("country", { required: true })}
          defaultValue="United States"
        >
          <option value="United States">United States</option>
        </Select>
      </FormControl>
      <FormControl id="saveToProfile" pb={2}>
        <Checkbox {...register("saveToProfile")}>Update Profile?</Checkbox>
      </FormControl>
    </HStack>
  </>
);
const PersonalDetailsFields = ({ register, errors }) => (
  <HStack spacing={4} alignItems="flex-start">
    <FormControl id="name" isInvalid={errors?.name} isRequired>
      <FormLabel mb={1} fontWeight="semibold">
        Name
      </FormLabel>
      <Input
        {...register("name", {
          required: {
            value: true,
            message: "Name cannot be left empty",
          },
        })}
        type="text"
        placeholder="John Doe"
      />
      {errors?.name && (
        <FormErrorMessage>{errors.name.message}</FormErrorMessage>
      )}
    </FormControl>
    <FormControl id="phone">
      <FormLabel mb={1} fontWeight="semibold">
        Phone Number
      </FormLabel>
      <InputGroup>
        <Input type="tel" {...register("phone")} placeholder="(050) 123 4567" />
        <InputLeftElement>
          <Icon as={MdOutlinePhone} color="gray.400" fontSize="lg" />
        </InputLeftElement>
      </InputGroup>
    </FormControl>
  </HStack>
);

const UserAddressSummary = ({ address = null, handleCustomAddress }) => {
  if (!address)
    return (
      <Box p={4} bg="gray.100" rounded="md">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Address</Text>
          <IconButton
            size="xs"
            icon={<MdEdit />}
            onClick={handleCustomAddress}
          />
        </HStack>
        <Text>No address found</Text>
      </Box>
    );

  const { address1, address2, city, zip, state, country } = address ?? {
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
    country: "United States",
  };
  return (
    <Box p={4} bg="gray.100" rounded="md">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Address</Text>
        <IconButton size="xs" icon={<MdEdit />} onClick={handleCustomAddress} />
      </HStack>
      <Text>{address1}</Text>
      {address2 && <Text>{address2}</Text>}
      <Text>
        {city}, {state} {zip}
      </Text>
      <Text>{country}</Text>
    </Box>
  );
};

const UserPersonalDetailsSummary = ({ user = null, handleCustom }) => {
  if (!user)
    return (
      <Box p={4} bg="gray.100" rounded="md">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Details</Text>
          <IconButton size="xs" icon={<MdEdit />} onClick={handleCustom} />
        </HStack>
        <Text>No user details found</Text>
      </Box>
    );

  const { firstName, lastName, phone } = user ?? {
    firstName: "",
    lastName: "",
    phone: "",
  };
  return (
    <Box p={4} bg="gray.100" rounded="md">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Details</Text>
        <IconButton size="xs" icon={<MdEdit />} onClick={handleCustom} />
      </HStack>
      <Text>{`${firstName} ${lastName ?? ""}`.trim()}</Text>
      {phone ? <Text>{phone}</Text> : <Text>No phone on profile</Text>}
    </Box>
  );
};
