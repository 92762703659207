import React, { useState, useCallback } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/input";
import { Spinner } from "@chakra-ui/spinner";
import Icon from "@chakra-ui/icon";

/* icon imports */
import { FiSearch } from "react-icons/fi";

/* local imports */
import { client } from "utils/awsConfig.js";
import { PORTAL_ROOT } from "../../Routes";

import { SEARCH_USER_BY_EMAIL, SEARCH_USER_BY_USERNAME } from "graphql/queries";

const UserSearch = () => {
  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      height="100%"
    >
      <Container
        maxW="container.xl"
        height="calc(100vh - 4rem)"
        justifyContent="center"
        alignItems="center"
      >
        <VStack pt="48">
          <SearchWidget />
        </VStack>
      </Container>
    </Box>
  );
};

export default UserSearch;

export const SearchWidget = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUserList = async (searchStr) => {
    setLoading(true);
    setUsers([]);
    try {
      console.log("getting list of users");
      const searchByEmail = await client.query({
        query: SEARCH_USER_BY_EMAIL,
        variables: {
          email: searchStr,
          limit: 10,
        },
      });
      const searchByUsername = await client.query({
        query: SEARCH_USER_BY_USERNAME,
        variables: {
          preferredUsername: searchStr,
          limit: 10,
        },
      });

      let usersFound = [];
      if (searchByEmail.data.usersByEmail.items.length) {
        usersFound.push(...searchByEmail.data.usersByEmail.items);
      }
      if (searchByUsername.data.usersByUsername.items.length) {
        usersFound.push(...searchByUsername.data.usersByUsername.items);
      }

      setUsers(usersFound);
    } catch (error) {
      console.error("[UserSearch]: Search query failed", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedAPICall = useCallback(
    _.debounce((query) => fetchUserList(query), 750),
    []
  );
  const handleInputChange = (evt) => {
    const searchStr = evt.target.value;
    setSearchQuery(searchStr);
    if (searchStr.length) debouncedAPICall(searchStr.trim());
  };
  return (
    <Box p={6} bg="white" rounded="xl" shadow="md" maxHeight="sm" width="lg">
      <Heading color="linkedin.500" fontSize="2xl">
        Find Hero
      </Heading>
      <Divider width="lg" mt="2" ml="-6" />
      <InputGroup mt="4">
        <Input
          placeholder="Find Hero"
          fontWeight="medium"
          value={searchQuery}
          onChange={handleInputChange}
        />
        <InputRightElement>
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <Icon as={FiSearch} color="gray.500" />
          )}
        </InputRightElement>
      </InputGroup>
      {users.length ? (
        <List spacing={2} maxHeight="sm" overflowY="auto" rounded="md" mt="2">
          {users.map((user) => (
            <Link key={user.id} to={`${PORTAL_ROOT}/users/${user.id}`}>
              <ListItem
                p="4"
                bg="gray.100"
                _hover={{
                  bg: "blue.500",
                  color: "white",
                  ariaSelected: true,
                }}
              >
                <Flex justifyContent="space-between">
                  <Text fontWeight="semibold" fontSize="large">
                    {`${user.firstName} ${user.lastName}`}
                  </Text>
                </Flex>
              </ListItem>
            </Link>
          ))}
        </List>
      ) : null}
    </Box>
  );
};
