import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import {
  Text,
  Box,
  HStack,
  Divider,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { format } from "date-fns";

/* icon imports */
import { HiBadgeCheck } from "react-icons/hi";
import { FiArrowUpRight } from "react-icons/fi";

const SessionListModal = (props) => {
  const { isOpen, onClose, sessions = [], title } = props;
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleClick = (sessionId) =>
    sessionId
      ? history.push(`${url.replace(/\/+$/, "")}/session/${sessionId}`)
      : null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody padding={0}>
          <List maxHeight="sm" overflowY="auto">
            {sessions.map((session, index) => (
              <ListItem
                key={session.id}
                bgColor={index % 2 === 0 ? "gray.100" : "white"}
                borderBottomColor="gray.300"
                borderBottomWidth={index !== sessions.length ? 2 : 0}
                // px={0}
                // bgColor={index % 2 === 0 ? "blackAlpha.300" : "whiteAlpha.100"}
              >
                <HStack spacing={0} px={6} py={2} align="center">
                  <ListIcon as={HiBadgeCheck} color="green.400" />
                  <Box
                    width="full"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Text alignSelf="center">
                      {format(new Date(session.fullDate), "PPp")}
                    </Text>
                    {/* {session.date} */}
                    <IconButton
                      colorScheme="linkedin"
                      variant="outline"
                      size="sm"
                      onClick={() => handleClick(session.id)}
                      // isDisabled={!canPreviousPage}
                      icon={<Icon as={FiArrowUpRight} h={5} w={5} />}
                    />
                    {/* <ChakraLink
						fontWeight="semibold"
						color="linkedin.500"
						as={Link}
						to={{
						  pathname: `${url.replace(/\/+$/, "")}/session/${
							session.id
						  }`,
						}}
					  >
						<FiArrowUpRight />
					  </ChakraLink> */}
                    {/* <Text fontWeight="light" color="gray.400" mr="2">
						Heloo
						{format(new Date(session.createdAt), "PPp")}
					  </Text> */}
                  </Box>
                </HStack>
              </ListItem>
            ))}
          </List>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
export default SessionListModal;
