import styled from "styled-components";

/** asset imports */
import centerBg from "assets/home/center-bg.png";

/** local imports */
import { device } from "utils/device";

const Hero = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 32px;

  @media ${device.laptopL} {
    margin-top: 70px;
  }

  padding: 0 3vw;
  @media ${device.tablet} {
    padding: 0 10vw;
  }
  margin-bottom: 180px;

  .iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 2rem;
    @media ${device.laptopL} {
      margin-bottom: 0;
      margin-top: 2.6rem;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      z-index: 1;
    }
  }

  .image {
    grid-column: span 12;
    img {
      width: 100%;
    }
    @media ${device.laptopL} {
      grid-column: span 6;
    }
  }
  .card {
    grid-column: span 12;

    @media ${device.laptopL} {
      padding-left: 2vw;
      grid-column: span 6;
    }
  }
`;
const HomeStyle = styled.div`
  background: linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh);
  min-height: 100vh;
`;

const WhiteContainer = styled.div`
  background: white;

  .support-message {
    width: 80vw;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 80px;
    @media ${device.laptop} {
      width: 35vw;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
  .app-features {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    text-align: center;
    padding: 0 3vw;
    @media ${device.laptopL} {
      padding: 0 10vw;
    }
    .right .image {
      @media ${device.laptop} {
        border-left: 1px solid #c0cff9;
      }
    }
    margin-bottom: 100px;
    .right,
    .left {
      margin-bottom: 2.5rem;
      grid-column: span 12;
      @media ${device.laptop} {
        grid-column: span 6;
        p {
          min-height: 100px;
        }
      }
      p {
        flex: 1;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      img {
        width: 50%;
        margin: 0 auto;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
`;

const Tag = styled.h3`
  background: ${(props) => (props.bg ? props.bg : "#dcfce7")};
  color: ${(props) => (props.color ? props.color : "#166534")};
  font-size: 0.7em;
  font-weight: 700;
  padding: 0.1em 1em;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "0.15em"};
  border-radius: 0.5em;
  max-width: max-content;
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};
`;
const CardContent = styled.div`
    position: relative;
    padding: 0 0.865rem;
    @media ${device.mobileL} {
      padding: 0 1.25rem;
    }

    .prev {
        text-decoration: line-through;
        color: rgba(0, 0, 0, 0.3);
        margin-right: 0.25em;
        font-size: 1.4rem;
      }

    .header {
      font-family: "Tommy Soft";
      font-size: 2.5rem;
      line-height: 2.8rem;
      font-weight: normal;
      color: #43435E;
      text-align: left;
      margin: 0.25rem 0 0 0;
      
      @media ${device.mobileL} {
        font-size: 2.26rem;
        line-height: 44.96px;
      }

    }
    .subheader {
      margin: 0 0;
      font-family: "Tommy Soft";
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 16px;
      color: rgba(156, 163, 175, 0.76);
      text-transform: uppercase;
      letter-spacing: 0.16em;
      text-align: left;
    }

    .featureDescription {
      margin-top: 0;
      color: rgba(75, 85, 99, 0.85);
      max-width: 85%;
    }

    .featurePhoto {
      display: none;
      
      @media ${device.tablet} {
        display: block;
        width: 6rem;
        height: 6rem;
        object-fit: cover;
      }
    }
   
    .priceContainer {
      display: flex;
      align-items: baseline;
      color: #43435E;
      margin-left: 0;
      margin-top: 0.65rem;
      font-size: 1.5rem; 
    }

    .lightDivider {
      width: 100%;
      height: 2px;
      border: none;
      background: rgba(191, 213, 240, 0.5);
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .strike {
      display: inline-block;
      margin-left: 4px;
      margin-right: 4px;
      position: relative;
      ::before {
        content: "";
        border-bottom: 2px solid #5072ec;
        position: absolute;
        left: -2px;
        right -2px;
        margin-top: 0.7em;
      }
    }
    .free {
      font-family: "Tommy Soft";
      font-weight: 500;
      line-height: 26px;
      display: inline-block;
      align-items: center;
      color: #ffffff;
      background: #5072ec;
      border-radius: 2px;
      padding: 2px 9px;
      font-size: 1rem;
      @media ${device.mobileL} {
        font-size: 1.25rem;
      }


    }
    .checkmarks{
      margin: 0.75rem 0 0 0;
    }
    .mt-1 {
      margin-top: 28px;
    }
    .check {
        padding: 0.5rem 0px;
        display: flex;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: bold;
        color: #43435E;
        align-items: center;
        img {
          margin-top: 2px;
          margin-right: 0.65rem;
          width: 1.5rem;
        }
        
        @media ${device.mobileL} {
          font-size: 1.125rem;
        }
    }
    .unchecked {
        padding: 0.5rem 0px;
        display: flex;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: bold;
        color: #9ca3af;
        align-items: center;
        img {
          margin-top: 2px;
          margin-right: 0.65rem;
          width: 1.5rem;
        }
        
        @media ${device.mobileL} {
          font-size: 1.125rem;
        }
    }
    p{
      font-size: 1rem;
      line-height: 1.5rem;
      // margin-bottom:34px;

      @media ${device.mobileL} {
        font-size: 1.125rem;
      }
    }
  `;

const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 80px;
  padding-bottom: 56px;
`;
const Feature = styled.div`
  grid-column: span 12;
  text-align: center;
  margin-bottom: 1.25rem;
  img {
    height: 150px;
  }
  p {
    font-family: Nunito;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: center;
    color: #252b40;
  }
  &.center {
    @media ${device.laptop} {
      margin-left: 80px;
      margin-right: 80px;
    }
  }
  @media ${device.laptop} {
    grid-column: span 4;
  }
`;

const Center = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  background: url(${centerBg});
  min-height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 10vw;
  padding-right: 10vw;

  .image {
    grid-column: span 12;
    align-items: center;
    display: flex;
    img {
      width: 100%;
    }
    @media ${device.laptop} {
      grid-column: span 6;
    }
  }
  .content {
    grid-column: span 12;
    padding-left: 5vw;
    p {
      font-family: Nunito;
      line-height: 1.5rem;
      color: #252b40;
      font-size: 1.25rem;
    }

    .item {
      padding-top: 26px;
      padding-bottom: 1.25rem;
      p {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
    @media ${device.laptop} {
      grid-column: span 6;
    }
  }
`;

const PlanSelector = styled.div`
  width: 100%;

  // reset button styling
  button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    background-color: white;
    width: 100%;
  }

  .card {
    height: 110px;
    position: relative;
    padding: 0.75rem;
    border: 3px solid rgba(243, 244, 246, 1);
    border-radius: 8px;
    box-shadow: 0px 2px 5px 0.2px rgba(0, 44, 129, 0.2);
    margin-top: 1rem;
    :not(:first-child) {
      margin-top: 1.5rem;
    }

    .badge {
      position: absolute;
      right: 18px;
      top: 0;
      transform: translateY(-50%);
      padding: 0.25rem 2rem;
      border-radius: 1rem;
      height: 25px;
      display: flex;
      align-items: center;
      background-color: rgba(243, 244, 246, 1);
      color: rgba(156, 163, 175, 1);
    }
    .badge.selected {
      background-color: rgba(248, 194, 110, 1);
      color: white;
    }

    #cardBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  .card.selected {
    border-color: rgba(248, 194, 110, 1);
    box-shadow: 0px 2px 20px 0.2px rgba(0, 44, 129, 0.2);
  }
  .card h4 {
    margin: 0 0;
    font-family: Nunito;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .prices {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: 0;
  }

  .prices p {
    margin: 0;
    font-family: Nunito;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;

    &.prev {
      text-decoration: line-through;
      color: rgba(0, 0, 0, 0.3);
      margin-right: 0.25em;
    }
  }

  .productDescription {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: #000a;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

export {
  LoadingContainer,
  CardContent,
  Center,
  Feature,
  Features,
  Hero,
  HomeStyle,
  PlanSelector,
  WhiteContainer,
  Tag,
};
