import React, { useCallback, useRef, useState } from "react";
import { Link as ChakraLink, VStack, Text, HStack } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { useParams } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { v4 as uuidv4 } from "uuid";
import { debounce, isEqual } from "lodash";

/* local imports */
import H1 from "common/H1";
import { publicClient } from "utils/awsConfig.js";
import { PURCHASE_GIFT } from "graphql/mutations";
import ShipStation from "utils/shipstation";
import usePrevious from "hooks/usePrevious";

const PaymentDetailsForm = ({
  formState,
  next,
  goBack,
  updateFormState,
  handleChange,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { subscriptionType, subscriptionProductId } = useParams();
  const prevShippingAddress = usePrevious(formState.shippingAddress);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading("Unlocking the ultimate Flowly experience...");
    event.preventDefault();

    window.analytics.track("gift_purchase_attempted", {
      plan: "pro",
      type: subscriptionType.toLowerCase(),
    });

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        if (
          error.payment_intent.status === "succeeded" ||
          error.payment_intent.status === "processing"
        ) {
          console.log(error);
        } else {
          console.log(error);
          alert(error.message);
          setLoading(false);
          return;
        }
      }
    } catch (e) {
      setLoading(false);
      alert("Couldn't verify payment method. Please check card details.");
      console.error(e);
      window.analytics.track("gift_purchase_failed", {
        plan: "pro",
        type: subscriptionType.toLowerCase(),
        error: JSON.stringify(e),
      });
      return;
    }

    try {
      const {
        name,
        addr1: address1,
        addr2: address2,
        city,
        state,
        zip,
        country,
      } = formState.shippingAddress;
      const response = await publicClient.mutate({
        mutation: PURCHASE_GIFT,
        variables: {
          input: {
            gifterName: name,
            gifterEmail: formState.email,
            subscriptionType,
            shippingAddress: {
              name,
              address1,
              address2,
              city,
              state,
              zip,
              country,
            },
            shippingPrice: formState.shippingPrice,
          },
        },
      });

      if (response.data?.purchaseGift?.error) {
        throw new Error(response.data.purchaseGift.error);
      }

      return onPaymentSuccess();
    } catch (err) {
      console.log(err.message);
      alert(
        "There was an error processing your subscription. Please email us at contact@flowly.world. Thanks!"
      );
      window.analytics.track("gift_purchase_failed", {
        plan: "pro",
        type: subscriptionType.toLowerCase(),
        error: JSON.stringify(err),
      });
    } finally {
      setLoading(false);
    }
  };

  const onPaymentSuccess = () => {
    window.analytics.track("Gift Purchase Completed", {
      orderId: uuidv4(),
      total: formState.total,
    });
    updateFormState("orderComplete", true);
    next();
  };

  const handleShippingAddressChange = (event) => {
    const { name, address } = event.value;
    updateFormState("shippingAddress", {
      name,
      ...{
        addr1: address.line1,
        addr2: address.line2,
        city: address.city,
        country: address.country,
        state: address.state,
        zip: address.postal_code,
      },
    });
  };

  const retries = useRef(0);
  const handleBlurShippingAddress = useCallback(() => {
    const { state, zip, city } = formState.shippingAddress;

    if (
      !state ||
      !zip ||
      !city ||
      isEqual(formState.shippingAddress, prevShippingAddress)
    )
      return;

    setLoading("Calculating shipping");
    window.analytics.track("shipping_address_submitted");
    ShipStation.shipments
      .getRates({
        toState: state,
        toPostalCode: zip,
        toCity: city,
      })
      .then((res) => {
        if (res.data.length) {
          const [{ shipmentCost }] = res.data;
          updateFormState("shippingPrice", shipmentCost);
          return;
        } else {
          throw new Error(
            "Failed to process your shipping address. Please check your entries and try again."
          );
        }
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          return alert("It looks like you're not connected to the internet.");
        }

        ++retries.current;

        if (retries.current > 1) {
          retries.current = 0;
          updateFormState("shippingPrice", 12.0);
          return alert(
            "Looks like we're unable to validate your shipping address. That's okay, we've gone ahead and applied a flat shipping rate so you may proceed. Please ensure your address is correct."
          );
        }

        return alert(
          "We couldn't process your shipping address. Please check your entries and try again."
        );
      })
      .finally(() => setLoading(false));
  }, [formState.shippingAddress, prevShippingAddress, updateFormState]);

  const debouncedBlurShippingAddress = debounce(
    handleBlurShippingAddress,
    1000
  );

  console.log({ formState });

  return (
    <form onSubmit={handleSubmit}>
      <VStack mt="20" alignItems="flex-start" spacing={4}>
        <H1>Checkout</H1>
        <Text
          fontSize="md"
          color="gray.400"
          opacity={0.76}
          textTransform="uppercase"
          fontFamily="Tommy Soft"
          fontWeight="bold"
          letterSpacing="0.12rem"
        >
          Payment Details
        </Text>
        <FormControl id="link_element">
          <FormLabel fontWeight="semibold">Contact Information</FormLabel>
          <LinkAuthenticationElement
            onChange={(event) => {
              updateFormState("email", event.value.email);
            }}
            options={{ placeholder: { email: "john.doe@gmail.com" } }}
          />
        </FormControl>

        <FormControl id="shipping_element">
          <FormLabel fontWeight="semibold">Shipping</FormLabel>
          <AddressElement
            options={{ allowedCountries: ["US"], mode: "shipping" }}
            onChange={handleShippingAddressChange}
            onBlur={debouncedBlurShippingAddress}
          />
        </FormControl>

        <FormControl id="payment_element">
          <FormLabel fontWeight="semibold">Payment</FormLabel>
          <PaymentElement />
        </FormControl>
        {/* <HStack>
          <FormControl id="gifterName">
            <FormLabel fontWeight="semibold">Full Name</FormLabel>
            <Input
              name="gifterName"
              type="text"
              value={formState.name}
              onChange={handleChange("name")}
              required
              size="lg"
            />
          </FormControl>
          <FormControl id="gifterEmail">
            <FormLabel fontWeight="semibold">Email</FormLabel>
            <Input
              name="gifterEmail"
              type="text"
              value={formState.email}
              onChange={handleChange("email")}
              required
              size="lg"
            />
          </FormControl>
        </HStack> */}
        {/* <FormControl id="cc_number">
          <FormLabel fontWeight="semibold">Credit Card Number</FormLabel>
          <Box
            py={3}
            my={2}
            height={12}
            border="1px solid"
            borderColor="gray.200"
            paddingInlineStart={4}
            paddingInlineEnd={4}
            fontSize="lg"
            outline="2px solid transparent"
            rounded="md"
            width="full"
            _hover={{ borderColor: "gray.300" }}
            _focus={{ borderColor: "#3182ce", boxShadow: "0 0 0 1px #3182ce" }}
          >
            <CardNumberElement options={options} />
          </Box>
        </FormControl>
        <HStack width="full">
          <FormControl id="cc_expiration_date">
            <FormLabel fontWeight="semibold">Expiration (MM/YY)</FormLabel>
            <Box
              py={3}
              my={2}
              height={12}
              border="1px solid"
              borderColor="gray.200"
              paddingInlineStart={4}
              paddingInlineEnd={4}
              fontSize="lg"
              outline="2px solid transparent"
              rounded="md"
              width="full"
              _hover={{ borderColor: "gray.300" }}
              _focus={{
                borderColor: "#3182ce",
                boxShadow: "0 0 0 1px #3182ce",
              }}
            >
              <CardExpiryElement options={options} />
            </Box>
          </FormControl>
          <FormControl id="cc_cvc">
            <FormLabel fontWeight="semibold">CVC</FormLabel>
            <Box
              py={3}
              my={2}
              height={12}
              border="1px solid"
              borderColor="gray.200"
              paddingInlineStart={4}
              paddingInlineEnd={4}
              fontSize="lg"
              outline="2px solid transparent"
              rounded="md"
              width="full"
              _hover={{ borderColor: "gray.300" }}
              _focus={{
                borderColor: "#3182ce",
                boxShadow: "0 0 0 1px #3182ce",
              }}
            >
              <CardCvcElement options={options} />
            </Box>
          </FormControl>
        </HStack> */}
        <HStack alignItems="center" spacing={8} flexWrap="wrap" maxW="full">
          <Button
            maxW="full"
            type="submit"
            onClick={handleSubmit}
            variant="solid"
            bg="#5072ec"
            colorScheme="blue"
            rounded="xl"
            height="100%"
            isLoading={!!loading}
            loadingText={loading}
            py="1rem"
            px="5em"
          >
            Place order
          </Button>
          <ChakraLink onClick={goBack} color="gray.400">
            Choose a different plan
          </ChakraLink>
          {/* <ChakraLink onClick={goBack} color="gray.400">
            Modify shipping details
          </ChakraLink> */}
        </HStack>
      </VStack>
    </form>
  );
};

export default PaymentDetailsForm;
