import React, { useState } from "react";
import {
  Link as ChakraLink,
  VStack,
  Text,
  HStack,
  Box,
} from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

/* local imports */
import H1 from "common/H1";
import { client } from "utils/awsConfig.js";

import { SUBSCRIBE_USER } from "graphql/mutations";

const options = {
  style: {
    base: {
      fontSize: "16px",
      color: "#252b40",
      "::placeholder": {
        color: "#8892ad",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
const PaymentDetailsForm = ({
  handleChange,
  formState,
  next,
  goBack,
  updateFormState,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    window.analytics.track("subscription_attempted", {
      plan: formState.selectedPlan.subscriptionPlan,
      type: formState.selectedPlan.subscriptionType.toLowerCase(),
    });
    let paymentMethod;
    try {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (result.error) {
        alert(result.error.message);
        setLoading(false);
        return;
      }
      paymentMethod = result.paymentMethod;
    } catch (e) {
      setLoading(false);
      alert("Couldn't verify payment method. Please check card details.");
      console.error(e);
      window.analytics.track("subscription_failed", {
        plan: formState.selectedPlan.subscriptionPlan,
        type: formState.selectedPlan.subscriptionType.toLowerCase(),
        error: JSON.stringify(e),
      });
      return;
    }
    const user = await Auth.currentAuthenticatedUser();

    try {
      const {
        addr1,
        addr2,
        city,
        state,
        zip,
        country,
        phone,
        shippingPrice,
        selectedPlan,
      } = formState;

      const {
        id: subscriptionProductId,
        subscriptionPlan,
        subscriptionType,
      } = selectedPlan;
      const response = await client.mutate({
        mutation: SUBSCRIBE_USER,
        variables: {
          input: {
            email: user.attributes.email,
            firstName: user.attributes.given_name,
            lastName: user.attributes.family_name,
            phone,
            shippingAddress: {
              address1: addr1,
              address2: addr2,
              city,
              state,
              zip,
              country,
            },
            productId: subscriptionProductId,
            subscriptionType,
            subscriptionPlan,
            stripePaymentMethod: paymentMethod.id,
            coupon: formState.promoCode ? formState.promoCode : null,
            referralCode: formState.referralCode
              ? formState.referralCode
              : null,
            shippingPrice,
          },
        },
      });

      if (response.data?.subscribe?.error) {
        alert(response.data.subscribe.error.message);
        setLoading(false);

        return;
      }
      setLoading(false);

      return onPaymentSuccess();
    } catch (e) {
      alert(
        "There was an error processing your subscription. Please email us at contact@flowly.world. Thanks!"
      );
      setLoading(false);
      window.analytics.track("subscription_failed", {
        plan: formState.selectedPlan.subscriptionPlan,
        type: formState.selectedPlan.subscriptionType.toLowerCase(),
        error: JSON.stringify(e),
      });
    }
  };

  const onPaymentSuccess = () => {
    window.analytics.track("Order Completed", {
      orderId: uuidv4(),
      total: formState.total,
    });
    updateFormState("orderComplete", true);
    next();
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack mt="20" alignItems="flex-start" spacing={4}>
        <H1>Checkout</H1>
        <Text
          fontSize="md"
          color="gray.400"
          opacity={0.76}
          textTransform="uppercase"
          fontFamily="Tommy Soft"
          fontWeight="bold"
          letterSpacing="0.12rem"
        >
          Payment Details
        </Text>
        <FormControl id="cc_number">
          <FormLabel fontWeight="semibold">Credit Card Number</FormLabel>
          <Box
            py={3}
            my={2}
            height={12}
            border="1px solid"
            borderColor="gray.200"
            paddingInlineStart={4}
            paddingInlineEnd={4}
            fontSize="lg"
            outline="2px solid transparent"
            rounded="md"
            width="full"
            _hover={{ borderColor: "gray.300" }}
            _focus={{ borderColor: "#3182ce", boxShadow: "0 0 0 1px #3182ce" }}
          >
            <CardNumberElement options={options} />
          </Box>
        </FormControl>
        <HStack width="full">
          <FormControl id="cc_expiration_date">
            <FormLabel fontWeight="semibold">Expiration (MM/YY)</FormLabel>
            <Box
              // px={4}
              py={3}
              my={2}
              height={12}
              border="1px solid"
              borderColor="gray.200"
              paddingInlineStart={4}
              paddingInlineEnd={4}
              fontSize="lg"
              outline="2px solid transparent"
              rounded="md"
              width="full"
              _hover={{ borderColor: "gray.300" }}
              _focus={{
                borderColor: "#3182ce",
                boxShadow: "0 0 0 1px #3182ce",
              }}
            >
              <CardExpiryElement options={options} />
            </Box>
          </FormControl>
          <FormControl id="cc_cvc">
            <FormLabel fontWeight="semibold">CVC</FormLabel>
            <Box
              // px={4}
              py={3}
              my={2}
              height={12}
              border="1px solid"
              borderColor="gray.200"
              paddingInlineStart={4}
              paddingInlineEnd={4}
              fontSize="lg"
              outline="2px solid transparent"
              rounded="md"
              width="full"
              _hover={{ borderColor: "gray.300" }}
              _focus={{
                borderColor: "#3182ce",
                boxShadow: "0 0 0 1px #3182ce",
              }}
            >
              <CardCvcElement options={options} />
            </Box>
          </FormControl>
        </HStack>
        <HStack alignItems="center" spacing={8} flexWrap="wrap" maxW="full">
          <Button
            maxW="full"
            type="submit"
            onClick={handleSubmit}
            variant="solid"
            bg="#5072ec"
            colorScheme="blue"
            rounded="xl"
            height="100%"
            isLoading={loading}
            loadingText={"Unlocking the ultimate Flowly experience..."}
            py="1rem"
            px="110px"
          >
            Place order
          </Button>
          <ChakraLink onClick={goBack} color="gray.400">
            Modify shipping details
          </ChakraLink>
        </HStack>
      </VStack>
    </form>
  );
};

export default PaymentDetailsForm;
