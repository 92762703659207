import React, { useState, useEffect, useMemo } from "react";
import { useHistory, NavLink } from "react-router-dom";
import {
  Container,
  VStack,
  Heading,
  Text,
  HStack,
  Button,
  Box,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";

/* local imports */
import { client } from "utils/awsConfig.js";
import DataGrid from "common/DataGrid";

import { GET_USER_LIST } from "graphql/queries";
import { PORTAL_ROOT } from "Routes";
import DebouncedInput from "common/DebouncedInput";

const columnHelper = createColumnHelper();

function UserList(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    data: response,
    isError,
    isLoading,
    ...rest
  } = useQuery(`user-list`, () =>
    client.query({
      query: GET_USER_LIST,
      variables: { payload: { limit: 500 } },
    })
  );
  // const [userList, setUserList] = useState([]);

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     setLoading(true);
  //     console.log("getting list of users");
  //     const response = await client.query({
  //       query: GET_USER_LIST,
  //       variables: {
  //         limit: 500,
  //       },
  //     });
  //     setLoading(false);

  //     console.log(response.data.listUsers.items);
  //     setUserList(response.data.listUsers.items);
  //   };
  //   fetchUsers();
  // }, []);

  const columns = useMemo(
    () => [
      {
        header: "Hero ID",
        accessorKey: "id",
        id: "heroId",
      },
      {
        header: "Hero First Name",
        accessorKey: "firstName",
        id: "heroFirstName",
      },
      {
        header: "Hero Last Name",
        accessorKey: "lastName",
        id: "heroLastName",
      },
      {
        header: "Hero Email",
        accessorKey: "email",
        id: "heroEmail",
      },
      columnHelper.display({
        id: "displayName",
        header: "Name",
        cell: ({ row }) => {
          return (
            <VStack
              as={NavLink}
              to={`${PORTAL_ROOT}/users/${row.getValue("heroId")}`}
              align="flex-start"
              spacing={0}
              maxW="xs"
            >
              <Text fontWeight="semibold" color="gray.700">
                {`${row.getValue("heroFirstName")} ${
                  row.getValue("heroLastName") ?? ""
                }`.trim()}
              </Text>
              <Text color="gray.500">{row.getValue("heroEmail")}</Text>
            </VStack>
          );
        },
      }),
      {
        header: "Sessions Completed",
        accessorKey: "sessionsCompleted",
        meta: {
          isNumeric: true,
        },
      },
    ],
    []
  );
  console.log(response?.data?.listUsers?.items ?? []);
  const { table: tableInstance, ...dataGridProps } = DataGrid.useDataGrid({
    columns,
    data: response?.data?.listUsers?.items ?? [],
    initialState: {
      columnVisibility: {
        heroId: false,
        heroFirstName: false,
        heroLastName: false,
        heroEmail: false,
      },
    },
    enablePagination: true,
    // enableRowSelection: true,
  });

  const handleRowClick = (rowData) =>
    history.push(`${PORTAL_ROOT}/users/${rowData.original.id}`);

  return (
    <Container maxW="8xl" pt="8">
      <Heading pb="6">Heroes</Heading>
      <VStack
        width="full"
        spacing={3}
        bg="white"
        rounded="xl"
        border="1px solid"
        borderColor="gray.200"
        shadow="md"
        overflow="hidden"
      >
        <DataGrid.Composer table={tableInstance}>
          <Header {...dataGridProps} />
          <Box
            w="full"
            borderTop="1px solid"
            borderColor="gray.100"
            overflowX="scroll"
          >
            <DataGrid.Table loading={loading} onRowClick={handleRowClick} />
            <DataGrid.Footer pagination />
          </Box>
        </DataGrid.Composer>
      </VStack>
    </Container>
  );
}

export default UserList;

const Header = ({ globalFilter, setGlobalFilter }) => {
  // const { getColumn } = DataGrid.useDataGridContext();

  return (
    <HStack width="full" justifyContent="space-between" p={3}>
      <HStack alignItems="center" spacing={2} w="full">
        <DebouncedInput
          initialValue={globalFilter}
          onChange={setGlobalFilter}
          placeholder="Find hero"
        />
        {/* {getColumn("status") && (
          <DataGrid.DropdownFilter
            column={getColumn("status")}
            title="Status"
            options={Object.values(SHIPMENT_STATUSES).map((status) => ({
              value: status,
              label: getStatusTitle(status),
            }))}
          />
        )} */}
      </HStack>
      <HStack alignItems="center" spacing={2}>
        {/* <IconButton
          size="sm"
          onClick={refetch}
          aria-label="Refetch shipments"
          icon={<IoMdRefresh />}
          isLoading={loading}
        /> */}
        <Button
          size="sm"
          colorScheme="linkedin"
          // onClick={disclosureProps.onOpen}
        >
          Create Hero
        </Button>
      </HStack>
    </HStack>
  );
};
