import React from "react";
import styled from "styled-components";

/* local imports */
import popularBg from "assets/popular-bg.svg";
import Button from "./Button";
import Card from "./Card";

const CardHeader = styled.div`
  font-family: "Tommy Soft";
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #464e64;
  text-align: center;
`;

const CardSubHeader = styled.div`
  font-family: "Tommy Soft";
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.87rem;
  text-align: center;
  color: #464e64;
`;

const PopularText = styled.div`
  font-family: "Tommy Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  transform: rotate(45deg);
  position: absolute;
  right: 0px;
  top: 15px;
`;

const PopularBackground = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const Price = styled.div`
  font-family: "Tommy Soft";
  font-weight: 500;
  font-size: 4rem;
  line-height: 80px;
  text-align: center;
  color: #5072ec;
  .strike {
    font-size: 3rem;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    position: relative;
    ::before {
      content: "";
      border-bottom: 3px solid #5072ec;
      position: absolute;
      left: -2px;
      right -2px;
      margin-top: 0.8em;
    }
  }
`;

const Pricing = styled.div`
  margin-top: 52px;
  margin-bottom: 35px;
  text-align: center;
`;

const Save = styled.div`
  font-family: "Tommy Soft";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 26px;
  text-align: center;
  color: #5072ec;
  margin-bottom: 46px;
`;

function PriceCard({
  period,
  monthlyPrice,
  basePrice,
  link,
  mostPopular = false,
  saveMessage,
  description,
  className,
}) {
  return (
    <Card className={className}>
      {mostPopular && (
        <PopularBackground>
          <img src={popularBg} alt="Most Popular"></img>
          <PopularText>
            Most <br /> Popular
          </PopularText>
        </PopularBackground>
      )}
      <CardHeader>{period}</CardHeader>
      <CardSubHeader>Membership</CardSubHeader>
      <Pricing>
        <Price>
          <div className="strike">{basePrice}</div>
        </Price>
        <Price>{monthlyPrice}</Price>
        per month
      </Pricing>
      <p style={{ textAlign: "center", marginBottom: "8px" }}> {description}</p>

      <Save>{saveMessage ? <>{saveMessage}</> : <>&nbsp;</>}</Save>

      <Button to={link} outline={true}>
        Choose
      </Button>
    </Card>
  );
}

export default PriceCard;
