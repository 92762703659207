import React, { useRef, useState } from "react";
import {
  Box,
  Link as ChakraLink,
  VStack,
  Grid,
  GridItem,
  Text,
  Stack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import Icon from "@chakra-ui/icon";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import { get } from "lodash";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import { STEPS } from "../constants";

import H1 from "common/H1";
import AddressElements from "common/AddressElements";
import { PhoneNumberInput } from "common/PhoneNumberInput";
import { getFormattedUsername } from "utils/authHelpers";
import confirmation from "assets/confirmation/confirmation.png";
import { redeemLicense } from "../services";

const RedeemVoucherRegister = ({
  navigate,
  handleChange,
  formState,
  updateFormState,
}) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const next = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      const formattedUsername = getFormattedUsername(formState.email);

      await Auth.signUp({
        username: formattedUsername,
        password: formState.password,
        attributes: {
          email: formState.email.toLowerCase(),
          given_name: formState.name,
          family_name: "",
          birthdate: "00/00/0000",
        },
      });
      window.analytics.identify(formattedUsername, {
        email: formState.email.toLowerCase(),
        name: formState.name,
      });
      const date = new Date();
      const params = {
        year: date.getFullYear(),
        day: Math.floor(
          (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
        ),
      };
      window.analytics.track("account_created", params);
      firebase.analytics().logEvent("account_created", params);

      await Auth.signIn(formattedUsername, formState.password);

      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        window.analytics.identify(user.username, {
          email: user.attributes.email,
          name: user.attributes.given_name,
        });
      }

      await handleRedeemLicense();
    } catch (e) {
      console.log(e);
      alert(e.message);
      goBack();
    } finally {
      setLoading(false);
    }
  };
  const goBack = () => navigate(STEPS.authDirect);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  const handleRedeemLicense = async () => {
    let parsedAddress = get(formState, "addr1", null);
    if (parsedAddress) {
      const {
        name = "",
        addr1: address1 = "",
        addr2: address2 = "",
        city = "",
        state = "",
        zip = "",
        country = "United States",
      } = formState;
      parsedAddress = { name, address1, address2, city, state, zip, country };
    }

    try {
      setLoading(true);
      const response = await redeemLicense(formState.orgId, {
        address: parsedAddress,
        mobile: formState?.mobile ?? null,
        voucherCode: formState?.voucherCode,
        orderReferenceNumber: formState?.orderReferenceNumber,
      });
      if (response) {
        updateFormState("redeemSuccess", response);
        navigate(STEPS.thankyou);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={next}>
          <VStack my="20" alignItems="flex-start" spacing={6}>
            <VStack alignItems="flex-start" spacing={1}>
              <H1>Welcome to Flowly!</H1>
              <Text fontSize="lg" color="gray.400">
                Please register to unlock the full Flowly experience.
              </Text>
            </VStack>
            <Stack
              direction={["column", "row", "row", "column", "row"]}
              w="full"
              spacing={6}
            >
              <FormControl id="fullName">
                <FormLabel fontWeight="semibold">Full Name</FormLabel>
                <Input
                  name="fullName"
                  type="text"
                  value={formState.name}
                  onChange={handleChange("name")}
                  required
                  placeholder="Full Name"
                  size="lg"
                />
              </FormControl>
              <FormControl id="mobile">
                <FormLabel fontWeight="semibold">Phone Number</FormLabel>
                <PhoneNumberInput
                  size="lg"
                  id="phone"
                  name="phone"
                  value={formState.mobile}
                  onChange={handleChange("mobile")}
                  placeholder="050 123 4567"
                />
              </FormControl>
            </Stack>
            <FormControl id="password">
              <FormLabel fontWeight="semibold">Password</FormLabel>
              <InputGroup>
                <Input
                  name="password"
                  type={isOpen ? "text" : "password"}
                  placeholder="Password"
                  value={formState.password}
                  onChange={handleChange("password")}
                  size="lg"
                  required
                />
                <InputRightElement top="1">
                  <IconButton
                    bg="transparent !important"
                    variant="ghost"
                    aria-label={isOpen ? "Mask password" : "Reveal password"}
                    icon={isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />}
                    onClick={onClickReveal}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            {formState.requiresKit ? (
              <>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="blue.600"
                  bg="blue.50"
                  p="4"
                  rounded="md"
                  w="full"
                >
                  Let us know where to send your kit!
                </Text>
                <AddressElements
                  state={formState}
                  handleChange={handleChange}
                  elements={{ streets: true, region: true }}
                />
              </>
            ) : null}
            <Stack
              direction={["column", "row", "row", "column", "row"]}
              alignItems="center"
              spacing={8}
              w="full"
            >
              <Button
                type="submit"
                onClick={next}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText={"Creating your account..."}
                py="1rem"
                w={[
                  "full",
                  "fit-content",
                  "fit-content",
                  "full",
                  "fit-content",
                ]}
                px="20"
              >
                Submit
              </Button>
              <ChakraLink onClick={goBack} color="gray.400">
                Use a different email
              </ChakraLink>
            </Stack>
            <Text pt="6" color="gray.500">
              By continuing, you agree to Flowly&apos;s{" "}
              <ChakraLink
                color="blue.500"
                href="https://www.flowly.world/terms-of-service"
                isExternal
              >
                Terms of Service
              </ChakraLink>{" "}
              and{" "}
              <ChakraLink
                color="blue.500"
                href="https://www.flowly.world/privacy-policy"
                isExternal
              >
                Privacy Policy
              </ChakraLink>
            </Text>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        pt="16"
        pl="5vw"
        pr="10vw"
      >
        <Box display="grid" placeItems="center">
          <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemVoucherRegister;
