import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const ButtonStyle = styled.button`
  display: block;
  width: 100%;
  border: 2px solid #5072ec;
  border-radius: 10px;
  background: #5072ec;
  font-family: "Tommy Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 26px;
  color: #fff;
  text-align: center;
  padding: 15px 15px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    background: #acbff6;
    border: 2px solid #acbff6;
  }

  &.outline {
    color: #5072ec;
    background: transparent;
  }

  &.fullWidth {
    width: 100%;
  }

  &.inline {
    width: auto;
    display: inline-block;
    padding-left: 110px;
    padding-right: 110px;
  }

  .no-underline {
    text-decoration: none;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  width: 100%;
`;

function Button({ to, ...buttonProps }) {
  return to ? (
    <StyledNavLink to={to}>
      <RenderButton {...buttonProps} />
    </StyledNavLink>
  ) : (
    <RenderButton {...buttonProps} />
  );
}

export default Button;

const RenderButton = ({
  children,
  outline = false,
  inline = false,
  disabled = false,
  fullWidth = false,
  onClick,
  ...rest
}) => (
  <ButtonStyle
    onClick={onClick}
    className={Object.entries({ inline, outline, disabled, fullWidth })
      .map(([k, v]) => (v ? k : ""))
      .join(" ")}
    {...rest}
  >
    {children}
  </ButtonStyle>
);
