import React from "react";
import { Container, Grid, GridItem, VStack, Heading } from "@chakra-ui/layout";

/** local imports */
import EventsListWidget from "./components/EventsListWidget";

const EventsList = () => {
  return (
    <Container maxW="8xl" pt="8">
      <Heading pb="6">Events</Heading>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(4, 1fr)" }}
        gap={6}
      >
        <GridItem colSpan={{ base: 4, xl: 4 }}>
          <EventsListWidget />
        </GridItem>
        <GridItem colSpan={{ base: 4, xl: 1 }}>
          <VStack spacing={4}></VStack>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default EventsList;
