import React, { createContext, useEffect, useReducer } from "react";
import Auth from "@aws-amplify/auth";
import jwtDecode from "jwt-decode";

/* local imports */
import { ContextFactory } from "./ContextFactory";
import { parseRole } from "./authHelpers";

const AuthContext = createContext();
const AuthUpdateContext = createContext();

const INITIAL_STATE = {
  isAuthenticated: false,
  user: null,
};

const loadFromStorage = () => {
  const keys = Object.keys(localStorage);
  const accessTokenKey = keys.find(
    (k) =>
      k.includes("CognitoIdentityServiceProvider") && k.includes("accessToken")
  );
  const userKey = keys.find(
    (k) =>
      k.includes("CognitoIdentityServiceProvider") && k.includes("userData")
  );

  let user = null;
  try {
    const loadUser = localStorage.getItem(userKey);
    const loadToken = localStorage.getItem(accessTokenKey);

    const tokenBody = jwtDecode(loadToken);

    if (loadUser === null) {
      user = null;
    }

    const userObj = JSON.parse(loadUser);
    user = {
      id: userObj.Username,
      name: userObj.UserAttributes.find((attr) => attr.Name === "given_name")
        .Value,
      email: userObj.UserAttributes.find((attr) => attr.Name === "email").Value,
      role: parseRole(tokenBody["cognito:groups"]),
    };
  } catch (err) {
    console.error(
      `[Load from Storage]: Failed to get user attributes from LocalStorage`
    );
  }
  return { isAuthenticated: !!accessTokenKey, user };
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, ...action.payload };
    }
    case "LOGOUT": {
      return INITIAL_STATE;
    }
    case "SYNC_STORAGE": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [store, dispatch] = useReducer(AuthReducer, {
    ...INITIAL_STATE,
    ...loadFromStorage(),
  });

  useEffect(() => {
    dispatch({ type: "SYNC_STORAGE", payload: loadFromStorage() });
  }, []);

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          dispatch({ type: "LOGIN", payload: user });
        }
      } catch (error) {
        console.log(`An error occured when attempting to fetch User`, error);
        // dispatch({type: "FAILED"})
      }
    };

    getUser();
  }, []);

  return (
    <AuthContext.Provider value={store}>
      <AuthUpdateContext.Provider value={dispatch}>
        {children}
      </AuthUpdateContext.Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuthContext = ContextFactory("Auth", AuthContext);
export const useAuthUpdateContext = ContextFactory(
  "AuthUpdate",
  AuthUpdateContext
);

export const useAuth = () => [useAuthContext(), useAuthUpdateContext()];
