import { useQuery } from "react-query";
/** local imports */
import { CHECK_ORGANIZATION_SLUG } from "graphql/queries";
import { publicClient } from "utils/awsConfig";

export default function usePartnerData(id, config) {
  const {
    data: res,
    isError,
    isLoading,
    ...rest
  } = useQuery(`organization-${id}`, () => getPartnerData(config));

  const org = !isError && !isLoading ? res.data.checkOrganizationSlug : {};

  return { data: org, isError, isLoading, ...rest };
}

const getPartnerData = (config) =>
  publicClient.query({
    query: CHECK_ORGANIZATION_SLUG,
    ...config,
  });
