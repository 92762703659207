import React, { useState, useEffect, useMemo } from "react";
import {
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/stat";
import { Box, Flex, Heading, SimpleGrid, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Avatar, AvatarBadge } from "@chakra-ui/avatar";
import { useHistory, useParams, useRouteMatch } from "react-router";
import {
  format,
  subWeeks,
  isAfter,
  parseISO,
  compareAsc,
  isSameDay,
} from "date-fns/esm";
import Icon from "@chakra-ui/icon";
import { IconButton } from "@chakra-ui/button";

/* icon imports */
import { MdEdit } from "react-icons/md";

/* local imports */
import Loading from "common/Loading";
import Can from "common/Can";
import { client } from "utils/awsConfig.js";
import { ROLES, verifyCanAccess } from "utils/authHelpers";
import { coherenceCalculation, RMSSD } from "utils/flow";

import { GET_USER, GET_USER_SESSIONS } from "graphql/queries";
import { useAuth } from "utils/AuthContext";

const UserProfileSummary = ({ user: loadedUser, unAuthorized = false }) => {
  const [{ user: currentAuthenticatedUser }] = useAuth();
  const userRole = currentAuthenticatedUser.role;

  const { userId, sessionId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  // console.log("@UserProfileSummary: ", { loadedUser, userId });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(() =>
    loadedUser
      ? loadedUser
      : {
          id: "",
          firstName: "",
          lastName: "",
          email: "",
          streak: 0,
          resonantFrequency: null,
          sessionsCompleted: 0,
          lastSessionDate: "",
          sessions: [],
        }
  );

  console.log({ unAuthorized });
  const [summaryLength, setSummaryLength] = useState(1);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      // console.log("getting user");
      try {
        const userData = await client.query({
          query: GET_USER,
          variables: {
            id: userId,
          },
        });
        const sessionData = await client.query({
          query: GET_USER_SESSIONS,
          variables: {
            id: userId,
            limit: 3650,
            fetchPolicy: "network-only",
          },
        });
        setLoading(false);
        const user = userData.data.getUser;
        const sessions = sessionData.data.getUser.sessions;
        setUser({ ...user, sessions: sessions.items });
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };
    if (!loadedUser?.id?.length && !unAuthorized) {
      fetchUser();
    }
  }, [loadedUser?.id?.length, unAuthorized, userId]);

  const prevWeekStats = useMemo(() => {
    if (!user.sessions.length)
      return {
        avgTimeInFlow: null,
        cumulativeTimeInFlow: null,
        avgPreSurvey: null,
        avgPostSurvey: null,
        sessionsCompleted: null,
      };

    const referenceWeek = subWeeks(new Date(), summaryLength);
    const data = user.sessions.filter(
      (s) =>
        isSameDay(parseISO(s.createdAt.split("T")[0]), referenceWeek) ||
        isAfter(parseISO(s.createdAt.split("T")[0]), referenceWeek)
    );

    let timeInFlowScores = [];
    data.forEach((s) => {
      if (s.timeInFlow !== null && s.timeInFlow > 0)
        timeInFlowScores.push(s.timeInFlow);
    });

    console.log("@UserSummary", timeInFlowScores);
    let avgTimeInFlow = null;
    let cumulativeTimeInFlow = null;
    if (timeInFlowScores.length) {
      const total = timeInFlowScores.reduce((a, b) => a + b, 0);
      avgTimeInFlow = total / timeInFlowScores.length;

      const d = new Date(1000 * Math.round(total));
      cumulativeTimeInFlow = format(d, "m:ss");
    }
    if (avgTimeInFlow) {
      const d = new Date(1000 * Math.round(avgTimeInFlow));
      avgTimeInFlow = format(d, "m:ss");
    }

    const formattedSurveys = data
      .sort((a, b) => compareAsc(new Date(a.createdAt), new Date(b.createdAt)))
      .map((s) => {
        let formattedSurvey = JSON.parse(s.survey);
        if (formattedSurvey && Object.keys(formattedSurvey).length) {
          const { relaxation } = formattedSurvey;
          if (typeof relaxation?.pre === "object") {
            relaxation.pre = relaxation.pre.relaxation ?? null;
          }
          if (typeof relaxation?.post === "object") {
            relaxation.post = relaxation.post.relaxation ?? null;
          }
        }

        let formattedTimeInFlow = s.timeInFlow;
        if (formattedTimeInFlow) {
          const d = new Date(1000 * Math.round(formattedTimeInFlow));
          formattedTimeInFlow = format(d, "m:ss");
        }

        let sessionIntro = null;
        let sessionBody = null;
        if (s.data) {
          [sessionIntro, sessionBody] = s.data.split("_").map((part) => {
            try {
              return JSON.parse(part);
            } catch (error) {
              console.error(
                `[User Profile Summary]: Failed to parse HR Data`,
                part
              );
              return [];
            }
          });
        }

        return {
          ...s,
          survey: formattedSurvey,
          relaxationPre: formattedSurvey?.relaxation?.pre
            ? +formattedSurvey.relaxation.pre
            : null,
          relaxationPost: formattedSurvey?.relaxation?.post
            ? +formattedSurvey.relaxation.post
            : null,
          timeInFlow: formattedTimeInFlow ?? 0,
          data: {
            intro: sessionIntro,
            body: sessionBody,
          },
        };
      });

    const preSessionScores = [];
    const postSessionScores = [];
    const sessionTrajectories = [];
    const restingHRV = [];
    const flow = [];
    formattedSurveys.map((s) => {
      if (s.relaxationPre !== null) preSessionScores.push(s.relaxationPre);
      if (s.relaxationPost !== null) postSessionScores.push(s.relaxationPost);
      if (s.relaxationPre !== null && s.relaxationPost !== null)
        sessionTrajectories.push(
          ((s.relaxationPost - s.relaxationPre) / s.relaxationPre) * 100
        );

      const calcRMSSD = RMSSD(s.data.intro);
      if (!!calcRMSSD && isFinite(calcRMSSD) && !isNaN(calcRMSSD))
        restingHRV.push(calcRMSSD);

      const calcFlow = coherenceCalculation(s.data.body);
      if (calcFlow) flow.push(Math.round(calcFlow * 100));

      return null;
    });

    const sessionsCompleted = data.length;

    let avgPreSurvey = null;
    if (preSessionScores.length)
      avgPreSurvey =
        preSessionScores.reduce((a, b) => a + b, 0) / preSessionScores.length;

    let avgPostSurvey = null;
    if (postSessionScores.length)
      avgPostSurvey =
        postSessionScores.reduce((a, b) => a + b, 0) / postSessionScores.length;

    let avgRestingHRV = null;
    if (restingHRV.length)
      avgRestingHRV = parseFloat(
        (restingHRV.reduce((a, b) => a + b, 0) / restingHRV.length).toFixed(2)
      );

    let avgFlowScore = null;
    if (flow.length)
      avgFlowScore = Math.round(flow.reduce((a, b) => a + b, 0) / flow.length);

    let avgSessionImpact = null;
    if (sessionTrajectories.length)
      avgSessionImpact = Math.round(
        sessionTrajectories.reduce((a, b) => a + b, 0) /
          sessionTrajectories.length
      );

    console.log(`@UserProfileSummary: `, {
      avgTimeInFlow,
      cumulativeTimeInFlow,
      avgPreSurvey,
      avgPostSurvey,
      sessionsCompleted,
      sessionTrajectories,
      timeInFlowScores,
      // restingHRV,
      // flow,
      avgSessionImpact,
      avgRestingHRV,
      avgFlowScore,
    });
    return {
      avgTimeInFlow,
      cumulativeTimeInFlow,
      avgPreSurvey,
      avgPostSurvey,
      sessionsCompleted,
      avgRestingHRV,
      avgFlowScore,
      avgSessionImpact,
    };
  }, [user.sessions, summaryLength]);

  console.log("@UserProfileSummary: ", {
    user,
    prevWeekStats,
  });

  const handleEditProfile = () =>
    sessionId
      ? history.replace("../settings")
      : history.push(`${url.replace(/\/+$/, "")}/settings`);
  return (
    <Flex
      direction="column"
      alignItems="center"
      width="full"
      position={{ lg: "sticky" }}
      top="10"
    >
      {loading ? <Loading /> : null}
      <Avatar
        name={`${user.firstName} ${user.lastName}`}
        // src="https://bit.ly/sage-adebayo"
        src=""
        alt={`${user.firstName} ${user.lastName}`}
        width="12rem"
        height="12rem"
        shadow="xl"
      >
        {verifyCanAccess(userRole, ROLES.ADMIN) ||
        user?.id === currentAuthenticatedUser.id ? (
          <AvatarBadge
            transform="inital"
            bottom="1rem"
            boxSize="2em"
            bg="white"
            shadow="md"
          >
            <IconButton
              onClick={handleEditProfile}
              rounded="full"
              bg="white"
              color="gray.600"
              border="1.5px solid"
              borderColor="gray.100"
              icon={<Icon as={MdEdit} />}
            />
          </AvatarBadge>
        ) : null}
      </Avatar>
      <Card maxW="3xl" width="full" mt="-4rem" rounded="3xl" p="8" pt="20">
        <VStack spacing={4}>
          <VStack spacing={0}>
            <Heading fontSize="3xl" color="linkedin.500">
              {`${user.firstName} ${user.lastName ? user.lastName : ""}`}{" "}
            </Heading>
            <Heading fontSize="xs" fontWeight="normal" color="gray.400">
              {`Joined on ${format(
                new Date(user.createdAt ?? null),
                "MMM do, yyyy"
              )}`}
            </Heading>
          </VStack>
          <StatGroup mt="5">
            <SimpleGrid columns={2} spacing={8}>
              <Stat>
                <StatLabel>Resonant Frequency</StatLabel>
                <StatNumber>{user.resonantFrequency ?? "N/A"}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Sessions Completed</StatLabel>
                <StatNumber>{user.sessionsCompleted ?? 0}</StatNumber>
              </Stat>
            </SimpleGrid>
          </StatGroup>
          <VStack spacing={1}>
            <Heading fontSize="md" fontWeight="normal" color="gray.800">
              Sessions at a glance{" "}
            </Heading>
            <Heading fontSize="xs" fontWeight="normal" color="gray.400">
              <Select
                size="xs"
                // maxW="28"
                variant="filled"
                onChange={(evt) => setSummaryLength(evt.target.value)}
              >
                <option value={1}>Last 7 days</option>
                <option value={4}>Last 30 days</option>
                <option value={12}>Last 90 days</option>
              </Select>
            </Heading>
          </VStack>
        </VStack>
        {/* <CardHeader
          title={`${user.firstName} ${user.lastName}`}
          // subtitle="Last 90 days"
          // subtitle={`Joined on ${format(
          //   new Date(user.createdAt ?? null),
          //   "MMM do, yyyy"
          // )}`}
        /> */}
        <Box>
          <SessionStatisticsSummary
            sessionsCompleted={prevWeekStats.sessionsCompleted}
            cumulativeTimeInFlow={prevWeekStats.cumulativeTimeInFlow ?? 0}
            avgTimeInFlow={prevWeekStats.avgTimeInFlow}
            preSurvey={prevWeekStats.avgPreSurvey}
            postSurvey={prevWeekStats.avgPostSurvey}
            avgRestingHRV={prevWeekStats.avgRestingHRV}
            avgFlowScore={prevWeekStats.avgFlowScore}
            avgSessionImpact={prevWeekStats.avgSessionImpact}
          />
        </Box>
      </Card>
    </Flex>
  );
};

export default UserProfileSummary;

const CardHeader = ({ title, subtitle }) => (
  <Flex align="center" direction="column" justify="space-between">
    <Heading fontSize="3xl" color="linkedin.500">
      {title}
    </Heading>
    <Heading fontSize="sm" fontWeight="normal" color="gray.500">
      {subtitle}
    </Heading>
  </Flex>
);

const Card = (props) => (
  <Box
    bg="white"
    rounded={{ md: "lg" }}
    overflow="hidden"
    shadow="md"
    {...props}
  />
);

const SessionStatisticsSummary = ({
  avgTimeInFlow,
  cumulativeTimeInFlow,
  preSurvey,
  postSurvey,
  sessionsCompleted,
  avgRestingHRV,
  avgFlowScore,
  avgSessionImpact,
}) => (
  <StatGroup mt="5">
    <SimpleGrid columns={[2, 4, null, null, 2]} spacingX={4} spacingY={5}>
      <Stat>
        <StatLabel>Sessions Completed</StatLabel>
        <StatNumber>{sessionsCompleted ? sessionsCompleted : 0}</StatNumber>
        {/* <StatHelpText>
          <StatArrow type="increase" />
          23.36%
        </StatHelpText> */}
      </Stat>
      <Stat>
        <StatLabel>Avg. Flow Score</StatLabel>
        <StatNumber>{avgFlowScore ? avgFlowScore : "N/A"}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Avg. Resting HRV</StatLabel>
        <StatNumber>{avgRestingHRV ? avgRestingHRV : 0}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Total Time in Flow</StatLabel>
        <StatNumber>
          {cumulativeTimeInFlow ? cumulativeTimeInFlow : 0}
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Avg. Time in Flow</StatLabel>
        <StatNumber>{avgTimeInFlow ? avgTimeInFlow : 0}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Avg. Pre-session Score</StatLabel>
        <StatNumber>{preSurvey ? preSurvey.toFixed(2) : "N/A"}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Avg. Post-session Score</StatLabel>
        <StatNumber>{postSurvey ? postSurvey.toFixed(2) : "N/A"}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Avg. Session Impact</StatLabel>
        <StatNumber>
          {avgSessionImpact ? (
            <StatArrow type={avgSessionImpact > 0 ? "increase" : "decrease"} />
          ) : null}
          {avgSessionImpact
            ? Math.round(Math.abs(avgSessionImpact)) + "%"
            : "N/A"}
        </StatNumber>
        {avgSessionImpact ? (
          <StatHelpText>
            {avgSessionImpact > 0 ? "Avg. Improvement" : "Avg. Decline"}
          </StatHelpText>
        ) : null}
      </Stat>
    </SimpleGrid>
  </StatGroup>
);
