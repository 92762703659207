import React, { useRef } from "react";
import _ from "lodash";
import {
  Checkbox,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

export const PasswordView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text
      fontWeight="bold"
      fontSize="md"
      bg="gray.100"
      px="4"
      py="2"
      rounded="md"
    >
      ••••••••••••
    </Text>
  </Flex>
);

export const PasswordEdit = ({ state, handleChange }) => {
  const inputRef = useRef(null);
  const { isOpen, onToggle } = useDisclosure();

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <VStack alignItems="flex-start">
      <FormControl id="password">
        <InputGroup>
          <Input
            name="password"
            type={isOpen ? "text" : "password"}
            placeholder="Password"
            value={_.get(state, "password")}
            onChange={handleChange("password")}
            size="sm"
            rounded="md"
            required
          />
          <InputRightElement top="-1">
            <IconButton
              bg="transparent !important"
              variant="ghost"
              aria-label={isOpen ? "Mask password" : "Reveal password"}
              icon={isOpen ? <Icon as={HiEyeOff} /> : <Icon as={HiEye} />}
              onClick={onClickReveal}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Checkbox
        pl={1}
        isChecked={_.get(state, "permanent")}
        onChange={handleChange("permanent")}
      >
        Set permanent?
      </Checkbox>
    </VStack>
  );
};
