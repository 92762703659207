import React, { useMemo, useState } from "react";
import { useRouteMatch, NavLink } from "react-router-dom";
import { Box, Flex, Text, Stack } from "@chakra-ui/layout";
import { ButtonGroup, Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";

/* icon imports */
import { RiHomeHeartFill, RiMessage3Fill } from "react-icons/ri";
import { MdLocalShipping } from "react-icons/md";
import { BsCalendar2EventFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";

/* local imports */
import UserSearch from "common/UserSearch";
import IconBox from "common/IconBox";

const getRoutes = (basename) => [
  // { to: `${basename}/dashboard`, title: "Dashboard", icon: RiHomeHeartFill },
  { to: `${basename}/users`, title: "Heroes", icon: FaUsers },
  { to: `${basename}/shipments`, title: "Shipments", icon: MdLocalShipping },
  { to: `${basename}/events`, title: "Events", icon: BsCalendar2EventFill },
  { to: `${basename}/messages`, title: "Messages", icon: RiMessage3Fill },
];

const AdminNav = () => {
  const { url } = useRouteMatch();
  const adminRoutes = useMemo(() => getRoutes(url), []);
  const [activeIdx, setActiveIdx] = useState(null);

  return (
    <Box pb="2" shadow="sm" borderBottomRadius="3xl">
      <Stack
        width="full"
        maxW="8xl"
        mx="auto"
        pt="6"
        justifyContent="space-between"
        alignItems="baseline"
        px={2}
        direction={{ base: "column", md: "row" }}
      >
        <ButtonGroup alignItems="center" variant="ghost" size="xs" isAttached>
          {adminRoutes.map(({ to, title, icon: NavItemIcon }, idx) => (
            <NavLink
              to={to}
              key={title}
              className={(isActive) => (isActive ? setActiveIdx(idx) : null)}
            >
              <Button
                fontSize="sm"
                // ms="0px"
                // px="0px"
                // me={{ sm: "2px", md: "16px" }}
                // color="gray.700"
                variant="transparent-with-icon"
                leftIcon={
                  <IconBox
                    bg={`${idx === activeIdx ? "linkedin.300" : "transparent"}`}
                    color={`${idx === activeIdx ? "white" : "gray.700"}`}
                    me="-2px"
                    transition="0.2s linear"
                    p="1.5"
                  >
                    <Icon as={NavItemIcon} w="4" h="4" me="0px" />
                  </IconBox>
                }
              >
                <Text color={idx === activeIdx ? "linkedin.500" : "gray.700"}>
                  {title}
                </Text>
              </Button>
            </NavLink>
          ))}
        </ButtonGroup>
        <Flex w={{ base: "full", lg: "revert" }} px={4}>
          <UserSearch size={{ base: "full", lg: "sm" }} />
        </Flex>
      </Stack>
    </Box>
  );
};

export default AdminNav;
