import React from "react";
/**
 * Takes a context and returns a useContext wrapped function
 * @method ContextFactory
 * @param name {String}
 * @param context {React Context}
 * @returns useContext wrapped hook {Object}
 */

export const ContextFactory = (name, context) => () => {
  const ctx = React.useContext(context); //eslint-disable-line
  if (ctx === undefined) {
    throw new Error(
      `use${name}Context must be used within a ${name}ContextProvider`
    );
  }
  return ctx;
};
