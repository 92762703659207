import React from "react";
import styled from "styled-components";

/* local imports */
import truck from "assets/gift/truck-gift-shipping.png";
import appstore from "assets/confirmation/appstore.png";
import { device } from "utils/device";

const ConfirmationStyle = styled.div`
  background: linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh);
  min-height: 100vh;
  text-align: center;
  padding-top: 10vh;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 10vh;
  img {
    display: unset;
    max-width: 50vw;
    @media ${device.laptop} {
      max-width: 30vw;
    }
  }
  a {
    display: block;
    font-family: Nunito;
    font-size: 18px;
    line-height: 1.5rem;
    color: #5072ec;
    margin-bottom: 1rem;
    text-decoration: none;
  }
  .appstore {
    width: 100%;
    display: unset;
    @media ${device.mobileL} {
      max-width: 250px;
    }
  }
  p {
    display: block;
    font-family: Nunito;
    font-size: 18px;
    line-height: 1.5rem;
    color: #464e64;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;

    @media ${device.laptop} {
      width: 40vw;
    }
  }
`;

const ThankYou = styled.div`
  font-family: "Tommy Soft";
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.5em;
  color: #5072ec;
  margin-top: 2rem;
  @media ${device.laptop} {
    font-size: 4rem;
    line-height: 90px;
  }
`;

function GiftConfirmation() {
  return (
    <ConfirmationStyle>
      <img src={truck} alt="" />
      <ThankYou>Your Gift is sent!</ThankYou>
      <p>
        {/* <b>Congratulations on becoming a Flowly Member!</b> <br /> */}
        {/* <br /> */}
        We will be shipping out your gifted Flowly Kit very soon. Keep an eye
        out for emails from us regarding your shipping and tracking information.
        <br />
        <br />
        You will receive an email with the link to redeem your gift shortly.
        When the time comes to share the gift with your recipient, share the
        included link with them which they can use to sign up or login and
        redeem their gift.
        <br />
        Thank you for sharing the gift of relaxation.
        {/* You can start exploring Flowly now! Your Intro Worlds can be experienced
        in VR or in 360 mode on your phone. */}
      </p>
      <a
        href="https://apps.apple.com/us/app/flowly-relaxation-training/id1485955236"
        target="_blank"
        rel="noreferrer"
      >
        <img className="appstore" src={appstore} alt="App Store" />
      </a>
      <a href="https://www.flowly.world/">Get back to home page</a>
    </ConfirmationStyle>
  );
}

export default GiftConfirmation;
