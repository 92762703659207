import React, { useEffect, useState } from "react";
import { Box, Container, Link, Spinner, Text } from "@chakra-ui/react";
import { get } from "lodash";
import { Auth } from "aws-amplify";

/** local imports */
import H1 from "common/H1";
import { useAuth } from "utils/AuthContext";
import { client } from "utils/awsConfig";
import { GET_USER_SETTINGS } from "graphql/queries";
import { GEN_STRIPE_PORTAL_LINK } from "graphql/mutations";

const StripeUserBillingPortal = () => {
  const [{ user }] = useAuth();
  const [link, setLink] = useState("#");

  const loadCustomerPortalSesion = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userSettings = await client.query({
        query: GET_USER_SETTINGS,
        variables: {
          id: user.username,
        },
      });

      const generateLink = await client.mutate({
        mutation: GEN_STRIPE_PORTAL_LINK,
        variables: {
          customerId:
            userSettings.data.getUser.subscriptionInfo.stripeSubscriptionInfo
              .stripeCustomerId,
        },
      });

      const parsedLink = get(
        generateLink,
        "data.getStripePortalLink.result",
        null
      );
      if (parsedLink) {
        setLink(parsedLink);
        window.location.replace(parsedLink);
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    if (link === "#") loadCustomerPortalSesion();
  }, []);

  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      height="100%"
    >
      <Container
        maxW="8xl"
        pt="12"
        minHeight="calc(100vh - 4rem)"
        align="center"
      >
        <Spinner size="xl" colorScheme="linkedin" />
        <H1>Welcome back, {user?.name || "you"}!</H1>
        <Text>You will be redirected to your billing portal in 5 seconds.</Text>
        {link !== "#" && (
          <Text>
            If you&apos;re not automatically redirected , follow{" "}
            <Link color="linkedin.500" isExternal href={link}>
              this link
            </Link>
          </Text>
        )}
      </Container>
    </Box>
  );
};

export default StripeUserBillingPortal;
