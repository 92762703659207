import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Grid,
  GridItem,
  VStack,
  Heading,
  Flex,
  Text,
  Box,
  HStack,
  Link as ChakraLink,
} from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { useToast } from "@chakra-ui/toast";
// import {
//   NumberInput,
//   NumberInputField,
//   NumberInputStepper,
//   NumberIncrementStepper,
//   NumberDecrementStepper,
// } from "@chakra-ui/number-input";
import { useFieldArray, useForm } from "react-hook-form";
import { Select } from "@chakra-ui/select";
import { Spinner } from "@chakra-ui/spinner";
import { Avatar } from "@chakra-ui/avatar";
import { IconButton } from "@chakra-ui/button";
import { Link, useLocation, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns/esm";
import { ErrorBoundary } from "react-error-boundary";
import { get, find } from "lodash";

/* icon imports */
import { MdEdit } from "react-icons/md";
import { FiLink } from "react-icons/fi";
// import { FaShippingFast } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { RiArrowRightUpLine } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";

/* local imports */
import {
  createLabel,
  getCarriers,
  getPackages,
  getServices,
  getShipment,
  getUserShipments,
  updateShipment,
} from "./services";
import useFetchOnce from "hooks/useFetchOnce";
import DataGrid from "common/DataGrid";
import ErrorFallback from "common/ErrorFallback";
import { PORTAL_ROOT } from "../../Routes";

const EMPTY_ADDRESS = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
};

const ShipmentView = () => {
  const { id: shipmentId } = useParams();
  const { state } = useLocation();
  const [shipment, setShipment] = useState(() =>
    state
      ? {
          ...state,
          createdAt: parseISO(state.createdAt),
        }
      : null
  );

  const [
    {
      value: userShipments,
      error: userShipmentsErr,
      loading: userShipmentsLoading,
    },
  ] = useFetchOnce(
    () => getUserShipments(shipment?.userId),
    [shipment?.userId],
    [],
    {
      getPath: "data.getUser.shipments.items",
      fetchCondition: !!shipment?.userId,
    }
  );
  const [
    {
      value: currentShipment,
      error: currentShipmentErr,
      loading: currentShipmentLoading,
    },
  ] = useFetchOnce(() => getShipment(shipmentId), [shipmentId], [], {
    getPath: "data.getShipment",
    fetchCondition: !shipment,
  });

  useEffect(() => {
    if (currentShipment) setShipment(currentShipment);
  }, [currentShipment]);

  const updateShipment = (properties) =>
    setShipment((prev) => ({ ...prev, ...properties }));

  const [settingsFields, setSettingsFields] = useState({ shipping: false });
  const toggleFields = (field) => (e) =>
    setSettingsFields((prev) => ({ ...prev, [field]: !prev[field] }));
  //   const [
  //     { value: shipments, error: shipmentsErr, loading: shipmentsLoading },
  //     fetchShipments,
  //   ] = useFetchOnce(getShipments, [], [], {
  //     getPath: "data.listShipments.items",
  //   });
  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${get(shipment, "shipstationOrderId")}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const handleCreateLabel = async () => {
    // TODO: Shipstation includes a total weight calculation to be sent to carriers
    const {
      shipstationOrderId,
      carrierCode,
      serviceCode,
      confirmation,
      weight,
    } = shipment;
    try {
      const label = await createLabel({
        orderId: shipstationOrderId,
        carrierCode: carrierCode,
        serviceCode: serviceCode,
        confirmation: confirmation,
        weight: weight,
        shipDate: new Date().toISOString(),
      });
      downloadPDF(label.data.labelData);
    } catch (err) {
      alert("Unable to print label for this order");
      console.log("An error occurred: ", err);
    }
  };

  const orderKey = get(shipment, ["shipstationOrderKey"], "");
  const customerName = get(shipment, "userId")
    ? `${get(shipment, "user.firstName")} ${get(shipment, "user.lastName")}`
    : get(shipment, ["shipstationInfo", "billTo", "name"], "Customer Name");
  const orderItems = get(shipment, ["items"], []);
  const carrierCode = get(shipment, ["carrierCode"], "");
  const serviceCode = get(shipment, ["serviceCode"], "");
  const customerShippingAddress = get(
    shipment,
    ["shipstationInfo", "shipTo"],
    get(shipment, ["recipientAddress"], EMPTY_ADDRESS)
  );
  const customerBillingAddress = get(
    shipment,
    ["shipstationInfo", "billTo"],
    get(shipment, ["recipientAddress"], EMPTY_ADDRESS)
  );

  const shipmentColumns = useMemo(
    () => [
      {
        header: "Name",
        cell: ({ row }) => (
          <VStack align="flex-start">
            <Text fontWeight="bold" fontSize="lg">
              {get(row.original, "name")}
            </Text>
            <Text color="gray.500">SKU: {get(row.original, "sku")}</Text>
          </VStack>
        ),
        enableSorting: false,
      },
      {
        header: "Qty",
        accessorKey: "quantity",
        enableSorting: false,
      },
    ],
    []
  );

  const { table: tableInstance, ...dataGridProps } = DataGrid.useDataGrid({
    columns: shipmentColumns,
    data:
      get(shipment, "shipstationInfo.items", get(shipment, "items") ?? []) ??
      [],
    // initialState: {
    //   columnVisibility: { status: false },
    // },
    enableRowSelection: false,
  });

  console.log({ state, shipment, settingsFields });
  return (
    <Container maxW="8xl" pt="8">
      <Flex>
        <Heading fontSize="3xl" mr="2">
          Shipment ID:
        </Heading>
        <Heading fontSize="3xl" color="gray.400">
          #{shipment?.shipstationOrderId}
        </Heading>
        <ChakraLink
          isExternal
          href={`https://ship11.shipstation.com/orders/all-orders-search-result?quickSearch=${orderKey}`}
        >
          <IconButton
            ml="px"
            mt="px"
            size="sm"
            variant="ghost"
            colorScheme="blackAlpha"
            icon={<FiLink />}
          />
        </ChakraLink>
      </Flex>
      <Text color="gray.500">
        {format(shipment?.createdAt ?? new Date(), "MMM do yyyy")} at{" "}
        {format(shipment?.createdAt ?? new Date(), "hh:mm aaa")}
      </Text>

      <Grid templateColumns={{ lg: "repeat(12, 1fr)" }} gap={6} py={6}>
        <Card colSpan={{ lg: 4 }}>
          <HStack my="1" justify="space-between">
            <Heading fontSize="2xl" mr="2" color="gray.600" mb="5">
              Customer&apos;s Cart
            </Heading>
            {/* <IconButton icon={<MdEdit />} /> */}
          </HStack>

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <DataGrid.Composer table={tableInstance}>
              <DataGrid.Table />
            </DataGrid.Composer>
          </ErrorBoundary>
        </Card>

        <Card
          // gridColumnStart={{ lg: 4 }}
          colSpan={{ lg: 4 }}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <HStack justify="space-between">
              <Heading fontSize="2xl" mt="1" mr="2" color="gray.600" mb="5">
                Shipping
              </Heading>

              {!settingsFields["shipping"] && (
                <HStack spacing={1}>
                  <IconButton
                    variant="outline"
                    icon={<IoDocumentText />}
                    onClick={handleCreateLabel}
                  />
                  <IconButton
                    variant="outline"
                    icon={<MdEdit />}
                    onClick={toggleFields("shipping")}
                  />
                </HStack>
              )}
            </HStack>
            {settingsFields["shipping"] ? (
              <ShippingForm
                shipment={shipment}
                updateShipment={updateShipment}
                onClose={toggleFields("shipping")}
              />
            ) : (
              <ShippingBody shipment={shipment} />
            )}
          </ErrorBoundary>
        </Card>

        <Card
          // gridRow="1 / span 2"
          alignSelf="start"
          //   maxH="full"
          //   gridAutoRows={"minmax(min-content, max-content)"}
          //   gridRow="1"
          // gridColumnStart={{ lg: 7 }}
          colSpan={{ lg: 4 }}
        >
          <HStack justify="space-between">
            <Heading fontSize="2xl" mr="2" mt="1" color="gray.600">
              Customer
            </Heading>
            {/* <IconButton icon={<MdEdit />} /> */}
          </HStack>

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <HStack align="center" mt="4">
              <Avatar size="md" ml={0} name={customerName} />
              <VStack align="flex-start" spacing="-1">
                <HStack align="baseline" spacing={0}>
                  <Text fontWeight="bold">{customerName}</Text>
                  {shipment.userId ? (
                    <IconButton
                      size="xs"
                      variant="ghost"
                      icon={<RiArrowRightUpLine />}
                      as={Link}
                      to={`${PORTAL_ROOT}/users/${shipment.userId}`}
                      // href={}
                    />
                  ) : null}
                </HStack>
                {shipment.userId && get(userShipments, "0", null) ? (
                  <Text>
                    {userShipments.length === 1 ? (
                      "This is their first order!"
                    ) : userShipments.length > 1 ? (
                      <>
                        <strong>{userShipments.length - 1}</strong> previous
                        orders
                      </>
                    ) : (
                      "No previous orders"
                    )}
                  </Text>
                ) : null}
              </VStack>
            </HStack>

            <Box borderTop="1px" borderColor="gray.200" mt={6} py={6}>
              <Text fontWeight="bold" mb={2}>
                Shipping Address
              </Text>
              <Text>
                {get(
                  customerShippingAddress,
                  "street1",
                  get(customerShippingAddress, "address1", "")
                )}
              </Text>
              {get(
                customerShippingAddress,
                "street2",
                get(customerShippingAddress, "address2", null)
              ) && (
                <Text>
                  {get(
                    customerShippingAddress,
                    "street2",
                    get(customerShippingAddress, "address2", null)
                  )}
                </Text>
              )}
              <Text>
                {customerShippingAddress.city}, {customerShippingAddress.state}
              </Text>
              <Text>{customerShippingAddress.postalCode}</Text>
              <Text>{customerShippingAddress.country}</Text>
            </Box>
            <Box borderTop="1px" borderColor="gray.200" py={6}>
              <Text fontWeight="bold" mb={2}>
                Billing Address
              </Text>
              <Text>
                {get(
                  customerBillingAddress,
                  "street1",
                  get(customerBillingAddress, "address1", "")
                )}
              </Text>
              {get(
                customerBillingAddress,
                "street2",
                get(customerBillingAddress, "address2", null)
              ) && (
                <Text>
                  {get(
                    customerBillingAddress,
                    "street2",
                    get(customerBillingAddress, "address2", null)
                  )}
                </Text>
              )}
              <Text>
                {customerBillingAddress.city}, {customerBillingAddress.state}
              </Text>
              <Text>{customerBillingAddress.postalCode}</Text>
              <Text>{customerBillingAddress.country}</Text>
            </Box>
          </ErrorBoundary>
        </Card>
      </Grid>
    </Container>
  );
};

export default ShipmentView;

const Card = (props) => (
  <GridItem
    bg="#fcfcfc"
    p={6}
    rounded="3xl"
    shadow="xs"
    w="full"
    minHeight="xs"
    {...props}
  />
);

const confirmationOptions = [
  "signature",
  "adult_signature",
  "direct_signature",
  "delivery",
  "none",
];

const ShippingBody = ({ shipment }) => {
  // const [shippingDetails, setShippingDetails] = useState({
  //   carrier: "Stamps.com",
  //   service: "First class",
  //   package: "Envelope",
  // });

  const [{ value: carriers, error: carriersErr, loading: carriersLoading }] =
    useFetchOnce(getCarriers, [], [], {
      getPath: "data",
      syncToStorage: true,
      syncKey: "ssapi-carriers",
    });
  const [{ value: services, error: servicesErr, loading: servicesLoading }] =
    useFetchOnce(
      () => getServices(get(shipment, "shipstationInfo.carrierCode")),
      [get(shipment, "shipstationInfo.carrierCode")],
      [],
      {
        getPath: "data",
        fetchCondition: !!get(shipment, "shipstationInfo.serviceCode"),
        syncToStorage: true,
        syncKey: "ssapi-services-" + (carriers?.[0]?.code ?? "stamps_com"),
      }
    );
  const [{ value: packages, error: packagesErr, loading: packagesLoading }] =
    useFetchOnce(
      () => getPackages(get(shipment, "shipstationInfo.carrierCode")),
      [get(shipment, "shipstationInfo.carrierCode")],
      [],
      {
        getPath: "data",
        fetchCondition: !!get(shipment, "shipstationInfo.packageCode"),
        syncToStorage: true,
        syncKey: "ssapi-packages-" + (carriers?.[0]?.code ?? "stamps_com"),
      }
    );

  console.log({ carriers, services, packages });

  const deliveryStatement = (code) => {
    switch (code) {
      case "signature":
        return "Confirmed by Signature";
      case "adult_signature":
        return "Confirmed by an Adult Signature";
      case "direct_signature":
        return "Confirmed by Direct Signature";
      case "delivery":
        return "Confirmed on Delivery";
      case "none":
      default:
        return "No confirmation of delivery selected";
    }
  };

  return (
    <HStack
      pt={4}
      width="full"
      justifyContent="space-between"
      align="flex-start"
    >
      <HStack>
        <Image
          bg="gray.100"
          p={3}
          me={2}
          rounded="3xl"
          boxSize="4.5rem"
          objectFit="cover"
          src={`https://logos.shipstation.com/ipaas/carriers/${
            get(shipment, "shipstationInfo.carrierCode") ?? "stamps_com"
          }/icon.svg`}
          alt={`${
            get(shipment, "shipstationInfo.carrierCode") ?? "stamps_com"
          } logo`}
        />
        <VStack align="flex-start" spacing={0}>
          <Text fontWeight="bold" fontSize="lg">
            {get(
              find(
                services,
                (c) => c.code === get(shipment, "shipstationInfo.serviceCode")
              ),
              "name"
            )}
          </Text>
          <Text color="gray.400">
            {get(
              find(
                packages,
                (pkg) =>
                  pkg.code === get(shipment, "shipstationInfo.packageCode")
              ),
              "name"
            )}
          </Text>
          <Text color="gray.400">
            {deliveryStatement(get(shipment, "shipstationInfo.confirmation"))}
          </Text>
        </VStack>
      </HStack>
      <Text fontWeight="bold" fontSize="lg">
        ${get(shipment, "shipstationInfo.shippingAmount", 0).toFixed(2)}
      </Text>
    </HStack>
  );
};
const ShippingForm = ({
  shipment,
  updateShipment: mutateLocalShipment,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
    watch,
    clearErrors,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      weight: {
        pounds: 0,
        ounces: 0,
        units: "ounces",
      },
      dimensions: {
        length: 0,
        width: 0,
        height: 0,
        units: "inches",
      },
      carrier: get(shipment, "shipstationInfo.carrierCode", "stamps_com"),
      service: get(shipment, "shipstationInfo.serviceCode", ""),
      package: get(shipment, "shipstationInfo.packageCode", ""),
      confirmation: get(shipment, "shipstationInfo.confirmation", "none"),
    },
  });
  useEffect(() => {
    reset({
      carrier: get(shipment, "shipstationInfo.carrierCode", "stamps_com"),
      service: get(shipment, "shipstationInfo.serviceCode", ""),
      package: get(shipment, "shipstationInfo.packageCode", ""),
      confirmation: get(shipment, "shipstationInfo.confirmation", "none"),
    });
  }, []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      // const order = await updateOrder(
      //   get(shipment, "shipstationOrderKey"),
      //   {
      //     ...get(shipment, "shipstationInfo"),
      //     ...data,
      //     carrierCode: data.carrier,
      //     serviceCode: data.service,
      //     packageCode: data.package,
      //   }
      // );
      // console.log(order.data);

      const modifiedShipment = await updateShipment({
        id: shipment.id,
        ...{
          carrierCode: data.carrier,
          serviceCode: data.service,
          packageCode: data.package,
        },
        shipstationInfo: JSON.stringify({
          // ...get(shipment, "shipstationInfo"),
          //  ...data,
          ...shipment,
          carrierCode: data.carrier,
          serviceCode: data.service,
          packageCode: data.package,
        }),
      });
      console.log(modifiedShipment.data);

      mutateLocalShipment({
        ...get(modifiedShipment, "data.updateShipment"),
        shipstationInfo: JSON.parse(
          get(modifiedShipment, "data.updateShipment.shipstationInfo")
        ),
        createdAt: parseISO(
          get(modifiedShipment, "data.updateShipment.createdAt")
        ),
      });

      toast({
        title: "Shipment updated.",
        status: "success",
        duration: 7500,
        isClosable: true,
      });

      onClose();
      return;
    } catch (err) {
      alert("Something went wrong during order update");
      console.log("An error occurred: ", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const selectedCarrier = watch("carrier");
  const [{ value: carriers, error: carriersErr, loading: carriersLoading }] =
    useFetchOnce(getCarriers, [], [], {
      getPath: "data",
      syncToStorage: true,
      syncKey: "ssapi-carriers",
    });

  const [{ value: services, error: servicesErr, loading: servicesLoading }] =
    useFetchOnce(() => getServices(selectedCarrier), [selectedCarrier], [], {
      getPath: "data",
      fetchCondition: !!selectedCarrier,
      syncToStorage: true,
      syncKey: "ssapi-services-" + selectedCarrier,
    });
  const [{ value: packages, error: packagesErr, loading: packagesLoading }] =
    useFetchOnce(() => getPackages(selectedCarrier), [selectedCarrier], [], {
      getPath: "data",
      fetchCondition: !!selectedCarrier,
      syncToStorage: true,
      syncKey: "ssapi-packages-" + selectedCarrier,
    });

  console.log({ carriers, services, packages, selectedCarrier });

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <VStack mt="4" spacing={4}>
        {/* <FormControl
          id={`quantity`}
          isInvalid={errors?.bill?.quantity}
          isRequired
          flexShrink={2}
        >
          <FormLabel mb={1} fontWeight="semibold">
            Quantity
          </FormLabel>
          <HStack
            // width="full"
            justifyContent="space-between"
            align="flex-start"
          >
            <Box>
              <HStack>
                <NumberInput>
                  <NumberInputField
                    {...register(`weight.pounds`, {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      valueAsNumber: true,
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text>(lb)</Text>
              </HStack>
              {get(errors, `weight.pounds`) && (
                <FormErrorMessage>
                  {get(errors, `weight.pounds.message`)}
                </FormErrorMessage>
              )}
            </Box>
            <Box>
              <HStack>
                <NumberInput>
                  <NumberInputField
                    {...register(`weight.ounces`, {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      valueAsNumber: true,
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text>(oz)</Text>
              </HStack>
              {get(errors, `weight.ounces`) && (
                <FormErrorMessage>
                  {get(errors, `weight.ounces.message`)}
                </FormErrorMessage>
              )}
            </Box>
          </HStack>
        </FormControl> */}
        <FormControl id="carrier">
          <FormLabel mb={1} fontWeight="semibold">
            Carrier
          </FormLabel>
          <Select
            {...register("carrier")}
            icon={carriersLoading ? <Spinner /> : <BiChevronDown />}
            required
            rounded="md"
            // defaultValue={
            //   carriers.length
            //     ? carriers.find((s) => s.storeName.includes("Ad Hoc")).storeId
            //     : 463176
            // }
          >
            {!carriersLoading && !carriersErr && Array.isArray(carriers)
              ? carriers.map((carrier) => (
                  <option key={carrier.code} value={carrier.code}>
                    {carrier.name}
                  </option>
                ))
              : null}
          </Select>
        </FormControl>
        <FormControl id="service">
          <FormLabel mb={1} fontWeight="semibold">
            Service
          </FormLabel>
          <Select
            {...register("service")}
            icon={servicesLoading ? <Spinner /> : <BiChevronDown />}
            required
            rounded="md"
          >
            {!servicesLoading && !servicesErr && Array.isArray(services)
              ? services.map((service) => (
                  <option key={service.code} value={service.code}>
                    {service.name}
                  </option>
                ))
              : null}
          </Select>
        </FormControl>
        <FormControl id="package">
          <FormLabel mb={1} fontWeight="semibold">
            Package
          </FormLabel>
          <Select
            {...register("package")}
            icon={packagesLoading ? <Spinner /> : <BiChevronDown />}
            required
            rounded="md"
          >
            {!packagesLoading && !packagesErr && Array.isArray(packages)
              ? packages.map((pkg) => (
                  <option key={pkg.code} value={pkg.code}>
                    {pkg.name}
                  </option>
                ))
              : null}
          </Select>
        </FormControl>
        <FormControl id="confirmation">
          <FormLabel mb={1} fontWeight="semibold">
            Package
          </FormLabel>
          <Select
            {...register("confirmation")}
            icon={<BiChevronDown />}
            required
            rounded="md"
          >
            {confirmationOptions.map((co) => (
              <option key={co} value={co}>
                {co
                  .split("_")
                  .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                  .join(" ")}
              </option>
            ))}
          </Select>
        </FormControl>

        <HStack>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" colorScheme="linkedin" isLoading={isSubmitting}>
            Save
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

// Summary Widget:

// <Card gridColumnStart={{ lg: 1 }}
//         colSpan={{ lg: 3 }}>
//           <HStack justify="space-between">
//             <Heading fontSize="2xl" mt="1" mr="2" color="gray.600">
//               Summary
//             </Heading>
//             {/* <IconButton icon={<MdEdit />} /> */}
//           </HStack>

//           <ErrorBoundary FallbackComponent={ErrorFallback}>
//             <VStack
//               width="full"
//               // borderTop="1px solid #c0cff9"
//               borderBottom="1px solid"
//               borderBottomColor="gray.100"
//               pt={8}
//               pb={4}
//             >
//               <HStack width="full" justifyContent="space-between">
//                 <Text>Subtotal</Text>
//                 <Text fontWeight="bold" fontSize="lg">
//                   $
//                   {get(shipment, "items")
//                     .reduce((acc, val) => acc + val.unitPrice, 0)
//                     .toFixed(2)}
//                 </Text>
//               </HStack>
//               <HStack width="full" justifyContent="space-between">
//                 <Text>Shipping</Text>
//                 <Text fontWeight="bold" fontSize="lg">
//                   ${get(shipment, "shippingAmount", 0).toFixed(2)}
//                 </Text>
//               </HStack>
//             </VStack>
//             <HStack mt="4" width="full" justifyContent="space-between">
//               <Text>Total</Text>
//               <Text fontWeight="bold" fontSize="lg">
//                 $
//                 {(
//                   get(shipment, "items").reduce(
//                     (acc, val) => acc + val.unitPrice,
//                     0
//                   ) + get(shipment, "shippingAmount", 0)
//                 ).toFixed(2)}
//               </Text>
//             </HStack>
//           </ErrorBoundary>
//         </Card>
