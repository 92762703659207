import React, { useMemo, useState } from "react";
import _, { cloneDeep, groupBy } from "lodash";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Text, HStack } from "@chakra-ui/layout";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";
import { ButtonGroup, IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";

/* icon imports */
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

/* local imports */
import NoDataAvailable from "common/NoDataAvailable";
import { beautify } from "utils/string";

import useTimeWindow from "../hooks/useTimeWindow";
import { withinBounds } from "../utils/temporal";
import SessionListModal from "./SessionListModal";

const WorldPreference = ({ data, filterWeeks }) => {
  const { windowShift, windowStart, windowEnd, shiftLeft, shiftRight } =
    useTimeWindow({
      weeks: filterWeeks,
    });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPoint, setSelectedPoint] = useState(null);

  const worldData = useMemo(() => {
    if (!data) return { data: [{ value: null }] };

    let cleaned = data;

    if (filterWeeks) {
      cleaned = cleaned.filter(withinBounds(windowStart, windowEnd));
    }

    let preAggregation = cloneDeep(cleaned);

    cleaned = Object.entries(groupBy(cleaned, "id")).map(
      ([world, sessions]) => ({ id: world, sessions: sessions.length })
    );

    cleaned = cleaned.sort((a, b) => b.sessions - a.sessions);

    return { data: cleaned, raw: preAggregation };
  }, [data, filterWeeks, windowStart, windowEnd]);

  const handlePointClick = (evt) => {
    if (!evt) return;
    const { activePayload } = evt;
    const [{ payload }] = activePayload;

    setSelectedPoint(payload);
    onOpen();
  };

  const getModalTitle = (weeks) => {
    if (!weeks) return `All time @ ${beautify(selectedPoint?.id)}`;

    return `${format(windowStart, "MMM do, yyyy")} — ${format(
      windowEnd,
      "MMM do, yyyy"
    )} @ ${beautify(selectedPoint?.id)}`;
  };
  // if (!worldData.length) return <NoDataAvailable />;
  return (
    <>
      {filterWeeks && !!worldData.data.length ? (
        <HStack justify="center">
          <Text color="gray.600" fontWeight="bold">
            {format(windowStart, "MMM do, yyyy")} —{" "}
            {format(windowEnd, "MMM do, yyyy")}
          </Text>
        </HStack>
      ) : null}
      <Box height="sm" width="full">
        <Chart
          data={worldData.data}
          filterWeeks={filterWeeks}
          windowStart={windowStart}
          windowEnd={windowEnd}
          handlePointClick={handlePointClick}
        />
      </Box>
      {filterWeeks ? (
        <HStack mt="4" justifyContent="center" spacing={2}>
          <ButtonGroup>
            <IconButton
              onClick={shiftLeft}
              aria-label="Week before"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowLeft} />}
            />
            <IconButton
              onClick={shiftRight}
              disabled={!windowShift}
              aria-label="Week after"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowRight} />}
            />
          </ButtonGroup>
        </HStack>
      ) : null}
      <SessionListModal
        title={getModalTitle(filterWeeks)}
        isOpen={isOpen}
        onClose={onClose}
        sessions={
          selectedPoint
            ? worldData.raw.filter(
                (point) =>
                  point["id"] === selectedPoint["id"] && point.value !== null
              )
            : []
        }
      />
    </>
  );
};

export default WorldPreference;

const CustomizedAxisTick = ({ x, y, stroke, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-35)"
    >
      {beautify(payload.value)}
    </text>
  </g>
);

const Chart = ({
  data,
  filterWeeks,
  windowStart,
  windowEnd,
  handlePointClick,
}) => {
  if (!data.length) {
    return (
      <NoDataAvailable>
        {!!filterWeeks && (
          <Text color="gray.600" fontWeight="bold">
            {format(windowStart, "MMM do, yyyy")} —{" "}
            {format(windowEnd, "MMM do, yyyy")}
          </Text>
        )}
      </NoDataAvailable>
    );
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        onClick={handlePointClick}
        data={data}
        margin={{
          top: 20,
          right: 80,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="id"
          height={96}
          interval={0}
          tick={<CustomizedAxisTick />}
        />
        <YAxis
          allowDecimals={false}
          axisLine={false}
          label={{
            value: "Sessions",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip
          labelStyle={{ color: "#1a202c", fontWeight: 600 }}
          cursor={{ strokeDasharray: "2,2", color: "#4A5568" }}
          labelFormatter={(val) => beautify(val)}
        />
        <Bar dataKey="sessions" fill="#00a0dc" />
      </BarChart>
    </ResponsiveContainer>
  );
};
