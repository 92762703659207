import React, { useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Spinner,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

/** icon imports */
import {
  HiBadgeCheck,
  HiCheck,
  HiMinusSm,
  HiOutlinePencilAlt,
  HiPlusSm,
} from "react-icons/hi";
import { MdDragIndicator } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { FiLoader } from "react-icons/fi";

/** local imports */
import { client } from "utils/awsConfig";
import {
  CREATE_USERJOURNEYRECORD,
  UPDATE_USERJOURNEYRECORD,
} from "graphql/mutations";
import useUserJourneyRecords from "../hooks/useUserJourneyRecords";
import { difference, uniqBy } from "lodash";
import useJourneys from "../hooks/useJourneys";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const MapProgressEditor = () => {
  const { userId } = useParams();
  const {
    isOpen: switchDialogIsOpen,
    onOpen: showSwitchJourneyDialog,
    onClose: handleCloseSwitchDialog,
  } = useDisclosure();

  const {
    isOpen: unlockJourneyDialogIsOpen,
    onOpen: showUnlockJourneyDialog,
    onClose: handleCloseUnlockJourneyDialog,
  } = useDisclosure();

  const [isEditing, setIsEditing] = React.useState(false);
  const [savingToAPI, setSavingToAPI] = React.useState(false);
  const toast = useToast();

  const [mapProgress, setMapProgress] = React.useState({
    main: [],
    other: [],
  });

  const [activeJourneyId, setActiveJourneyId] =
    React.useState("standard-journey");

  const activeJourneyIdRef = React.useRef();

  const {
    data: userJourneys,
    loading: userJourneysLoading,
    refetch: refetchUserJourneys,
  } = useUserJourneyRecords(
    {
      variables: {
        id: userId,
      },
      fetchPolicy: "network-only",
    },
    [userId]
  );

  const { data: journeys, loading: journeysLoading } = useJourneys({}, [], {
    enabled: !userJourneys.length,
  });

  const handleChangeActiveJourney = (evt) => {
    if (evt.target.value === "unlock-new-journey")
      return showUnlockJourneyDialog();

    setActiveJourneyId((prev) => {
      const currentActiveJourney = userJourneys.find(
        (journeyRecord) => journeyRecord.journey.id === prev
      );

      const prevMapProgress = currentActiveJourney?.mapOrder ?? [];
      const modifiedMapProgress = mapProgress.main.map((world) => world.slug);
      const prevUnlockedExperiences = // unlocked worlds not including the ones already in mainMap
        Object.keys(
          JSON.parse(currentActiveJourney?.currentExperiences ?? "{}")
        ).filter((w) => !prevMapProgress.includes(w)) ?? [];
      const modifiedUnlockedExperiences = mapProgress.other.map(
        (world) => world.slug
      );

      const hasModifications =
        symmetricDiff(modifiedMapProgress, prevMapProgress, {
          returnBoolean: true,
        }) ||
        symmetricDiff(modifiedUnlockedExperiences, prevUnlockedExperiences, {
          returnBoolean: true,
        });

      if (hasModifications) {
        activeJourneyIdRef.current = evt.target.value;
        showSwitchJourneyDialog();
        return prev;
      }

      return evt.target.value;
    });
  };

  const activeJourney = React.useMemo(() => {
    if (userJourneysLoading) return null;

    if (!userJourneys || !userJourneys?.length) return null;

    const activeJourneyRecord = userJourneys.find(
      (journeyRecord) => journeyRecord.journey.id === activeJourneyId
    );

    if (activeJourneyRecord) {
      return activeJourneyRecord;
    }

    setActiveJourneyId(userJourneys[0]?.journey?.id);
    return userJourneys[0];
  }, [activeJourneyId, userJourneys, userJourneysLoading]);

  const worlds = React.useMemo(() => {
    if (!activeJourney) return [];
    return uniqBy(
      activeJourney.journey.worlds.items.map(
        (worldLink) => worldLink.worldConfiguration
      ),
      "slug"
    );
  }, [activeJourney]);

  useEffect(() => {
    if (activeJourney && !!worlds.length) {
      setMapProgress(() => {
        if (activeJourney?.currentExperiences) {
          try {
            return getParsedCurrentExperiences(
              activeJourney?.currentExperiences,
              {
                mapOrder: activeJourney?.mapOrder,
                worlds,
              }
            );
          } catch (err) {
            console.log(
              "[Failed to parse experiences]: An error occurred.",
              err
            );
            return { main: [], other: [] };
          }
        }
        return { main: [], other: [] };
      });
    }
  }, [activeJourney, worlds]);

  const openEditor = () => setIsEditing(true);
  const closeEditor = () => {
    setMapProgress(() => {
      if (activeJourney?.currentExperiences) {
        try {
          return getParsedCurrentExperiences(
            activeJourney?.currentExperiences,
            {
              mapOrder: activeJourney?.mapOrder,
              worlds,
            }
          );
        } catch (err) {
          console.log("[Failed to parse experiences]: An error occurred.", err);
          return { main: [], other: [] };
        }
      }
      return { main: [], other: [] };
    });
    setIsEditing(false);
  };
  const saveAndExitEditor = async () => {
    const apiFormattedMapOrder = mapProgress.main.map((world) => world.slug);
    const apiFormattedCurrentExperiences = [
      ...mapProgress.main,
      ...mapProgress.other,
    ].reduce((acc, currentVal) => {
      acc[currentVal.slug] = currentVal.stage;

      return acc;
    }, {});

    try {
      setSavingToAPI(true);
      await client.mutate({
        mutation: UPDATE_USERJOURNEYRECORD,
        variables: {
          input: {
            mapOrder: apiFormattedMapOrder,
            currentExperiences: JSON.stringify(apiFormattedCurrentExperiences),
            id: activeJourney.id,
          },
        },
      });
      refetchUserJourneys();
    } catch (error) {
      alert("An error occurred: Unable to update user journey");
      console.log("An error occurred: Unable to update user journey");
    } finally {
      setSavingToAPI(false);
      setIsEditing(false);
    }
  };

  const advanceStage = (worldId, type) => {
    if (type === "other") {
      const toBeIncremented = mapProgress.other.find(
        (world) => world.id === worldId
      );
      if (toBeIncremented.stage + 1 === toBeIncremented.numSessions) {
        toast({
          title: `${toBeIncremented.name} Completed!`,
          description: `${toBeIncremented.name} was added to the main map just before the latest world.`,
          status: "success",
          variant: "left-accent",
        });

        setMapProgress((prev) => {
          return {
            ...prev,
            main: arrInsert(prev.main, {
              index: prev.main.length - 1,
              item: { ...toBeIncremented, stage: toBeIncremented.numSessions },
            }),
            other: arrRemove(prev.other, toBeIncremented.id),
          };
        });
      }
    }

    setMapProgress((prev) => {
      return {
        ...prev,
        [type]: prev[type].map((prog) =>
          prog.id === worldId ? { ...prog, stage: prog.stage + 1 } : prog
        ),
      };
    });
  };

  const downgradeStage = (worldId, type) => {
    setMapProgress((prev) => {
      return {
        ...prev,
        [type]: prev[type].map((prog) =>
          prog.id === worldId ? { ...prog, stage: prog.stage - 1 } : prog
        ),
      };
    });
  };

  function arrInsert(array, { index, item } = { index: 0, item: null }) {
    return [...array.slice(0, index), item, ...array.slice(index)];
  }
  function arrRemove(array, id) {
    return array.filter((x) => x.id !== id);
  }

  const removeWorld = (worldId, type) => {
    setMapProgress((prev) => {
      return {
        ...prev,
        [type]: arrRemove(prev[type], worldId),
      };
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    const { source, destination } = result;
    const strictProgression = activeJourney?.journey?.hasStrictWorldProgression;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sId = source.droppableId;
    const dId = destination.droppableId;

    if (sId !== dId && dId === "availableWorlds") {
      /**
       * Remove item from list
       */

      if (sId === "otherWorldProgress") {
        const toBeRemoved = mapProgress.other[source.index];
        return removeWorld(toBeRemoved.id, "other");
      } else {
        const toBeRemoved = mapProgress.main[source.index];
        return removeWorld(toBeRemoved.id, "main");
      }
    }

    // re-ordering same list
    if (sId === dId) {
      // we don't care about re-ordering these two columns;
      if (["availableWorlds", "otherWorldProgress"].includes(sId)) return;

      // Only remaining path is reordering mainMap Order
      if (strictProgression) {
        // Disable main Map reorderng
        return;
      }

      const reorderedList = reorder(
        mapProgress.main,
        source.index,
        destination.index
      );

      setMapProgress((prev) => {
        return {
          ...prev,
          main: reorderedList.map((w, idx) =>
            idx !== reorderedList.length - 1
              ? { ...w, stage: w.numSessions }
              : w
          ),
        };
      });
    } else {
      switch (sId) {
        case "availableWorlds": {
          const currentWorlds = [
            ...mapProgress.main,
            ...mapProgress.other,
          ].flat();
          const availableWorlds = worlds.filter(
            (w) => !currentWorlds.some((progress) => progress.id === w.id)
          );

          const toBeAdded = availableWorlds[source.index];

          // adding a new map to user
          if (dId === "otherWorldProgress") {
            return setMapProgress((prev) => {
              return {
                ...prev,
                other: arrInsert(prev.other, {
                  index: destination.index,
                  item: { ...toBeAdded, stage: 0 },
                }),
              };
            });
          } else {
            // unlock & add it to main map
            return setMapProgress((prev) => {
              const mainMapProgress = arrInsert(prev.main, {
                index: destination.index,
                item: { ...toBeAdded, stage: 0 },
              });

              if (strictProgression) {
                mainMapProgress.sort((a, b) => a.order - b.order);

                if (!toast.isActive("predesignated-order")) {
                  toast({
                    id: "predesignated-order",
                    title: `Added world in the predesignated order`,
                    variant: "left-accent",
                  });
                }
              }

              const polyfillWorldProgress = mainMapProgress.map(
                (world, idx) => {
                  if (
                    idx !== mainMapProgress.length - 1 &&
                    world.stage !== world.numSessions
                  ) {
                    return { ...world, stage: world.numSessions };
                  }

                  return world;
                }
              );

              return {
                ...prev,
                main: polyfillWorldProgress,
              };
            });
          }
        }
        case "otherWorldProgress": {
          // adding unlocked progress to main map
          const toBeAdded = mapProgress.other[source.index];

          return setMapProgress((prev) => {
            return {
              ...prev,
              main: [
                ...prev.main
                  .slice(0, destination.index)
                  .map((w) => ({ ...w, stage: w.numSessions })),
                {
                  ...toBeAdded,
                  stage:
                    destination.index !== prev.main.length
                      ? toBeAdded.numSessions
                      : 0,
                },
                ...prev.main.slice(destination.index),
              ],
              other: arrRemove(prev.other, toBeAdded.id),
            };
          });
        }
        default: {
          // moving main map to other progress
          const toBeAdded = mapProgress.main[source.index];
          return setMapProgress((prev) => {
            return {
              ...prev,
              main: arrRemove(prev.main, toBeAdded.id),
              other: arrInsert(prev.other, {
                index: destination.index,
                item: toBeAdded,
              }),
            };
          });
        }
      }
    }
  };

  const handleSwitchWithoutSaving = () => {
    setActiveJourneyId(activeJourneyIdRef.current);
    handleCloseSwitchDialog();
  };

  if (userJourneysLoading || journeysLoading) {
    return (
      <>
        <CardHeader isLoading={true} />
        <Divider my="4" mx="-8" pl="16" />
        <LoadingState />
      </>
    );
  }
  const currentWorlds = [...mapProgress.main, ...mapProgress.other].flat();

  const hasStrictWorldProgression =
    activeJourney?.journey?.hasStrictWorldProgression ?? false;

  const lockedJourneys = journeys.filter(
    (journey) =>
      !userJourneys.map((record) => record.journey.id).includes(journey.id)
  );

  if (!currentWorlds.length) {
    return (
      <>
        <CardHeader
          isEditing={isEditing}
          activeJourneyId={activeJourneyId}
          onJourneyChange={handleChangeActiveJourney}
          userJourneys={userJourneys}
          openEditor={openEditor}
          closeEditor={closeEditor}
          saveAndExit={saveAndExitEditor}
          isSaving={savingToAPI}
          enableJourneyUnlock={!!lockedJourneys.length}
        />
        <Divider my="4" mx="-8" pl="16" />
        {!isEditing ? (
          <EmptyState
            openEditor={openEditor}
            hasJourneys={!!userJourneys.length}
            unlockJourneyDialogIsOpen={unlockJourneyDialogIsOpen}
            showUnlockJourneyDialog={showUnlockJourneyDialog}
            handleCloseUnlockJourneyDialog={handleCloseUnlockJourneyDialog}
          />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <EditingView
              maps={mapProgress}
              worlds={worlds}
              advanceStage={advanceStage}
              downgradeStage={downgradeStage}
              removeWorld={removeWorld}
              hasStrictWorldProgression={hasStrictWorldProgression}
            />
          </DragDropContext>
        )}

        <ConfirmSwitchDialog
          isOpen={switchDialogIsOpen}
          onClose={handleCloseSwitchDialog}
          handleConfirm={handleSwitchWithoutSaving}
        />
        <UnlockJourneyDialog
          availableJourneys={lockedJourneys}
          isOpen={unlockJourneyDialogIsOpen}
          onClose={handleCloseUnlockJourneyDialog}
        />
      </>
    );
  }

  return (
    <>
      <CardHeader
        isEditing={isEditing}
        activeJourneyId={activeJourneyId}
        onJourneyChange={handleChangeActiveJourney}
        userJourneys={userJourneys}
        openEditor={openEditor}
        closeEditor={closeEditor}
        saveAndExit={saveAndExitEditor}
        isSaving={savingToAPI}
        enableJourneyUnlock={!!lockedJourneys.length}
      />
      <Divider my="4" mx="-8" pl="16" />
      {!isEditing ? (
        <ProgressView
          maps={mapProgress}
          hasStrictWorldProgression={hasStrictWorldProgression}
        />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <EditingView
            maps={mapProgress}
            worlds={worlds}
            advanceStage={advanceStage}
            downgradeStage={downgradeStage}
            removeWorld={removeWorld}
            hasStrictWorldProgression={hasStrictWorldProgression}
          />
        </DragDropContext>
      )}

      <ConfirmSwitchDialog
        isOpen={switchDialogIsOpen}
        onClose={handleCloseSwitchDialog}
        handleConfirm={handleSwitchWithoutSaving}
      />
      <UnlockJourneyDialog
        availableJourneys={lockedJourneys}
        isOpen={unlockJourneyDialogIsOpen}
        onClose={handleCloseUnlockJourneyDialog}
      />
    </>
  );
};

export default MapProgressEditor;

const CardTitle = (props) => (
  <Text fontSize="md" fontWeight="bold" {...props} />
);

const CardHeader = ({
  isLoading = false,
  isEditing = false,
  isSaving = false,
  activeJourneyId,
  onJourneyChange,
  userJourneys,
  openEditor,
  closeEditor,
  saveAndExit,
  enableJourneyUnlock = true,
}) => {
  if (isLoading)
    return (
      <HStack justifyContent="space-between" alignItems="center">
        <CardTitle>Journey Configuration</CardTitle>
        <HStack>
          <Skeleton>
            <Select w="64" size="md"></Select>
          </Skeleton>
          <IconButton
            disabled
            ml="4"
            icon={<HiOutlinePencilAlt />}
            size="md"
            variant="outline"
          />
        </HStack>
      </HStack>
    );

  return (
    <HStack justifyContent="space-between" alignItems="center">
      <CardTitle>Journey Configuration</CardTitle>
      <HStack>
        {!!userJourneys.length && (
          <Select
            name="journey"
            value={activeJourneyId}
            onChange={onJourneyChange}
            size="md"
            maxW="64"
          >
            <option value="" selected disabled hidden>
              Select Journey
            </option>
            {userJourneys.map((journeyRecord) => (
              <option
                key={journeyRecord?.journey?.id}
                value={journeyRecord?.journey?.id}
              >
                {journeyRecord?.journey?.title}
              </option>
            ))}
            {enableJourneyUnlock && (
              <option key="new-journey" value="unlock-new-journey">
                Unlock journey
              </option>
            )}
          </Select>
        )}
        {isEditing ? (
          <>
            <Button ml="4" size="md" onClick={closeEditor}>
              Cancel
            </Button>
            <IconButton
              ml="4"
              icon={<HiCheck />}
              size="md"
              //   variant="solid"
              colorScheme="green"
              onClick={saveAndExit}
              isLoading={isSaving}
            />
          </>
        ) : (
          <IconButton
            disabled={!userJourneys.length}
            ml="4"
            icon={<HiOutlinePencilAlt />}
            size="md"
            variant="outline"
            onClick={openEditor}
          />
        )}
      </HStack>
    </HStack>
  );
};

const LoadingState = () => (
  <Box p="12" align="center" w="full">
    <Spinner />
  </Box>
);

const EmptyState = ({ openEditor, hasJourneys, showUnlockJourneyDialog }) => {
  if (!hasJourneys) {
    return (
      <VStack p="12">
        <Text>User is not part of any journeys</Text>
        <Button onClick={showUnlockJourneyDialog} variant="outline">
          Unlock journey
        </Button>
      </VStack>
    );
  }

  return (
    <VStack p="12">
      <Text>User has no worlds on their map</Text>
      <Button onClick={openEditor} variant="outline">
        Unlock worlds
      </Button>
    </VStack>
  );
};

const ProgressView = ({ maps, hasStrictWorldProgression }) => (
  <Flex m="-8" mt="-4">
    <VStack
      p="8"
      alignItems="flex-start"
      spacing={4}
      bg="gray.100"
      w="full"
      overflow="auto"
      maxHeight="sm"
    >
      <Text
        fontSize="md"
        fontWeight="bold"
        letterSpacing="wider"
        textTransform="uppercase"
        color="gray.400"
      >
        Main Map
      </Text>

      <List w="full" spacing={0} mb="4">
        {maps.main.map(({ id, name, numSessions, stage }, idx) => (
          <>
            <ListItem key={`${id}-main-map-viewing`} bg="white" rounded="md">
              <HStack
                spacing={0}
                p={3}
                border="1px"
                borderColor="gray.200"
                justifyContent="space-between"
                w="full"
                alignItems="center"
              >
                <Flex alignItems="center">
                  <ListIcon
                    as={stage !== numSessions ? FiLoader : HiBadgeCheck}
                    color={stage !== numSessions ? "blue.400" : "green.400"}
                  />
                  <Text fontWeight="semibold">{name}</Text>
                </Flex>

                <Text fontWeight="semibold" color="gray.400">
                  {stage} / {numSessions}
                </Text>
              </HStack>
            </ListItem>
            {idx !== maps.main.length - 1 ? (
              <Box bg="gray.300" w="2px" h="4" mx="auto" />
            ) : null}
          </>
        ))}
      </List>
    </VStack>

    {!hasStrictWorldProgression ? (
      <VStack
        p="8"
        alignItems="flex-start"
        spacing={4}
        w="full"
        overflow="auto"
        maxHeight="sm"
      >
        <Text
          fontSize="md"
          fontWeight="bold"
          letterSpacing="wider"
          textTransform="uppercase"
          color="gray.400"
        >
          Other World Progress
        </Text>
        <HStack w="full" spacing={8} flexWrap="wrap" justify="center">
          {maps.other.map(
            ({
              id,
              name,
              stage,
              numSessions,
              firstGradientColor,
              secondGradientColor,
            }) => (
              <VStack
                key={`${id}-other-progress-viewing`}
                bg="white"
                rounded="md"
                position="relative"
                spacing={"-1"}
              >
                <WorldProgressDonut
                  name={name}
                  currentStage={stage}
                  maxNumSessions={numSessions}
                  circleSize={70}
                >
                  <defs>
                    <linearGradient
                      id={`${id}-world-gradient`}
                      x1="-5%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop stopColor={`#${firstGradientColor}`} offset="0%" />
                      <stop
                        stopColor={`#${secondGradientColor}`}
                        offset="100%"
                      />
                    </linearGradient>
                  </defs>
                  <circle
                    cx={70 / 2}
                    cy={70 / 2}
                    r={"27%"}
                    fill={`url(#${id}-world-gradient)`}
                  />
                </WorldProgressDonut>
                <Text fontWeight="semibold" mt="0">
                  {name}
                </Text>
              </VStack>
            )
          )}
        </HStack>
      </VStack>
    ) : null}
  </Flex>
);
const EditingView = ({
  maps,
  worlds,
  advanceStage,
  removeWorld,
  downgradeStage,
  hasStrictWorldProgression,
}) => {
  const currentWorlds = [...maps.main, ...maps.other].flat();
  const availableWorlds = worlds.filter(
    (w) => !currentWorlds.some((progress) => progress.id === w.id)
  );

  return (
    <Flex m="-8" mt="-4" px={4}>
      {/* Available Worlds */}
      <Droppable droppableId="availableWorlds">
        {(provided, snapshot) => (
          <VStack
            ref={provided.innerRef}
            p="4"
            alignItems="flex-start"
            spacing={4}
            w="full"
            shadow="xl"
            zIndex="1"
            bg={snapshot.isDraggingOver ? "red.50" : "gray.50"}
            overflow="auto"
            maxHeight="sm"
            {...provided.droppableProps}
          >
            <Text
              fontSize="sm"
              fontWeight="bold"
              letterSpacing="wide"
              textTransform="uppercase"
              color="gray.400"
            >
              Available Worlds
            </Text>
            {snapshot.isDraggingOver ? (
              <HStack
                bg="red.50"
                border="2px"
                borderColor="red.100"
                borderStyle="dashed"
                rounded="md"
                w="full"
                p="6"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="red.700"
              >
                <BiTrash />
                <Text>Remove World</Text>
              </HStack>
            ) : null}
            <List w="full" spacing={2}>
              {availableWorlds.map(({ id, name }, idx) => (
                <Draggable
                  key={`${id}-available-world`}
                  draggableId={id}
                  index={idx}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      bg="white"
                      rounded="md"
                      p="3"
                      border="1px"
                      borderColor="gray.200"
                      display="flex"
                      alignItems="stretch"
                      justifyContent="start"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ListIcon
                        as={MdDragIndicator}
                        color="gray.400"
                        alignSelf="center"
                        cursor="-webkit-grab"
                      />
                      <Text fontWeight="semibold" flexWrap="wrap">
                        {name}
                      </Text>
                    </ListItem>
                  )}
                </Draggable>
              ))}
            </List>

            {provided.placeholder}
          </VStack>
        )}
      </Droppable>

      {/* Main Map */}
      <Droppable droppableId="mainMapWorlds">
        {(provided, snapshot) => (
          <VStack
            p="4"
            alignItems="flex-start"
            spacing={4}
            w="full"
            overflow="auto"
            maxHeight="sm"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <HStack w="full" justifyContent="space-between">
              <Text
                fontSize="sm"
                fontWeight="bold"
                letterSpacing="wide"
                textTransform="uppercase"
                color="gray.400"
              >
                Main Map
              </Text>
              {hasStrictWorldProgression ? (
                <Badge colorScheme="red">Strict Order</Badge>
              ) : null}
            </HStack>
            <List w="full" spacing={0}>
              {maps.main.map(({ id, name, numSessions, stage }, idx) => {
                if (idx === maps.main.length - 1 || stage < numSessions) {
                  return (
                    <Draggable
                      key={`${id}-main-map`}
                      draggableId={id}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <EditableWorld
                            id={id}
                            name={name}
                            currentStage={stage}
                            maxNumSessions={numSessions}
                            advanceStage={advanceStage}
                            downgradeStage={downgradeStage}
                            removeWorld={removeWorld}
                            type="main"
                          />
                          {idx !== maps.main.length - 1 ? (
                            <Box bg="gray.300" w="2px" h="4" mx="auto" />
                          ) : null}
                        </div>
                      )}
                    </Draggable>
                  );
                }
                return (
                  <Draggable
                    key={`${id}-main-map`}
                    draggableId={id}
                    index={idx}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CompletedWorld
                          name={name}
                          currentStage={stage}
                          maxNumSessions={numSessions}
                        />
                        {idx !== maps.main.length - 1 ? (
                          <Box bg="gray.300" w="2px" h="4" mx="auto" />
                        ) : null}
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </List>
            {provided.placeholder}
          </VStack>
        )}
      </Droppable>

      {/* Other Worlds */}
      {!hasStrictWorldProgression ? (
        <Droppable droppableId="otherWorldProgress">
          {(provided, snapshot) => (
            <VStack
              p="4"
              alignItems="flex-start"
              spacing={4}
              w="full"
              overflow="auto"
              maxHeight="sm"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Text
                fontSize="sm"
                fontWeight="bold"
                letterSpacing="wide"
                textTransform="uppercase"
                color="gray.400"
              >
                Other World Progress
              </Text>

              <List w="full" spacing={2}>
                {maps.other.map(({ id, name, stage, numSessions }, idx) => (
                  <Draggable
                    key={`${id}-main-map`}
                    draggableId={id}
                    index={idx}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <EditableWorld
                          key={`${id}-other-progress`}
                          id={id}
                          name={name}
                          currentStage={stage}
                          maxNumSessions={numSessions}
                          advanceStage={advanceStage}
                          downgradeStage={downgradeStage}
                          removeWorld={removeWorld}
                          type="other"
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </List>
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      ) : null}
    </Flex>
  );
};

const WorldProgressDonut = ({
  name,
  currentStage,
  maxNumSessions,
  circleSize = 30,
  children,
}) => {
  const data = [
    {
      name: name,
      stage: currentStage,
    },
  ];

  return (
    <RadialBarChart
      width={circleSize}
      height={circleSize}
      cx={circleSize / 2}
      cy={circleSize / 2}
      innerRadius={"70%"}
      outerRadius={"100%"}
      barSize={3}
      data={data}
      startAngle={90}
      endAngle={-270}
      margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
    >
      <PolarAngleAxis
        type="number"
        domain={[0, maxNumSessions]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        background
        clockWise
        dataKey="stage"
        cornerRadius={circleSize / 2}
        fill="#50bb79"
      />

      {children}
    </RadialBarChart>
  );
};

const CompletedWorld = ({ name, currentStage, maxNumSessions }) => (
  <ListItem bg="white" rounded="md" border="1px" borderColor="gray.200">
    <HStack
      spacing={0}
      p={3}
      justifyContent="space-between"
      w="full"
      alignItems="center"
    >
      <Flex alignItems="center">
        <ListIcon as={HiBadgeCheck} color="green.400" />
        <Text fontWeight="semibold">{name}</Text>
      </Flex>

      <Text fontWeight="semibold" color="gray.400" flexShrink={0}>
        {currentStage} / {maxNumSessions}
      </Text>
    </HStack>
  </ListItem>
);

const EditableWorld = ({
  id,
  name,
  currentStage,
  maxNumSessions,
  advanceStage,
  downgradeStage,
  removeWorld,
  type,
}) => (
  <ListItem
    bg="white"
    rounded="md"
    border="1px"
    borderColor="gray.200"
    shadow="md"
  >
    <VStack spacing={4} p={3} align="start">
      <HStack spacing={2}>
        <WorldProgressDonut
          name={name}
          currentStage={currentStage}
          maxNumSessions={maxNumSessions}
        />
        <Text fontWeight="semibold">{name}</Text>
      </HStack>
      <HStack justifyContent="space-between" w="full">
        <HStack spacing={2} align="center">
          <IconButton
            variant="outline"
            size="xs"
            aria-label="Previous stage"
            icon={<HiMinusSm />}
            onClick={() => downgradeStage(id, type)}
            disabled={currentStage === 0}
          />
          <Flex align="baseline">
            <Text
              fontWeight="bold"
              fontSize="md"
              minWidth="4"
              textAlign="center"
            >
              {currentStage}
            </Text>
            <Text fontWeight="normal" fontSize="sm" color="gray.400">
              / {maxNumSessions}
            </Text>
          </Flex>
          <IconButton
            variant="outline"
            size="xs"
            aria-label="Next stage"
            icon={<HiPlusSm />}
            onClick={() => advanceStage(id, type)}
            disabled={currentStage > maxNumSessions - 1}
          />
        </HStack>
        <IconButton
          variant="ghost"
          size="xs"
          aria-label="Remove world"
          icon={<AiOutlineDelete />}
          onClick={() => removeWorld(id, type)}
        />
      </HStack>
    </VStack>
  </ListItem>
);

const getParsedCurrentExperiences = (experiencesStr, { mapOrder, worlds }) => {
  const parsed = JSON.parse(experiencesStr);

  const mainMap = [];
  const otherProgress = [];

  Object.entries(parsed).forEach(([world, stage]) => {
    if (mapOrder.includes(world)) {
      mainMap.push({
        ...worlds.find((w) => w.slug === world),
        stage,
      });
    } else {
      otherProgress.push({
        ...worlds.find((w) => w.slug === world),
        stage,
      });
    }
  });

  /**
   * Follow the mapOrder provided instead of the order of the keys in currentExperiences object which is not guaranteed
   */
  mainMap.sort((a, b) => mapOrder.indexOf(a.slug) - mapOrder.indexOf(b.slug));

  return {
    main: mainMap,
    other: otherProgress,
  };
};

const ConfirmSwitchDialog = ({ isOpen, onClose, handleConfirm }) => {
  const cancelRef = React.useRef();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Switch Journey?</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Are you sure you want to switch Journey? You have unsaved changes that
          will be lost.
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" ml={3} onClick={handleConfirm}>
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const UnlockJourneyDialog = ({ availableJourneys, isOpen, onClose }) => {
  const { userId } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      journeys: [],
    },
  });
  const toast = useToast();
  const queryClient = useQueryClient();

  const [isUnlocking, setIsUnlocking] = React.useState(false);

  const onSubmit = async ({ journeys }) => {
    setIsUnlocking(true);

    try {
      await Promise.all(
        journeys.map((journey) =>
          client.mutate({
            mutation: CREATE_USERJOURNEYRECORD,
            variables: {
              input: {
                userId,
                journeyId: journey,
              },
            },
          })
        )
      );
      toast({
        title: `Journeys unlocked successfully!`,
        status: "success",
        variant: "left-accent",
      });

      queryClient.invalidateQueries({
        queryKey: ["userJourneyRecords", userId],
      });
      handleClose();
    } catch (error) {
      console.error("Failed to create User Journey Record");
    } finally {
      setIsUnlocking(false);
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Journeys to Unlock</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody id="form">
            <FormControl id="journeys" isInvalid={!!errors["journeys"]}>
              <VStack align="flex-start">
                {availableJourneys.map(({ id, title }) => (
                  <Checkbox
                    key={id}
                    value={id}
                    defaultChecked={false}
                    {...register(`journeys`, {
                      required: {
                        value: true,
                        message: "Must select at least one journey to unlock",
                      },
                    })}
                  >
                    {title}
                  </Checkbox>
                ))}
                {errors?.journeys && (
                  <FormErrorMessage>{errors.journeys.message}</FormErrorMessage>
                )}
              </VStack>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              isLoading={isUnlocking}
              colorScheme="linkedin"
              rounded="md"
              type="submit"
              loadingText="Unlocking journeys..."
            >
              Unlock Journeys
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const symmetricDiff = (
  arr1,
  arr2,
  { returnBoolean = false } = { returnBoolean: false }
) => {
  const diff = arr1
    .filter((x) => !arr2.includes(x))
    .concat(arr2.filter((x) => !arr1.includes(x)));

  if (returnBoolean) {
    return diff.length > 0;
  }

  return diff;
};
