import React from "react";
import { SimpleGrid, Flex, Box } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import {
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/stat";

/* icon imports */
import { FaRegQuestionCircle } from "react-icons/fa";

const SessionStatistics = ({ relaxation, timeInFlow, flowScore, rmssd }) => (
  <StatGroup mt="5">
    <SimpleGrid columns={5} spacing={4}>
      <Stat>
        <StatLabel>
          <Flex alignItems="center">
            Time in Flow
            <Tooltip
              label="It is a short-term metric to give you a snapshot of how you're body is responding in each Flowly session. Some days your Time in Flow will be longer, some days it will be shorter (or none at all). Simply observe the time as a way to understand where your body is at each day."
              fontSize="sm"
            >
              <Box as="span" ml="1">
                <FaRegQuestionCircle fontSize="12" />
              </Box>
            </Tooltip>
          </Flex>
        </StatLabel>
        <StatNumber fontSize="4xl">{timeInFlow ? timeInFlow : 0}</StatNumber>
        {/* <StatHelpText>
          <StatArrow type="increase" />
          23.36% from last session
        </StatHelpText> */}
      </Stat>
      <Stat>
        <StatLabel>
          <Flex alignItems="center">
            Flow Score
            <Tooltip
              label="Your Flow Score is a good indication of how well you're doing in each session. Think of it this way, your Resting HRV is a long-term metric of your progress. Your Flow Score is a short-term metric of how well you're doing in each individual session.
              Some days your score will be higher, some days it will be lower. Simply observe the score as a way to understand where your body is at each day."
              fontSize="sm"
            >
              <Box as="span" ml="1">
                <FaRegQuestionCircle fontSize="12" />
              </Box>
            </Tooltip>
          </Flex>
        </StatLabel>
        <StatNumber fontSize="4xl">{Math.round(flowScore * 100)}</StatNumber>
        {/* <StatHelpText>
          <StatArrow type="decrease" />
          9.05% from last session
        </StatHelpText> */}
      </Stat>
      <Stat>
        <StatLabel>
          <Flex alignItems="center">
            Resting HRV
            <Tooltip
              label="A higher Resting HRV indicates a more active parasympathetic nervous system, which is great!
              We collect your Resting HRV in the first minute of every session before you start paced breathing. We do this because we are collecting a sample of your HRV from your day-to-day life outside of Flowly sessions.
              Therefore, your Resting HRV is a long-term metric to see how Flowly sessions could be helping improve your nervous system control over time. Progress will not be overnight for this. Consistency is key for improving Resting HRV."
              fontSize="sm"
            >
              <Box as="span" ml="1">
                <FaRegQuestionCircle fontSize="12" />
              </Box>
            </Tooltip>
          </Flex>
        </StatLabel>
        <StatNumber fontSize="4xl">{Math.round(rmssd)}</StatNumber>
        {/* <StatHelpText>
          <StatArrow type="decrease" />
          9.05% from last session
        </StatHelpText> */}
      </Stat>
      <Stat>
        <StatLabel>
          <Flex alignItems="center">
            Pre-session Survey
            <Tooltip
              label="This is the subjective score you input before each Flowly session."
              fontSize="sm"
            >
              <Box as="span" ml="1">
                <FaRegQuestionCircle fontSize="12" />
              </Box>
            </Tooltip>
          </Flex>
        </StatLabel>
        <StatNumber fontSize="4xl">
          {relaxation?.pre ? +relaxation.pre + "/10" : "N/A"}
        </StatNumber>
        {/* <StatHelpText>
          <StatArrow type="increase" />
          23.36% from last session
        </StatHelpText> */}
      </Stat>
      <Stat>
        <StatLabel>
          <Flex alignItems="center">
            Post-session Survey
            <Tooltip
              label="This is the subjective score you input after each Flowly session. Flowly members typically report an average increase of 33% or more in relaxation after each session."
              fontSize="sm"
            >
              <Box as="span" ml="1">
                <FaRegQuestionCircle fontSize="12" />
              </Box>
            </Tooltip>
          </Flex>
        </StatLabel>
        <StatNumber fontSize="4xl">
          {relaxation?.post ? +relaxation.post + "/10" : "N/A"}
        </StatNumber>
        {/* <StatHelpText>
          <StatArrow type="decrease" />
          9.05% from last session
        </StatHelpText> */}
      </Stat>
    </SimpleGrid>
  </StatGroup>
);

export default SessionStatistics;
