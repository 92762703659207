import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";

/** local imports */
import { PhoneNumberInput } from "./PhoneNumberInput";

const AddressElements = ({
  state,
  handleChange,
  elements,
  errors,
  required,
}) => {
  const context = { state, handleChange, errors, required };
  const { name = null, phone = null, streets = null, region = null } = elements;

  return (
    <>
      {name || phone ? (
        <PersonalInfo {...context} name={name} phone={phone} />
      ) : null}

      {streets ? (
        <Streets {...context} variant={streets?.variant ?? "flush"} />
      ) : null}
      {region ? <Region {...context} /> : null}
    </>
  );
};
export default AddressElements;

const PersonalInfo = ({
  state,
  handleChange,
  errors = { name: null, phone: null },
  required = { name: null, phone: null },
}) => {
  const direction = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Stack
      direction={direction}
      spacing={direction === "column" ? 6 : 2}
      w="full"
    >
      <FormControl
        id="fullName"
        isInvalid={!!errors["name"]}
        isRequired={required["name"]}
      >
        <FormLabel fontWeight="semibold">Full Name</FormLabel>
        <Input
          name="fullName"
          type="text"
          value={state.name}
          onChange={handleChange("name")}
          placeholder="Full Name"
          size="lg"
        />
        {!!errors["name"] && (
          <FormErrorMessage>{errors["name"]}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl
        id="phone"
        isInvalid={!!errors["phone"]}
        isRequired={required["phone"]}
      >
        <FormLabel fontWeight="semibold">Mobile Phone</FormLabel>
        <PhoneNumberInput
          size="lg"
          id="phone"
          name="phone"
          value={state.phone}
          onChange={handleChange("phone")}
          placeholder="050 123 4567"
          isInvalid={!!errors["phone"]}
          required={required["phone"]}
        />
        {!!errors["phone"] && (
          <FormErrorMessage>{errors["phone"]}</FormErrorMessage>
        )}
      </FormControl>
    </Stack>
  );
};
const Streets = ({ state, handleChange, variant = "flush" }) => {
  if (variant === "split")
    return (
      <>
        <FormControl id="addr1">
          <FormLabel fontWeight="semibold">Address Line 1</FormLabel>
          <Input
            name="addr1"
            type="text"
            value={state.addr1}
            onChange={handleChange("addr1")}
            required
            placeholder=""
            size="lg"
          />
        </FormControl>
        <FormControl id="addr2">
          <FormLabel fontWeight="semibold">Address Line 2</FormLabel>
          <Input
            name="addr2"
            type="text"
            value={state.addr2}
            onChange={handleChange("addr2")}
            placeholder=""
            size="lg"
          />
        </FormControl>
      </>
    );

  return (
    <FormControl id="address">
      <FormLabel mb={1} fontWeight="semibold">
        Address
      </FormLabel>
      <Input
        name="addr1"
        type="text"
        value={state.addr1}
        onChange={handleChange("addr1")}
        required
        // placeholder=""
        size="lg"
        borderBottomRadius="none"
        placeholder="Address Line 1"
      />
      <Input
        name="addr2"
        type="text"
        value={state.addr2}
        onChange={handleChange("addr2")}
        //  placeholder=""
        size="lg"
        mt="-px"
        borderTopRadius="none"
        placeholder="Address Line 2"
      />
    </FormControl>
  );
};
const Region = ({ state, handleChange }) => {
  return (
    <>
      <HStack>
        <FormControl id="city">
          <FormLabel fontWeight="semibold">City</FormLabel>
          <Input
            name="city"
            type="text"
            value={state.city}
            onChange={handleChange("city")}
            required
            placeholder=""
            size="lg"
          />
        </FormControl>
        <FormControl id="state">
          <FormLabel fontWeight="semibold">State</FormLabel>
          <Input
            name="state"
            type="text"
            value={state.state}
            onChange={handleChange("state")}
            required
            placeholder=""
            size="lg"
          />
        </FormControl>
        <FormControl id="zip">
          <FormLabel fontWeight="semibold">Zip Code</FormLabel>
          <Input
            name="zip"
            type="text"
            value={state.zip}
            onChange={handleChange("zip")}
            required
            placeholder=""
            size="lg"
          />
        </FormControl>
      </HStack>
      <FormControl id="country">
        <FormLabel fontWeight="semibold">Country</FormLabel>
        <Select
          name="country"
          type="text"
          value={state.country}
          onChange={handleChange("country")}
          required
          defaultValue="United States"
          size="lg"
        >
          <option value="United States">United States</option>
        </Select>
      </FormControl>
    </>
  );
};
