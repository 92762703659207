import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/layout";
// import { MenuItem, MenuList, Menu, MenuButton } from "@chakra-ui/menu";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/input";
import { Spinner } from "@chakra-ui/spinner";
import Icon from "@chakra-ui/icon";
import { useDisclosure } from "@chakra-ui/hooks";

/* icon imports */
import { FiSearch } from "react-icons/fi";

/* local imports */
import { PORTAL_ROOT } from "Routes";
import { client } from "utils/awsConfig";
import { SEARCH_USER_BY_EMAIL, SEARCH_USER_BY_USERNAME } from "graphql/queries";
import { getCoachLinkedPatients } from "pages/Coach/services";
import { useAuth } from "utils/AuthContext";

const ChatSearch = (props) => {
  const { handleSelectedResult = null, size, inputProps, ...rest } = props;
  const [{ user }] = useAuth();
  const currentUserId = user?.id;

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { isOpen, onClose, onOpen } = useDisclosure();
  const listRef = useRef();
  const firstResultRef = useRef();
  const fetchUserList = async (searchStr) => {
    setLoading(true);
    setUsers([]);
    try {
      const searchByEmail = await client.query({
        query: SEARCH_USER_BY_EMAIL,
        variables: {
          email: searchStr,
          limit: 10,
          filter: {
            coachIds: {
              contains: currentUserId,
            },
          },
        },
      });
      const searchByUsername = await client.query({
        query: SEARCH_USER_BY_USERNAME,
        variables: {
          preferredUsername: searchStr,
          filter: {
            coachIds: {
              contains: currentUserId,
            },
          },
        },
      });

      let usersFound = [];
      if (searchByEmail.data.usersByEmail.items.length) {
        usersFound.push(...searchByEmail.data.usersByEmail.items);
      }
      if (searchByUsername.data.usersByUsername.items.length) {
        usersFound.push(...searchByUsername.data.usersByUsername.items);
      }

      const searchByConversation = await getCoachLinkedPatients({
        variables: {
          coachId: user.id,
          filter: {
            or: usersFound.map((user) => ({
              userId: { eq: user.id },
            })),
          },
        },
      });

      const coachUserLinks = searchByConversation.data.getCoach.patients.items;
      console.log(
        "linksFound",
        searchByConversation.data.getCoach.patients.items
      );
      usersFound = usersFound
        .filter((user) =>
          coachUserLinks.find((link) => link.userId === user.id)
        )
        .map((user) => ({
          ...user,
          conversationId: coachUserLinks.find((link) => link.userId === user.id)
            ?.id,
        }));

      console.log("usersFound", usersFound);
      setUsers(usersFound);
    } catch (error) {
      console.error("[ChatSearch]: Search query failed", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedAPICall = useCallback(
    _.debounce((query) => fetchUserList(query), 750),
    []
  );
  const handleInputChange = (evt) => {
    const searchStr = evt.target.value;
    setSearchQuery(searchStr);
    if (searchStr.length) {
      debouncedAPICall(searchStr.trim());
    } else {
      onClose();
    }
  };
  useEffect(() => {
    if (users.length) onOpen();

    return () => {
      onClose();
    };
  }, [users]);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      closeOnEsc={true}
      placement="bottom-start"
      closeOnBlur={true}
      offset={[0, 2]}
      initialFocusRef={firstResultRef}
      matchWidth={true}
    >
      <PopoverTrigger>
        <InputGroup {...inputProps}>
          <Input
            w={size ? size : "sm"}
            placeholder="Find Hero"
            fontWeight="medium"
            value={searchQuery}
            onChange={handleInputChange}
            onFocus={() => {
              if (searchQuery.length) onOpen();
            }}
            onBlur={(evt) => {
              const listIsFocused = evt.relatedTarget === listRef?.current;
              if (!listIsFocused && !users.length) onClose();
            }}
            {...inputProps}
          />
          <InputRightElement>
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <Icon as={FiSearch} color="gray.500" />
            )}
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      {!!(users.length || searchQuery.length || !loading) && (
        <PopoverContent
          ref={listRef}
          w={size ? size : "sm"}
          py="2"
          maxH="350px"
          zIndex="popover"
          bg="white"
          shadow="base"
        >
          {users.length ? (
            users.map((user, idx) =>
              handleSelectedResult ? (
                <Flex
                  ref={idx === 0 ? firstResultRef : null}
                  key={user.id}
                  py="2"
                  px="4"
                  mx="2"
                  rounded="md"
                  cursor="pointer"
                  _hover={{
                    bg: "gray.100",
                    color: "gray.700",
                    ariaSelected: true,
                  }}
                  onClick={() => {
                    handleSelectedResult(user);
                    onClose();
                  }}
                >
                  <Text>{`${user.firstName} ${user.lastName}`}</Text>
                </Flex>
              ) : (
                <Link key={user.id} to={`${PORTAL_ROOT}/users/${user.id}`}>
                  <Flex
                    ref={idx === 0 ? firstResultRef : null}
                    py="2"
                    px="4"
                    mx="2"
                    rounded="md"
                    cursor="pointer"
                    _hover={{
                      bg: "gray.100",
                      color: "gray.700",
                      ariaSelected: true,
                    }}
                  >
                    <Text>{`${user.firstName} ${user.lastName}`}</Text>
                  </Flex>
                </Link>
              )
            )
          ) : searchQuery.length ? (
            <Flex
              px="2"
              fontSize="sm"
              align="center"
              justify="center"
              fontStyle="italic"
            >
              <Text>Nothing found, try again</Text>
            </Flex>
          ) : null}
        </PopoverContent>
      )}
    </Popover>
  );
};
export default ChatSearch;
