import React, { useEffect } from "react";
import { Box, VStack, Grid, GridItem, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

/* local imports */
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";
import { removeItemLocalStorage } from "utils/localStorage";

const InviteComplete = ({ formState }) => {
  useEffect(() => {
    removeItemLocalStorage(["promo", "referrer"]);
  }, []);
  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <VStack mt="20" alignItems="flex-start" spacing={4}>
          <H1>All done!</H1>
          <Text fontSize="lg" color="gray.400">
            Welcome to <strong>{formState.guideOrganization}</strong> on Flowly
          </Text>
          {!!formState?.orderComplete && (
            <Text fontSize="lg" color="gray.400">
              <b>Congratulations on becoming a Flowly Member!</b> <br />
              <br />
              We will be shipping out your Pro Kit very soon. Keep an eye out
              for emails from us regarding your shipping and tracking
              information.
              <br />
              <br />
              You can start exploring Flowly now! Your Intro Worlds can be
              experienced in VR or in 360 mode on your phone.
            </Text>
          )}
        </VStack>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        pt="16"
        pl="5vw"
        pr="10vw"
      >
        <Box display="grid" placeItems="center">
          <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default InviteComplete;
