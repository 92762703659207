import React, { useState } from "react";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";
import { Box, Flex, Grid, GridItem, Text, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Input } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { useLocation, Redirect, useHistory } from "react-router-dom";

/* icon imports */

/* local imports */
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";
import { PORTAL_ROOT } from "../../Routes";
import { useAuth } from "utils/AuthContext";
import { client } from "utils/awsConfig.js";
import { parseRole } from "utils/authHelpers";

import { CREATE_GUIDE } from "graphql/mutations";

const CompleteProfile = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [, authDispatch] = useAuth();

  const [redirectToReferrer, setR2R] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [accountState, setAccountState] = useState({
    name: "",
    email: "",
    password: "",
    org_name: "",
  });
  const handleChange = (property) => (e) =>
    setAccountState((prev) => ({ ...prev, [property]: e.target.value }));

  const loadCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const userObj = {
          name: user.attributes.given_name,
          email: user.attributes.email,
          role: parseRole(
            user.signInUserSession.accessToken.payload["cognito:groups"]
          ),
        };
        window.analytics.identify(user.username, userObj);
        // setAccountState((prev) => ({ ...prev, ...userObj }));
        authDispatch({
          type: "LOGIN",
          payload: { isAuthenticated: true, user: userObj },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createGuide = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await client.mutate({
        mutation: CREATE_GUIDE,
        variables: {
          input: {
            id: user.username,
            organizationName: accountState.org_name,
          },
        },
      });

      await loadCurrentUser();
      window.analytics.track("logged_in");
      firebase.analytics().logEvent("login");
      setTimeout(() => {
        setR2R(true);
      }, 1000);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const skipToDashboard = () => history.push(`${PORTAL_ROOT}`);

  return (
    <Box
      height="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      {redirectToReferrer ? (
        <Redirect to={state?.from?.pathname ?? `${PORTAL_ROOT}/data`} />
      ) : (
        <Grid gridTemplateColumns="repeat(12, 1fr)" height="full">
          {/* Left */}
          <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
            <form onSubmit={createGuide}>
              <VStack mt="20" alignItems="flex-start" spacing={4}>
                <VStack alignItems="flex-start" spacing={1}>
                  <H1>Become a Flowly Guide today!</H1>
                  <Text fontSize="xl" color="gray.400">
                    We love getting to know our guides.
                  </Text>
                </VStack>

                <FormControl id="org_name">
                  <FormLabel fontWeight="semibold">Organization Name</FormLabel>
                  <Input
                    name="org_name"
                    type="text"
                    value={accountState.org_name}
                    onChange={handleChange("org_name")}
                    required
                    placeholder="Organization Name"
                    size="lg"
                  />
                </FormControl>
                <Flex alignItems="center">
                  <Button
                    type="submit"
                    onClick={createGuide}
                    variant="solid"
                    bg="#5072ec"
                    colorScheme="blue"
                    rounded="xl"
                    height="100%"
                    isLoading={loading}
                    loadingText={"Upgrading your account..."}
                    py="1rem"
                    px="110px"
                  >
                    Upgrade
                  </Button>
                  <Button
                    type="submit"
                    onClick={skipToDashboard}
                    variant="ghost"
                    color="#5072ec"
                    colorScheme="blue"
                    rounded="xl"
                    height="100%"
                    py="1rem"
                    px="5rem"
                    ml="4"
                  >
                    Skip for now
                  </Button>
                </Flex>
              </VStack>
            </form>
          </GridItem>

          {/* Right */}
          <GridItem
            colSpan={6}
            display={{ base: "none", lg: "block" }}
            width="full"
            pt="16"
            pl="5vw"
            pr="10vw"
          >
            <Box display="grid" placeItems="center">
              <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
            </Box>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

export default CompleteProfile;
