import React from "react";
import { Box, Container, Grid, GridItem } from "@chakra-ui/react";

/** local imports */
import VisitUserProfile from "./components/VisitUserProfile";
import ManageSubscription from "./components/ManageSubscription";
import BecomeGuidePlaceholder from "./components/BecomeGuidePlaceholder";

const UserDashboard = () => {
  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      height="100%"
    >
      <Container maxW="8xl" pt={6} minHeight="calc(100vh - 4rem)">
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(4, 1fr)" }}
          gap={6}
          pb={12}
        >
          <GridItem colSpan={{ base: 4, sm: 2 }}>
            <VisitUserProfile />
          </GridItem>
          <GridItem colSpan={{ base: 4, sm: 2 }}>
            <ManageSubscription />
          </GridItem>
          {/* <GridItem colSpan={{ base: 3, lg: 1 }}>
            <BecomeGuidePlaceholder />
          </GridItem> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default UserDashboard;
