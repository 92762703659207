import React, { useState } from "react";
import {
  Box,
  Flex,
  VStack,
  HStack,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";

/* local imports */
import { checkOrgCode } from "../services";
import { STEPS } from "../constants";
import H1 from "common/H1";
import landscapeBg from "assets/gift/relaxation-northernlights-bg.jpg";
import ebbiVR from "assets/gift/EbbiVR.png";
import worldProgress from "assets/products/iphone-worldprogress.png";

const RedeemOrgLanding = ({
  navigate,
  handleChange,
  formState,
  updateFormState,
}) => {
  const [loading, setLoading] = useState(false);

  const next = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      const { data: orgInfo } = await checkOrgCode(formState.orgCode);
      if (orgInfo) {
        console.log(orgInfo);
        updateFormState("orgId", orgInfo.id);
        updateFormState("orgName", orgInfo.orgName);
        updateFormState("subscription", orgInfo.subscriptionDescription);
        updateFormState("requiresKit", orgInfo.requiresKit);
        setTimeout(() => {
          navigate(STEPS.authDirect);
        }, 500);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={next} noValidate>
          <VStack mt="20" alignItems="flex-start" spacing={6}>
            <VStack alignItems="flex-start" spacing={2}>
              <H1>
                Become a Flowly Hero & benefit from the #1 VR biofeedback app
              </H1>
              <Text fontSize="lg" color="gray.500">
                Welcome to Flowly! Flowly helps you and your team change your relationship to your pain, anxiety, and sleep.
              </Text>
            </VStack>
            <FormControl id="email">
              <FormLabel fontWeight="semibold">
                Enter your organization code to get started.
              </FormLabel>
              <HStack>
                <PinInput
                  type="alphanumeric"
                  size="lg"
                  onComplete={handleChange("orgCode")}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </FormControl>
            <Flex alignItems="center" w="full">
              <Button
                type="submit"
                onClick={next}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                disabled={!formState.orgCode.length}
                isLoading={loading}
                loadingText={"Finding your organization..."}
                py="1rem"
                w={["full", "fit-content"]}
                px="20"
              >
                Next
              </Button>
            </Flex>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
          pl="10%"
        >
          <Image src={ebbiVR} maxW="16rem" alt="welcome-to-flowly" zIndex={2} />
          <Image
            position="relative"
            src={worldProgress}
            top="-15"
            left="-12"
            transform="rotateY(17deg) translate(-2rem, -2rem)"
            maxW="16rem"
            alt="Flowly world progress"
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemOrgLanding;
