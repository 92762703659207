import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Elements } from "@stripe/react-stripe-js";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useParams } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import TagManager from "react-gtm-module";
import { Auth } from "aws-amplify";

/* local imports */
import Shipping from "./Shipping";
import Payment from "./Payment";
import Account from "./Account";
import OrderDetails from "./OrderDetails";

import H1 from "common/H1";
import done from "assets/checkout/done.svg";
import stripePromise from "utils/stripeConfig";
import { device } from "utils/device";
import Loading from "common/Loading";
import ShipStation from "utils/shipstation";
import { publicClient } from "utils/awsConfig";
import { GET_SUBSCRIPTION_PRODUCT_BY_ID } from "graphql/queries";
import { SUBSCRIPTION_PLAN_TYPES, SUBSCRIPTION_TIME_UNITS } from "./constants";

const CheckoutStyle = styled.div`
  background: linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh);
  min-height: 100vh;
  .row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .column {
    grid-column: span 12;
    @media ${device.laptop} {
      grid-column: span 6;
    }
  }

  .header {
    margin-top: 70px;
    margin-bottom: 2.81rem;
  }

  .left {
    grid-row: 2;
    @media ${device.laptop} {
      grid-row: auto;
    }
    background-color: #fff;
    padding-left: 10vw;
    padding-right: 5vw;
    min-height: 100vh;
    padding-bottom: 5vw;
  }
  .right {
    padding-right: 10vw;
    padding-left: 5vw;
    padding-bottom: 5vw;
  }

  input {
    font-family: Nunito;
    font-size: 16px;
    line-height: 1.5rem;
    padding: 12px 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #92a7b5;
    border-radius: 5px;
    flex: 1;
  }
  input::placeholder {
    color: #8892ad;
  }

  .line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .line > * {
    margin-left: 4px;
    margin-right: 4px;
    width: 100%;
  }

  .line > :first-child {
    margin-left: 0px;
  }
  .line > :last-child {
    margin-right: 0px;
  }
  select {
    display: block;
    width: 100%;
    appearance: none;

    font-family: Nunito;
    font-size: 16px;
    line-height: 1.5rem;
    padding: 12px 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #92a7b5;
    border-radius: 5px;
    flex: 1;
  }
  .promo-title {
    font-family: "Tommy Soft";
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 26px;
    color: #5072ec;
    img {
      margin-bottom: -0.35em;
    }
  }
  .promo-code {
    width: 70%;
  }
`;

const AccordionHeaderStyle = styled.div`
  display: flex;
  .title {
    font-family: "Tommy Soft";
    font-size: 1.5rem;
    line-height: 1.87rem;
    padding-bottom: 8px;
    color: #464e64;
    flex: 1;
    img {
      margin-bottom: -0.25em;
    }
  }
  .edit {
    text-align: right;
    font-family: Nunito;
    font-size: 18px;
    line-height: 1.5rem;
    color: #5072ec;
    cursor: pointer;
  }
`;

const AccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c0cff9;
  margin-bottom: 1.25rem;
  padding-bottom: 12px;

  &.hidden * {
    display: none;
  }
`;

function Checkout(props) {
  const { subscriptionType, subscriptionProductId } = useParams();
  const { state: { from = "", selected } = { from: "", selected: null } } =
    useLocation();

  TagManager.dataLayer({
    dataLayerName: "CheckoutDataLayer",
  });

  const [promo] = useLocalStorageState("promo");
  const [referrer] = useLocalStorageState("referrer");

  const [selectedPlan, setSelectedPlan] = useState(
    () =>
      selected ?? {
        id: subscriptionProductId,
        subscriptionType,
        price: 0,
        timeUnits: SUBSCRIPTION_TIME_UNITS.MONTH,
        initialPrice: 0,
      }
  );

  const [shippingPrice, setShippingPrice] = useState(null);

  const [user, setUser] = useState(null);
  const [orderDetails, setOrderDetails] = useState({
    promoApplied: false,
    promoCode: promo,
    total: selectedPlan?.price ?? 0,
    subscriptionType,
    discount: 0,
    discountType: null,
    discountDuration: "",
    referralCode: referrer,
  });
  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "United States",
  });

  const [step, setCheckoutStep] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProductInfo = async () => {
      setLoading(true);
      try {
        console.log("getting list of products");
        const subscriptionProduct = await publicClient.query({
          query: GET_SUBSCRIPTION_PRODUCT_BY_ID,
          variables: {
            id: subscriptionProductId,
          },
        });

        const productDetails = subscriptionProduct.data.getSubscriptionProduct;
        setSelectedPlan(productDetails);
      } catch (error) {
        console.error(
          `[SubscriptionProduct]: Fetch Product of ID ${subscriptionProductId} failed`,
          error
        );
      } finally {
        setLoading(false);
      }
    };

    if (!selected) {
      fetchProductInfo();
    }
  }, []);

  const onAuth = (user) => {
    window.analytics.track("account_submitted");
    setUser(user);
    setCheckoutStep(1);
  };

  const retries = useRef(0);

  const registerShippingAddress = (address) => {
    if (selectedPlan.subscriptionPlan === SUBSCRIPTION_PLAN_TYPES.COMMUNITY)
      return setCheckoutStep(2);

    window.analytics.track("shipping_address_submitted");
    setLoading(true);
    ShipStation.shipments
      .getRates({
        toState: address.state,
        toPostalCode: address.zip,
        toCity: address.city,
      })
      .then((res) => {
        if (res.data.length) {
          const [{ shipmentCost }] = res.data;
          setShippingAddress((prev) => ({ ...prev, ...address }));
          setShippingPrice(shipmentCost);
          setCheckoutStep(2);
          return;
        } else {
          throw new Error(
            "We couldn't process your shipping address. Please check your entries and try again."
          );
        }
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          return alert("It looks like you're not connected to the internet.");
        }

        ++retries.current;

        if (retries.current > 1) {
          retries.current = 0;
          setShippingPrice(12.0);
          setCheckoutStep(2);
          return alert(
            "Looks like we're unable to validate your shipping address. That's okay, we've gone ahead and applied a flat shipping rate so you may proceed. Please ensure your address is correct."
          );
        }

        return alert(
          "We couldn't process your shipping address. Please check your entries and try again."
        );
      })
      .finally(() => setLoading(false));
  };

  const onPaymentSuccess = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userSub = user.attributes.sub;

    window.analytics.track("Order Completed", {
      orderId: uuidv4(),
      total: orderDetails.total,
    });
    window.dataLayer.push({
      orderId: `${userSub}_${Date.now()}`,
      amount: orderDetails.total,
    });

    const confirmationPageState = {
      email: user?.attributes?.email ?? "",
      name: user?.attributes?.given_name ?? "",
      username: user?.username ?? "",
    };
    if (from === "JOURNEY_CHECKOUT") {
      props.history.push({
        pathname: "/journey-confirmation",
        state: confirmationPageState,
      });
    } else {
      props.history.push({
        pathname: "/confirmation",
        state: confirmationPageState,
      });
    }
  };

  return (
    <>
      {loading ? <Loading /> : <></>}
      <CheckoutStyle>
        <div className="row">
          <div className="column left">
            <div className="header">
              <H1>Checkout</H1>
            </div>
            <AccordionHeader
              currentStep={step}
              index={0}
              setState={setCheckoutStep}
            >
              Create your flowly account
            </AccordionHeader>
            <AccordionContent className={step !== 0 ? "hidden" : ""}>
              <Account onNext={onAuth} />
            </AccordionContent>

            <AccordionHeader
              currentStep={step}
              index={1}
              setState={setCheckoutStep}
            >
              Enter shipping details
            </AccordionHeader>
            <AccordionContent className={step !== 1 ? "hidden" : ""}>
              <Shipping
                shipping={shippingAddress}
                setShipping={setShippingAddress}
                done={registerShippingAddress}
                // done={() => {
                //   setLoading(true);
                //   setTimeout(() => {
                //     setCheckoutStep(2);
                //     setLoading(false);
                //   }, 800);
                // }}
              />
            </AccordionContent>

            <AccordionHeader
              currentStep={step}
              index={2}
              setState={setCheckoutStep}
            >
              Enter payment method
            </AccordionHeader>
            <AccordionContent className={step !== 2 ? "hidden" : ""}>
              <Elements stripe={stripePromise}>
                <Payment
                  orderDetails={orderDetails}
                  shipping={shippingAddress}
                  shippingPrice={shippingPrice}
                  onPaymentSuccess={onPaymentSuccess}
                />
              </Elements>
            </AccordionContent>
          </div>
          <div className="column right">
            <OrderDetails
              showCouponField={from === "MAIN_CHECKOUT"}
              orderDetails={orderDetails}
              setOrderDetails={setOrderDetails}
              selectedPlan={selectedPlan}
              subscriptionType={subscriptionType}
              shippingPrice={shippingPrice}
            />
          </div>
        </div>
      </CheckoutStyle>
    </>
  );
}

export default Checkout;

const AccordionHeader = ({ children, currentStep, index, setState }) => (
  <AccordionHeaderStyle>
    <div className="title">
      {currentStep > index ? <img src={done} alt="done" /> : <>{index + 1}.</>}
      {children}
    </div>

    {currentStep > index && (
      <div
        className="edit"
        onClick={() => setState(index)}
        onKeyDown={() => setState(index)}
        role="button"
        tabIndex={0}
      >
        Edit
      </div>
    )}
  </AccordionHeaderStyle>
);
