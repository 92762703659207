import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import { get } from "lodash";

/* asset imports */
import vr from "assets/home/vr.png";
import support from "assets/home/support.png";
import analytics from "assets/home/analytics.png";
import screenshots from "assets/home/screenshots.png";
import check from "assets/home/hi_check-badge.svg";
import xcircle from "assets/home/x-circle.svg";
import coach from "assets/home/coach.png";
import community from "assets/home/community.png";

/* local imports */
import Button from "common/Button";
import Card from "common/Card";
import H2 from "common/H2";
import H3 from "common/H3";
import Spinner from "common/Spinner";
import useSubscriptionProducts from "hooks/useSubscriptionProducts";
import { SUBSCRIPTION_PLAN_TYPES } from "../Checkout/constants";
import {
  CardContent,
  Center,
  Feature,
  Features,
  HomeStyle,
  Tag,
  WhiteContainer,
} from "../Checkout/components";
import { Container, FeatureCard, Grid, GridItem, HeroStyle } from "./styles";
import usePartnerData from "./usePartnerData";
import {
  FLOWLY_COMMUNITY_FEATURES,
  FLOWLY_PRO_FEATURES,
} from "constants/featureList";

const PartnerLanding = () => {
  const { partnerId } = useParams();

  const { data: partnerDetails, isLoading: partnerLoading } = usePartnerData(
    partnerId,
    {
      variables: {
        urlSlug: partnerId,
      },
    }
  );

  const { data: products, isLoading: productsLoading } =
    useSubscriptionProducts(
      {
        variables: {
          filter: {
            or: get(
              partnerDetails,
              ["partnerDealConfiguration", "subscriptionDeals"],
              []
            ).map((prod) => ({ id: { eq: prod.subscriptionProductId } })),
          },
        },
      },
      [],
      {
        enabled: Boolean(
          get(partnerDetails, ["partnerDealConfiguration", "subscriptionDeals"])
        ),
      }
    );

  const productsToDisplay = useMemo(() => {
    if (partnerLoading) {
      return [];
    }
    const offeredProducts = get(
      partnerDetails,
      ["partnerDealConfiguration", "subscriptionDeals"],
      []
    );

    const activeOffers = offeredProducts
      .map((offeredProduct) => ({
        ...offeredProduct,
        ...products.find(
          (prod) => prod.id === offeredProduct.subscriptionProductId
        ), //merge with SubscriptionProduct from API
      }))
      .map((prod) => ({
        ...prod,
        ...(prod.subscriptionPlan
          ? prod.subscriptionPlan === SUBSCRIPTION_PLAN_TYPES.COMMUNITY
            ? { features: FLOWLY_COMMUNITY_FEATURES }
            : { features: FLOWLY_PRO_FEATURES }
          : {}), // merge with features
      }))
      .sort((a, b) => a.price - b.price);

    if (activeOffers.length === 0) {
      return [];
    }

    return activeOffers;
  }, [products, partnerDetails, partnerLoading]);

  useEffect(() => {
    firebase.analytics().logEvent(`partner_home_visited: [${partnerId}]`);
  }, []);

  const currentCommunityPlan = productsToDisplay.find(
    (prod) => prod.subscriptionPlan === SUBSCRIPTION_PLAN_TYPES.COMMUNITY
  );
  const currentProPlan = productsToDisplay.find(
    (prod) => prod.subscriptionPlan === SUBSCRIPTION_PLAN_TYPES.PRO
  );

  console.log({ currentCommunityPlan, currentProPlan });

  return (
    <>
      <HomeStyle>
        <HeroStyle>
          {productsLoading || partnerLoading ? (
            <Container maxWidth="2xl" mt="20vh">
              <Spinner />
            </Container>
          ) : productsToDisplay.length === 2 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PageHeader
                  title={partnerDetails?.partnerDealConfiguration?.header ?? ""}
                  subtitle={
                    partnerDetails?.partnerDealConfiguration?.subHeader ?? null
                  }
                />
              </div>
              <Container maxWidth="2xl">
                <Grid
                  templateColumns="1fr 1.4fr"
                  templateAreas="'community pro'"
                >
                  {currentCommunityPlan && (
                    <GridItem area="community">
                      <FeatureCard
                        borderRadius="1rem"
                        mt="2rem"
                        pt="2rem"
                        pb="1.5rem"
                        pl="1.5rem"
                        pr="3.5rem"
                        mr="0.2rem"
                        ml="0.2rem"
                      >
                        <CardContent>
                          <h1 className="header">Join the Community</h1>
                          <h3 className="featureDescription">
                            {currentCommunityPlan.dealDescription}
                          </h3>

                          <span className="priceContainer">
                            <h2 style={{ marginTop: "2rem", marginBottom: 0 }}>
                              {currentCommunityPlan?.dealPrice
                                ? "$" + currentCommunityPlan?.dealPrice
                                : "Free"}
                            </h2>
                          </span>
                          <div
                            style={{
                              marginBottom: "2rem",
                              marginTop: "2rem",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Button
                              outline
                              to={{
                                pathname: `/checkout/${currentCommunityPlan?.subscriptionType}/${currentCommunityPlan?.id}`,
                                state: currentCommunityPlan,
                                search: `?promo=${currentCommunityPlan?.promoCode}`,
                              }}
                            >
                              Get started
                            </Button>
                            {!!currentCommunityPlan?.price && (
                              <p
                                style={{
                                  color: "#9CA3AF",
                                  fontSize: "1rem",
                                }}
                              >
                                renews at ${currentCommunityPlan?.price} per{" "}
                                {currentCommunityPlan?.timeUnits?.toLowerCase() ||
                                  ""}
                              </p>
                            )}
                          </div>
                          {currentCommunityPlan?.features?.map(
                            (section, sectionIdx) => (
                              <div
                                className="checkmarks"
                                key={"COMMUNITY" + section.title}
                              >
                                <h3 className="subheader">{section.title}</h3>
                                {section.features.map((feature, idx) => (
                                  <PlanFeature
                                    key={idx}
                                    title={feature.title}
                                    description={feature.description}
                                    locked={feature.locked}
                                  />
                                ))}

                                {sectionIdx !==
                                  currentCommunityPlan?.features.length - 1 && (
                                  <hr className="lightDivider" />
                                )}
                              </div>
                            )
                          )}
                        </CardContent>
                      </FeatureCard>
                    </GridItem>
                  )}
                  {currentProPlan && (
                    <GridItem area="pro" ml="-10px" mr="-10px">
                      <Card>
                        <CardContent>
                          <div
                            style={{ display: "flex", marginBottom: "1rem" }}
                          >
                            <Tag>RECOMMENDED</Tag>
                          </div>
                          <h1 className="header">Change My Life Membership</h1>
                          <h3 className="featureDescription">
                            {currentProPlan.dealDescription}
                          </h3>

                          <span className="priceContainer">
                            <h2 style={{ marginTop: "2rem", marginBottom: 0 }}>
                              {currentProPlan?.dealPrice
                                ? "$" + currentProPlan?.dealPrice
                                : "Free"}
                            </h2>
                          </span>
                          <div
                            style={{
                              marginBottom: "2rem",
                              marginTop: "2rem",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Button
                              to={{
                                pathname: `/checkout/${currentProPlan?.subscriptionType}/${currentProPlan?.id}`,
                                state: currentProPlan,
                                search: `?promo=${currentProPlan?.promoCode}`,
                              }}
                            >
                              Get started
                            </Button>
                            {!!currentProPlan?.price && (
                              <p
                                style={{
                                  color: "#9CA3AF",
                                  fontSize: "1rem",
                                }}
                              >
                                renews at ${currentProPlan?.price} per{" "}
                                {currentProPlan?.timeUnits?.toLowerCase() || ""}
                              </p>
                            )}
                          </div>
                          {currentProPlan?.features?.map(
                            (section, sectionIdx) => (
                              <div
                                className="checkmarks"
                                key={"PRO" + section.title}
                              >
                                <h3 className="subheader">{section.title}</h3>
                                {section.features.map((feature, idx) => (
                                  <PlanFeature
                                    key={idx}
                                    title={feature.title}
                                    description={feature.description}
                                    locked={feature.locked}
                                  />
                                ))}

                                {sectionIdx !==
                                  currentProPlan?.features.length - 1 && (
                                  <hr className="lightDivider" />
                                )}
                              </div>
                            )
                          )}
                        </CardContent>
                      </Card>
                    </GridItem>
                  )}
                </Grid>
              </Container>
            </>
          ) : (
            <Container maxWidth="2xl" mt="2rem">
              <Grid
                templateColumns="1fr 1.4fr"
                templateAreas="'title planCard'"
              >
                <GridItem area="title" mr="2rem">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <PageHeader
                      title={
                        partnerDetails?.partnerDealConfiguration?.header ?? ""
                      }
                      subtitle={
                        partnerDetails?.partnerDealConfiguration?.subHeader ??
                        null
                      }
                    />
                  </div>
                </GridItem>
                {currentCommunityPlan && (
                  <GridItem area="planCard">
                    <Card>
                      <CardContent>
                        <h1 className="header">Join the Community</h1>
                        <h3 className="featureDescription">
                          {currentCommunityPlan.dealDescription}
                        </h3>

                        <span className="priceContainer">
                          <h2 style={{ marginTop: "2rem", marginBottom: 0 }}>
                            {currentCommunityPlan?.dealPrice
                              ? "$" + currentCommunityPlan?.dealPrice
                              : "Free"}
                          </h2>
                        </span>
                        <div
                          style={{
                            marginBottom: "2rem",
                            marginTop: "2rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Button
                            to={{
                              pathname: `/checkout/${currentCommunityPlan?.subscriptionType}/${currentCommunityPlan?.id}`,
                              state: currentCommunityPlan,
                              search: `?promo=${currentCommunityPlan?.promoCode}`,
                            }}
                          >
                            Get started
                          </Button>
                          {!!currentCommunityPlan?.price && (
                            <p
                              style={{
                                color: "#9CA3AF",
                                fontSize: "1rem",
                              }}
                            >
                              renews at ${currentCommunityPlan?.price} per{" "}
                              {currentCommunityPlan?.timeUnits?.toLowerCase() ||
                                ""}
                            </p>
                          )}
                        </div>
                        {currentCommunityPlan?.features?.map(
                          (section, sectionIdx) => (
                            <div
                              className="checkmarks"
                              key={"COMMUNITY" + section.title}
                            >
                              <h3 className="subheader">{section.title}</h3>
                              {section.features.map((feature, idx) => (
                                <PlanFeature
                                  key={idx}
                                  title={feature.title}
                                  description={feature.description}
                                  locked={feature.locked}
                                />
                              ))}

                              {sectionIdx !==
                                currentCommunityPlan?.features.length - 1 && (
                                <hr className="lightDivider" />
                              )}
                            </div>
                          )
                        )}
                      </CardContent>
                    </Card>
                  </GridItem>
                )}
                {currentProPlan && (
                  <GridItem area="planCard">
                    <Card>
                      <CardContent>
                        <h1 className="header">Change My Life Membership</h1>
                        <h3 className="featureDescription">
                          {currentProPlan.dealDescription}
                        </h3>

                        <span className="priceContainer">
                          <h2 style={{ marginTop: "2rem", marginBottom: 0 }}>
                            {currentProPlan?.dealPrice
                              ? "$" + currentProPlan?.dealPrice
                              : "Free"}
                          </h2>
                        </span>
                        <div
                          style={{
                            marginBottom: "2rem",
                            marginTop: "2rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Button
                            to={{
                              pathname: `/checkout/${currentProPlan?.subscriptionType}/${currentProPlan?.id}`,
                              state: currentProPlan,
                              search: `?promo=${currentProPlan?.promoCode}`,
                            }}
                          >
                            Get started
                          </Button>
                          {!!currentProPlan?.price && (
                            <p
                              style={{
                                color: "#9CA3AF",
                                fontSize: "1rem",
                              }}
                            >
                              renews at ${currentProPlan?.price} per{" "}
                              {currentProPlan?.timeUnits?.toLowerCase() || ""}
                            </p>
                          )}
                        </div>
                        {currentProPlan?.features?.map(
                          (section, sectionIdx) => (
                            <div
                              className="checkmarks"
                              key={"PRO" + section.title}
                            >
                              <h3 className="subheader">{section.title}</h3>
                              {section.features.map((feature, idx) => (
                                <PlanFeature
                                  key={idx}
                                  title={feature.title}
                                  description={feature.description}
                                  locked={feature.locked}
                                />
                              ))}

                              {sectionIdx !==
                                currentProPlan?.features.length - 1 && (
                                <hr className="lightDivider" />
                              )}
                            </div>
                          )
                        )}
                      </CardContent>
                    </Card>
                  </GridItem>
                )}
              </Grid>
            </Container>
          )}
        </HeroStyle>

        <WhiteContainer>
          <Features>
            <Feature>
              <img src={vr} alt="Virtual Reality" />
              <p>
                designed to relax and help you better manage your nervous system
              </p>
            </Feature>
            <Feature className="center">
              <img src={analytics} alt="Analytics" />
              <p>actually see how your body is doing in your data portal</p>
            </Feature>
            <Feature>
              <img src={support} alt="Support" />
              <p>in our in-app community and from our health coach</p>
            </Feature>
          </Features>

          <Center>
            <div className="image">
              <img src={screenshots} alt="App Screenshots" />
            </div>

            <div className="content">
              <H2>flowly app</H2>
              <p>
                {/* copy here <br /> */}
                <br />
              </p>
              <div className="item">
                <H3>Experience relaxation</H3>
                <p>
                  in immersive Virtual Reality worlds designed and tested for
                  optimal comfort and relaxation
                </p>
              </div>
              <div className="item">
                <H3>Balance your nervous system</H3>
                <p>
                  by re-learning stress responses through biofeedback methods to influence various body metrics including HR, HRV, and respiration in every session.
                </p>
              </div>
              <div className="item">
                <H3>Track your progress</H3>
                <p>
                  in a data portal that tracks your biometrics, streaks, and
                  journal notes.
                </p>
              </div>
            </div>
          </Center>

          <div className="support-message">
            <H2>We Support You</H2>
            <p>
              Our Flowly community is growing every day and we want to give you the access to tools, processes, methods, and support you need to feel supported on your health journey
            </p>
          </div>

          <div className="app-features">
            <div className="left">
              <H3>Access our Health Coach</H3>
              <p>
                Call or Text our Flowly Health coach to get help setting up,
                receive advice about how to best integrate Flowly into a ritual,
                and more.
              </p>
              <div className="image">
                <img src={coach} alt="Coach"></img>
              </div>
            </div>
            <div className="right">
              <H3>Share in our Community</H3>
              <p>Become part of our safe, restricted in-app community.</p>
              <div className="image">
                <img src={community} alt="Community"></img>
              </div>
            </div>
          </div>
        </WhiteContainer>
      </HomeStyle>
    </>
  );
};

export default PartnerLanding;

const PageHeader = ({ title, subtitle }) => {
  return (
    <>
      <h1 className="title">{title}</h1>
      <h2 className="subtitle">{subtitle}</h2>
    </>
  );
};

const PlanFeature = ({ title, description, locked = false }) => (
  <>
    <div className={locked ? "unchecked" : "check"}>
      <img
        src={locked ? xcircle : check}
        alt={locked ? "X icon" : "Checkmark"}
      />
      {title}
    </div>
    {description && <p className="featureDescription">{description}</p>}
  </>
);
