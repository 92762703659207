import React, { useState } from "react";
import { Box, VStack, Grid, GridItem, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spinner,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

/** asset imports */
import landscapeBg from "assets/gift/relaxation-northernlights-bg.jpg";
import ebbiVR from "assets/gift/EbbiVR.png";
import worldProgress from "assets/products/iphone-worldprogress.png";

/* local imports */
import { STEPS } from "../constants";
import H1 from "common/H1";
import useOrgData from "../useOrgData";
import { validateVoucher } from "../services";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";

const RedeemVoucherLanding = ({ navigate, updateFormState }) => {
  const {
    register,
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      voucherCode: "",
    },
  });

  const { orgSlug } = useParams();

  const { data: orgDetails, isLoading: orgLoading } = useOrgData(orgSlug, {
    variables: {
      urlSlug: orgSlug,
    },
  });

  const [loading, setLoading] = useState(false);

  const handleValidateVoucher = async (value) => {
    try {
      setLoading(true);
      await validateVoucher(value, orgDetails.id);
      return true;
    } catch (error) {
      return error.message;
    } finally {
      setLoading(false);
    }
  };

  const shouldTriggerValidation = debounce(
    async () => await trigger("voucherCode"),
    750
  );

  const onSubmit = async (data) => {
    const { voucherCode, orderReferenceNumber } = data;

    updateFormState(
      [
        "voucherCode",
        "orderReferenceNumber",
        "orgId",
        "orgName",
        "subscription",
        "requiresKit",
      ],
      [
        voucherCode,
        orderReferenceNumber,
        orgDetails.id,
        orgDetails.orgName,
        orgDetails.subscriptionDescription,
        orgDetails.requiresKit,
      ]
    );

    setTimeout(() => {
      navigate(STEPS.authDirect);
    }, 500);
  };

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        {orgLoading ? (
          <SkeletonView />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <VStack mt="20" alignItems="flex-start" spacing={10}>
              <VStack alignItems="flex-start" spacing={2}>
                <H1>
                  {orgDetails?.partnerDealConfiguration?.header
                    ? orgDetails?.partnerDealConfiguration?.header
                    : "Become a Flowly Hero & benefit from the #1 VR biofeedback app"}
                </H1>
                <Text fontSize="lg" color="gray.500">
                  {orgDetails?.partnerDealConfiguration?.subHeader
                    ? orgDetails?.partnerDealConfiguration?.subHeader
                    : "Welcome to Flowly! Flowly helps you and your team take immediate control of your pain, anxiety, and improve sleep."}
                </Text>
              </VStack>

              <VStack align="flex-start" w="full" spacing={4}>
                <Controller
                  control={control}
                  name="voucherCode"
                  rules={{
                    required: {
                      value: true,
                      message: "Voucher is required",
                    },
                    validate: {
                      validVoucher: handleValidateVoucher,
                    },
                  }}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      id="voucherCode"
                      isInvalid={!!error}
                      isRequired
                    >
                      <FormLabel fontWeight="semibold">Voucher Code</FormLabel>
                      <InputGroup>
                        <Input
                          {...rest}
                          name="voucherCode"
                          type="text"
                          onChange={(evt) => {
                            onChange(evt);
                            // Due to RHF's aggressive validation strategy (onChange calls API too many times), we set validation to only occur onSubmit &, using a debounce, trigger API validation manually
                            shouldTriggerValidation();
                          }}
                          required
                          placeholder="ABC1234456789"
                          size="lg"
                        />

                        <InputRightElement>
                          {loading ? <Spinner mt="1" size="sm" /> : null}
                        </InputRightElement>
                      </InputGroup>
                      {!!error && (
                        <FormErrorMessage>{error.message}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
                <FormControl id="orderReferenceNumber">
                  <FormLabel fontWeight="semibold">Reference Number</FormLabel>
                  <Input
                    {...register("orderReferenceNumber")}
                    name="orderReferenceNumber"
                    type="text"
                    placeholder="#1234-56789"
                    size="lg"
                  />
                </FormControl>
                <Button
                  type="submit"
                  // onClick={next}
                  variant="solid"
                  bg="#5072ec"
                  colorScheme="blue"
                  rounded="xl"
                  height="100%"
                  isDisabled={loading || !!errors["voucherCode"]}
                  loadingText={"Redeeming voucher..."}
                  py="1rem"
                  w={["full", "fit-content"]}
                  px="20"
                >
                  Redeem voucher
                </Button>
              </VStack>
            </VStack>
          </form>
        )}
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        position="relative"
      >
        <Image src={landscapeBg} height="full" alt="relaxation-landscape" />
        <Box
          display="flex"
          width="full"
          justifyContent="center"
          zIndex="overlay"
          position="absolute"
          top="0"
          pt="27%"
          pl="10%"
        >
          <Image src={ebbiVR} maxW="16rem" alt="welcome-to-flowly" zIndex={2} />
          <Image
            position="relative"
            src={worldProgress}
            top="-15"
            left="-12"
            transform="rotateY(17deg) translate(-2rem, -2rem)"
            maxW="16rem"
            alt="Flowly world progress"
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default RedeemVoucherLanding;

const SkeletonView = () => (
  <VStack mt="20" alignItems="flex-start" spacing={10}>
    <VStack align="flex-start" spacing={2}>
      <Skeleton rounded="lg">
        <H1>Become a Flowly Hero</H1>
      </Skeleton>
      <Skeleton rounded="lg">
        <Text fontSize="lg" color="gray.500">
          Welcome to Flowly! Flowly helps you and your team change your relationship to your pain, anxiety, and sleep.
        </Text>
      </Skeleton>
    </VStack>
    <VStack align="flex-start" w="full" spacing={4}>
      <FormControl id="voucherCode">
        <Skeleton rounded="lg" w="40%">
          <FormLabel fontWeight="semibold">Voucher Code</FormLabel>
        </Skeleton>
        <Skeleton rounded="lg">
          <Input
            name="voucherCode"
            type="text"
            placeholder="ABC1234456789"
            size="lg"
          />
        </Skeleton>
      </FormControl>
      <FormControl id="orderReferenceNumber">
        <Skeleton rounded="lg" w="40%">
          <FormLabel fontWeight="semibold">Reference Number</FormLabel>
        </Skeleton>
        <Skeleton rounded="lg">
          <Input
            name="orderReferenceNumber"
            type="text"
            placeholder="#1234-56789"
            size="lg"
          />
        </Skeleton>
      </FormControl>
      <Skeleton rounded="lg">
        <Button
          type="submit"
          variant="solid"
          bg="#5072ec"
          colorScheme="blue"
          rounded="xl"
          height="100%"
          loadingText={"Redeeming voucher..."}
          py="1rem"
          w={["full", "fit-content"]}
          px="20"
        >
          Redeem voucher
        </Button>
      </Skeleton>
    </VStack>
  </VStack>
);
