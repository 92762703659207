import React, { useRef, useState } from "react";
import { Link as ChakraLink, VStack, Text, HStack } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Select } from "@chakra-ui/select";

/* local imports */
import H1 from "common/H1";
import { PhoneNumberInput } from "common/PhoneNumberInput";
import ShipStation from "utils/shipstation";

const ShippingDetailsForm = ({
  handleChange,
  formState,
  updateFormState,
  next,
  goBack,
}) => {
  const [loading, setLoading] = useState(false);
  const validateShipping = (evt) => {
    evt.preventDefault();
    if (!formState?.name || formState?.name?.length < 1) {
      alert("Name cannot be left empty");
      return;
    }
    if (!formState?.phone || formState?.phone?.length < 1) {
      alert("Please enter a phone number");
      return;
    }
    if (!formState?.addr1 || formState?.addr1?.length < 1) {
      alert("Please enter an address");
      return;
    }
    if (!formState?.city || formState?.city?.length < 1) {
      alert("Please enter a City");
      return;
    }
    if (!formState?.state || formState?.state?.length < 1) {
      alert("Please enter a State");
      return;
    }
    if (!formState?.zip || formState?.zip?.length < 1) {
      alert("Zip code cannot be left empty");
      return;
    }
    if (formState?.zip?.length !== 5) {
      alert("Zip code must be 5 digits");
      return;
    }

    registerShippingAddress();
    // setLoading(true);
    // setTimeout(() => {
    //   next();
    //   setLoading(false);
    // }, 800);
  };

  const retries = useRef(0);

  const registerShippingAddress = () => {
    setLoading(true);
    window.analytics.track("shipping_address_submitted");
    ShipStation.shipments
      .getRates({
        toState: formState.state,
        toPostalCode: formState.zip,
        toCity: formState.city,
      })
      .then((res) => {
        if (res.data.length) {
          const [{ shipmentCost }] = res.data;
          updateFormState("shippingPrice", shipmentCost);
          next();
          return;
        } else {
          throw new Error(
            "Failed to process your shipping address. Please check your entries and try again."
          );
        }
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          return alert("It looks like you're not connected to the internet.");
        }

        ++retries.current;

        if (retries.current > 1) {
          retries.current = 0;
          updateFormState("shippingPrice", 12.0);
          next();
          return alert(
            "Looks like we're unable to validate your shipping address. That's okay, we've gone ahead and applied a flat shipping rate so you may proceed. Please ensure your address is correct."
          );
        }

        return alert(
          "We couldn't process your shipping address. Please check your entries and try again."
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <form onSubmit={validateShipping}>
      <VStack mt="20" alignItems="flex-start" spacing={4}>
        <H1>Checkout</H1>
        <Text
          fontSize="md"
          color="gray.400"
          opacity={0.76}
          textTransform="uppercase"
          fontFamily="Tommy Soft"
          fontWeight="bold"
          letterSpacing="0.12rem"
        >
          Shipping Details
        </Text>
        <HStack>
          <FormControl id="fullName">
            <FormLabel fontWeight="semibold">Full Name</FormLabel>
            <Input
              name="fullName"
              type="text"
              value={formState.name}
              onChange={handleChange("name")}
              required
              placeholder="Full Name"
              size="lg"
            />
          </FormControl>
          <FormControl id="phone">
            <FormLabel fontWeight="semibold">Mobile Phone</FormLabel>
            <PhoneNumberInput
              size="lg"
              name="phone"
              value={formState.phone}
              onChange={handleChange("phone")}
              placeholder="050 123 4567"
            />
          </FormControl>
        </HStack>
        <FormControl id="addr1">
          <FormLabel fontWeight="semibold">Address Line 1</FormLabel>
          <Input
            name="addr1"
            type="text"
            value={formState.addr1}
            onChange={handleChange("addr1")}
            required
            placeholder=""
            size="lg"
          />
        </FormControl>
        <FormControl id="addr2">
          <FormLabel fontWeight="semibold">Address Line 2</FormLabel>
          <Input
            name="addr2"
            type="text"
            value={formState.addr2}
            onChange={handleChange("addr2")}
            placeholder=""
            size="lg"
          />
        </FormControl>
        <HStack>
          <FormControl id="city">
            <FormLabel fontWeight="semibold">City</FormLabel>
            <Input
              name="city"
              type="text"
              value={formState.city}
              onChange={handleChange("city")}
              required
              placeholder=""
              size="lg"
            />
          </FormControl>
          <FormControl id="state">
            <FormLabel fontWeight="semibold">State</FormLabel>
            <Input
              name="state"
              type="text"
              value={formState.state}
              onChange={handleChange("state")}
              required
              placeholder=""
              size="lg"
            />
          </FormControl>
          <FormControl id="zip">
            <FormLabel fontWeight="semibold">Zip Code</FormLabel>
            <Input
              name="zip"
              type="text"
              value={formState.zip}
              onChange={handleChange("zip")}
              required
              placeholder=""
              size="lg"
            />
          </FormControl>
        </HStack>
        <FormControl id="country">
          <FormLabel fontWeight="semibold">Country</FormLabel>
          <Select
            name="country"
            type="text"
            value={formState.country}
            onChange={handleChange("country")}
            required
            defaultValue="United States"
            size="lg"
          >
            <option value="United States">United States</option>
          </Select>
        </FormControl>
        <HStack alignItems="center" spacing={8} flexWrap="wrap" maxW="full">
          <Button
            type="submit"
            maxW="full"
            onClick={validateShipping}
            variant="solid"
            bg="#5072ec"
            colorScheme="blue"
            rounded="xl"
            height="100%"
            isLoading={loading}
            loadingText={"Calculating shipping..."}
            py="1rem"
            px="110px"
          >
            Proceed to Payment
          </Button>
          <ChakraLink onClick={goBack} color="gray.400">
            Modify plan
          </ChakraLink>
        </HStack>
      </VStack>
    </form>
  );
};
export default ShippingDetailsForm;
