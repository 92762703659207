import React, { useMemo } from "react";
import { Box, Container, Grid, GridItem, VStack } from "@chakra-ui/layout";
import { Tabs, Tab, TabList, TabPanels, TabPanel } from "@chakra-ui/tabs";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { compareDesc, parseISO } from "date-fns/esm";

/* local imports */
import { getGuide } from "./constants";
import InviteHeroWidget from "./components/InviteHeroWidget";
import HeroListWidget from "./components/HeroListWidget";
import GuideCreateHeroWidget from "./components/GuideCreateHeroWidget";

import { SearchWidget } from "../Users/UserSearch";

import ErrorFallback from "common/ErrorFallback";
import { useAuth } from "utils/AuthContext";
import { ROLES, verifyCanAccess } from "utils/authHelpers";

const GuideDashboard = () => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["GetGuide"],
    queryFn: getGuide,
  });

  const invitations = useMemo(() => {
    const [invitations] = data;
    return invitations.items
      .filter((invite) => !invite.accepted)
      .sort((a, b) =>
        compareDesc(parseISO(a.lastSentDate), parseISO(b.lastSentDate))
      );
  }, [data]);
  const userList = useMemo(() => {
    const [, linkedUsers] = data;
    return linkedUsers.items.map((u) => ({ ...u.user, linkId: u.id }));
  }, [data]);

  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      height="100%"
    >
      <Container maxW="8xl" pt={6} minHeight="calc(100vh - 4rem)">
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(3, 1fr)" }}
          gap={6}
          pb={12}
        >
          <GridItem colSpan={{ base: 3, xl: 2 }}>
            <HeroListWidget
              invitations={invitations}
              userList={userList}
              loading={isFetching}
              onSuccess={refetch}
            />
          </GridItem>
          <GridItem colSpan={{ base: 3, xl: 1 }}>
            <VStack spacing={4}>
              <AddHeroWidget onSuccess={refetch} />
              <SearchWidget />
            </VStack>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default GuideDashboard;

const AddHeroWidget = ({ onSuccess }) => {
  const [{ user }] = useAuth();
  const userRole = user.role;

  const canGuideCreateUser = verifyCanAccess(userRole, ROLES.PILOT_CLINICIAN);

  const selectedStyles = {
    bg: "linkedin.500",
    color: "white",
  };
  return (
    <Tabs
      p={0}
      variant="soft-rounded"
      size="sm"
      bg="white"
      rounded="xl"
      shadow="md"
      width="full"
      isLazy
    >
      <TabList px={6} mt={6}>
        <StyledTab fontSize="xs" _selected={selectedStyles}>
          Invite Hero to Flowly
        </StyledTab>
        <StyledTab isDisabled={!canGuideCreateUser}>
          Create Hero Account
        </StyledTab>
      </TabList>
      <TabPanels>
        <TabPanel p={6}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <InviteHeroWidget onSuccess={onSuccess} />
          </ErrorBoundary>
        </TabPanel>
        <TabPanel p={6}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GuideCreateHeroWidget onSuccess={onSuccess} />
          </ErrorBoundary>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const StyledTab = (props) => (
  <Tab
    fontSize="xs"
    _selected={{
      bg: "linkedin.500",
      color: "white",
    }}
    _disabled={{
      color: "gray.400",
      cursor: "not-allowed",
    }}
    {...props}
  />
);
