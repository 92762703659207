import React from "react";
import {
  Avatar,
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  List,
  ListItem,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  formatRelative,
  format,
  isToday,
  isAfter,
  subDays,
} from "date-fns/esm";

/** icon imports */
import { IoSend } from "react-icons/io5";
import { BsCheckAll } from "react-icons/bs";

/** local imports */
import UserSearch from "common/UserSearch";
import { useMessageProviders, useMessages } from "./hooks";
import { sendMessage } from "./services";
import { getDisplayName } from "./utils";

const MessagingPortal = () => {
  const [activeSession, switchActiveSession] = React.useState(null);
  const [newChat, setNewChat] = React.useState(null);
  const { data: messages, isLoading, isError, refetch } = useMessages();
  const { data: messageProviders, isLoading: providersLoading } =
    useMessageProviders();

  const [chatInputs, setChatInputs] = React.useState({});

  const handleSwitchChat = (userNumber) => {
    if (newChat) {
      setNewChat(null);
      setChatInputs((prev) => ({ ...prev, "": "" }));
    }
    switchActiveSession(userNumber);
  };
  const isActiveChat = (userNumber) => userNumber === activeSession;

  React.useEffect(() => {
    if (activeSession === null && messages) {
      handleSwitchChat(messages[0]?.mobile);
    }
  }, [activeSession, messages]);

  const activeSessionDetails =
    messages?.find(({ mobile }) => mobile === activeSession) ?? {};

  const activeSessionNumber = activeSessionDetails?.mobile ?? "__newChat__";
  const activeSessionMessages = activeSessionDetails?.messages ?? [];
  const defaultMessageProvider = messageProviders?.[0]?.phoneNumber ?? null;

  const handleSendMessage = async (evt) => {
    evt.preventDefault();
    const toBeSent = chatInputs[activeSessionNumber];

    const payload = {
      from: defaultMessageProvider,
      body: toBeSent,
    };

    if (newChat) {
      sendMessage({
        ...payload,
        to: newChat?.recipient,
      });
    } else {
      sendMessage({
        ...payload,
        to: activeSessionNumber,
      });
    }

    setChatInputs((prev) => ({ ...prev, [activeSessionNumber]: "" }));
    refetch();
    handleSwitchChat(messages[0]?.mobile);
  };

  const handleNewMessageSession = (user) => {
    const { mobile } = user;

    if (messages.find((chat) => chat.mobile === mobile)) {
      return handleSwitchChat(mobile);
    }

    return setNewChat(user);
  };

  if (
    [
      isLoading,
      providersLoading,
      !messageProviders,
      !messages,
      isError,
    ].includes(true)
  ) {
    return (
      <Container maxW="8xl" pt="8">
        <Center rounded="3xl" bg="white" shadow="lg" overflow="hidden" h="2xl">
          <Box textAlign="center">
            <Spinner />
            {isError ? (
              <Text>
                We seem to be having some trouble reaching your messages, please
                wait while we try again...
              </Text>
            ) : (
              <Text>Fetching your messages...</Text>
            )}
          </Box>
        </Center>
      </Container>
    );
  }

  return (
    <Container maxW="8xl" pt="8">
      <Grid
        templateColumns={{ lg: "repeat(6, 1fr)" }}
        rounded="3xl"
        bg="white"
        shadow="lg"
        overflow="hidden"
        height="2xl"
      >
        <LeftPanel
          recentChats={messages}
          activeSession={activeSession}
          handleSwitchChat={handleSwitchChat}
          isActiveChat={isActiveChat}
          draftMessages={chatInputs}
          handleNewMessageSession={handleNewMessageSession}
          disableHighlightedChats={!!newChat}
        />
        <GridItem
          rounded="3xl"
          roundedLeft="none"
          shadow="xs"
          w="full"
          colSpan={{ lg: 4 }}
          display="flex"
          flexDirection="column"
          bg="blackAlpha.50"
          position="relative"
        >
          <HStack w="full" bg="white" p={6} shadow="xs">
            {newChat ? (
              <VStack align="flex-start" w="full">
                <Text fontSize="lg" fontWeight="bold">
                  New message with {getDisplayName(newChat)}, no registered
                  number available
                </Text>
                <InputGroup>
                  <InputLeftAddon>{"To:"}</InputLeftAddon>
                  <Input
                    w="full"
                    bg="white"
                    name="recipientNumber"
                    autoComplete="off"
                    value={newChat?.recipient ?? ""}
                    onChange={(evt) =>
                      setNewChat((prev) => ({
                        ...prev,
                        recipient: evt.target.value,
                      }))
                    }
                    onBlur={() => switchActiveSession(newChat?.recipient)}
                  />
                </InputGroup>
              </VStack>
            ) : (
              <Text fontWeight="bold">
                {getDisplayName(activeSessionDetails)}
              </Text>
            )}
          </HStack>
          <VStack
            spacing={2}
            flex={1}
            w="full"
            overflow="scroll"
            maxH="xl"
            p={6}
            pb="56"
          >
            {!newChat
              ? activeSessionMessages.map(({ sid, body, direction }) => {
                  return (
                    <ChatBubble key={sid} isInbound={direction === "inbound"}>
                      <Text>{body}</Text>
                    </ChatBubble>
                  );
                })
              : null}
          </VStack>

          <HStack
            bg="white"
            p="6"
            position="absolute"
            bottom="0"
            w="full"
            shadow="xs"
          >
            <form onSubmit={handleSendMessage} style={{ width: "100%" }}>
              <InputGroup>
                <Input
                  disabled={
                    !defaultMessageProvider ||
                    (!!newChat && !newChat?.recipient)
                  }
                  w="full"
                  bg="white"
                  key={activeSessionNumber}
                  name="messageContent"
                  autoComplete="off"
                  value={chatInputs[activeSessionNumber] ?? ""}
                  onChange={(evt) => {
                    setChatInputs((prev) => ({
                      ...prev,
                      [activeSessionNumber]: evt.target.value,
                    }));
                  }}
                />
                <InputRightElement>
                  <IconButton
                    type="submit"
                    icon={<Icon as={IoSend} />}
                    colorScheme="linkedin"
                    borderLeftRadius="none"
                    disabled={
                      !defaultMessageProvider ||
                      (!!newChat && !newChat?.recipient)
                    }
                  />
                </InputRightElement>
              </InputGroup>
            </form>
          </HStack>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default MessagingPortal;

const LeftPanel = ({
  recentChats,
  handleSwitchChat,
  isActiveChat,
  draftMessages,
  handleNewMessageSession,
  disableHighlightedChats,
}) => {
  return (
    <GridItem
      rounded="3xl"
      roundedRight="none"
      shadow="xs"
      w="full"
      colSpan={{ lg: 2 }}
    >
      <VStack spacing={4} p="4" borderBottom="1px" borderBottomColor="gray.200">
        <Header />
        <UserSearch
          size="full"
          setSelectedUser={(user) => handleNewMessageSession(user)}
        />
      </VStack>
      <List shadow="xs">
        {recentChats.map(({ mobile, messages, ...userProps }) => {
          const displayName = getDisplayName({ mobile, ...userProps });
          const latestMessage = messages[messages.length - 1];

          return (
            <ChatPerson
              key={mobile}
              isActive={disableHighlightedChats ? false : isActiveChat(mobile)}
              onClick={() => handleSwitchChat(mobile)}
              name={displayName}
              message={
                draftMessages[mobile]
                  ? `Draft: ${draftMessages[mobile]}`
                  : latestMessage?.body ?? ""
              }
              date={latestMessage?.dateSent}
              status={
                draftMessages[mobile] ? "draft" : latestMessage?.status ?? ""
              }
            />
          );
        })}
      </List>
    </GridItem>
  );
};

const Header = () => (
  <HStack justifyContent="space-between" w="full">
    <Heading fontSize="3xl">Messages</Heading>
  </HStack>
);

const ChatPerson = ({
  isActive = false,
  name = "",
  message = "",
  date = "",
  status,
  ...rest
}) => {
  const dateSentObj = new Date(date);
  const today = new Date();
  const dateStr = isToday(dateSentObj)
    ? format(dateSentObj, "hh:mm aa")
    : isAfter(dateSentObj, subDays(today, 7))
    ? format(dateSentObj, "EEEE")
    : formatRelative(dateSentObj, new Date());

  return (
    <ListItem
      as={HStack}
      rounded="none"
      bg={isActive ? "linkedin.50" : null}
      justifyContent="flex-start"
      spacing={4}
      px={6}
      py={4}
      {...rest}
    >
      <Avatar ml="0" name={name} src="" />
      <Flex flexDirection="column" pr="8" minW="calc(100% - 32px);">
        <Flex justify="space-between" align="baseline">
          <Text fontWeight="bold">{name}</Text>
          <Text fontSize="sm" color="#667781">
            {dateStr}
          </Text>
        </Flex>
        <HStack spacing={1}>
          {status !== "draft" ? (
            <Icon
              as={BsCheckAll}
              color={status === "delivered" ? "#53bdeb" : "#667781"}
            />
          ) : null}
          <Text
            color="#667781"
            fontSize="sm"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            fontStyle={status === "draft" ? "italic" : "normal"}
          >
            {message}
          </Text>
        </HStack>
      </Flex>
    </ListItem>
  );
};

const ChatBubble = ({ isInbound, children, ...rest }) => {
  return (
    <Box
      maxW="64"
      alignSelf={isInbound ? "flex-start" : "flex-end"}
      bg={isInbound ? "white" : "twitter.100"}
      p={3}
      px={6}
      rounded="xl"
      flexShrink={0}
      {...rest}
    >
      {children}
    </Box>
  );
};
