import React, { useMemo, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";

import NoDataAvailable from "common/NoDataAvailable";

const HRGraph = ({ data, introData }) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const introCleaned = useMemo(
    () =>
      introData
        ? introData
            .reduce(
              (acc, val, idx) => {
                acc["ts"] = acc["ts"] + idx;

                acc["vals"].push({
                  name: acc["ts"],
                  value: (60000 / val).toFixed(2),
                });

                return acc;
              },
              { ts: 0, vals: [] }
            )
            .vals.filter((dp) => dp.value < 500)
        : [],
    [introData]
  );
  const sessionData = useMemo(
    () =>
      data
        ? data
            .reduce(
              (acc, val, idx) => {
                acc["ts"] = acc["ts"] + idx;

                acc["vals"].push({
                  name: acc["ts"],
                  value: (60000 / val).toFixed(2),
                });

                return acc;
              },
              { ts: 0, vals: [] }
            )
            .vals.filter((dp) => dp.value < 500)
        : [],
    [data]
  );
  const minVal = sessionData.length
    ? Math.min(...sessionData.map((v) => v.value)) - 10
    : 0;
  const maxVal = sessionData.length
    ? Math.max(...sessionData.map((v) => v.value)) + 10
    : 256;

  const lastIntroBeat = sessionData.findIndex(
    (d) => d.name === introCleaned[introCleaned.length - 1].name
  );

  const percentage =
    100 - ((end - (lastIntroBeat - start)) * 100) / (end - start);

  const handleBrushChange = (evt) => {
    const { startIndex, endIndex } = evt;
    setStart(startIndex);
    setEnd(endIndex);
  };

  if (!sessionData.length) return <NoDataAvailable />;
  return (
    <ResponsiveContainer>
      <LineChart data={sessionData}>
        <defs>
          <linearGradient id="introBodySplit" x1="0%" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor="orange" />
            <stop offset={`${percentage}%`} stopColor="orange" />
            <stop offset={`${percentage}%`} stopColor="#00a0dc" />
            <stop offset={`${100}%`} stopColor="#00a0dc" />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis dataKey="name" minTickGap={20} tickFormatter={formatTime} />
        <YAxis
          axisLine={false}
          domain={[minVal, maxVal]}
          type="number"
          tickFormatter={(t) => t.toFixed(2)}
        />
        <Tooltip
          labelStyle={{ color: "#1a202c", fontWeight: 600 }}
          cursor={{ strokeDasharray: "2,2", color: "#4A5568" }}
          labelFormatter={formatTime}
        />
        <Brush
          dataKey="name"
          height={48}
          stroke="#00a0dc"
          tickFormatter={formatTime}
          startIndex={start}
          endIndex={end}
          onChange={handleBrushChange}
        />
        <Line
          type="basis"
          dataKey="value"
          stroke="url(#introBodySplit)"
          dot={null}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HRGraph;

function formatTime(timeMS) {
  const [MS_IN_SEC, SEC_IN_MIN] = [1000, 60];
  let seconds = Math.round(Math.abs(timeMS) / MS_IN_SEC);
  const minutes = Math.floor(seconds / SEC_IN_MIN);
  seconds = Math.floor(seconds % SEC_IN_MIN);
  const [mm, ss] = [minutes, seconds].map((item) =>
    item < 10 ? "0" + item : item.toString()
  );
  return mm + ":" + ss;
}
