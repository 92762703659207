import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz";

const timezones = Intl.supportedValuesOf("timeZone")
  .map((tz) => ({
    code: tz,
    city: tz.split("/")[tz.split("/").length - 1].replace(/_/g, " "),
    name: formatInTimeZone(new Date(), tz, "zzzz"),
    offset: formatInTimeZone(new Date(), tz, "z"),
  }))
  .sort((a, b) => getTimezoneOffset(a.code) - getTimezoneOffset(b.code));

export default timezones;
