import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const NoDataAvailable = ({ children, ...rest }) => {
  return (
    <Flex
      width="full"
      height="sm"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      {...rest}
    >
      {/* eslint-disable-next-line */}
      <Text color="gray.400">No data available</Text>
      {children}
    </Flex>
  );
};

export default NoDataAvailable;
