import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  FormLabel,
  Flex,
  Text,
  Input,
  useRadio,
  Box,
  useRadioGroup,
  HStack,
  VStack,
} from "@chakra-ui/react";

const defaultReward = "200";

export const CoinView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      Reward Heroes for their commitment with extra flokens!
    </Text>
  </Flex>
);

export const CoinEdit = ({
  state,
  handleChange,
  initialValue: defaultState,
}) => {
  const initialValue = _.get(defaultState, "totalRewardsAmount");

  const [selectedReward, setSelectedReward] = useState();
  const [rewardAmount, setRewardAmount] = useState(defaultReward);

  const options = ["100", "200", "2000", "custom"];

  const updateReward = (amount) => {
    handleChange("totalRewardsAmount")({
      target: {
        value: parseInt(initialValue) + +amount,
      },
    });
    handleChange("_transactionAmount")({
      target: {
        value: `${amount}`,
      },
    });
  };
  const debouncedUpdate = useCallback(_.debounce(updateReward, 200), []);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "totalRewardsAmount",
    defaultValue: defaultReward,
    onChange: (val) => {
      setSelectedReward(val);
      val !== "custom" ? updateReward(val) : updateReward(defaultReward);
    },
  });

  const group = getRootProps();

  useEffect(() => {
    if (state?.totalRewardsAmount === initialValue)
      updateReward(selectedReward ?? defaultReward);
  }, [state, initialValue]);

  useEffect(() => {
    updateReward(defaultReward);
    return () => {
      handleChange("totalRewardsAmount")({
        target: {
          value: parseInt(initialValue),
        },
      });
    };
  }, []);

  console.log(
    "Add Form",
    "state: ",
    state,
    "defaultState (drilled): ",
    defaultState
  );

  return (
    <VStack alignItems="flex-start">
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
      {selectedReward === "custom" ? (
        <FormControl id="rewardAmount">
          <FormLabel fontWeight="semibold">Enter Custom Amount</FormLabel>
          <Input
            name="rewardAmount"
            type="text"
            rounded="md"
            value={rewardAmount}
            onChange={(evt) => {
              const amount = evt.target.value;
              setRewardAmount(amount);
              if (amount.length && !isNaN(parseInt(amount)))
                debouncedUpdate(amount);
            }}
            size="sm"
          />
        </FormControl>
      ) : null}
    </VStack>
  );
};

export const CoinDeductView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      Made a mistake? No worries
    </Text>
  </Flex>
);
export const CoinDeductEdit = ({
  state,
  handleChange,
  initialValue: defaultState,
}) => {
  const initialValue = _.get(defaultState, "totalRewardsAmount");

  const [selectedReward, setSelectedReward] = useState();
  const [rewardAmount, setRewardAmount] = useState(defaultReward);

  const options = ["100", "200", "2000", "custom"];

  const updateReward = (amount) => {
    handleChange("totalRewardsAmount")({
      target: {
        value:
          parseInt(initialValue ?? "0") - +amount > 0
            ? parseInt(initialValue ?? "0") - +amount
            : 0,
      },
    });
    handleChange("_transactionAmount")({
      target: {
        value: `-${amount > initialValue ? initialValue : amount}`,
      },
    });
  };
  const debouncedUpdate = useCallback(_.debounce(updateReward, 200), []);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "totalRewardsAmount",
    defaultValue: defaultReward,
    onChange: (val) => {
      setSelectedReward(val);
      val !== "custom" ? updateReward(val) : updateReward(defaultReward);
    },
  });

  const group = getRootProps();

  useEffect(() => {
    if (state?.totalRewardsAmount === initialValue)
      updateReward(selectedReward ?? defaultReward);
  }, [state, initialValue]);

  useEffect(() => {
    updateReward(defaultReward);
    return () => {
      handleChange("totalRewardsAmount")({
        target: {
          value: parseInt(initialValue),
        },
      });
    };
  }, []);

  console.log(
    "Deduct Form",
    "state: ",
    state,
    "defaultState (drilled): ",
    defaultState
  );

  return (
    <VStack alignItems="flex-start">
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
      {selectedReward === "custom" ? (
        <FormControl id="deductAmount">
          <FormLabel fontWeight="semibold">Enter Custom Amount</FormLabel>
          <Input
            name="deductAmount"
            type="text"
            rounded="md"
            value={rewardAmount}
            onChange={(evt) => {
              const amount = evt.target.value;
              setRewardAmount(amount);
              if (amount.length && !isNaN(parseInt(amount)))
                debouncedUpdate(amount);
            }}
            size="sm"
          />
        </FormControl>
      ) : null}
    </VStack>
  );
};

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        mb="6"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="full"
        boxShadow="base"
        _checked={{
          bg: "linkedin.600",
          color: "white",
          borderColor: "linkedin.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        _hover={{
          boxShadow: "lg",
        }}
        px={4}
        py={2}
        fontSize="sm"
      >
        {props.children}
      </Box>
    </Box>
  );
}
