import { client } from "utils/awsConfig";
import { GET_EVENTS } from "graphql/queries";
import { CREATE_EVENT, DELETE_EVENT, UPDATE_EVENT } from "graphql/mutations";

export const getEvents = (...args) =>
  client.query({ query: GET_EVENTS, fetchPolicy: "network-only", ...args });

export const createEvent = (data) =>
  client.mutate({
    mutation: CREATE_EVENT,
    variables: {
      input: data,
    },
  });
export const updateEvent = (data) =>
  client.mutate({
    mutation: UPDATE_EVENT,
    variables: {
      input: data,
    },
  });
export const destroyEvent = (id) =>
  client.mutate({
    mutation: DELETE_EVENT,
    variables: {
      input: { id },
    },
  });
