export const setItemLocalStorage = (key, val) => {
  try {
    const serializedValue = JSON.stringify(val);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.log(`Failed to save ${key} in localStorage`, error);
  }
};
export const getItemLocalStorage = (key) => {
  try {
    const serializedValue = localStorage.getItem(key);
    return serializedValue ? JSON.parse(serializedValue) : undefined;
  } catch (error) {
    console.log(`No ${key} found in localStorage - or unable to parse`, error);
    return undefined;
  }
};
export const removeItemLocalStorage = (key) => {
  if (typeof key === "string") {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.log(`Unable to remove ${key} from localStorage.`, error);
    }
  }
  if (Array.isArray(key)) {
    key.forEach((k) => {
      removeItemLocalStorage(k);
    });
  }
  console.log("[RemoveItem Error]: Unknown key received", key);
};
