import React from "react";
import _ from "lodash";
import {
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Text,
  Input,
} from "@chakra-ui/react";

export const UserNameView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {`${content.firstName} ${content.lastName}`}
    </Text>
  </Flex>
);
export const UserNameEdit = ({ state, handleChange }) => {
  return (
    <HStack alignItems="baseline" spacing={4}>
      <FormControl id="firstName">
        <FormLabel fontWeight="semibold">First Name</FormLabel>
        <Input
          name="firstName"
          type="text"
          rounded="md"
          value={_.get(state, "firstName")}
          onChange={handleChange("firstName")}
          placeholder={_.get(state, "firstName")}
          required
          size="sm"
        />
      </FormControl>
      <FormControl id="lastName">
        <FormLabel fontWeight="semibold">Last Name</FormLabel>
        <Input
          name="lastName"
          type="text"
          rounded="md"
          value={_.get(state, "lastName")}
          onChange={handleChange("lastName")}
          placeholder={_.get(state, "lastName")}
          size="sm"
        />
      </FormControl>
    </HStack>
  );
};
