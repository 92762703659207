import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { Box } from "@chakra-ui/layout";
import { get } from "lodash";
import qs from "qs";

/* local imports */
import InviteLanding from "./components/InviteLanding";
import InviteRegister from "./components/InviteRegister";
import InviteLogin from "./components/InviteLogin";
import InviteSubscription from "./components/InviteSubscription";
import InviteCheckout from "./components/InviteCheckout";
import InviteComplete from "./components/InviteComplete";

export const STEPS = {
  landing: "landing",
  register: "register",
  login: "login",
  subscribe: "subscribe",
  shipping: "shipping",
  payment: "payment",
  thankyou: "thankyou",
};

const UserInvitation = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const [step, setStep] = useState(STEPS.landing);

  const navigate = useCallback(
    (selectedStep) => {
      history.push({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: selectedStep }),
      });
      setStep(STEPS[selectedStep]);
    },
    [url, currentParams, history]
  );

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    password: "",
    confirmation_code: "",
    step: "landing",
    country: "United States",
    shippingPrice: 12.0,
  });

  useEffect(() => {
    setFormState((prev) => ({ ...prev, ...currentParams }));
  }, [currentParams]);

  const handleInputChange = (property) => (e) =>
    setFormState((prev) => ({
      ...prev,
      [property]: get(e, ["target", "value"], e),
    }));

  const updateFormState = (property, value) =>
    setFormState((prev) => ({ ...prev, [property]: value }));

  const currentStep = useMemo(() => currentParams.step, [currentParams]);
  useEffect(() => {
    if (!currentStep) {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS.landing }),
      });
    } else {
      navigate(currentStep);
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS[currentStep] }),
      });
    }
  }, [currentStep]);

  console.log(formState);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <CurrentStep
        step={step}
        navigate={navigate}
        handleChange={handleInputChange}
        formState={formState}
        updateFormState={updateFormState}
      />
    </Box>
  );
};

export default UserInvitation;

const CurrentStep = ({ step, ...controls }) => {
  switch (step) {
    case STEPS.thankyou:
      return <InviteComplete {...controls} />;
    case STEPS.payment:
    case STEPS.shipping:
      return <InviteCheckout {...controls} />;
    case STEPS.subscribe:
      return <InviteSubscription {...controls} />;
    case STEPS.login:
      return <InviteLogin {...controls} />;
    case STEPS.register:
      return <InviteRegister {...controls} />;
    case STEPS.landing:
    default:
      return <InviteLanding {...controls} />;
  }
};
