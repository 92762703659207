import React from "react";
import styled from "styled-components";

const H1Style = styled.h1`
  font-family: "Tommy Soft";
  font-weight: 700;
  font-size: 2.26rem;
  line-height: 2.81rem;
  color: #5072ec;
`;
function H1({ children }) {
  return <H1Style>{children}</H1Style>;
}

export default H1;
