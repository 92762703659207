import gql from "graphql-tag";

export const CREATE_GUIDE = gql`
  mutation CreateGuide($input: CreateGuideInput!) {
    createGuide(input: $input) {
      id
      organizationName
      createdAt
      updatedAt
      user {
        id
        createdAt
        email
        firstName
        lastName
        updatedAt
      }
    }
  }
`;

export const CREATE_COACH = gql`
  mutation CreateCoach($input: CreateCoachInput!) {
    createCoach(input: $input) {
      id
    }
  }
`;
export const CREATE_COACH_USER_LINK = gql`
  mutation CreateCoachUserLink($input: CreateCoachUserLinkInput!) {
    createCoachUserLink(input: $input) {
      id
    }
  }
`;
export const ADMIN_UPDATE_USER = gql`
  mutation AdminUpdateUser($userId: ID!, $input: CognitoUserAttributesInput!) {
    adminUpdateUserAttributes(userId: $userId, input: $input) {
      id
      firstName
      lastName
      email
      preferredUsername
    }
  }
`;
export const ADMIN_SET_USER_PASSWORD = gql`
  mutation AdminSetUserPassword($userId: ID!, $input: PasswordUpdateInput!) {
    adminSetUserPassword(userId: $userId, input: $input) {
      result
      error
    }
  }
`;
export const ADMIN_UPDATE_USER_GROUP = gql`
  mutation AdminUpdateUserGroup(
    $userId: ID!
    $input: CognitoGroupUpdateInput!
  ) {
    adminUpdateUserGroup(userId: $userId, input: $input) {
      result
      error
    }
  }
`;
export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      createdAt
      streak
      resonantFrequency
      sessionsCompleted
      bestStreak
      badgeCollection
      currentExperiences
      mapOrder
      totalRewardsAmount
      totalRedeemedAmount
      address {
        address1
        address2
        city
        state
        zip
        country
      }
    }
  }
`;

export const CREATE_USERJOURNEYRECORD = gql`
  mutation CreateUserJourneyRecord($input: CreateUserJourneyRecordInput!) {
    createUserJourneyRecord(input: $input) {
      id
    }
  }
`;
export const UPDATE_USERJOURNEYRECORD = gql`
  mutation UpdateUserJourneyRecord($input: UpdateUserJourneyRecordInput!) {
    updateUserJourneyRecord(input: $input) {
      id
      currentExperiences
      mapOrder
      journey {
        id
        title
        hasStrictWorldProgression
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInfoInput!) {
    updateSubscriptionInfo(input: $input) {
      id
      subscriptionPlan
      subscriptionType
      subscriptionMethod
      subscriptionDate
      expirationDate
      stripeSubscriptionInfo {
        stripeCustomerId
        stripeSubscriptionId
      }
      appleSubscriptionInfo {
        appleTransactionId
        appleReceipt
        expirationDate
      }
      subscriptionProductId
      subscriptionProduct {
        subscriptionPlan
        subscriptionType
        subscriptionMethod
        subscriptionProductType
        active
        duration
        timeUnits
        price
      }
      appleTransactionId
      stripeCustomerId
      introPrice
      grossMRR
      netMRR
    }
  }
`;

export const REGISTER_USER_ON_DEMIO = gql`
  mutation RegisterOnDemio($demioRegistrationInput: DemioRegistrationInput) {
    registerOnDemio(demioRegistrationInput: $demioRegistrationInput) {
      result
      error
    }
  }
`;
export const INVITE_USER = gql`
  mutation InviteUser($input: GuideUserInvitationInput!) {
    inviteUser(input: $input) {
      id
      userEmail
      userFirstName
      userLastName
      guideId
      createdAt
      lastSentDate
      attempts
      accepted
    }
  }
`;

export const GUIDE_CREATE_USER = gql`
  mutation GuideCreateUser(
    $name: String!
    $email: String!
    $phone: String
    $birthdate: String!
    $temporaryPassword: String
    $notifyUser: Boolean
  ) {
    guideCreateUser(
      name: $name
      email: $email
      phone: $phone
      birthdate: $birthdate
      temporaryPassword: $temporaryPassword
      notifyUser: $notifyUser
    )
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptGuideInvitation($invitationId: String!) {
    acceptGuideInvitation(invitationId: $invitationId) {
      result
      error
    }
  }
`;

export const REMOVE_USER = gql`
  mutation DeleteGuideUserLink($input: DeleteGuideUserLinkInput!) {
    deleteGuideUserLink(input: $input) {
      id
      userId
      guideId
    }
  }
`;

export const SUBSCRIBE_USER = gql`
  mutation SUBSCRIBE_USER($input: SubscriptionInput!) {
    subscribe(input: $input) {
      userId
      subscriptionPlan
      subscriptionType
      subscriptionMethod
      error {
        code
        message
      }
    }
  }
`;

export const PURCHASE_GIFT = gql`
  mutation purchaseGift($input: PurchaseGiftInput!) {
    purchaseGift(input: $input) {
      result
      error
    }
  }
`;

export const REDEEM_GIFT = gql`
  mutation redeemGift($input: GiftRedemptionInput!) {
    redeemGift(input: $input) {
      result
      error
    }
  }
`;
export const GET_STRIPE_PAYMENT_INTENT = gql`
  mutation getStripePaymentSecret(
    $operation: StripePaymentOperation!
    $input: StripePaymentInput!
  ) {
    getStripePaymentSecret(operation: $operation, input: $input) {
      result
      error
    }
  }
`;

export const VALIDATE_ORG_CODE = gql`
  mutation checkOrganizationCode($organizationCode: String!) {
    checkOrganizationCode(organizationCode: $organizationCode) {
      id
      orgName
      subscriptionDescription
      requiresKit
      specialGroup
      error
    }
  }
`;

export const SUBSCRIBE_WITH_ORG = gql`
  mutation subscribeWithOrganization(
    $organizationId: ID!
    $options: SubscriptionOptions
  ) {
    subscribeWithOrganization(
      organizationId: $organizationId
      options: $options
    ) {
      result
      error
    }
  }
`;

export const GEN_STRIPE_PORTAL_LINK = gql`
  mutation getStripePortalLink($customerId: String) {
    getStripePortalLink(customerId: $customerId) {
      result
      error
    }
  }
`;

export const CREATE_CONSENT = gql`
  mutation createConsent($input: CreateConsentInput!) {
    createConsent(input: $input) {
      id
      signature
      ipAddress
      createdAt
      content
      form
      updatedAt
    }
  }
`;
export const CREATE_REWARDS_TRANSACTION = gql`
  mutation CreateUserRewardsTransaction(
    $input: CreateUserRewardsTransactionInput!
  ) {
    createUserRewardsTransaction(input: $input) {
      id
      userId
      type
      rewardAmount
      createdAt
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      title
      description
      joinLink
      signupLink
      startDate
      duration
      type
      imageURL
      permissionTier
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_EVENT = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      title
      description
      joinLink
      signupLink
      startDate
      duration
      type
      imageURL
      permissionTier
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_EVENT = gql`
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      id
    }
  }
`;

export const SEND_TEXT_MESSAGE = gql`
  mutation SendMessage($payload: UserMessagingInput) {
    sendTextMessage(payload: $payload) {
      result
      error
    }
  }
`;
export const SEND_INAPP_MESSAGE = gql`
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      userId
      recipientId
      conversationId
      createdAt
      updatedAt
      body
    }
  }
`;

export const CREATE_SHIPMENT = gql`
  mutation CreateShipment($input: CreateShipmentInput!) {
    createShipment(input: $input) {
      id
      userId
      createdAt
      status
      createdBy
      shipstationInfo
      updatedAt
      shouldCreateOrder
      user {
        id
        firstName
        lastName
        createdAt
      }
    }
  }
`;
export const UPDATE_SHIPMENT = gql`
  mutation UpdateShipment($input: UpdateShipmentInput!) {
    updateShipment(input: $input) {
      id
      userId
      createdAt
      status
      createdBy
      shipstationInfo
      updatedAt
      carrierCode
      serviceCode
      packageCode
      user {
        id
        firstName
        lastName
        createdAt
      }
    }
  }
`;
