import React from "react";
import { Global } from "@emotion/react";
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Tommy Soft",
    body: "Nunito",
  },
});

export default theme;

export const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Tommy Soft';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url('../assets/fonts/TommySoftRegular.woff') format('woff');
      }
      @font-face {
        font-family: 'Tommy Soft';
        font-style: medium;
        font-weight: medium;
        font-display: swap;
        src: url('../assets/fonts/TommySoftMedium.woff') format('woff');
      }
      @font-face {
        font-family: 'Tommy Soft';
        font-style: bold;
        font-weight: bold;
        font-display: swap;
        src: url('../assets/fonts/TommySoftBold.woff') format('woff');
      }
      `}
  />
);
