import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { get, debounce } from "lodash";
import { HStack, VStack, Flex, Badge, Text, Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
// import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Spinner } from "@chakra-ui/spinner";
import { IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { ErrorBoundary } from "react-error-boundary";
import { compareDesc, format, parseISO } from "date-fns/esm";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";

/* icon imports */
import { FiSearch } from "react-icons/fi";
import { IoMdRefresh } from "react-icons/io";

/* local imports */
import { getShipments } from "../services";
import {
  SHIPMENT_STATUSES,
  getStatusColor,
  getStatusTitle,
} from "../constants";
import CreateShipmentModal from "./CreateShipmentModal";

import DataGrid from "common/DataGrid";
import ErrorFallback from "common/ErrorFallback";
import { PORTAL_ROOT } from "Routes";
import { getParsedJSON, toSentenceCase } from "utils/string";
import DebouncedInput from "common/DebouncedInput";

const ShipmentListWidget = () => {
  const history = useHistory();

  const handleRowClick = (rowData) =>
    history.push({
      pathname: `${PORTAL_ROOT}/admin/shipments/${rowData.original.shipstationOrderNumber}`,
      state: rowData.original,
    });

  const {
    data: result,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery("shipmentsList", getShipments);

  const shipments = useMemo(() => {
    let data =
      !isError && !isLoading
        ? result.data.listShipments.items
            .sort((a, b) =>
              compareDesc(
                parseISO(get(a, "createdAt")),
                parseISO(get(b, "createdAt"))
              )
            )
            .map((s) => ({
              ...s,
              shipstationInfo: getParsedJSON(s.shipstationInfo),
            }))
        : [];

    return data;
  }, [isLoading, isError, result?.data?.listShipments?.items]);

  const columnHelper = createColumnHelper();

  const shipmentColumns = useMemo(
    () => [
      {
        header: "Order #",
        accessorFn: (row) => get(row, "shipstationOrderId") ?? "—",
        meta: {
          isNumeric: true,
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      columnHelper.accessor(
        (row) => {
          const fullName = row?.user?.firstName
            ? `${row.user.firstName} ${row.user.lastName ?? ""}`
            : row?.shipstationInfo?.billTo?.name;

          return fullName;
        },
        {
          id: "fullName",
          header: "Title",
          cell: ({ row }) => {
            const status = get(row.original, "shipstationOrderId")
              ? row.original.status
              : "Pending";
            return (
              <HStack>
                <Badge
                  variant="outline"
                  rounded="md"
                  colorScheme={getStatusColor(status)}
                >
                  {getStatusTitle(status)}
                </Badge>
                <Text fontWeight="semibold">
                  {row.getValue("fullName") ?? "—"}
                </Text>
              </HStack>
            );
          },
        }
      ),
      columnHelper.accessor(
        (row) => {
          const items = row?.items ?? [];
          if (items.length <= 1)
            return items.map((item) => `${item.name}`).join("");

          return (
            `${items[0].quantity}x ${items[0].name}` +
            ` (+${items.length - 1} more)`
          );
        },
        {
          id: "purchased",
          header: "Purchased",
        }
      ),
      {
        id: "createdAt",
        header: "Date",
        accessorFn: (row) =>
          format(parseISO(row.createdAt), "MMM do yyyy, HH:mm"),
      },
    ],
    []
  );

  const { table: tableInstance, ...dataGridProps } = DataGrid.useDataGrid({
    columns: shipmentColumns,
    data: shipments,
    initialState: {
      columnVisibility: { status: false },
    },
    enableRowSelection: false,
    enablePagination: true,
  });

  console.log("dataGridProps", dataGridProps);

  return (
    <VStack
      width="full"
      spacing={3}
      bg="white"
      rounded="xl"
      border="1px solid"
      borderColor="gray.200"
      shadow="md"
      overflow="hidden"
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <DataGrid.Composer table={tableInstance}>
          <Header loading={isFetching} refetch={refetch} {...dataGridProps} />
          <Box
            w="full"
            borderTop="1px solid"
            borderColor="gray.100"
            overflowX="scroll"
          >
            <DataGrid.Table onRowClick={handleRowClick} isLoading={isLoading} />
            <DataGrid.Footer pagination />
          </Box>
        </DataGrid.Composer>
      </ErrorBoundary>
    </VStack>
  );
};

export default ShipmentListWidget;

const Header = ({ loading, refetch, globalFilter, setGlobalFilter }) => {
  const { getColumn } = DataGrid.useDataGridContext();

  const disclosureProps = useDisclosure();

  return (
    <HStack width="full" justifyContent="space-between" p={3}>
      <HStack alignItems="center" spacing={2} w="full">
        <DebouncedInput
          initialValue={globalFilter}
          onChange={setGlobalFilter}
          placeholder="Find shipment"
        />
        {getColumn("status") && (
          <DataGrid.DropdownFilter
            column={getColumn("status")}
            title="Status"
            options={Object.values(SHIPMENT_STATUSES).map((status) => ({
              value: status,
              label: getStatusTitle(status),
            }))}
          />
        )}
      </HStack>
      <HStack alignItems="center" spacing={2}>
        <IconButton
          size="sm"
          onClick={refetch}
          aria-label="Refetch shipments"
          icon={<IoMdRefresh />}
          isLoading={loading}
        />
        <Button
          size="sm"
          colorScheme="linkedin"
          onClick={disclosureProps.onOpen}
        >
          Create Shipment
        </Button>
        <CreateShipmentModal {...disclosureProps} />
      </HStack>
    </HStack>
  );
};
