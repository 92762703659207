import React, { useEffect, useState } from "react";
import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import _ from "lodash";
import { Auth } from "aws-amplify";

/* local imports */
import {
  ADMIN_SET_USER_PASSWORD,
  ADMIN_UPDATE_USER,
  ADMIN_UPDATE_USER_GROUP,
  CREATE_COACH,
  CREATE_REWARDS_TRANSACTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER,
} from "graphql/mutations";
import { client } from "utils/awsConfig";
import ErrorFallback from "common/ErrorFallback";
import { ROLES } from "utils/authHelpers";

const camelToSnakeCase = (val, key) =>
  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const endpoints = {
  USER: UPDATE_USER,
  ADMIN_UPDATE_USER: ADMIN_UPDATE_USER,
  ADMIN_SET_USER_PASSWORD: ADMIN_SET_USER_PASSWORD,
  ADMIN_UPDATE_USER_GROUP: ADMIN_UPDATE_USER_GROUP,
  REWARDS: UPDATE_USER,
  SUBSCRIPTION: UPDATE_SUBSCRIPTION,
};

const InlineEditRow = ({
  title,
  belongsToModel,
  ViewComponent,
  EditComponent,
  content,
  mutateLocalUser,
  fullWidth = false,
  ButtonProps = { edit: {}, submit: {} },
  readOnly = undefined,
}) => {
  const { userId } = useParams();
  const [state, setState] = useState(() => (content ? content : {}));

  const [isEditing, setIsEditing] = useState(false);
  const toggleEdit = () => setIsEditing((prev) => !prev);

  const [loading, setLoading] = useState(false);
  const updateUser = async () => {
    setLoading(true);
    try {
      const protectedProps = Object.keys(state).filter((k) =>
        k.startsWith("_")
      );

      let variables = {
        input: {
          ..._.omit(state, protectedProps),
        },
      };

      if (!belongsToModel.startsWith("ADMIN_")) {
        variables.input.id = userId;
      } else {
        variables.userId = userId;
      }

      if (belongsToModel === "ADMIN_UPDATE_USER") {
        variables.input = _.mapKeys(variables.input, camelToSnakeCase);
        if (Object.keys(variables.input).includes("first_name"))
          variables.input["given_name"] =
            variables.input["first_name"] +
            (variables.input["last_name"]
              ? " " + variables.input["last_name"]
              : "");
      }

      if (belongsToModel === "ADMIN_UPDATE_USER_GROUP") {
        if (variables.input.roles.includes(ROLES.COACH) && !content.isCoach) {
          await client.mutate({
            mutation: CREATE_COACH,
            variables: {
              input: { id: userId },
            },
          });
        }

        const currentGroups = Array.isArray(content.roles)
          ? content.roles
          : [content.roles];
        console.log("CurrentGroups", currentGroups);

        const newSelectedRoles = variables.input.roles;
        console.log("SelectedRoles", newSelectedRoles);

        const removedRoles = _.difference(currentGroups, newSelectedRoles);
        const addedRoles = _.difference(newSelectedRoles, currentGroups);
        console.log("Removing", removedRoles);
        console.log("Adding", addedRoles);

        await Promise.all([
          removedRoles.map((currentRole) =>
            client.mutate({
              mutation: endpoints[belongsToModel],
              variables: {
                ...variables,
                input: {
                  action: "REMOVE",
                  group: currentRole,
                },
              },
            })
          ),
          addedRoles.map((currentRole) =>
            client.mutate({
              mutation: endpoints[belongsToModel],
              variables: {
                ...variables,
                input: {
                  action: "ADD",
                  group: currentRole,
                },
              },
            })
          ),
        ]);

        mutateLocalUser({ roles: variables.input.roles });
        return;
      }

      const user = await client.mutate({
        mutation: endpoints[belongsToModel],
        variables,
      });

      switch (belongsToModel) {
        case "ADMIN_UPDATE_USER_GROUP":
          // updates all handled above
          break;
        case "ADMIN_UPDATE_USER":
          mutateLocalUser(user.data.adminUpdateUserAttributes);
          break;
        case "REWARDS":
        case "USER":
          mutateLocalUser(user.data.updateUser);
          break;
        case "SUBSCRIPTION":
          mutateLocalUser({
            subscriptionInfo: { ...user.data.updateSubscriptionInfo },
          });
          break;
        default:
          console.log("Failed to parse result, please refresh the page.");
      }

      if (belongsToModel === "REWARDS") {
        logTransaction();
      }
    } catch (err) {
      alert("Failed to update user");
      console.log("[Update User] Error occured: ", err);
    } finally {
      setLoading(false);
      toggleEdit();
    }
  };

  const logTransaction = async () => {
    try {
      await client.mutate({
        mutation: CREATE_REWARDS_TRANSACTION,
        variables: {
          input: {
            userId,
            type: "MANUAL",
            rewardAmount: state._transactionAmount,
          },
        },
      });
    } catch (err) {
      console.log("[Create Transaction] Error occured: ", err);
    }
  };

  const handleChange = (property) => (e) =>
    setState((prev) => {
      const clone = _.cloneDeep(prev);
      const updated = _.set(
        clone,
        property,
        e.target.type === "checkbox" ? e.target.checked : e.target.value
      );
      return updated;
    });

  useEffect(() => {
    if (content) setState(content);
  }, [content]);

  console.log("InlineEdit", state, content);

  if (!ViewComponent || !EditComponent)
    throw new Error("Cannot use InlineEditRow without providing both views");

  if (!isEditing)
    return (
      <Box w="full">
        <Flex justifyContent="space-between" width="full" overflowX="auto">
          <Box>
            <FormTitle>{title}</FormTitle>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {!fullWidth && <ViewComponent content={content} />}
            </ErrorBoundary>
          </Box>
          {!readOnly && (
            <Button {...ButtonProps.edit} size="sm" onClick={toggleEdit}>
              {ButtonProps.edit?.text ? ButtonProps.edit.text : "Edit"}
            </Button>
          )}
        </Flex>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {!!fullWidth && <ViewComponent content={content} />}
        </ErrorBoundary>
      </Box>
    );

  return (
    <Box w="full">
      <HStack
        overflowX={{ base: "auto", md: "hidden" }}
        justifyContent="space-between"
        width="full"
        // mt="5"
        alignItems="flex-start"
        spacing="16"
      >
        <Box>
          <FormTitle>{title}</FormTitle>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {!fullWidth && (
              <EditComponent
                state={state}
                handleChange={handleChange}
                initialValue={content}
              />
            )}
          </ErrorBoundary>
        </Box>
        <HStack spacing={2}>
          <Button size="sm" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button
            colorScheme="green"
            {...ButtonProps.submit}
            size="sm"
            onClick={updateUser}
            isLoading={loading}
          >
            {ButtonProps.submit?.text ? ButtonProps.submit.text : "Save"}
          </Button>
        </HStack>
      </HStack>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {!!fullWidth && (
          <EditComponent
            state={state}
            handleChange={handleChange}
            initialValue={content}
          />
        )}
      </ErrorBoundary>
    </Box>
  );
};

export default InlineEditRow;

const FormTitle = (props) => (
  <Text fontWeight="semibold" fontSize="md" color="gray.400" {...props} />
);
