import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { Box } from "@chakra-ui/layout";
import qs from "qs";

/* local imports */
import RedeemLanding from "./components_redeem/RedeemLanding";
import RedeemRegister from "./components_redeem/RedeemRegister";
import RedeemLogin from "./components_redeem/RedeemLogin";
import RedeemComplete from "./components_redeem/RedeemComplete";

export const STEPS = {
  landing: "landing",
  register: "register",
  login: "login",
  thankyou: "thankyou",
};

const RedeemGift = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const [step, setStep] = useState(STEPS.landing);

  const navigate = useCallback(
    (selectedStep) => {
      history.push({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: selectedStep }),
      });
      setStep(STEPS[selectedStep]);
    },
    [url, currentParams, history]
  );

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    password: "",
    giftId: "",
    redemptionCode: "",
    step: "landing",
  });

  useEffect(() => {
    setFormState((prev) => ({ ...prev, ...currentParams }));
  }, [currentParams]);

  const handleInputChange = (property) => (e) =>
    setFormState((prev) => ({ ...prev, [property]: e.target.value }));

  const updateFormState = (property, value) =>
    setFormState((prev) => ({ ...prev, [property]: value }));

  const currentStep = useMemo(() => currentParams.step, [currentParams]);
  useEffect(() => {
    if (!currentStep) {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS.landing }),
      });
    } else {
      navigate(currentStep);
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS[currentStep] }),
      });
    }
  }, [currentStep]);

  console.log(formState);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <CurrentStep
        step={step}
        navigate={navigate}
        handleChange={handleInputChange}
        formState={formState}
        updateFormState={updateFormState}
      />
    </Box>
  );
};

export default RedeemGift;

const CurrentStep = ({ step, ...controls }) => {
  switch (step) {
    case STEPS.thankyou:
      return <RedeemComplete {...controls} />;
    case STEPS.login:
      return <RedeemLogin {...controls} />;
    case STEPS.register:
      return <RedeemRegister {...controls} />;
    case STEPS.landing:
    default:
      return <RedeemLanding {...controls} />;
  }
};
