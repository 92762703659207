export const USER_TYPES = {
  EXISTING: "existingUser",
  CUSTOM: "customRecipient",
};

export const getStatusColor = (status) => {
  switch (status) {
    case "FREE":
      return "blue";
    case "COMMUNITYSUBSCRIBER":
      return "green";
    case "PROSUBSCRIBER":
      return "purple";
    default:
      return "gray";
  }
};

export const getStatusTitle = (status) => {
  switch (status) {
    case "FREE":
      return "Free";
    case "COMMUNITYSUBSCRIBER":
      return "Community";
    case "PROSUBSCRIBER":
    default:
      return "Pro";
  }
};

export const EVENT_TYPES = [
  { label: "Virtual", value: "VIRTUAL" },
  { label: "In Person", value: "INPERSON" },
  { label: "Async", value: "ASYNC" },
  { label: "In App", value: "INAPP" },
];

export const PERMISSION_TIERS = [
  { label: "Free", value: "FREE" },
  { label: "Community Subscriber", value: "COMMUNITYSUBSCRIBER" },
  { label: "Pro Subscriber", value: "PROSUBSCRIBER" },
];
