import { Box } from "@chakra-ui/react";
import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";

/* local imports */
import AdminNav from "./components/AdminNav";
import { PORTAL_ROOT, ProtectedRoute } from "../../Routes";

import AdminDashboard from "pages/Admin/AdminDashboard";
import Shipments from "pages/Shipments/Shipments";
import ShipmentView from "pages/Shipments/ShipmentView";
import EventsList from "pages/Events/EventsList";
import EventView from "pages/Events/EventView";
import MessagingPortal from "pages/Messages/MessagingPortal";
import UserList from "pages/Users/UserList";

const AdminRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      minHeight="calc(100vh - 4rem)"
    >
      <AdminNav />
      <Switch>
        <ProtectedRoute path={`${url}/users`} component={UserList} />
        <ProtectedRoute path={`${url}/messages`} component={MessagingPortal} />
        <ProtectedRoute path={`${url}/events/:id`} component={EventView} />
        <ProtectedRoute path={`${url}/events`} component={EventsList} />
        <ProtectedRoute
          path={`${url}/shipments/:id`}
          component={ShipmentView}
        />
        <ProtectedRoute path={`${url}/shipments`} component={Shipments} />
        {/* <ProtectedRoute path={`${url}/dashboard`} component={AdminDashboard} /> */}
        <Redirect
          from={`${PORTAL_ROOT}/admin`}
          exact
          to={`${PORTAL_ROOT}/admin/users`}
        />
      </Switch>
    </Box>
  );
};

export default AdminRoutes;
