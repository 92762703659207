import React, { useState } from "react";
import { get } from "lodash";

/* local imports */
import Button from "common/Button";
import { PhoneNumberInput } from "common/PhoneNumberInput";

function Shipping({ done }) {
  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "United States",
  });

  const inputChange = (property) => (e) => {
    setShippingAddress((prev) => ({
      ...prev,
      [property]: get(e, ["target", "value"], e),
    }));
  };

  const validateShipping = () => {
    if (shippingAddress.phone.length < 1) {
      alert("A phone number is required");
      return;
    }
    if (shippingAddress.zip.length !== 5) {
      alert("zip code must be 5 digits");
      return;
    }
    if (shippingAddress.addressLine1.length < 1) {
      alert("Please enter an address");
      return;
    }
    if (shippingAddress.city.length < 1) {
      alert("Please enter a City");
      return;
    }
    if (shippingAddress.state.length < 1) {
      alert("Please enter a State");
      return;
    }
    done(shippingAddress);
  };

  return (
    <>
      <div className="line">
        <input
          type="text"
          placeholder="Full name"
          value={shippingAddress.name}
          onChange={inputChange("name")}
        />
        <PhoneNumberInput
          native
          size="lg"
          name="phone"
          value={shippingAddress.phone}
          onChange={inputChange("phone")}
          placeholder="050 123 4567"
        />
      </div>
      <input
        type="text"
        placeholder="Address line 1"
        value={shippingAddress.addressLine1}
        onChange={inputChange("addressLine1")}
      />
      <input
        type="text"
        placeholder="Address line 2"
        value={shippingAddress.addressLine2}
        onChange={inputChange("addressLine2")}
      />
      <div className="line">
        <input
          type="text"
          placeholder="City"
          value={shippingAddress.city}
          onChange={inputChange("city")}
        />
        <input
          type="text"
          placeholder="State"
          value={shippingAddress.state}
          onChange={inputChange("state")}
        />
        <input
          type="text"
          placeholder="Zip code"
          value={shippingAddress.zip}
          onChange={inputChange("zip")}
        />
      </div>
      <select>
        <option>United States</option>
      </select>
      <div>
        <Button onClick={validateShipping} outline={true} inline={true}>
          Next
        </Button>
      </div>
    </>
  );
}
export default Shipping;
