import React, { useState, useRef } from "react";
import {
  Box,
  Text,
  InputGroup,
  useDisclosure,
  useOutsideClick,
  InputLeftElement,
  chakra,
  Icon,
  useMultiStyleConfig,
  Input,
  useStyleConfig,
  omitThemingProps,
  useFormControl,
} from "@chakra-ui/react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Cleave from "cleave.js/react";

/* local imports */
// Do not remove import, this is necessary for correct input masking
import CleavePhone from "cleave.js/dist/addons/cleave-phone.i18n"; //eslint-disable-line no-unused-vars
import Countries from "./countries.json";
import CountryAutocomplete from "./CountryAutocomplete";

const StyledCleave = chakra(Cleave);

const PhoneNumberInput = (props) => {
  const {
    native = null,
    size,
    value,
    defaultCountry = "US",
    options,
    onChange,
    placeholder,
    ...rest
  } = props;

  const styles = useMultiStyleConfig("Input", { size, ...rest });
  const ownProps = omitThemingProps(rest);
  const input = useFormControl(ownProps);

  const ref = useRef(null);
  const inputRef = useRef(null);
  const [number, setNumber] = useState(() => (value ? value : ""));
  const [selectedCountry, setSelectedCountry] = useState(() => {
    return defaultCountry
      ? Countries.find((c) => c.code === defaultCountry)
      : Countries.find((c) => c.code === "US");
  });

  const { isOpen, onToggle, onClose } = useDisclosure();

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });

  const onCountryChange = (item) => {
    setSelectedCountry(item);
    onChange(`${item?.dial_code}${number}`);
    onClose();
  };

  const onPhoneNumberChange = (event) => {
    // show user pretty thing
    setNumber(event.target.value);

    if (event.target.rawValue.length) {
      // capture raw input
      onChange(`${selectedCountry?.dial_code}${event.target.rawValue}`);
    } else {
      onChange("");
    }
  };

  if (native) {
    return (
      <>
        <chakra.select
          maxWidth="3.2em"
          defaultValue={JSON.stringify(selectedCountry)}
          onChange={(evt) => onCountryChange(JSON.parse(evt.target.value))}
        >
          {Countries.map((country, idx) => (
            <option key={idx} value={JSON.stringify(country)}>
              {country.flag} {country.name}
            </option>
          ))}
        </chakra.select>
        <Cleave
          type="tel"
          value={number}
          pattern="[0-9]"
          placeholder={placeholder || "Enter phone number"}
          options={{ phone: true, phoneRegionCode: selectedCountry?.code }}
          onChange={onPhoneNumberChange}
        />
      </>
    );
  }

  return (
    <Box as="section" ref={ref} position="relative">
      <InputGroup size={size}>
        <InputLeftElement width="5em" cursor="pointer" onClick={onToggle}>
          <Text as="span" mr={3}>
            {selectedCountry?.flag}
          </Text>
          {isOpen ? (
            <Icon boxSize={5} color="gray.500" as={FiChevronUp} />
          ) : (
            <Icon boxSize={5} color="gray.500" as={FiChevronDown} />
          )}
        </InputLeftElement>
        <StyledCleave
          {...input}
          htmlRef={(ref) => (inputRef.current = ref)}
          className="chakra-input"
          __css={styles.field}
          pl="5em"
          type="tel"
          value={number}
          // pattern="[0-9]"
          placeholder={placeholder || "Enter phone number"}
          options={{ phone: true, phoneRegionCode: selectedCountry?.code }}
          onChange={onPhoneNumberChange}
          {...rest}
        />
      </InputGroup>

      {isOpen ? (
        <CountryAutocomplete data={Countries} onChange={onCountryChange} />
      ) : null}
    </Box>
  );
};

export default PhoneNumberInput;
