import React, { useMemo, useState } from "react";
import { groupBy, mapValues, uniqBy } from "lodash";
import { Text, Box, HStack } from "@chakra-ui/layout";
import { Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/stat";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Scatter,
  ReferenceLine,
  Legend,
} from "recharts";
import { format, isValid } from "date-fns/esm";
import { ButtonGroup, IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";

/* icon imports */
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

/* local imports */
import NoDataAvailable from "common/NoDataAvailable";
import useTimeWindow from "../hooks/useTimeWindow";
import {
  binMillisecondDateToStdTimeUnits,
  fillTicksData,
  getGroupByLabel,
  getPeriodTicks,
  getPeriodVal,
  getTicks,
  timeUnit,
  withinBounds,
} from "../utils/temporal";
import SessionListModal from "./SessionListModal";

const FlowScoreGraph = ({ data, filterWeeks }) => {
  const filledData = fillTicksData(data);
  const chartUnit = timeUnit(filterWeeks);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPoint, setSelectedPoint] = useState(null);

  const { windowShift, windowStart, windowEnd, shiftLeft, shiftRight } =
    useTimeWindow({
      weeks: filterWeeks,
    });

  const sessionData = useMemo(() => {
    if (!filledData) return { data: [] };

    let cleaned = filledData
      .map((dp) => ({
        ...dp,
        value: dp.value !== null ? Math.round(dp.value * 100) : null,
      }))
      .map(binMillisecondDateToStdTimeUnits);
    if (filterWeeks) {
      cleaned = cleaned.filter(withinBounds(windowStart, windowEnd));
    }

    const getGroupedByTimeUnit = groupBy(cleaned, chartUnit);

    // Calculate avg and set avg to all instances on same timeUnit
    mapValues(getGroupedByTimeUnit, (sessions) => {
      const calculatedAverage = Math.round(
        sessions.reduce((acc, current) => (acc += current.value), 0) /
          sessions.filter((s) => s.value !== null).length
      );

      sessions.forEach(
        (session) => (session.average = calculatedAverage || null)
      );
    });

    return {
      raw: cleaned,
      data: uniqBy(cleaned, chartUnit),
    };
  }, [filledData, filterWeeks, chartUnit, windowStart, windowEnd]);

  const metrics = useMemo(() => {
    if (!sessionData.data.length) return 0;

    const validData = sessionData.data.filter((dp) => dp.value !== null);

    if (!validData.length) return 0;

    return {
      avg: Math.round(
        validData.reduce((acc, current) => acc + current.value, 0) /
          validData.length
      ),
    };
  }, [sessionData]);

  const handlePointClick = (evt) => {
    if (!evt) return;
    const { activePayload } = evt;
    const [{ payload }] = activePayload;

    setSelectedPoint(payload);
    onOpen();
  };

  return (
    <>
      <StatGroup>
        <Stat>
          <StatNumber fontSize="4xl">{metrics.avg ?? 0}</StatNumber>
          <StatLabel mt="-3">Avg. Flow Score</StatLabel>
        </Stat>
      </StatGroup>
      <Box height="sm" width="full" mt="4">
        <Chart
          data={sessionData.data}
          windowStart={windowStart}
          windowEnd={windowEnd}
          filterWeeks={filterWeeks}
          handlePointClick={handlePointClick}
          metrics={metrics}
        />
      </Box>
      {filterWeeks ? (
        <HStack mt="4" justifyContent="center" spacing={2}>
          <ButtonGroup>
            <IconButton
              onClick={shiftLeft}
              aria-label="Week before"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowLeft} />}
            />
            <IconButton
              onClick={shiftRight}
              disabled={!windowShift}
              aria-label="Week after"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowRight} />}
            />
          </ButtonGroup>
        </HStack>
      ) : null}
      <SessionListModal
        title={`${getGroupByLabel(filterWeeks)} — ${getPeriodVal(
          filterWeeks,
          new Date(selectedPoint?.fullDate ?? null)
        )}`}
        isOpen={isOpen}
        onClose={onClose}
        sessions={
          selectedPoint
            ? sessionData.raw.filter(
                (point) =>
                  point[chartUnit] === selectedPoint[chartUnit] &&
                  point.value !== null
              )
            : []
        }
      />
    </>
  );
};

export default FlowScoreGraph;

const Chart = ({
  data,
  filterWeeks,
  windowStart,
  windowEnd,
  metrics,
  handlePointClick,
}) => {
  const ticks = getTicks(
    filterWeeks ? windowStart : new Date(data[0]?.fullDate ?? null),
    windowEnd,
    getPeriodTicks(filterWeeks)
  );
  const domain = [(dataMin) => dataMin, (dataMax) => windowEnd.getTime()];

  if (!data.length) {
    return (
      <NoDataAvailable>
        {!!filterWeeks && (
          <Text color="gray.600" fontWeight="bold">
            {format(windowStart, "MMM do, yyyy")} —{" "}
            {format(windowEnd, "MMM do, yyyy")}
          </Text>
        )}
      </NoDataAvailable>
    );
  }
  return (
    <ResponsiveContainer>
      <ComposedChart
        onClick={handlePointClick}
        data={data}
        margin={{
          top: 20,
          right: 80,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis
          dataKey="fullDate"
          allowDuplicatedCategory={false}
          tickFormatter={(val) =>
            val && isValid(new Date(val))
              ? format(
                  new Date(val),
                  `MMM do${filterWeeks > 4 ? ", yyyy" : ""}`
                )
              : format(new Date(), `MMM do${filterWeeks > 4 ? ", yyyy" : ""}`)
          }
          scale="time"
          type="number"
          ticks={ticks}
          domain={domain}
          // domain={[getTime(windowStart), getTime(windowEnd)]}
        />
        <YAxis
          axisLine={false}
          type="number"
          label={{
            value: "Flow Score",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Legend wrapperStyle={{ position: "relative" }} />
        <ReferenceLine
          y={metrics.avg}
          position="end"
          stroke="red"
          strokeDasharray="6 3"
        />
        {filterWeeks && filterWeeks < 12 ? (
          <Scatter
            name="Flow Score"
            dataKey="average"
            fill="#00a0dc"
            isAnimationActive={false}
          />
        ) : null}
        <defs>
          <linearGradient id="colorRecent" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#0083ff" stopOpacity={0.3} />
            <stop offset="70%" stopColor="#0083ff" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          animationDuration={500}
          fill="url(#colorRecent)"
          fillOpacity={1}
          strokeWidth={2}
          // type="monotone"
          type="basis"
          name={`${getGroupByLabel(filterWeeks)}'s Average`}
          dataKey="average"
          stroke="#00a0dc"
          // dot={false}
        />
        <Tooltip
          formatter={(val) => Math.round(val)}
          // labelFormatter={(val) =>
          //   val && isValid(new Date(val))
          //     ? format(new Date(val), "MMM do, yyyy hh:mm aaa")
          //     : format(new Date(), "MMM do, yyyy hh:mm aaa")
          // }
          labelFormatter={(val) =>
            val && isValid(new Date(val))
              ? `${getPeriodVal(filterWeeks, new Date(val))}`
              : format(new Date(), "MMM do, yyyy ")
          }
          labelStyle={{ color: "#1a202c", fontWeight: 600 }}
          cursor={{ strokeDasharray: "2,2", color: "#4A5568" }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
