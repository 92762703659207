import React, { useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Button } from "@chakra-ui/button";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { Link } from "react-router-dom";

/* icon imports */
import { TiArrowForward } from "react-icons/ti";

/* local imports */
import { PORTAL_ROOT } from "../../../Routes";

const VisitUserProfile = () => {
  const [currentUser, setCurrentUser] = useState();

  const loadCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setCurrentUser(user);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    loadCurrentUser();
  }, []);

  return (
    <Box
      p="8"
      bg="white"
      rounded="3xl"
      shadow="md"
      height="xs"
      width="full"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
    >
      <Box w="full">
        <Heading color="linkedin.500" fontSize="2xl">
          Track your progress
        </Heading>
        <Text fontSize="md" color="gray.400" maxW="75%">
          Through this portal, you can view a full history of your usage of
          Flowly. A complete log of your experiences, for you to explore.
        </Text>
      </Box>
      <Box>
        <Button
          mt="4"
          colorScheme="linkedin"
          as={Link}
          to={`${PORTAL_ROOT}/users/${currentUser?.username}`}
          rightIcon={<TiArrowForward />}
        >
          Take me there!
        </Button>
      </Box>
    </Box>
  );
};

export default VisitUserProfile;
