import { addDays, format, isAfter } from "date-fns/esm";

export function getDates(startDate, stopDate) {
  const dateArray = [];
  let currentDate = startDate;
  while (!isAfter(currentDate, stopDate)) {
    dateArray.push(format(currentDate, "yyyy-MM-dd"));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}
