import React, { useMemo } from "react";
import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { useRouteMatch } from "react-router";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";

/* icon imports */
import { GiSwipeCard } from "react-icons/gi";
import { IoIosSend, IoIosUnlock } from "react-icons/io";

/* local imports */
import heartLogo from "assets/heart-logo.svg";
import headsetBg from "assets/products/flowly-1year@2x.png";
import appBg from "assets/products/threemonth-sub-bg-cropped.png";
import { SUBSCRIPTION_PRODUCT_TYPES } from "pages/Checkout/constants";
import useSubscriptionProducts from "hooks/useSubscriptionProducts";

const TAGLINES = [
  "Experience deep, daily relaxation all year long",
  "Take immediate control of your pain, anxiety, and sleep",
];

const GiftUser = () => {
  const { url } = useRouteMatch();
  const { data: products, isLoading } = useSubscriptionProducts({
    variables: {
      filter: {
        and: [
          {
            active: {
              eq: true,
            },
          },
          {
            subscriptionMethod: {
              eq: "STRIPE",
            },
          },
          {
            subscriptionProductType: {
              eq: SUBSCRIPTION_PRODUCT_TYPES.GIFT,
            },
          },
        ],
      },
    },
  });

  const productsToDisplay = useMemo(
    () => products.sort((a, b) => b.price - a.price),
    [products]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <Container maxW="container.lg" height="100%">
        <VStack width="full" mt={{ base: "12", lg: "8", "2xl": "12" }}>
          <Heading
            color="#5072EC"
            size="2xl"
            fontWeight="bold"
            mb={{ base: "8", lg: "6", "2xl": "8" }}
          >
            Life-changing relaxation, gifted by you.
          </Heading>
          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            width="full"
            spacing="8"
          >
            {isLoading ? (
              <Spinner />
            ) : (
              productsToDisplay.map(
                (
                  { id, subscriptionType, price, duration, timeUnits, ...rest },
                  idx
                ) => (
                  <PlanOption
                    key={id}
                    text={`${duration} ${timeUnits.toLowerCase()}${
                      duration > 1 ? "s" : ""
                    } of Flowly Pro`}
                    price={`${price}`}
                    currency="$"
                    tagline={TAGLINES[idx % TAGLINES.length]}
                    to={{
                      pathname: `${url}/${subscriptionType}/${id}`,
                      state: {
                        id,
                        subscriptionType,
                        price,
                        duration,
                        timeUnits,
                        ...rest,
                      },
                    }}
                    cardBg={idx % 2 === 0 ? headsetBg : appBg}
                  />
                )
              )
            )}
            {/* <PlanOption
              text="3 months of Flowly Pro"
              price="89.99"
              currency="$"
              tagline="Take immediate control of your pain, anxiety, and sleep"
              to={`${url}/three`}
              cardBg={appBg}
            />
            <PlanOption
              text="1 year of Flowly Pro"
              price="179.99"
              currency="$"
              tagline="Experience deep, daily relaxation all year long"
              to={`${url}/twelve`}
              cardBg={headsetBg}
            /> */}
          </Stack>
        </VStack>
      </Container>
      <Box boxShadow="2xl" bg="white" mt={{ base: "20", lg: "8", "2xl": "20" }}>
        <Container maxW="container.lg" height="100%" py="8">
          <VStack spacing="8">
            <Heading color="#5072EC">How it works</Heading>
            <Stack direction={{ base: "column", md: "row" }} spacing="16">
              <Instruction
                icon={GiSwipeCard}
                iconBg="linear-gradient(90deg, #8EA9EA 15.1%, #8CC5F6 100%)"
                step="1"
                name="Purchase"
                description="Gift your friends and family the Flowly Pro experience. Pre-pay for 3 months or 1 year of a Flowly subscription that they can later redeem."
              />
              <Instruction
                icon={IoIosSend}
                iconBg=" linear-gradient(90deg, #8CC5F6 17.71%, #8ADBFF 100%)"
                step="2"
                name="Send"
                description="We'll ship the included hardware kit to the address you provide, whether it's yours or theirs. Flowly kits ship through USPS Priority shipping and are estimated to arrive in 3-4 business days."
              />
              <Instruction
                icon={IoIosUnlock}
                iconBg="rgba(138, 219, 255, 1)"
                step="3"
                name="Redeem"
                description="We'll email you a redemption link that you can share with your gift recipient whenever suits your plans best. They can use that link to signup or login and redeem their gift."
              />
            </Stack>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default GiftUser;

const PlanOption = ({ text, price, currency, tagline, to, cardBg }) => {
  return (
    <Box
      bg="white"
      _hover={{
        boxShadow: "2xl",
        transition: "box-shadow 0.4s ease-in-out",
      }}
      boxShadow="lg"
      rounded="2xl"
      p="8"
      w={{ base: "full", md: "26.5rem" }}
      height="28.25rem"
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="flex-start"
      position="relative"
      overflow="hidden"
      as={Link}
      to={to}
    >
      <Image src={heartLogo} placeholder="flowly-emblem" height="9" />
      <Box>
        <Text
          fontSize="2xl"
          fontWeight="extrabold"
          letterSpacing="tight"
          maxW="50%"
        >
          {text},
          <br />
          <Text color="#5072EC">
            {currency}
            {price}
          </Text>
        </Text>
        <Text maxW="65%" color="gray.400">
          {tagline}
        </Text>
        <Image
          w="20rem"
          position="absolute"
          top="0"
          right="0"
          transform="translate(2rem, -1rem)"
          src={cardBg}
        />
      </Box>
      <Box width="full">
        <Button as={Link} to={to} colorScheme="blue" width="full">
          Get Started
        </Button>
      </Box>
    </Box>
  );
};

const Instruction = ({ icon: Icon, step, name, description, iconBg }) => {
  return (
    <VStack alignItems="flex-start" w={{ base: "full", md: "calc(100% / 3)" }}>
      <Box
        rounded="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={4}
        width="4rem"
        height="4rem"
        bg={iconBg}
      >
        <Icon size="1.6rem" color="white" />
      </Box>
      <Text fontWeight="extrabold">
        {step}. {name}
      </Text>
      <Text maxW={{ base: "full", md: "80%" }}>{description}</Text>
    </VStack>
  );
};
