import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { API } from "aws-amplify";
import useLocalStorageState from "use-local-storage-state";

/* asset imports */
import vr from "assets/products/vr.png";
import ebbi from "assets/confirmation/confirmation.png";
import necklace from "assets/products/member-necklace.png";
import subscription from "assets/products/subscription.svg";
import done from "assets/checkout/done.svg";

/* local imports */
import Product from "common/Product";
import H1 from "common/H1";
import Loading from "common/Loading";
import Button from "common/Button";
import { toSentenceCase } from "utils/string";

import { SUBSCRIPTIONS } from "../Invitation/components/InviteSubscription";
import {
  SUBSCRIPTION_PLAN_TYPES,
  SUBSCRIPTION_PRODUCT_TYPES,
  SUBSCRIPTION_TIME_UNITS,
} from "./constants";

const Pricing = styled.div`
  border-top: 1px solid #c0cff9;
  border-bottom: 1px solid #c0cff9;
  padding: 2rem 0px;
  margin: 1.25rem 0px;
  .line {
    display: flex;
    .description {
      flex: 1;
    }
    #discountValue {
      color: #718096;
      font-size: 1rem;
      font-style: italic;
      margin-top: 0;
    }
    .value {
      text-align: right;
      flex: 1;
      font-family: Nunito;
      font-weight: bold;
      font-size: 18px;
      line-height: 1.5rem;
      color: #252b40;
    }
  }
`;

const durationInMonths = (duration, unit) => {
  switch (unit) {
    case SUBSCRIPTION_TIME_UNITS.LIFETIME:
      return `For life`;
    case SUBSCRIPTION_TIME_UNITS.YEAR:
      return `${duration * 12} months`;

    case SUBSCRIPTION_TIME_UNITS.MONTH:
    default:
      return `${duration} month${duration > 1 ? "s" : ""}`;
  }
};

const ProductDescription = ({ selectedPlan }) => (
  <>
    {selectedPlan.description ? <p>{selectedPlan.description}</p> : null}
    <p>
      {durationInMonths(
        selectedPlan.duration ?? 1,
        selectedPlan.timeUnits ?? SUBSCRIPTION_TIME_UNITS.YEAR
      )}
    </p>
  </>
);

function OrderDetails({
  subscriptionType,
  shippingPrice,
  orderDetails,
  setOrderDetails,
  selectedPlan,
  showCouponField,
}) {
  const {
    price: planPrice,
    kitPrice = 0,
    title: planTitle,
    subscriptionPlan,
  } = selectedPlan;

  const [promo] = useLocalStorageState("promo");
  const [referrer, , { removeItem: removeReferrer }] =
    useLocalStorageState("referrer");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (planPrice) {
      if (referrer) {
        const fixURLParsedReferrer = referrer.replace(" ", "+");
        checkReferrer(fixURLParsedReferrer).then((coupon) => {
          if (!coupon && promo) {
            applyPromo(promo);
          }
        });
      } else if (promo && !orderDetails.promoApplied) {
        applyPromo(promo);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planPrice]);

  const checkReferrer = async (userId) => {
    setLoading(true);
    try {
      const result = await API.get("flowlyRestAPI", "/check-referrer-id", {
        queryStringParameters: {
          userId: userId,
          subscriptionType: subscriptionType,
        },
      });
      if (result) {
        if (result.coupon) {
          setOrderDetails((prev) => ({
            ...prev,
            promoCode: result.coupon,
            referralCode: result.referralCode,
          }));
          applyPromo(result.coupon);
          return result.coupon;
        } else {
          removeReferrer();
          return null;
        }
      }
    } catch (error) {
      console.log("Error occurred in processing referrer :>> ", error.message);
    } finally {
      setLoading(false);
    }

    return null;
  };

  const applyPromo = async (promoArg) => {
    if (
      !(
        (promoArg && typeof promoArg === "string") ||
        orderDetails.promoCode ||
        orderDetails.promo
      )
    )
      return null;

    const validCode =
      promoArg && typeof promoArg === "string"
        ? promoArg
        : orderDetails.promoCode
        ? orderDetails.promoCode
        : orderDetails.promo;

    setLoading(true);
    try {
      const result = await API.get("flowlyRestAPI", "/check-coupon", {
        queryStringParameters: {
          coupon: validCode,
        },
      });
      if (!result.valid) {
        alert("Invalid promo code");
      } else if (result.type === "invalid_request_error") {
        if (result.code === "resource_missing") {
          alert("Invalid promo code");
        } else if (result.code === "coupon_expired") {
          alert("Promo code is expired");
        } else {
          alert(
            "We had an error processing the promo code. Please, try again later."
          );
        }
        setOrderDetails((prev) => ({ ...prev, promoCode: "" }));
      } else {
        setOrderDetails((prev) => {
          let updatedPayment = { ...prev };
          let amountText = "";

          updatedPayment.promoCode = validCode;

          if (result.amount_off) {
            const calculatedDiscount = +result.amount_off / 100.0;
            amountText = calculatedDiscount.toString();

            updatedPayment.discountType = "amount_off";
            updatedPayment.discount =
              calculatedDiscount > planPrice ? planPrice : calculatedDiscount;
          } else if (result.percent_off) {
            amountText = `${result.percent_off}%`;
            updatedPayment.discountType = "percent";
            updatedPayment.discount = result.percent_off;
          }

          if (result.duration === "once") {
            updatedPayment.discountDuration = `${amountText} off once`;
          }

          if (result.duration === "repeating") {
            updatedPayment.discountDuration = `${amountText} off for ${result.duration_in_months} months`;
            updatedPayment.discountProps = result;
          }
          if (result.duration === "forever") {
            updatedPayment.discountDuration = `${amountText} off for life`;
          }

          updatedPayment.promoApplied = true;

          return updatedPayment;
        });
      }
    } catch (e) {
      alert(
        "We had an error processing the promo code. Please, try again later."
      );

      setOrderDetails((prev) => ({ ...prev, promoCode: "" }));
    } finally {
      setLoading(false);
    }
  };

  const journeyMaintenanceSubscriptionPrice = !selectedPlan
    ?.journeySubscriptionProduct?.trialMonths
    ? selectedPlan?.journeySubscriptionProduct?.price
    : 0;
  const productTotal =
    planPrice +
    (kitPrice ?? 0) +
    (journeyMaintenanceSubscriptionPrice ?? 0) +
    shippingPrice;
  const discountAmount = () => {
    switch (orderDetails.discountType) {
      case "amount_off":
        return orderDetails.discount > planPrice
          ? planPrice
          : orderDetails.discount;
      case "percent":
        return (productTotal * orderDetails.discount) / 100;

      default:
        return null;
    }
  };

  const calculatedTotal = productTotal - discountAmount();
  const discountValue = orderDetails?.discountProps
    ? orderDetails.subscriptionType !== SUBSCRIPTIONS.monthly
      ? Math.round(
          (orderDetails.discountProps.amount_off
            ? +orderDetails.discountProps.amount_off / 100.0
            : (orderDetails.discountProps.percent_off / 100) * planPrice) *
            Math.ceil(orderDetails.discountProps.duration_in_months / 12)
        )
      : Math.round(
          (orderDetails.discountProps.amount_off
            ? +orderDetails.discountProps.amount_off / 100.0
            : (orderDetails.discountProps.percent_off / 100) * planPrice) *
            orderDetails?.discountProps?.duration_in_months ?? 0
        )
    : null;

  useEffect(() => {
    if (calculatedTotal && orderDetails.total !== calculatedTotal) {
      setOrderDetails((prev) => ({
        ...prev,
        total: calculatedTotal,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedTotal, orderDetails.total]);

  console.log("orderDetails :>> ", orderDetails);
  return (
    <>
      {loading ? <Loading /> : <></>}
      <div className="header">
        <H1>Order details</H1>
      </div>
      {selectedPlan.subscriptionProductType ===
        SUBSCRIPTION_PRODUCT_TYPES.JOURNEY &&
      selectedPlan?.journeySubscriptionProduct ? (
        <>
          <Product
            name={`Journey Program Fee`}
            image={ebbi}
            price={"$" + planPrice}
          />
          <Product
            name={
              selectedPlan?.journeySubscriptionProduct?.title
                ? `${selectedPlan?.journeySubscriptionProduct?.title} Membership`
                : `${toSentenceCase(
                    selectedPlan?.journeySubscriptionProduct?.subscriptionType
                  )} Flowly Membership`
            }
            image={subscription}
            price={
              !selectedPlan?.journeySubscriptionProduct?.trialMonths
                ? "$" + selectedPlan?.journeySubscriptionProduct?.price
                : "Free"
            }
            qty={selectedPlan?.journeySubscriptionProduct?.trialMonths}
            description={
              <ProductDescription
                selectedPlan={selectedPlan?.journeySubscriptionProduct}
              />
            }
          />
        </>
      ) : (
        <Product
          name={
            planTitle
              ? `${planTitle} Membership`
              : `${toSentenceCase(subscriptionType)} Flowly Membership`
          }
          image={subscription}
          price={"$" + planPrice}
          description={<ProductDescription selectedPlan={selectedPlan} />}
        />
      )}
      <Product
        name="Member Necklace"
        image={necklace}
        price="Free"
        description=""
      />
      {subscriptionPlan === SUBSCRIPTION_PLAN_TYPES.PRO ? (
        <Product
          name="VR Headset and Biosensor"
          image={vr}
          price={kitPrice ? `$${kitPrice.toFixed(2)}` : "Free"}
          description={
            kitPrice
              ? `You will be charged a one time fee of $${kitPrice} for the Flowly kit`
              : null
          }
        />
      ) : null}
      <Pricing>
        <div className="line">
          <div className="description">Subtotal</div>
          <div className="value">
            {"$" +
              (
                planPrice +
                (kitPrice ?? 0) +
                (journeyMaintenanceSubscriptionPrice ?? 0)
              ).toFixed(2)}
          </div>
        </div>
        {subscriptionPlan !== SUBSCRIPTION_PLAN_TYPES.COMMUNITY ? (
          <div className="line">
            <div className="description">Shipping (Priority 2-3 days)</div>
            <div className="value">
              {shippingPrice ? `$${shippingPrice}` : "..."}
            </div>
          </div>
        ) : null}
        {discountAmount() ? (
          <div className="line">
            <div className="description">
              Discount
              {orderDetails.discountDuration
                ? ` (${orderDetails.discountDuration})`
                : ""}
              <br />
              <p id="discountValue">
                {discountValue && `Total value of $${discountValue.toFixed(2)}`}
              </p>
            </div>
            <div className="value">-${discountAmount().toFixed(2)}</div>
          </div>
        ) : null}
        <div className="line">
          <div className="description">Total Due Today</div>
          <div className="value">${calculatedTotal.toFixed(2)}</div>
        </div>
      </Pricing>
      {orderDetails.promoApplied ? (
        <>
          <div className="promo-title">
            <img src={done} alt="done" /> Code{" "}
            {orderDetails.promoCode || orderDetails.promo || ""} applied
            successfully!
          </div>
        </>
      ) : showCouponField ? (
        <>
          <div className="promo-title">Have a promo code?</div>
          <input
            className="promo-code"
            type="text"
            placeholder="Enter your code"
            value={orderDetails.promoCode}
            onChange={(evt) =>
              setOrderDetails((prev) => ({
                ...prev,
                promoCode: evt.target.value,
              }))
            }
          />
          <Button onClick={applyPromo} outline={true} inline={true}>
            Apply
          </Button>
        </>
      ) : null}
    </>
  );
}
export default OrderDetails;
