import axios from "axios";

/* local imports */
import {
  CREATE_CONSENT,
  SUBSCRIBE_WITH_ORG,
  UPDATE_USER,
  VALIDATE_ORG_CODE,
} from "graphql/mutations";
import { client, publicClient } from "utils/awsConfig";

export const checkOrgCode = async (orgCode) => {
  try {
    const orgFound = await publicClient.mutate({
      mutation: VALIDATE_ORG_CODE,
      variables: {
        organizationCode: orgCode,
      },
    });

    const response = orgFound.data.checkOrganizationCode;

    if (response.id) {
      return { data: response, error: null };
    } else if (response.error) {
      // alert(response.error);
      throw Error(response.error);
    }
  } catch (err) {
    // alert(
    //   "Something went wrong while attempting to find your organization. \n" +
    //     err.message
    // );
    console.log("[Org Code Check] Error occured:", err.message);
    return { data: null, error: err.message };
  }
};

export const saveSignature = async (signature, options) => {
  if (!signature) throw Error("No signature provided");
  return client.mutate({
    mutation: CREATE_CONSENT,
    variables: {
      input: {
        signature: `/${signature}/`,
        form: "anthem_use_case_consent-v1.0",
        ...(options.ipAddress ? { ipAddress: options.ipAddress } : {}),
      },
    },
  });
};

export const redeemLicense = async (
  orgId,
  options = { address: null, mobile: null }
) => {
  try {
    const subscriptionStatus = await client.mutate({
      mutation: SUBSCRIBE_WITH_ORG,
      variables: {
        organizationId: orgId,
        options: {
          ...(options.address ? { shippingAddress: options.address } : {}),
          ...(options.mobile ? { mobile: options.mobile } : {}),
        },
      },
    });

    const response = subscriptionStatus.data.subscribeWithOrganization;
    if (response.result) {
      return onRedeemSuccess(response.result, orgId);
    } else if (response.error) {
      alert(response.error);
      return null;
    }
  } catch (err) {
    alert(
      "Something went wrong while attempting to redeem your license. \n" +
        err.message
    );
    console.log("[Redeem Org License] Error occured: ", err);
  }
};

export const onRedeemSuccess = (successMsg, orgId) => {
  window.analytics.track("Organization Subscription Redeemed Successfully", {
    orgId,
  });
  console.log("Organization Subscription redeemed successfully!");

  return successMsg;
};

export const getUserIPAddress = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  console.log(res.data);

  return res.data.IPv4;
};

export const updateUserParams = (user, { consentId }) => {
  return client.mutate({
    mutation: UPDATE_USER,
    variables: {
      input: {
        id: user.username,
        consentIds: [consentId],
      },
    },
  });
};
