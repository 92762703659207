export const STEPS = {
  landing: "landing",
  authDirect: "authDirect",
  register: "register",
  login: "login",
  thankyou: "thankyou",
  shipping: "shipping",
  error: "error",
  voucherDetails: "voucherDetails",
};
