import { compareAsc, differenceInCalendarDays, format } from "date-fns";
import {
  getMonth,
  getTime,
  getWeek,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  add,
} from "date-fns/esm";
import { unionBy } from "lodash";
/** local imports */
import { getDates } from "utils/dateUtils";

export const withinBounds =
  (start = new Date(), end = new Date()) =>
  (dataPoint) =>
    (isSameDay(new Date(dataPoint.date), start) ||
      isAfter(new Date(dataPoint.date), start)) &&
    (isSameDay(new Date(dataPoint.date), start) ||
      isBefore(new Date(dataPoint.date), end));

export const timeUnit = (weeks) => {
  if (weeks === 12) {
    return "week";
  }
  if (weeks === 52) {
    return "month";
  }
  if (weeks === null) {
    return "MMyyyy";
  }

  return "date";
};

export const binMillisecondDateToStdTimeUnits = (dp) => ({
  ...dp,
  fullDate: getTime(parseISO(dp.fullDate)),
  week: getWeek(parseISO(dp.fullDate)),
  month: getMonth(parseISO(dp.fullDate)),
  MMyyyy: `${getMonth(parseISO(dp.fullDate))}-${getYear(
    parseISO(dp.fullDate)
  )}`,
});

export const isValidNumber = (dp) => isFinite(dp.value) && !isNaN(dp.value);

/**
 * Add data of the date in ticks,
 * if there is no data in that date in `data`.
 *
 * @param Array<number> _ticks
 * @param {*} data
 */
export const fillTicksData = (data, start, end) => {
  if (!data.length) return data;

  if (!!start && !!end) {
    const periodArr = getDates(start, end).map((day) => ({
      date: day,
      value: null,
    }));

    const merged = unionBy(data, periodArr, "date").sort((a, b) =>
      compareAsc(new Date(a.date), new Date(b.date))
    );

    return merged;
  }
  const first = new Date(data[0].date);
  const last = new Date(data[data.length - 1].date);
  const periodArr = getDates(first, last).map((day, idx) => ({
    date: day,
    fullDate: day,
    id: `polyfill_${idx}`,
    value: null,
  }));

  const merged = unionBy(data, periodArr, "date").sort((a, b) =>
    compareAsc(new Date(a.date), new Date(b.date))
  );

  return merged;
};

export const getPeriodTicks = (weeks) => {
  if (weeks === 12) {
    return 3;
  }
  if (weeks === 52 || weeks === null) {
    return 2;
  }

  if (weeks === 4) {
    return 4;
  }

  return 7;
};

export const getGroupByLabel = (weeks) => {
  if (weeks === 12) {
    return "Week";
  }
  if (weeks === 52 || weeks === null) {
    return "Month";
  }

  return "Day";
};
export const getPeriodVal = (weeks, date) => {
  if (weeks === 12) {
    return getWeek(date);
  }
  if (weeks === 52) {
    return format(date, "MMMM yyyy");
  }
  if (weeks === null) {
    return format(date, "MMMM yyyy");
  }

  return format(date, "MMM do, yyyy");
};

/**
 * get the dates between `startDate` and `endSate` with equal granularity
 */
export const getTicks = (startDate = new Date(), endDate, num) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  let current = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};
