import React, { useState } from "react";
import { Heading, VStack, Divider, Box } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";

/* local imports */
import { client } from "utils/awsConfig.js";

import { INVITE_USER } from "graphql/mutations";

const InviteHeroWidget = ({ onSuccess }) => {
  const [invitee, setInvitee] = useState({
    firstName: undefined,
    email: undefined,
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (property) => (e) =>
    setInvitee((prev) => ({ ...prev, [property]: e.target.value }));

  const sendInvitation = async (evt) => {
    evt.preventDefault();
    setIsSending(true);
    console.log(invitee.email);
    try {
      const userInvitation = await client.mutate({
        mutation: INVITE_USER,
        variables: {
          input: {
            userEmail: invitee.email,
            userFirstName: invitee.firstName,
            action: "INVITE",
          },
        },
      });

      setIsSending(false);
      alert("Invitation sent successfully");

      onSuccess();
      console.log({ userInvitation });
      setInvitee({
        firstName: "",
        email: "",
      });
    } catch (error) {
      alert("Failed to send invitation.");
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <form onSubmit={sendInvitation} style={{ width: "100%" }}>
        <VStack alignItems="flex-start" spacing={6}>
          <FormControl id="firstName">
            <FormLabel fontWeight="semibold">Name</FormLabel>
            <Input
              name="firstName"
              type="firstName"
              value={invitee.firstName}
              onChange={handleChange("firstName")}
              required
              placeholder="John Doe"
              size="lg"
            />
          </FormControl>
          <FormControl id="email">
            <FormLabel fontWeight="semibold">Email address</FormLabel>
            <Input
              name="email"
              type="email"
              value={invitee.email}
              onChange={handleChange("email")}
              required
              placeholder="john.doe@gmail.com"
              size="lg"
            />
          </FormControl>
          <Button
            type="submit"
            onClick={sendInvitation}
            variant="solid"
            colorScheme="linkedin"
            rounded="lg"
            // mt={2}
            isLoading={isSending}
            loadingText="Sending invitation..."
          >
            Send Invitation
          </Button>
        </VStack>
      </form>
    </>
  );
};

export default InviteHeroWidget;
