import React, { useCallback, useState } from "react";
import {
  HStack,
  VStack,
  Divider,
  Flex,
  Text,
  Badge,
  StackDivider,
  Box,
  OrderedList,
  ListItem,
  Link,
} from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { Spinner } from "@chakra-ui/spinner";
import { Tooltip } from "@chakra-ui/tooltip";
import Icon from "@chakra-ui/icon";
import { addMinutes, format, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useDropzone } from "react-dropzone";
import { parseString as csvParseString } from "@fast-csv/parse";
import { get, size } from "lodash";

/* icon imports */
import {
  BsFillFileEarmarkRuledFill,
  BsArrowRightShort,
  BsFillCalendarDateFill,
} from "react-icons/bs";
import { MdAssignment, MdLink } from "react-icons/md";

/* local imports */
import BulkEventsTemplate from "assets/events/bulk-events-template.csv";
import { getStatusColor } from "../constants";
import { createEvent } from "../services";

const ImportEventsModal = (props) => {
  const { isOpen, onClose } = props;

  const toast = useToast();

  const handleClose = () => {
    setUploadedEvents([]);
    onClose();
  };

  const [uploadedEvents, setUploadedEvents] = useState([]);
  const [parsing, setParsing] = useState(false);

  const handleUploadEvents = async () => {
    Promise.all(uploadedEvents.map((payload) => createEvent(payload)))
      .then(() => {
        toast({
          title: "Events created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log("[Create Event Failed] Error occurred", err);
        alert("An error occurred: Event not created");
      })
      .finally(handleClose);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = () => {
        // Do whatever you want with the file contents
        const rawString = reader.result;

        setParsing(true);

        const parsedEvents = [];
        try {
          csvParseString(rawString, { headers: true })
            .on("error", (error) => console.error(error))
            .on("data", (row) => {
              const requiredProps = [
                "title",
                "startDate",
                "startTime",
                "duration",
                "type",
                "permissionTier",
              ];

              if (
                requiredProps.every((requiredProp) =>
                  Object.keys(row).includes(requiredProp)
                ) &&
                requiredProps.every(
                  (requiredProp) => size(row[requiredProp]) > 0
                )
              ) {
                // this row contains all the required props for an event creation & contains valies for them (i.e. not empty)
                // this row can be transformed into an event
                try {
                  const {
                    title,
                    startDate,
                    startTime,
                    duration,
                    type,
                    permissionTier,
                  } = row;

                  const eventPayload = {
                    title,
                    description: get(row, "description").length
                      ? get(row, "description")
                      : null,
                    duration,
                    type: type.toUpperCase(),
                    permissionTier: permissionTier.toUpperCase(),
                    startDate: zonedTimeToUtc(
                      // unsafe, but adds flexibility. This will accept ISO yyyy-mm-dd, mm/dd/yyyy, and long dates mmm dd yyyy (such as Jan 1 2023). Wrapped in try-catch to fail gracefully. Will only import events with valid dates.
                      `${format(
                        new Date(startDate),
                        "yyyy-MM-dd"
                      )} ${startTime}`,
                      Intl.DateTimeFormat().resolvedOptions().timeZone
                    ).toISOString(),
                    signupLink: get(row, "signupLink").length
                      ? get(row, "signupLink")
                      : null,
                    joinLink: get(row, "joinLink").length
                      ? get(row, "joinLink")
                      : null,
                  };

                  parsedEvents.push(eventPayload);
                } catch (error) {
                  console.log("Failed to parse row", error);
                }
              } else {
                console.log("INVALID ROW", row);
              }
            })
            .on("end", (rowCount) => {
              if (rowCount === 0) {
                throw new Error("process Csv Data Failed: CSV is empty");
              }

              setUploadedEvents((evts) => [...evts, ...parsedEvents]);
              setParsing(false);
            });
        } catch (error) {
          console.error(error.message);
        }
      };
      reader.readAsText(file);
    });
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="3xl"
      blockScrollOnMount={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {uploadedEvents.length
            ? `Importing ${uploadedEvents.length} Events`
            : `Import Events`}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody p={6}>
          {uploadedEvents.length ? (
            <VStack divider={<StackDivider />} m={-6}>
              {uploadedEvents.map((event, idx) => (
                <EventCard key={`event-${idx}`} {...event} />
              ))}
            </VStack>
          ) : parsing ? (
            <Flex
              rounded="md"
              h="64"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              <FileUploadZone onDrop={onDrop} />
              <FileSchemaHelper />
            </>
          )}
        </ModalBody>
        {uploadedEvents.length ? (
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={handleUploadEvents}
              colorScheme="linkedin"
              rounded="md"
              type="submit"
              loadingText="Creating events..."
            >
              Create Events
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};
export default ImportEventsModal;

const EventCard = ({
  type,
  permissionTier,
  title,
  description,
  startDate,
  duration,
  ...rest
}) => {
  return (
    <HStack spacing={6} alignItems="flex-start" p={6} w="full">
      <VStack alignItems="flex-start" spacing={2} w="full">
        <Flex>
          <Badge ml={0}>{type}</Badge>
          <Badge ml="1.5" colorScheme={getStatusColor(permissionTier)}>
            {permissionTier}
          </Badge>
        </Flex>
        <VStack alignItems="flex-start" spacing="-1" w="full">
          <Text fontSize="2xl">{title}</Text>
          <Text color="gray.500">{description}</Text>
        </VStack>
      </VStack>

      <VStack justify="space-between" minW="64" align="flex-start" pt="7">
        <VStack w="full" align="flex-start" spacing="-1">
          <Text fontWeight="bold">{format(parseISO(startDate), "PPP")}</Text>
          <Text mt="-2" color="gray.500">
            {format(parseISO(startDate), "p")} —{" "}
            {format(addMinutes(parseISO(startDate), +duration), "p")}{" "}
            {format(parseISO(startDate), "z")}
          </Text>
        </VStack>
        <VStack w="full" align="flex-start" spacing={0}>
          {get(rest, "signupLink") ? (
            <Tooltip label={rest["signupLink"]}>
              <Flex align="center" color="gray.500">
                <MdAssignment />
                <Text ml="1.5">Registration link included</Text>
              </Flex>
            </Tooltip>
          ) : null}
          {get(rest, "joinLink") ? (
            <Tooltip label={rest["joinLink"]}>
              <Flex align="center" color="gray.500">
                <MdLink />
                <Text ml="1.5">Join link included</Text>
              </Flex>
            </Tooltip>
          ) : null}
        </VStack>
      </VStack>
    </HStack>
  );
};

const FileUploadZone = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "text/csv": [".csv"],
    },
  });
  return (
    <Flex
      _hover={{
        borderColor: "linkedin.500",
        color: "linkedin.700",
      }}
      borderWidth="2px"
      borderStyle="dashed"
      borderColor={isDragActive ? "linkedin.500" : "gray.300"}
      rounded="md"
      h="64"
      justifyContent="center"
      alignItems="center"
      color={isDragActive ? "linkedin.500" : "gray.600"}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <VStack spacing={6}>
        <HStack fontSize="4xl" spacing={1} color="gray.300">
          <BsFillFileEarmarkRuledFill />
          <BsArrowRightShort />
          <BsFillCalendarDateFill />
        </HStack>
        <VStack spacing={0}>
          <Text>Drag and drop a CSV file here</Text>
          <Text color="gray.400" fontSize="sm">
            or, click to browse files from your computer
          </Text>
        </VStack>
      </VStack>
    </Flex>
  );
};

const FileSchemaHelper = () => (
  <Accordion allowToggle mt="4">
    <AccordionItem
      bg="gray.100"
      border="none"
      borderLeft="2px"
      borderLeftColor="blue.400"
      rounded="md"
      borderStartRadius="0px"
    >
      <Text as="h2">
        <AccordionButton pl="0" borderBottom="1px" borderBottomColor="gray.300">
          <Box as="span" pl={4} flex="1" textAlign="left">
            Read more about the supported data format
          </Box>
          <Button
            as={Link}
            download
            href={BulkEventsTemplate}
            textDecoration="none"
            colorScheme="blue"
            variant="ghost"
            size="sm"
            leftIcon={<Icon as={BsFillFileEarmarkRuledFill} size="sm" />}
            mr={2}
            onClick={(evt) => {
              evt.stopPropagation();
            }}
          >
            Download Template
          </Button>
          <AccordionIcon />
        </AccordionButton>
      </Text>
      <AccordionPanel pb={4} pl="8" maxW="75%">
        <Text>
          A comma-separated-values file (CSV) should contain a header row and
          multiple columns. Each row should represent 1 event and each column
          should contain only 1 data type. Since CSV files are parsed as
          strings, they must follow specific patterns in order to be interpreted
          correctly.
        </Text>
        <Text mt="2">
          The supported columns and their patterns are as follows:
        </Text>
        <OrderedList color="orange.600" pl="6">
          <ListItem>title</ListItem>
          <ListItem>description</ListItem>
          <ListItem>
            startTime{" "}
            <Text display="inline" color="gray.500">
              — 24-hour clock (hh:mm){" "}
              <i>
                Note: automatically uses local timezone, custom timezone
                currently not supported for bulk creation
              </i>
            </Text>
          </ListItem>
          <ListItem>
            startDate{" "}
            <Text display="inline" color="gray.500">
              — in the following patterns: YYYY-MM-DD or MM/DD/YYYY
            </Text>
          </ListItem>
          <ListItem>
            duration{" "}
            <Text display="inline" color="gray.500">
              — in minutes
            </Text>
          </ListItem>
          <ListItem>
            type{" "}
            <Text display="inline" color="gray.500">
              — one of the following options: VIRTUAL | INPERSON | ASYNC | INAPP
            </Text>
          </ListItem>
          <ListItem>
            permissionTier{" "}
            <Text display="inline" color="gray.500">
              — one of the following options: FREE | COMMUNITYSUBSCRIBER |
              PROSUBSCRIBER
            </Text>
          </ListItem>
          <ListItem>
            joinLink{" "}
            <Text display="inline" color="gray.500">
              — link to join event
            </Text>
          </ListItem>
          <ListItem>
            signupLink{" "}
            <Text display="inline" color="gray.500">
              — link to signup to event (in case pre-registration is required)
            </Text>
          </ListItem>
        </OrderedList>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
