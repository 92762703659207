import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Auth } from "aws-amplify";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { Box } from "@chakra-ui/layout";
import { get } from "lodash";
import qs from "qs";

/* local imports */
import { checkOrgCode } from "./services";
import { STEPS } from "./constants";
import CustomOrgRegister from "./components_custom/CustomOrgRegister";
import CustomOrgShipping from "./components_custom/CustomOrgShipping";
import RedeemOrgComplete from "./components_redeem/RedeemOrgComplete";
import CustomOrgError from "./components_custom/CustomOrgError";

import { logout } from "utils/authHelpers";
import Loading from "common/Loading";

const CustomOrgSubscription = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const { orgCode } = useParams();

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(STEPS.landing);

  const navigate = useCallback(
    (selectedStep) => {
      history.push({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: selectedStep }),
      });
      setStep(STEPS[selectedStep]);
    },
    [url, currentParams, history]
  );

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    orgId: null,
    requiresKit: true,
    errorMessage: null,
    acceptedTos: false,
    acceptedPrivacyPolicy: false,
    consentGiven: "",
  });

  useEffect(() => {
    setFormState((prev) => ({ ...prev, ...currentParams }));
  }, [currentParams]);

  const handleInputChange = (property) => (e) => {
    const inputType = get(e, "target.type");
    switch (inputType) {
      case "checkbox":
        setFormState((prev) => ({
          ...prev,
          [property]: get(e, "target.checked") ?? e,
        }));
        return;

      default:
        setFormState((prev) => ({
          ...prev,
          [property]: get(e, "target.value") ?? e,
        }));
        return;
    }
  };

  const updateFormState = (property, value) =>
    setFormState((prev) => ({ ...prev, [property]: value }));

  const currentStep = useMemo(() => currentParams.step, [currentParams]);
  useEffect(() => {
    if (!currentStep) {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS.landing }),
      });
    } else {
      navigate(currentStep);
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS[currentStep] }),
      });
    }
  }, [currentStep]);

  useEffect(() => {
    const fetchOrgInfo = async () => {
      setLoading(true);
      const { data: orgInfo, error } = await checkOrgCode(orgCode);
      setLoading(false);

      if (orgInfo) {
        const {
          id: orgId,
          orgName,
          subscriptionDescription: subscription,
          requiresKit,
          specialGroup,
        } = orgInfo;
        setFormState((prev) => ({
          ...prev,
          orgId,
          orgName,
          subscription,
          requiresKit,
          specialGroup,
        }));
      } else {
        navigate(STEPS["error"]);
        history.replace({
          pathname: url,
          search: qs.stringify({ ...currentParams, step: STEPS["error"] }),
        });
        setFormState((prev) => ({ ...prev, errorMessage: error }));
      }
    };

    fetchOrgInfo();
  }, [orgCode]);

  useEffect(() => {
    const isLoggedIn = async () => {
      const user = await Auth.currentAuthenticatedUser();
      return !!user;
    };
    if (isLoggedIn()) {
      logout();
    }
  }, []);

  console.log(formState);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      {loading && <Loading />}
      <CurrentStep
        step={step}
        navigate={navigate}
        handleChange={handleInputChange}
        formState={formState}
        updateFormState={updateFormState}
      />
    </Box>
  );
};

export default CustomOrgSubscription;

const CurrentStep = ({ step, ...controls }) => {
  switch (step) {
    case STEPS.thankyou:
      return <RedeemOrgComplete {...controls} />;
    case STEPS.shipping:
      return <CustomOrgShipping {...controls} />;
    case STEPS.error:
      return <CustomOrgError {...controls} />;
    case STEPS.landing:
    default:
      return <CustomOrgRegister {...controls} />;
  }
};
