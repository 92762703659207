import React from "react";
import { Container, Grid, GridItem, VStack, Heading } from "@chakra-ui/layout";

/* icon imports */

/* local imports */
import ShipmentListWidget from "./components/ShipmentListWidget";

const Shipments = () => {
  return (
    <Container maxW="8xl" pt="8">
      <Heading pb="6">Shipments</Heading>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(4, 1fr)" }}
        gap={6}
      >
        <GridItem colSpan={{ base: 4, xl: 4 }}>
          <ShipmentListWidget />
        </GridItem>
        <GridItem colSpan={{ base: 4, xl: 1 }}>
          <VStack spacing={4}></VStack>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default Shipments;
