/* local imports */
import {
  CREATE_SHIPMENT,
  UPDATE_SHIPMENT,
  UPDATE_USER,
} from "graphql/mutations";
import {
  GET_SHIPMENT,
  GET_SHIPMENTS,
  GET_USER_SETTINGS,
  GET_USER_SHIPMENTS,
} from "graphql/queries";
import { client } from "utils/awsConfig";
import ShipStation from "utils/shipstation";

export const getProducts = (...args) =>
  ShipStation.products.getAll({ page: 1, pageSize: 10, ...args });

export const getStores = (...args) => ShipStation.stores.getAll({ ...args });
export const getCarriers = (...args) =>
  ShipStation.carriers.getAll({ ...args });
export const getServices = (carrierCode) =>
  ShipStation.carriers.getServices(carrierCode);
export const getPackages = (carrierCode) =>
  ShipStation.carriers.getPackages(carrierCode);

export const createOrder = (orderDetails) =>
  ShipStation.orders.createOrUpdate({
    orderNumber: orderDetails?.orderNumber,
    orderDate: new Date().toISOString(),
    orderStatus: "awaiting_shipment",
    customerEmail: orderDetails?.email ?? "",
    billTo: {
      name: orderDetails?.name ?? "",
      company: null,
      street1: orderDetails?.address1 ?? null,
      street2: orderDetails?.address2 ?? null,
      street3: null,
      city: orderDetails?.city ?? null,
      state: orderDetails?.state ?? null,
      postalCode: orderDetails?.zip ?? null,
      country: "US",
      phone: orderDetails?.phone ?? null,
      residential: null,
    },
    shipTo: {
      name: orderDetails?.name ?? "",
      company: null,
      street1: orderDetails?.address1 ?? null,
      street2: orderDetails?.address2 ?? null,
      street3: null,
      city: orderDetails?.city ?? null,
      state: orderDetails?.state ?? null,
      postalCode: orderDetails?.zip ?? null,
      country: "US",
      phone: orderDetails?.phone ?? null,
      residential: null,
    },
    items: orderDetails?.products.map((prod) => ({
      lineItemKey: null,
      sku: prod?.sku ?? null,
      name: prod?.name ?? null,
      weight: {
        value: prod?.weightOz ?? 0,
        units: "ounces",
      },
      quantity: prod?.quantity ?? null,
      taxAmount: null,
      shippingAmount: null,
      warehouseLocation: prod?.warehouseLocation,
      productId: prod?.productId ?? null,
      fulfillmentSku: prod?.fulfillmentSku,
    })),
    shippingAmount: 0,
    advancedOptions: {
      storeId: orderDetails?.storeId,
    },
  });

export const updateOrder = (orderKey, orderDetails) =>
  ShipStation.orders.createOrUpdate({ orderKey, ...orderDetails });

export const createLabel = (orderDetails = {}) =>
  ShipStation.orders.createLabelForOrder(orderDetails);

export const getUserDetails = (id = "", ...args) =>
  client.query({
    query: GET_USER_SETTINGS,
    variables: {
      id,
      ...args,
    },
  });

export const getShipments = (...args) =>
  client.query({ query: GET_SHIPMENTS, fetchPolicy: "network-only", ...args });
export const getShipment = (id, ...args) =>
  client.query({ query: GET_SHIPMENT, variables: { id }, ...args });

export const getUserShipments = (id = "", ...args) =>
  client.query({
    query: GET_USER_SHIPMENTS,
    fetchPolicy: "network-only",
    variables: {
      id,
    },
    ...args,
  });

export const createShipment = (orderParams) =>
  client.mutate({
    mutation: CREATE_SHIPMENT,
    variables: {
      input: orderParams,
    },
  });
export const updateShipment = (orderParams) =>
  client.mutate({
    mutation: UPDATE_SHIPMENT,
    variables: {
      input: orderParams,
    },
  });

export const updateUser = (userId, userParams) =>
  client.mutate({
    mutation: UPDATE_USER,
    variables: {
      input: { id: userId, ...userParams },
    },
  });
