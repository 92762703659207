import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import {
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  Link as ChakraLink,
  VStack,
  Container,
  Box,
  Spinner,
} from "@chakra-ui/react";
/** asset imports */
import appstore from "assets/confirmation/appstore.png";

/** local imports */
import { publicClient } from "utils/awsConfig";
import { REGISTER_USER_ON_DEMIO } from "graphql/mutations";
import { APPSTORE_LINK, PLAYSTORE_LINK } from "constants/downloadApp";

const AppBadges = (props) => (
  <HStack w="full" align="baseline" justify="center">
    <a href={APPSTORE_LINK} target="_blank" rel="noreferrer">
      <Image height="20" src={appstore} alt="Apple App Store Badge" />
    </a>
    <a href={PLAYSTORE_LINK} target="_blank" rel="noreferrer">
      <Image
        alt="Get it on Google Play"
        height="20"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
      />
    </a>
  </HStack>
);

const RegisterIntroWebinar = () => {
  const [status, setStatus] = useState("IDLE");
  const [error, setError] = useState({ type: "", message: null });

  const [query] = useQueryParams({
    payload: StringParam,
  });

  useEffect(() => {
    if (query.payload) {
      const decodedPayload = Buffer.from(query.payload, "base64").toString(
        "ascii"
      );
      setStatus("LOADING");
      try {
        const parsedPayload = JSON.parse(decodedPayload);
        publicClient
          .mutate({
            mutation: REGISTER_USER_ON_DEMIO,
            variables: {
              demioRegistrationInput: parsedPayload,
            },
          })
          .then((response) => {
            const { result, error } = response.data.registerOnDemio;
            if (error) throw new Error(error);

            setTimeout(() => {
              setStatus("SUCCESS");
            }, 3000);
            return result;
          })
          .catch((err) => {
            setError({ type: "APIError", message: err.message });
            setTimeout(() => {
              setStatus("FAILED");
            }, 3000);
          });
      } catch (error) {
        setStatus("FAILED");
        console.log("Malformed Payload - " + decodedPayload);
        setError({
          type: "MalformedPayloadError",
          message: "Malformed Payload - " + decodedPayload,
        });
      }
    }

    return () => {};
  }, [query.payload]);

  if (!query.payload) {
    return <Redirect to="/" />;
  }

  switch (status) {
    case "SUCCESS":
      return (
        <Box
          bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
          width="100%"
          height="100%"
          minH="100vh"
          pt="15vh"
        >
          <Container maxW="container.lg" textAlign="center">
            <Heading size="3xl" color="#5072ec">
              You&apos;re registered!
            </Heading>
            <Text my="6" fontSize="lg" color="gray.600">
              Please check your email shortly for an invitation to your very
              first Flowly Webinar. We are excited to meet you and help you get
              started!
            </Text>
            <VStack mt="12" spacing={6}>
              <AppBadges />
              <ChakraLink as={Link} to="/" color="blue.500">
                Take me back to homepage
              </ChakraLink>
            </VStack>
          </Container>
        </Box>
      );
    case "FAILED": {
      const { type: errorType, message: errorMessage } = error;
      const decodedPayload = Buffer.from(query.payload, "base64").toString(
        "ascii"
      );

      const errorFraming =
        errorType === "MalformedPayloadError"
          ? `I tried to register using a malformed payload (${query.payload})`
          : `I tried to register (ID:${JSON.parse(decodedPayload)?.dateId})`;

      const mailerMessage = `mailto:contact@flowly.world?subject=Unable to register for Intro Webinar on Demio&body=${errorFraming} on ${new Date().toISOString()} and received the following error: ${errorMessage}`;

      return (
        <Box
          bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
          width="100%"
          height="100%"
          minH="100vh"
          pt="15vh"
        >
          <Container maxW="container.lg" textAlign="center">
            <Heading size="3xl" color="#5072ec">
              Hmm, looks like we were not able to get you registered
            </Heading>
            {errorType === "MalformedPayloadError" ? (
              <Text mt="6" fontSize="lg" color="gray.600">
                Please check the link you entered and try again.
              </Text>
            ) : (
              <Text mt="6" fontSize="lg" color="gray.600">
                Please get in touch with one of our team members at{" "}
                <ChakraLink
                  display="inline"
                  href={mailerMessage}
                  color="blue.500"
                >
                  contact@flowly.world
                </ChakraLink>{" "}
                and we will help you sort it out. Thanks!
              </Text>
            )}
            <VStack mt="14">
              <ChakraLink href={mailerMessage} color="blue.500">
                Get in touch with Flowly Support
              </ChakraLink>

              <ChakraLink as={Link} to="/" color="blue.500">
                Take me back to homepage
              </ChakraLink>
            </VStack>
          </Container>
        </Box>
      );
    }

    case "IDLE":
    case "LOADING":
    default:
      return (
        <Box
          bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
          width="100%"
          height="100%"
          minH="100vh"
          pt="15vh"
        >
          <Container maxW="container.lg" textAlign="center">
            <Heading size="3xl" color="#5072ec">
              Your journey begins today!
            </Heading>
            <Flex my="16" justifyContent="center" alignItems="center">
              <Spinner size="xl" color="#5072ec" thickness="4px" />
            </Flex>
            <Text color="gray.600">
              <b>Congratulations on becoming a Flowly Hero!</b> <br />
              We&apos;re so excited to help you on your jouney towards a life
              free from pain & anxiety.
            </Text>

            <AppBadges />
          </Container>
        </Box>
      );
  }
};

export default RegisterIntroWebinar;
