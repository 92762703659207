import React, { useState } from "react";
import { Box, VStack, Grid, GridItem, Text, HStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { get } from "lodash";

/* local imports */
import { redeemLicense } from "../services";
import { STEPS } from "../constants";
import H1 from "common/H1";
import AddressElements from "common/AddressElements";
import confirmation from "assets/confirmation/confirmation.png";

const CustomOrgShipping = ({
  formState,
  handleChange,
  updateFormState,
  navigate,
}) => {
  const [loading, setLoading] = useState(false);

  const handleRedeemLicense = async (evt) => {
    evt.preventDefault();
    if (!isValidForm(formState)) return;

    let parsedAddress = get(formState, "addr1", null);
    if (parsedAddress) {
      const {
        name = "",
        addr1: address1 = "",
        addr2: address2 = "",
        city = "",
        state = "",
        zip = "",
        country = "United States",
      } = formState;
      parsedAddress = { name, address1, address2, city, state, zip, country };
    }
    try {
      setLoading(true);
      const response = await redeemLicense(formState.orgId, {
        address: parsedAddress,
        mobile: formState.phone,
      });
      if (response) {
        updateFormState("redeemSuccess", response);
        navigate(STEPS.thankyou);
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const isValidForm = (formState) => {
    if (!formState?.addr1 || formState?.addr1?.length < 1) {
      alert("Please enter an address");
      return false;
    }
    if (!formState?.city || formState?.city?.length < 1) {
      alert("Please enter a City");
      return false;
    }
    if (!formState?.state || formState?.state?.length < 1) {
      alert("Please enter a State");
      return false;
    }
    if (!formState?.zip || formState?.zip?.length < 1) {
      alert("Zip code cannot be left empty");
      return false;
    }
    if (formState?.zip?.length !== 5) {
      alert("Zip code must be 5 digits");
      return false;
    }

    return true;
  };

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
      {/* Left */}
      <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
        <form onSubmit={handleRedeemLicense}>
          <VStack my="20" alignItems="flex-start" spacing={6}>
            <VStack alignItems="flex-start" spacing={1}>
              <H1>Almost there</H1>
              <Text fontSize="lg" color="gray.400">
                Let us know where to send your Flowly Pro Kit!
              </Text>
            </VStack>

            <AddressElements
              state={formState}
              handleChange={handleChange}
              elements={{ streets: true, region: true }}
            />

            <HStack alignItems="center" spacing={8}>
              <Button
                type="submit"
                onClick={handleRedeemLicense}
                variant="solid"
                bg="#5072ec"
                colorScheme="blue"
                rounded="xl"
                height="100%"
                isLoading={loading}
                loadingText={"Redeeming your license..."}
                py="1rem"
                px="110px"
              >
                Submit
              </Button>
            </HStack>
          </VStack>
        </form>
      </GridItem>

      {/* Right */}
      <GridItem
        colSpan={6}
        display={{ base: "none", lg: "block" }}
        width="full"
        pt="16"
        pl="5vw"
        pr="10vw"
      >
        <Box display="grid" placeItems="center">
          <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default CustomOrgShipping;

//   <Text
// 	fontSize="lg"
// 	fontWeight="bold"
// 	color="blue.600"
// 	bg="blue.50"
// 	p="4"
// 	rounded="md"
// 	w="full"
//   >
// 	Let us know where to send your kit!
//   </Text>
