import React from "react";
import _ from "lodash";
import { FormControl, Flex, HStack, Text, Input } from "@chakra-ui/react";

export const PreferredUsernameView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content.preferredUsername}
    </Text>
  </Flex>
);
export const PreferredUsernameEdit = ({ state, handleChange }) => {
  return (
    <HStack alignItems="baseline" spacing={4}>
      <FormControl id="firstName">
        <Input
          name="preferredUsername"
          type="text"
          rounded="md"
          value={_.get(state, "preferredUsername")}
          onChange={handleChange("preferredUsername")}
          placeholder={_.get(state, "preferredUsername")}
          required
          size="sm"
        />
      </FormControl>
    </HStack>
  );
};
