import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";
import { Box, Flex, Grid, GridItem, Text, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Spinner } from "@chakra-ui/spinner";
import Icon from "@chakra-ui/icon";
import { useLocation, Redirect } from "react-router-dom";
import qs from "qs";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";
import { PORTAL_ROOT } from "../../Routes";
import { useAuth } from "utils/AuthContext";
import { parseRole } from "utils/authHelpers";

const ConfirmAccount = () => {
  const { state, search } = useLocation();
  const [, authDispatch] = useAuth();
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const [redirectToReferrer, setR2R] = React.useState(false);
  const [verified, setVerified] = React.useState({ is: false, status: "IDLE" });
  //   const [getConfirmation, setGetConfirmation] = useState(false);

  const [accountState, setAccountState] = useState({
    username: "",
    name: "",
    email: "",
    password: "",
    confirmation_code: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let { code, username, ...rest } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    username = username.replace(" ", "+");
    setAccountState((prev) => ({
      ...prev,
      ...rest,
      username,
      confirmation_code: code,
    }));
    confirmSignUp(username, code);
  }, []);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  const loadCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const userObj = {
          name: user.attributes.given_name,
          email: user.attributes.email,
          role: parseRole(
            user.signInUserSession.accessToken.payload["cognito:groups"]
          ),
        };
        window.analytics.identify(user.username, userObj);
        setAccountState((prev) => ({ ...prev, ...userObj }));
        authDispatch({
          type: "LOGIN",
          payload: { isAuthenticated: true, user: userObj },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const login = async () => {
    setLoading(true);
    try {
      await Auth.signIn(
        accountState.email.toLowerCase(),
        accountState.password
      );
      await loadCurrentUser();
      window.analytics.track("logged_in");
      firebase.analytics().logEvent("login");
      setTimeout(() => {
        setR2R(true);
      }, 1000);
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        alert(
          "Email already registered. Please use a different email or log in with the current email."
        );
        return;
      }
      if (e.code === "UserNotConfirmedException") {
        // setGetConfirmation(true);
        return;
      }
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const confirmSignUp = async (username, code) => {
    try {
      await Auth.confirmSignUp(username, code);
      console.log(`Verification successful!`);
      setVerified({ is: true, status: "SUCCESS" });
    } catch (e) {
      setVerified({ is: false, status: "FAILED" });
      console.log(`Confirmation failed:`, e);
      alert(e.message);
    }
  };

  const handleChange = (property) => (e) =>
    setAccountState((prev) => ({ ...prev, [property]: e.target.value }));

  console.log(accountState);
  return (
    <Box
      height="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      {redirectToReferrer ? (
        <Redirect to={state?.from?.pathname ?? `${PORTAL_ROOT}/data`} />
      ) : (
        <Grid gridTemplateColumns="repeat(12, 1fr)" height="full">
          {/* Left */}
          <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
            {verified.is ? (
              <form onSubmit={login}>
                <VStack mt="20" alignItems="flex-start" spacing={8}>
                  <VStack alignItems="flex-start" spacing={1}>
                    <H1>Awesome! Your account is now verified</H1>
                    <Text fontSize="xl" color="gray.400">
                      Enter your login information to proceed with Flowly.
                    </Text>
                  </VStack>
                  <FormControl id="email">
                    <FormLabel fontWeight="semibold">Email address</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={accountState.email}
                      onChange={handleChange("email")}
                      required
                      placeholder="Username or Email"
                      size="lg"
                    />
                  </FormControl>
                  <FormControl id="password">
                    <FormLabel fontWeight="semibold">Password</FormLabel>
                    <InputGroup>
                      <Input
                        name="password"
                        type={isOpen ? "text" : "password"}
                        autoComplete="current-password"
                        placeholder="Password"
                        value={accountState.password}
                        onChange={handleChange("password")}
                        size="lg"
                        required
                      />
                      <InputRightElement top="1">
                        <IconButton
                          bg="transparent !important"
                          variant="ghost"
                          aria-label={
                            isOpen ? "Mask password" : "Reveal password"
                          }
                          icon={
                            isOpen ? (
                              <Icon as={HiEyeOff} />
                            ) : (
                              <Icon as={HiEye} />
                            )
                          }
                          onClick={onClickReveal}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Flex alignItems="center">
                    <Button
                      type="submit"
                      onClick={login}
                      variant="solid"
                      bg="#5072ec"
                      colorScheme="blue"
                      rounded="xl"
                      height="100%"
                      isLoading={loading}
                      loadingText={"Signing in..."}
                      py="1rem"
                      px="110px"
                    >
                      Login
                    </Button>
                  </Flex>
                </VStack>
              </form>
            ) : (
              <VStack mt={56}>
                {verified.status !== "FAILED" ? (
                  <>
                    <Spinner />
                    <Text>
                      One moment please while we verify your account...
                    </Text>
                  </>
                ) : (
                  <>
                    <Text fontSize="xl" fontWeight="semibold">
                      :(
                    </Text>
                    <Text>
                      Looks like something went wrong, please get in touch with
                      a member of the Flowly team to assist you
                    </Text>
                  </>
                )}
              </VStack>
            )}
          </GridItem>

          {/* Right */}
          <GridItem
            colSpan={6}
            display={{ base: "none", lg: "block" }}
            width="full"
            pt="16"
            pl="5vw"
            pr="10vw"
          >
            <Box display="grid" placeItems="center">
              <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
            </Box>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

export default ConfirmAccount;
