import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";

/** local imports */
import { client } from "./awsConfig";

// TODO: This is a WIP refactor of all the most frequent API calls, attempting to abstract them into smaller functions to reduce code duplication.
const handleError = (err, showAlert = false) => {
  console.error(err);
  if (showAlert) alert(err.message);
};

const logSignUp = (username, email, name) => {
  window.analytics.identify(username, {
    email: email.toLowerCase(),
    name: name,
  });

  const date = new Date();
  const params = {
    year: date.getFullYear(),
    day: Math.floor(
      (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
    ),
  };
  window.analytics.track("account_created", params);
  firebase.analytics().logEvent("account_created", params);
};

const logSignIn = (username, email, name) => {
  window.analytics.identify(username, {
    email,
    name,
  });
};
const loadCurrentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      logSignIn(
        user.username,
        user.attributes.email,
        user.attributes.given_name
      );

      return { name: user.attributes.given_name, email: user.attributes.email };
    }
  } catch (err) {
    handleError(err);
  }
};

const signIn = async (email, password) => {
  try {
    await Auth.signIn(email.toLowerCase(), password);
    await loadCurrentUser();
    window.analytics.track("logged_in");
    firebase.analytics().logEvent("login");
  } catch (err) {
    handleError(err, true);
  }
};

const checkUser = async (email) => {
  try {
    await Auth.signIn(
      email.toLowerCase(),
      "aabbccdd134ranggwahfealfeuiabglaewifhafd=========aa=a=a" // long random unlikely password
    );
  } catch (err) {
    console.error(err);
    if (err.code === "NotAuthorizedException") {
      alert(
        "Email already registered. Please use a different email or log in with the current email."
      );
    }
  }
};

const signUpUser = async (email, password, name) => {
  try {
    await Auth.signUp({
      username: getFormattedUsername(email),
      password: password,
      attributes: {
        email: email.toLowerCase(),
        given_name: name,
        family_name: "",
        birthdate: "00/00/0000",
      },
    });
  } catch (err) {
    handleError(err, true);
  }
};
// TODO: ---- End of the Refactor fns -------

export const getFormattedUsername = (email) => {
  let date = new Date();
  var username = email.replace("@", "-");
  username = username.replace("@", "-");
  username = username + "_" + date.toISOString();

  return username.toLowerCase();
};

export const logout = async () => {
  try {
    await Auth.signOut();

    // clean up artifacts & log evt
    window.analytics.track("logged_out");
    window.analytics.reset();
    await client.resetStore();
    localStorage.clear();
  } catch (err) {
    console.log(err);
  }
};

/**
 * This is an ordered list with the highest roles at the top
 */
export const ROLES = {
  ADMIN: "admin",
  CX_TEAM: "cx",
  PILOT_CLINICIAN: "pilot-clinician",
  COACH: "coach",
  GUIDE: "guide",
  USER: "community-user",
};

export const verifyCanAccess = (userRole, requiredRole) => {
  const userLevel = Object.values(ROLES).indexOf(userRole);
  const requiredLevel = Object.values(ROLES).indexOf(requiredRole);
  return userLevel <= requiredLevel;
};

export const parseRole = (userGroups) => {
  if (!userGroups) return ROLES.USER;

  if (Array.isArray(userGroups)) {
    // find the highest value role they have
    const role = Object.values(ROLES).find((role) =>
      userGroups.some((g) => g === role)
    );
    return role;
  }

  console.log("Found non-array value", userGroups);
  const role = Object.values(ROLES).find((v) => userGroups === v);
  return role;
};
