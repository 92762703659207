import React, { useState } from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import { mapKeys, size } from "lodash";

/* local imports */
import Button from "common/Button";
import Loading from "common/Loading";
import { client } from "utils/awsConfig.js";
import Cookies from "utils/cookies";
import { SUBSCRIBE_USER } from "graphql/mutations";

const StripeInput = styled.div`
  line-height: 1.5rem;
  padding: 12px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #92a7b5;
  border-radius: 5px;
  flex: 1;
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    margin-left: 4px;
    margin-right: 4px;
    width: 100%;
  }

  & > :first-child {
    margin-left: 0px;
  }
  & > :last-child {
    margin-right: 0px;
  }
`;

function Payment({ onPaymentSuccess, orderDetails, shipping, shippingPrice }) {
  const stripe = useStripe();
  const elements = useElements();
  const { subscriptionProductId } = useParams();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    window.analytics.track("subscription_attempted", {
      plan: "pro",
      type: orderDetails.subscriptionType.toLowerCase(),
    });
    let paymentMethod;
    try {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (result.error) {
        alert(result.error.message);
        setLoading(false);
        return;
      }
      paymentMethod = result.paymentMethod;
    } catch (e) {
      alert("Couldn't verify payment method. Please check card details.");
      console.error(e);
      setLoading(false);
      window.analytics.track("subscription_failed", {
        plan: "pro",
        type: orderDetails.subscriptionType.toLowerCase(),
        error: JSON.stringify(e),
      });
      return;
    }
    const user = await Auth.currentAuthenticatedUser();
    let [utm, initial_utm] = [Cookies.get("utm"), Cookies.get("initial_utm")];
    let utmPresent = false;
    let initialUtmPresent = false;
    try {
      utmPresent = size(utm) > 0;
      initialUtmPresent = size(initial_utm) > 0;
      if (utmPresent) utm = JSON.parse(decodeURIComponent(utm));
      if (initialUtmPresent)
        initial_utm = JSON.parse(decodeURIComponent(initial_utm));
    } catch (error) {
      utmPresent = false;
      initialUtmPresent = false;
      console.log(error);
    }
    const referring_url = JSON.parse(
      decodeURIComponent(Cookies.get("referring_url"))
    );
    try {
      const response = await client.mutate({
        mutation: SUBSCRIBE_USER,
        variables: {
          input: {
            email: user.attributes.email,
            firstName: user.attributes.given_name,
            lastName: user.attributes.family_name,
            phone: shipping.phone,
            shippingAddress: {
              address1: shipping.addressLine1,
              address2: shipping.addressLine2,
              city: shipping.city,
              state: shipping.state,
              zip: shipping.zip,
              country: shipping.country,
            },
            productId: subscriptionProductId,
            stripePaymentMethod: paymentMethod.id,
            coupon: orderDetails.promoCode ? orderDetails.promoCode : null,
            referralCode: orderDetails.referralCode
              ? orderDetails.referralCode
              : null,
            shippingPrice: shippingPrice,
            utmParams:
              initialUtmPresent || utmPresent
                ? JSON.stringify({
                    ...(utmPresent ? utm : {}),
                    ...(initialUtmPresent
                      ? mapKeys(initial_utm, (v, k) => `initial_${k}`)
                      : {}),
                  })
                : null,
            // referring_url,
          },
        },
      });

      if (response.data?.subscribe?.error) {
        alert(response.data.subscribe.error.message);
        setLoading(false);

        return;
      }
      setLoading(false);

      return onPaymentSuccess();
    } catch (e) {
      alert(
        "There was an error processing your subscription. Please email us at contact@flowly.world. Thanks!"
      );
      setLoading(false);
      window.analytics.track("subscription_failed", {
        plan: "pro",
        type: orderDetails.subscriptionType.toLowerCase(),
        error: JSON.stringify(e),
      });
    }
  };

  const options = {
    style: {
      base: {
        fontSize: "16px",
        color: "#252b40",
        "::placeholder": {
          color: "#8892ad",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading ? <Loading /> : <></>}
      <StripeInput>
        <CardNumberElement options={options} />
      </StripeInput>
      <Line>
        <StripeInput>
          <CardExpiryElement options={options} />
        </StripeInput>
        <StripeInput>
          <CardCvcElement options={options} />
        </StripeInput>
      </Line>
      <Button>Place order</Button>
    </form>
  );
}

export default Payment;
