import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

/** local imports */
import { GUIDE_CREATE_USER } from "graphql/mutations";
import { PhoneNumberInput } from "common/PhoneNumberInput";
import { client } from "utils/awsConfig";

const GuideCreateHeroWidget = ({ onSuccess }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      birthdate: "",
      temporaryPassword: null,
      notifyUser: false,
    },
  });
  const [isSending, setIsSending] = useState(false);

  const onSubmit = async (data) => {
    const { name, email, phone, birthdate, notifyUser, temporaryPassword } = data;
    console.log("TO BE SENT", data);
    setIsSending(true);
    try {
      const createdUser = await client.mutate({
        mutation: GUIDE_CREATE_USER,
        variables: {
          name,
          email,
          phone,
          birthdate,
          notifyUser,
          temporaryPassword
        },
      });

      console.log({ createdUser });
      setIsSending(false);
      if (createdUser?.data?.guideCreateUser){
        alert(`Account created successfully\nHero's temporary password: ${createdUser?.data?.guideCreateUser}`);
      }else{
        alert(`Account created successfully!`);
      }
      

      onSuccess();
    } catch (error) {
      alert("Failed to create Hero account.");
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <VStack alignItems="flex-start" spacing={8}>
          <VStack spacing={4} alignItems="flex-start">
            <Text fontSize="xl">Basic Information</Text>
            <HStack align="flex-start">
              <FormControl id="name" isInvalid={!!errors["name"]} isRequired>
                <FormLabel fontWeight="semibold">Name</FormLabel>
                <Input
                  {...register("name", {
                    required: {
                      value: true,
                      message: "A name is required",
                    },
                  })}
                  isRequired
                  placeholder="John Doe"
                  size="md"
                />
                {!!errors["name"] && (
                  <FormErrorMessage>{errors["name"].message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl id="birthdate">
                <FormLabel fontWeight="semibold">Birthday</FormLabel>
                <Input {...register("birthdate")} type="date" size="md" />
              </FormControl>
            </HStack>
            <FormControl id="temporaryPassword" isInvalid={!!errors["temporaryPassword"]}>
            <FormLabel fontWeight="semibold">Temporary Password</FormLabel>
              <Input 
                {...register("temporaryPassword", {
                  minLength: {
                    value: 8,
                    message: "A temporary password must be at least 8 characters",
                  }
                })}
                size="md"
              />
              {!!errors["temporaryPassword"] && (
                <FormErrorMessage>{errors["temporaryPassword"].message}</FormErrorMessage>
              )}
            </FormControl>
          </VStack>
          <VStack spacing={4} alignItems="flex-start" width="full">
            <Text fontSize="xl">Contact Details</Text>
            <FormControl id="email" isRequired isInvalid={!!errors["email"]}>
              <FormLabel fontWeight="semibold">Email address</FormLabel>
              <Input
                {...register("email", {
                  required: {
                    value: true,
                    message: "An email address is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter a valid email",
                  },
                })}
                type="email"
                isRequired
                placeholder="john.doe@gmail.com"
                size="md"
              />
              {!!errors["email"] && (
                <FormErrorMessage>{errors["email"].message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl id="phone" width="75%">
              <FormLabel fontWeight="semibold">Mobile Phone</FormLabel>
              <Controller
                render={({ field }) => (
                  <PhoneNumberInput
                    {...field}
                    size="md"
                    placeholder="050 123 4567"
                  />
                )}
                name="phone"
                control={control}
                defaultValue=""
              />
            </FormControl>

            <Checkbox {...register("notifyUser")} color="gray.700">
              Would you like to notify them of the account&apos;s creation?
            </Checkbox>
          </VStack>

          <Button
            type="submit"
            variant="solid"
            colorScheme="linkedin"
            rounded="lg"
            // mt={2}
            isLoading={isSending}
            loadingText="Creating account..."
          >
            Create Account
          </Button>
        </VStack>
      </form>
    </>
  );
};
export default GuideCreateHeroWidget;
