import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  Container,
  Grid,
  GridItem,
  VStack,
  Heading,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns/esm";
import { Link } from "react-router-dom";

/* icon imports */
import { HiArrowLeft } from "react-icons/hi";

/* local imports */
import { client } from "utils/awsConfig.js";
import Loading from "common/Loading";
import { beautify } from "utils/string";
import { cleanHRV, coherenceCalculation, RMSSD } from "utils/flow";
import { GET_SESSION_INFO } from "graphql/queries";

import NoDataAvailable from "common/NoDataAvailable";
import HRGraph from "./components/HRGraph";
import SessionStatistics from "./components/SessionStatistics";
import UserProfileSummary from "../Users/components/UserProfileSummary";

function SessionView(props) {
  const { sessionId } = useParams();
  // console.log("@SessionView: ", sessionId);

  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState({
    id: "",
    worldId: "",
    sessionNumber: "",
    timeInFlow: null,
    data: { intro: null, body: null, total: null },
    createdAt: "",
    survey: null,
  });

  useEffect(() => {
    const getSession = async () => {
      setLoading(true);
      try {
        const response = await client.query({
          query: GET_SESSION_INFO,
          variables: {
            id: sessionId,
          },
        });
        const sessionDetails = response.data.getSession;
        // console.log("@SessionView: response ", response.data.getSession);
        setLoading(false);

        let sessionData = null;
        let sessionIntro = null;
        let sessionBody = null;
        if (sessionDetails.data) {
          [sessionIntro, sessionBody] = sessionDetails.data
            .split("_")
            .map((part) => {
              try {
                return JSON.parse(part);
              } catch (error) {
                console.error(`[Session View]: Failed to parse HR Data`, part);
                return [];
              }
            });
          // console.log({ sessionIntro, sessionBody });
          sessionData = [sessionIntro, sessionBody].flat();
          // console.log({ sessionData });
        }

        let formattedSurvey = JSON.parse(sessionDetails.survey);
        if (formattedSurvey && Object.keys(formattedSurvey).length) {
          const { relaxation } = formattedSurvey;
          if (typeof relaxation?.pre === "object") {
            relaxation.pre = relaxation.pre.relaxation ?? null;
          }
          if (typeof relaxation?.post === "object") {
            relaxation.post = relaxation.post.relaxation ?? null;
          }
        }

        let formattedTimeInFlow = sessionDetails.timeInFlow;
        if (formattedTimeInFlow) {
          const d = new Date(1000 * Math.round(formattedTimeInFlow));
          formattedTimeInFlow = format(d, "m:ss");
        }
        setSession({
          ...sessionDetails,
          data: {
            intro: sessionIntro,
            body: sessionBody,
            total: sessionData,
          },
          survey: formattedSurvey,
          timeInFlow: formattedTimeInFlow,
        });
      } catch (error) {
        alert(error);
        setLoading(false);
      }
    };
    getSession();
  }, []);

  // console.log("@SessionView: session", session);

  const coherence = React.useMemo(
    () => coherenceCalculation(session.data.body),
    [session]
  );
  const restingHRV = React.useMemo(() => RMSSD(session.data.intro), [session]);
  const heartrate = React.useMemo(
    () => cleanHRV(session.data.total),
    [session]
  );
  // console.log("DATA", session.data?.intro ?? []);

  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      height="100%"
    >
      <Container maxW="8xl" mt="8">
        {loading ? <Loading /> : null}
        <Box as="section" py="12">
          <Grid
            templateColumns={{ md: "repeat(1, 1fr)", xl: "repeat(3, 1fr)" }}
            gap={6}
          >
            <GridItem colSpan={1}>
              <UserProfileSummary />
            </GridItem>
            <GridItem colSpan={2}>
              <Box mb={8}>
                <Button as={Link} to=".." colorScheme="gray">
                  <Box mr="1.5">
                    <HiArrowLeft fontSize="12" />
                  </Box>
                  Session on{" "}
                  {format(
                    new Date(
                      session.createdAt.length ? session.createdAt : null
                    ),
                    "PPPPp"
                  )}
                </Button>
              </Box>
              <Heading color="linkedin.500" mb={6}>
                {beautify(session.worldId)} {session.sessionNumber}
              </Heading>
              <VStack align="flex-start" spacing={16} width="full">
                <SessionStatistics
                  relaxation={session.survey?.relaxation}
                  timeInFlow={session.timeInFlow}
                  // flowScore={coherenceCalculation}
                  flowScore={coherence}
                  rmssd={restingHRV}
                />
                <VStack width="full" align="flex-start" spacing={4}>
                  <Heading color="linkedin.500">Heartrate</Heading>
                  <Box
                    bg="white"
                    width="full"
                    p="12"
                    // rounded={{ md: "lg" }}
                    rounded="3xl"
                    shadow="md"
                    height="md"
                  >
                    <HRGraph
                      data={heartrate}
                      started={
                        session.createdAt.length ? session.createdAt : null
                      }
                      introData={session.data?.intro ?? []}
                    />
                  </Box>
                </VStack>
                <VStack width="full" align="flex-start" spacing={4}>
                  <Heading color="linkedin.500">Pre-session Notes</Heading>
                  <Box
                    p="8"
                    bg="white"
                    rounded="3xl"
                    shadow="md"
                    minHeight="xs"
                    width="full"
                  >
                    {session.preComment ? (
                      <Text fontSize="md">{session.preComment}</Text>
                    ) : (
                      <NoDataAvailable height="xs" />
                    )}
                  </Box>
                </VStack>
                <VStack width="full" align="flex-start" spacing={4}>
                  <Heading color="linkedin.500">Post-session Notes</Heading>
                  <Box
                    p="8"
                    bg="white"
                    rounded="3xl"
                    shadow="md"
                    minHeight="xs"
                    width="full"
                  >
                    {session.postComment ? (
                      <Text fontSize="md"> {session.postComment} </Text>
                    ) : (
                      <NoDataAvailable height="xs" />
                    )}
                  </Box>
                </VStack>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default SessionView;
