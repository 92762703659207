import React from "react";
import _ from "lodash";
import { FormControl, FormLabel, Flex, Text, Input } from "@chakra-ui/react";

export const EmailView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content.email ?? ""}
    </Text>
  </Flex>
);
export const EmailEdit = ({ state, handleChange }) => (
  <Flex alignItems="baseline">
    <FormControl id="email">
      {/* <FormLabel fontWeight="semibold">Email</FormLabel> */}
      <Input
        name="email"
        type="email"
        rounded="md"
        value={_.get(state, "email")}
        onChange={handleChange("email")}
        placeholder={_.get(state, "email")}
        required
        size="sm"
      />
    </FormControl>
  </Flex>
);
