import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  FormLabel,
  Flex,
  Text,
  Input,
  OrderedList,
  ListItem,
  VStack,
  Select,
  UnorderedList,
  IconButton,
  Box,
  ButtonGroup,
  HStack,
  Spinner,
  Button,
} from "@chakra-ui/react";

/* icon imports */
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { HiPlusSm, HiMinusSm } from "react-icons/hi";
import { AiOutlineDelete } from "react-icons/ai";

/* local imports */
import { beautify } from "utils/string";
import { GET_WORLDS } from "graphql/queries";
import { client } from "utils/awsConfig";
import DataGrid from "common/DataGrid";

export const ResonantFreqView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content.resonantFrequency ? content.resonantFrequency + " BPM" : "N/A"}
    </Text>
  </Flex>
);

export const ResonantFreqEdit = ({ state, handleChange }) => (
  <Flex alignItems="baseline">
    <FormControl id="resonantFrequency">
      {/* <FormLabel fontWeight="semibold">Resonant Frequency</FormLabel> */}
      <Input
        name="resonantFrequency"
        type="text"
        rounded="md"
        value={_.get(state, "resonantFrequency")}
        onChange={handleChange("resonantFrequency")}
        placeholder={_.get(state, "resonantFrequency")}
        required
        size="sm"
      />
    </FormControl>
  </Flex>
);

/*
export const UnlockedWorldsView = ({ content }) => (
  <Flex alignItems="baseline" w="full">
    {content.mapOrder ? (
      <OrderedList>
        {content.mapOrder.map((m) => (
          <ListItem key={m}>
            <Text fontWeight="bold" fontSize="md">
              {beautify(m)}
            </Text>
          </ListItem>
        ))}
      </OrderedList>
    ) : (
      <Text fontWeight="bold" fontSize="md">
        None
      </Text>
    )}
  </Flex>
);

export const UnlockedWorldsEdit = ({ state, handleChange }) => {
  const [selectedWorld, setSelectedWorld] = useState(null);
  const [allWorlds, setAllWorlds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWorlds = async () => {
      setLoading(true);
      try {
        const res = await client.query({
          query: GET_WORLDS,
          variables: {
            limit: 9000,
          },
        });

        setLoading(false);
        setAllWorlds(res.data.listWorlds.items);
        setSelectedWorld(res.data.listWorlds.items[0].id);
      } catch (err) {
        setLoading(false);
        console.log("[Fetch User Data] Error occured: ", err);
      }
    };

    fetchWorlds();
  }, []);

  const changeValuePosition = (arr, init, target) => {
    [arr[init], arr[target]] = [arr[target], arr[init]];
    return arr;
  };

  const moveUp = (idx) => {
    const worlds = _.cloneDeep(_.get(state, "mapOrder"));
    if (!(idx - 1 < 0)) changeValuePosition(worlds, idx, idx - 1);
    // worlds.splice(idx - 1, 0, worlds.splice(idx, 1)[0]);

    handleChange("mapOrder")({
      target: {
        value: worlds,
      },
    });
  };
  const moveDown = (idx) => {
    const worlds = _.cloneDeep(_.get(state, "mapOrder"));
    if (!(idx + 1 >= worlds.length)) changeValuePosition(worlds, idx, idx + 1);
    // worlds.splice(idx + 1, 0, worlds.splice(idx, 1)[0]);

    handleChange("mapOrder")({
      target: {
        value: worlds,
      },
    });
  };
  const removeWorld = (idx) => {
    const worlds = _.cloneDeep(_.get(state, "mapOrder"));
    worlds.splice(idx, 1);

    handleChange("mapOrder")({
      target: {
        value: worlds,
      },
    });
  };
  const addWorld = () => {
    const worlds = _.cloneDeep(_.get(state, "mapOrder"));
    if (!worlds)
      return handleChange("mapOrder")({
        target: {
          value: [selectedWorld],
        },
      });

    if (!worlds.includes(selectedWorld))
      worlds.splice(worlds.length - 1, 0, selectedWorld);

    return handleChange("mapOrder")({
      target: {
        value: worlds,
      },
    });
  };

  console.log(selectedWorld);

  return (
    <VStack alignItems="flex-start" spacing={6} minWidth="md" w="full">
      <OrderedList width="full" ml={0} spacing={2}>
        {_.get(state, "mapOrder")
          ? _.get(state, "mapOrder").map((m, idx) => (
              <ListItem
                key={m}
                role="group"
                display="flex"
                justifyContent="space-between"
                width="full"
                listStyleType="none"
                px="6"
                py="4"
                rounded="md"
                _even={{ bg: "gray.50" }}
              >
                <HStack spacing={4}>
                  <Text fontWeight="bold" fontSize="md" minWidth="180px">
                    {idx + 1}. {beautify(m)}
                  </Text>
                  <ButtonGroup isAttached>
                    <IconButton
                      colorScheme="linkedin"
                      bg="linkedin.400"
                      size="sm"
                      aria-label="Move up"
                      icon={<MdKeyboardArrowUp />}
                      onClick={() => moveUp(idx)}
                    />
                    <IconButton
                      colorScheme="linkedin"
                      bg="linkedin.400"
                      size="sm"
                      aria-label="Move down"
                      icon={<MdKeyboardArrowDown />}
                      onClick={() => moveDown(idx)}
                    />
                  </ButtonGroup>
                  <IconButton
                    colorScheme="red"
                    bg="red.400"
                    size="sm"
                    aria-label="Remove World"
                    icon={<AiOutlineDelete />}
                    onClick={() => removeWorld(idx)}
                  />
                </HStack>
              </ListItem>
            ))
          : null}
      </OrderedList>
      <FormControl id="addWorld">
        <Flex alignItems="flex-start">
          <FormLabel fontWeight="semibold">Unlock World</FormLabel>
          {loading && <Spinner mt="1" size="xs" />}
        </Flex>
        <HStack spacing={2}>
          <Select
            name="addWorld"
            value={selectedWorld}
            onChange={(evt) => setSelectedWorld(evt.target.value)}
            required
            rounded="md"
            size="sm"
          >
            {allWorlds.map((w) => (
              <option key={w.id} value={w.id}>
                {w.name}
              </option>
            ))}
          </Select>
          <Button
            colorScheme="linkedin"
            size="sm"
            onClick={addWorld}
            disabled={!allWorlds.length || loading}
          >
            Unlock
          </Button>
        </HStack>
      </FormControl>
    </VStack>
  );
};

export const CurrentExperiencesView = ({ content }) => {
  const experiences = useMemo(() => {
    if (content.currentExperiences) {
      try {
        const parsed = JSON.parse(content.currentExperiences);
        console.log({ parsed });

        let arr = [];
        Object.entries(parsed).map(([world, stage]) =>
          arr.push({ world: beautify(world), stage })
        );
        return arr;
      } catch (err) {
        console.log("[Failed to parse experiences]: An error occurred.", err);
        return [];
      }
    }
    return [];
  }, [content]);
  return (
    <Box minWidth="md" w="full">
      {experiences.length ? (
        <ChakraTable
          columns={[
            {
              Header: "World Name",
              accessor: "world",
            },
            {
              Header: "Stage",
              accessor: "stage",
            },
          ]}
          data={experiences}
          disablePagination={true}
        />
      ) : (
        <Text fontWeight="bold" fontSize="md">
          No recorded progress
        </Text>
      )}
    </Box>
  );
};
export const CurrentExperiencesEdit = ({ state, handleChange }) => {
  const [selectedWorld, setSelectedWorld] = useState(null);
  const [allWorlds, setAllWorlds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWorlds = async () => {
      setLoading(true);
      try {
        const res = await client.query({
          query: GET_WORLDS,
          variables: {
            limit: 9000,
          },
        });

        setLoading(false);
        setAllWorlds(res.data.listWorlds.items);
        setSelectedWorld(res.data.listWorlds.items[0].id);
      } catch (err) {
        setLoading(false);
        console.log("[Fetch User Data] Error occured: ", err);
      }
    };

    fetchWorlds();
  }, []);

  const experiences = useMemo(() => {
    if (_.get(state, "currentExperiences")) {
      try {
        return JSON.parse(_.get(state, "currentExperiences"));
      } catch (err) {
        console.log("[Failed to parse experiences]: An error occurred.", err);
        return [];
      }
    }
    return {};
  }, [state]);

  const isPastWorldLimit = (worldId) => {
    if (!allWorlds.length) return false;
    return (
      experiences[worldId] >=
      allWorlds.find((w) => w.id === worldId).numSessions + 1
    );
  };

  const advanceStage = (worldId) => {
    const copyExp = _.cloneDeep(experiences);
    copyExp[worldId] = copyExp[worldId] + 1;

    handleChange("currentExperiences")({
      target: {
        value: JSON.stringify(copyExp),
      },
    });
  };
  const downgradeStage = (worldId) => {
    const copyExp = _.cloneDeep(experiences);
    if (!(copyExp[worldId] - 1 < 0)) copyExp[worldId] = copyExp[worldId] - 1;

    handleChange("currentExperiences")({
      target: {
        value: JSON.stringify(copyExp),
      },
    });
  };

  const addWorld = () => {
    const copyExp = _.cloneDeep(experiences);
    if (!copyExp)
      return handleChange("currentExperiences")({
        target: {
          value: JSON.stringify({ [selectedWorld]: 1 }),
        },
      });

    if (!Object.keys(copyExp).includes(selectedWorld))
      copyExp[selectedWorld] = 1;

    return handleChange("currentExperiences")({
      target: {
        value: JSON.stringify(copyExp),
      },
    });
  };

  const removeWorld = (worldId) => {
    const copyExp = _.cloneDeep(experiences);

    handleChange("currentExperiences")({
      target: {
        value: JSON.stringify(_.omit(copyExp, worldId)),
      },
    });
  };

  return (
    <Box minWidth="md" w="full" mt="8">
      <UnorderedList width="full" ml={0} spacing={2}>
        {experiences
          ? Object.entries(experiences).map(([world, stage], idx) => (
              <ListItem
                key={world}
                display="flex"
                justifyContent="space-between"
                width="full"
                listStyleType="none"
                px="6"
                py="4"
                rounded="md"
                _even={{ bg: "gray.50" }}
              >
                <Box flexGrow="1" minWidth="180px">
                  {beautify(world)}
                </Box>
                <HStack
                  spacing={4}
                  fontWeight="semibold"
                  display="flex"
                  alignItems="center"
                >
                  <IconButton
                    colorScheme="linkedin"
                    bg="linkedin.400"
                    size="sm"
                    aria-label="Previous stage"
                    icon={<HiMinusSm />}
                    onClick={() => downgradeStage(world)}
                    disabled={experiences && experiences[world] === 0}
                  />
                  <Text
                    fontWeight="bold"
                    fontSize="md"
                    minWidth="8"
                    textAlign="center"
                  >
                    {stage}
                  </Text>
                  <IconButton
                    colorScheme="linkedin"
                    bg="linkedin.400"
                    size="sm"
                    aria-label="Next stage"
                    icon={<HiPlusSm />}
                    onClick={() => advanceStage(world)}
                    disabled={isPastWorldLimit(world)}
                  />
                  <IconButton
                    colorScheme="red"
                    bg="red.400"
                    size="sm"
                    aria-label="Remove world"
                    icon={<AiOutlineDelete />}
                    onClick={() => removeWorld(world)}
                  />
                </HStack>
              </ListItem>
            ))
          : null}
      </UnorderedList>
      <FormControl id="addWorld">
        <Flex alignItems="flex-start">
          <FormLabel fontWeight="semibold">Unlock World</FormLabel>
          {loading && <Spinner mt="1" size="xs" />}
        </Flex>
        <HStack spacing={2}>
          <Select
            name="addWorld"
            value={selectedWorld}
            onChange={(evt) => setSelectedWorld(evt.target.value)}
            required
            rounded="md"
            size="sm"
          >
            {allWorlds.map((w) => (
              <option key={w.id} value={w.id}>
                {w.name}
              </option>
            ))}
          </Select>
          <Button
            colorScheme="linkedin"
            size="sm"
            onClick={addWorld}
            px="6"
            disabled={!allWorlds.length || loading}
          >
            Add to Profile
          </Button>
        </HStack>
      </FormControl>
    </Box>
  );
};

*/
