import { useQuery } from "react-query";
/** local imports */
import { GET_SUBSCRIPTION_PRODUCTS } from "graphql/queries";
import { publicClient } from "utils/awsConfig";

function useSubscriptionProducts(config, deps = [], queryConfig = {}) {
  const {
    data: res,
    isError,
    isLoading,
    ...rest
  } = useQuery({
    queryKey: ["subscriptionProducts", ...deps],
    queryFn: () => getSubscriptionProducts(config),
    ...queryConfig,
  });

  const products =
    !isError && !isLoading && res
      ? res.data.listSubscriptionProducts.items
      : [];

  return { data: products, isError, isLoading, ...rest };
}

export default useSubscriptionProducts;

const getSubscriptionProducts = (config) =>
  publicClient.query({
    query: GET_SUBSCRIPTION_PRODUCTS,
    ...config,
  });
