import React from "react";
import _ from "lodash";
import { FormControl, Flex, HStack, Text, Input } from "@chakra-ui/react";
import { format } from "date-fns";

export const BirthdateView = ({ content }) => (
  <Flex alignItems="baseline">
    <Text fontWeight="bold" fontSize="md">
      {content.birthdate
        ? format(new Date(content.birthdate), "dd MMM yyyy")
        : "None specificed"}
    </Text>
  </Flex>
);
export const BirthdateEdit = ({ state, handleChange }) => {
  return (
    <HStack alignItems="baseline" spacing={4}>
      <FormControl id="birthdate">
        <Input
          name="birthdate"
          type="date"
          rounded="md"
          value={_.get(state, "birthdate")}
          onChange={handleChange("birthdate")}
          placeholder={_.get(state, "birthdate")}
          required
          size="sm"
        />
      </FormControl>
    </HStack>
  );
};
