import React from "react";
import styled from "styled-components";
import { Children } from "react";

const CardStyle = styled.div`
  filter: drop-shadow(0px 2px 1.25rem rgba(0, 44, 129, 0.2));
  background: #ffffff;
  border-radius: 1.5rem;
  flex: 1;
  margin: 0px 10px;
  padding-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 22px;
  position: relative;
`;
function Card(prop) {
  return (
    <CardStyle className={prop.className}>
      {Children.map(prop.children, (child, i) => child)}
    </CardStyle>
  );
}

export default Card;
