import { client } from "utils/awsConfig";
import { GET_COACH_LINKED_PATIENTS } from "graphql/queries";

export const getCoachLinkedPatients = ({ ...args }) => {
  return client.query({
    query: GET_COACH_LINKED_PATIENTS,
    fetchPolicy: "network-only",
    ...args,
  });
};
