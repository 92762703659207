import React from "react";

/* local imports */
import { useAuth } from "utils/AuthContext";
import { verifyCanAccess } from "utils/authHelpers.js";

const Can = ({ children, role: requiredRole }) => {
  const [{ user }] = useAuth();
  const userRole = user.role;

  const can = verifyCanAccess(userRole, requiredRole);

  if (can) return <>{children}</>;

  return null;
};

export default Can;
