import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { Box } from "@chakra-ui/layout";
import _ from "lodash";
import qs from "qs";

/* local imports */
import RedeemOrgAuth from "./components_redeem/RedeemOrgAuth";
import RedeemOrgRegister from "./components_redeem/RedeemOrgRegister";
import RedeemOrgLogin from "./components_redeem/RedeemOrgLogin";
import RedeemOrgComplete from "./components_redeem/RedeemOrgComplete";
import RedeemOrgLanding from "./components_redeem/RedeemOrgLanding";
import { STEPS } from "./constants";

const RedeemOrgSubscription = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { url } = useRouteMatch();

  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const [step, setStep] = useState(STEPS.landing);

  const navigate = useCallback(
    (selectedStep) => {
      history.push({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: selectedStep }),
      });
      setStep(STEPS[selectedStep]);
    },
    [url, currentParams, history]
  );

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    password: "",
    orgCode: "",
    step: "landing",
    requiresKit: null,
  });

  useEffect(() => {
    setFormState((prev) => ({ ...prev, ...currentParams }));
  }, [currentParams]);

  const handleInputChange = (property) => (e) =>
    setFormState((prev) => ({
      ...prev,
      [property]: _.get(e, "target.value") ?? e,
    }));

  const updateFormState = (property, value) =>
    setFormState((prev) => ({ ...prev, [property]: value }));

  const currentStep = useMemo(() => currentParams.step, [currentParams]);
  useEffect(() => {
    if (!currentStep) {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS.landing }),
      });
    } else {
      navigate(currentStep);
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS[currentStep] }),
      });
    }
  }, [currentStep]);

  console.log(formState);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <CurrentStep
        step={step}
        navigate={navigate}
        handleChange={handleInputChange}
        formState={formState}
        updateFormState={updateFormState}
      />
    </Box>
  );
};

export default RedeemOrgSubscription;

const CurrentStep = ({ step, ...controls }) => {
  switch (step) {
    case STEPS.thankyou:
      return <RedeemOrgComplete {...controls} />;
    case STEPS.login:
      return <RedeemOrgLogin {...controls} />;
    case STEPS.register:
      return <RedeemOrgRegister {...controls} />;
    case STEPS.authDirect:
      return <RedeemOrgAuth {...controls} />;
    case STEPS.landing:
    default:
      return <RedeemOrgLanding {...controls} />;
  }
};
