import styled from "styled-components";

/** local imports */
import { device } from "utils/device";

export const Sticky = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 1rem;
  z-index: 1000;
`;

export const SwitchStyle = styled.div`
  .switch-wrapper {
    position: sticky;
    top: 2rem;
    display: inline-flex;
    padding: 4px;
    border: 1px solid #dde1e9;
    margin-bottom: 40px;
    border-radius: 30px;
    background: white;
  }

  .switch-wrapper [type="radio"] {
    position: absolute;
    left: -9999px;
  }

  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"],
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"] {
    font-weight: 700;
    color: white;
  }

  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"]:hover,
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"]:hover {
    background: transparent;
  }

  .switch-wrapper
    [type="radio"]:checked#monthly
    + label[for="yearly"]
    ~ .highlighter {
    transform: none;
  }

  .switch-wrapper
    [type="radio"]:checked#yearly
    + label[for="monthly"]
    ~ .highlighter {
    transform: translateX(100%);
  }

  .switch-wrapper label {
    font-weight: 700;
    font-size: 18px;
    z-index: 1;
    min-width: 128px;
    line-height: 32px;
    cursor: pointer;
    border-radius: 30px;
    transition: color 0.25s ease-in-out;
    text-align: center;
  }

  .switch-wrapper label:hover {
    background: #f3f4f6;
  }

  .switch-wrapper .highlighter {
    position: absolute;
    top: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 30px;
    background: linear-gradient(96.72deg, #8adbff -38.81%, #8ea4e8 119.98%);
    box-shadow: 0px 2px 10px 0.2px rgba(0, 44, 129, 0.2);

    transition: transform 0.25s ease-in-out;
  }
`;
export const HeroStyle = styled.header`
  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 10px;
  padding: 0 3vw;
  @media ${device.tablet} {
    padding: 0 10vw;
  }
  margin-bottom: 180px;
  .image {
    grid-column: span 12;
    img {
      width: 100%;
    }

    @media ${device.laptopL} {
      grid-column: span 6;
    }
  }
  .card {
    grid-column: span 12;

    @media ${device.laptopL} {
      // padding-left: 5vw;
      grid-column: span 6;
    }
  }

  .title {
    font-size: 2.5rem;
    line-height: 120%;
    font-family: Tommy Soft;
    font-weight: 700;
    color: #5072ec;
    max-width: 1024px;

    @media ${device.laptopL} {
      font-size: 3.2rem;
      line-height: 3.5rem;
    }
  }

  .subtitle {
    font-size: 1.2rem;
    line-height: 120%;
    margin-top: -1.5rem;
    font-family: Tommy Soft;
    font-weight: 400;
    color: rgba(156, 163, 175, 1);
    text-align: center;
    max-width: 1024px;

    @media ${device.laptopL} {
      font-size: 1.5rem;
      line-height: 2.2rem;
    }
  }

  .subheader {
    margin: 0 0;
    font-family: "Tommy Soft";
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 16px;
    color: rgba(156, 163, 175, 0.76);
    text-transform: uppercase;
    letter-spacing: 0.16em;
    text-align: left;
  }
`;
export const StyledBanner = styled.nav`
  text-align: center;
  padding: 0.5rem 0;
  text-align: center;
  b {
    color: #83390b;
    font-size: 0.9rem;
  }
  background: linear-gradient(
    90.36deg,
    rgba(255, 242, 217, 0.66) 15.73%,
    #ffe7bb 73.78%
  );
`;

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  max-width: ${(props) =>
    breakpoints[props.maxWidth] ? breakpoints[props.maxWidth] : breakpoints.md};
`;

export const FeatureCard = styled.div`
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  min-width: ${(props) => (props.minW ? props.minW : 0)};
  max-width: ${(props) => (props.maxW ? props.maxW : "100%")};
  
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 0)};
  
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};
  
  padding-top: ${(props) => (props.pt ? props.pt : 0)};
  padding-bottom: ${(props) => (props.pb ? props.pb : 0)};
  padding-left: ${(props) => (props.pl ? props.pl : 0)};
  padding-right: ${(props) => (props.pr ? props.pr : 0)};

  
  @media ${device.laptopL} {
    margin-right: -2rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-areas: ${(props) =>
    props.templateAreas ? props.templateAreas : ""};
  grid-template-columns: ${(props) =>
    props.templateColumns ? props.templateColumns : ""};
  grid-gap: 2em;

  @media ${device.laptopL} {
    grid-gap: 0;
  }
`;

export const GridItem = styled.div`
  grid-column: span 2;
  grid-column-start: ${(props) => (props.colStart ? props.colStart : 0)};
  grid-column-end: ${(props) => (props.colEnd ? props.colEnd : 0)};
  grid-row-start: ${(props) => (props.area === "pro" ? 1 : "auto")};
  grid-row-end: ${(props) => (props.rowEnd ? props.rowEnd : 0)};

  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};

  @media ${device.laptopL} {
    grid-column: auto;
    grid-row-start: auto;
  }
`;

export const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};
