import React, { useRef, useState } from "react";
import { Auth } from "aws-amplify";
import firebase from "firebase/app";
import "firebase/analytics";
import {
  Box,
  Stack,
  Link as ChakraLink,
  VStack,
  Grid,
  GridItem,
  Text,
  HStack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Link, useHistory } from "react-router-dom";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import Icon from "@chakra-ui/icon";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";
import { IoRefresh } from "react-icons/io5";

/* local imports */
import H1 from "common/H1";
import { useAuth } from "utils/AuthContext";
import { getFormattedUsername } from "utils/authHelpers";
import confirmation from "assets/confirmation/confirmation.png";

const RegisterGuide = () => {
  const [, authDispatch] = useAuth();
  const history = useHistory();
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const [getConfirmation, setGetConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountState, setAccountState] = useState({
    name: "",
    email: "",
    password: "",
    org_name: "",
    confirmation_code: "",
  });

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  const handleChange = (property) => (e) =>
    setAccountState((prev) => ({ ...prev, [property]: e.target.value }));

  const registerAccount = async () => {
    setLoading(true);
    try {
      await Auth.signIn(
        accountState.email.toLowerCase(),
        "aabbccdd134ranggwahfealfeuiabglaewifhafd=========aa=a=a"
      );
    } catch (e) {
      console.log(e);
      if (e.code === "NotAuthorizedException") {
        setLoading(false);
        alert(
          "Email already registered. Please use a different email or log in with the current email."
        );
        return;
      }
    }

    try {
      const formattedUsername = getFormattedUsername(accountState.email);

      const { email, password, name, org_name } = accountState;
      await Auth.signUp({
        username: formattedUsername,
        password: password,
        attributes: {
          email: email.toLowerCase(),
          given_name: name,
          family_name: "",
          birthdate: "00/00/0000",
          "custom:is_guide": "true",
          "custom:registration_info": JSON.stringify({ org_name }),
        },
      });

      window.analytics.identify(formattedUsername, {
        email: email.toLowerCase(),
        name,
      });
      const date = new Date();
      const params = {
        year: date.getFullYear(),
        day: Math.floor(
          (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
        ),
      };

      window.analytics.track("account_created", params);
      firebase.analytics().logEvent("account_created", params);

      setAccountState((prev) => ({ ...prev, username: formattedUsername }));
      setLoading(false);
      setGetConfirmation(true);
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert(e.message);
    }
  };

  const resendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(accountState.username);
      console.log("code resent successfully");
    } catch (err) {
      console.log("error resending code: ", err);
    }
  };
  console.log(accountState);
  return (
    <Box
      height="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <Grid gridTemplateColumns="repeat(12, 1fr)" height="full">
        {/* Left */}
        <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
          {getConfirmation ? (
            <VStack mt="20" alignItems="flex-start" spacing={8}>
              <VStack alignItems="flex-start" spacing={3}>
                <H1>Almost there!</H1>
                <Text fontSize="xl">
                  We emailed a confirmation link to{" "}
                  <strong>{accountState.email}</strong> to verify your email
                  address. Check your email for a link to sign in.
                </Text>
              </VStack>
              <VStack alignItems="flex-start" spacing={1}>
                <Text fontSize="md" color="gray.400">
                  Didn&apos;t get a confirmation email?
                </Text>
                <HStack>
                  <Text fontSize="md" color="gray.400">
                    Check your spam folder or
                  </Text>
                  <ChakraLink onClick={resendConfirmationCode} color="blue.500">
                    <Icon
                      mr={1}
                      color="blue.500"
                      as={IoRefresh}
                      transform="scale(-1,1)"
                    />
                    Send again.
                  </ChakraLink>
                </HStack>
              </VStack>
            </VStack>
          ) : (
            <form onSubmit={registerAccount}>
              <VStack mt="20" alignItems="flex-start" spacing={4}>
                <H1>Join Flowly</H1>
                <FormControl id="fullName">
                  <FormLabel fontWeight="semibold">Full Name</FormLabel>
                  <Input
                    name="fullName"
                    type="text"
                    value={accountState.name}
                    onChange={handleChange("name")}
                    required
                    placeholder="Full Name"
                    size="lg"
                  />
                </FormControl>
                <FormControl id="org_name">
                  <FormLabel fontWeight="semibold">Organization Name</FormLabel>
                  <Input
                    name="org_name"
                    type="text"
                    value={accountState.org_name}
                    onChange={handleChange("org_name")}
                    required
                    placeholder="Organization Name"
                    size="lg"
                  />
                </FormControl>
                <FormControl id="email">
                  <FormLabel fontWeight="semibold">Email address</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={accountState.email}
                    onChange={handleChange("email")}
                    autoComplete="email"
                    required
                    placeholder="Username or Email"
                    size="lg"
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel fontWeight="semibold">Password</FormLabel>
                  <InputGroup>
                    <Input
                      name="password"
                      value={accountState.password}
                      onChange={handleChange("password")}
                      type={isOpen ? "text" : "password"}
                      autoComplete="current-password"
                      placeholder="Password"
                      size="lg"
                      required
                    />
                    <InputRightElement top="1">
                      <IconButton
                        bg="transparent !important"
                        variant="ghost"
                        aria-label={
                          isOpen ? "Mask password" : "Reveal password"
                        }
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "column",
                    xl: "row",
                  }}
                  spacing={4}
                  w="full"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    onClick={registerAccount}
                    variant="solid"
                    bg="#5072ec"
                    colorScheme="blue"
                    rounded="xl"
                    height="100%"
                    isLoading={loading}
                    loadingText={"Creating your account..."}
                    py="1rem"
                    px="110px"
                    w={{
                      base: "full",
                      md: "fit-content",
                      lg: "full",
                      xl: "fit-content",
                    }}
                  >
                    Sign up
                  </Button>
                  <ChakraLink as={Link} to="./login" color="blue.500">
                    Already have an account? Login here
                  </ChakraLink>
                </Stack>

                <Text pt="6" color="gray.500">
                  By continuing, you agree to Flowly&apos;s{" "}
                  <ChakraLink
                    color="blue.500"
                    href="https://www.flowly.world/terms-of-service"
                    isExternal
                  >
                    Terms of Service
                  </ChakraLink>{" "}
                  and{" "}
                  <ChakraLink
                    color="blue.500"
                    href="https://www.flowly.world/privacy-policy"
                    isExternal
                  >
                    Privacy Policy
                  </ChakraLink>
                </Text>
              </VStack>
            </form>
          )}
        </GridItem>

        {/* Right */}
        <GridItem
          colSpan={6}
          display={["none", "none", "none", "block"]}
          width="full"
          pt="16"
          pl="5vw"
          pr="10vw"
        >
          <Box display="grid" placeItems="center">
            <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default RegisterGuide;
