import React from "react";
import {
  GridItem,
  VStack,
  List,
  HStack,
  Heading,
  Avatar,
  Icon,
  ListItem,
  Text,
  Flex,
} from "@chakra-ui/react";
import {
  formatRelative,
  format,
  isToday,
  isAfter,
  subDays,
} from "date-fns/esm";

/** icon imports */
import { IoSend } from "react-icons/io5";
import { BsCheckAll } from "react-icons/bs";

/** local imports */
import UserSearch from "common/UserSearch";
import ChatSearch from "./ChatSearch";
import { useAuth } from "utils/AuthContext";
// import { useMessageProviders, useMessages } from "pages/Messages/hooks";
// import { sendMessage } from "pages/Messages/services";
import { getDisplayName } from "pages/Messages/utils";

const LeftPanel = ({
  conversations,
  handleSwitchChat,
  activeConversationId,
  draftMessages,
  handleNewConversation,
  disableHighlightedChats,
}) => {
  const [{ user }] = useAuth();
  const currentUserId = user?.id;

  return (
    <GridItem
      rounded="md"
      roundedRight="none"
      shadow="xs"
      w="full"
      colSpan={{ lg: 2 }}
    >
      {/* header */}
      <VStack
        spacing={4}
        p="4"
        borderBottom="1px"
        borderBottomColor="gray.100"
        h={16}
      >
        <ChatSearch //will need to extend UserSearch to use listUserCoachLinks and filter by userId
          inputProps={{
            size: "sm",
            rounded: "md",
          }}
          handleSelectedResult={handleNewConversation}
        />
      </VStack>
      {/* Conversation history list */}
      <List shadow="xs">
        {conversations.map(({ id, participants, messages }) => {
          const displayName = participants.filter(
            (participant) => participant.id !== currentUserId
          )[0].name;
          const latestMessage = messages[messages.length - 1];
          return (
            <ChatPerson
              key={id}
              isActive={
                disableHighlightedChats ? false : activeConversationId === id
              }
              onClick={() => handleSwitchChat(id)}
              name={displayName}
              message={
                draftMessages[id]
                  ? `Draft: ${draftMessages[id]}`
                  : latestMessage?.body ?? ""
              }
              date={latestMessage?.createdAt ?? new Date()}
              status={draftMessages[id] ? "draft" : latestMessage?.status ?? ""}
            />
          );
        })}
      </List>
    </GridItem>
  );
};

export default LeftPanel;

const ChatPerson = ({
  isActive = false,
  name = "",
  message = "",
  date = "",
  status,
  ...rest
}) => {
  const dateSentObj = new Date(date);
  const today = new Date();
  const dateStr = isToday(dateSentObj)
    ? format(dateSentObj, "hh:mm aa")
    : isAfter(dateSentObj, subDays(today, 7))
    ? format(dateSentObj, "EEEE")
    : formatRelative(dateSentObj, new Date());

  return (
    <ListItem
      as={HStack}
      rounded="none"
      bg={isActive ? "linkedin.50" : null}
      justifyContent="flex-start"
      spacing={3}
      pl={3}
      py={2}
      // px={6}
      // py={4}
      {...rest}
    >
      <Avatar ml="0" name={name} src="" size="sm" />
      <Flex flexDirection="column" pr="8" minW="calc(100% - 28px);">
        <Flex justify="space-between" align="baseline">
          <Text
            fontWeight="bold"
            whiteSpace="noWrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {name}
          </Text>
          <Text
            fontSize="xs"
            color="#667781"
            whiteSpace="noWrap"
            flexShrink={1}
          >
            {dateStr}
          </Text>
        </Flex>
        <HStack spacing={1}>
          {status !== "draft" ? (
            <Icon
              as={BsCheckAll}
              color={status === "delivered" ? "#53bdeb" : "#667781"}
            />
          ) : null}
          <Text
            color="#667781"
            fontSize="sm"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            fontStyle={status === "draft" ? "italic" : "normal"}
          >
            {message}
          </Text>
        </HStack>
      </Flex>
    </ListItem>
  );
};
