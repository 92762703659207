const fftWindow = require("fft-windowing");
const FFT = require("fft.js");

const freqToIdx = (frequency, maxFreq, numFreq) =>
  parseInt(Math.round(frequency / (maxFreq / asFloat(numFreq - 1))));

const padArray = (array, length, fill) => {
  return length > array.length
    ? [...array].concat(Array(length - array.length).fill(fill))
    : array;
};

const normalize = (input, iMin, iMax, oMin, oMax) => {
  return ((input - iMin) * (oMax - oMin)) / (iMax - iMin) + oMin;
};

const catMullRom = (t, p0, p1, p2, p3) => {
  const alpha = 0.5;
  const t0 = 0.0;

  const t1 =
    t0 +
    alpha * Math.sqrt(Math.pow(p1.x - p0.x, 2.0) + Math.pow(p1.y - p0.y, 2.0));

  const t2 =
    t1 +
    alpha * Math.sqrt(Math.pow(p2.x - p1.x, 2.0) + Math.pow(p2.y - p1.y, 2.0));

  const t3 =
    t2 +
    alpha * Math.sqrt(Math.pow(p3.x - p2.x, 2.0) + Math.pow(p3.y - p2.y, 2.0));

  const tt = t * (t2 - t1) + t1;

  const a1 = ((t1 - tt) / (t1 - t0)) * p0.y + ((tt - t0) / (t1 - t0)) * p1.y;
  const a2 = ((t2 - tt) / (t2 - t1)) * p1.y + ((tt - t1) / (t2 - t1)) * p2.y;
  const a3 = ((t3 - tt) / (t3 - t2)) * p2.y + ((tt - t2) / (t3 - t2)) * p3.y;

  const b1 = ((t2 - tt) / (t2 - t0)) * a1 + ((tt - t0) / (t2 - t0)) * a2;
  const b2 = ((t3 - tt) / (t3 - t1)) * a2 + ((tt - t1) / (t3 - t1)) * a3;

  const c = ((t2 - tt) / (t2 - t1)) * b1 + ((tt - t1) / (t2 - t1)) * b2;

  return c;
};

export const cleanHRV = (signal) => {
  if (!signal) return signal;
  // console.log({ signal }, "needed cleaning");
  const signalSum = signal.reduce(_sum, 0);

  if (signalSum < 12000) {
    return signal;
  }

  const returnSignal = [];
  let i = 0;

  const variation = variance(signal, signalSum / asFloat(signal.length));
  const percentage = lerp(0.2, 0.5, Math.max(...[variation - 500, 0]) / 20000);

  while (i < signal.length - 1) {
    let sum = 0.0;
    const slidingWindow = [];

    while (sum < 12000 && i < signal.length) {
      sum += signal[i];
      slidingWindow.push(signal[i]);
      i++;
    }

    const median = calcMedian(slidingWindow);
    // if (Math.min(...slidingWindow) == 643.0)

    const newWindow = slidingWindow.filter(
      (el) => Math.abs(el - median) < percentage * median
    );

    if (
      slidingWindow.length >= 4 &&
      asFloat(newWindow.length) >
        asFloat(slidingWindow.length) / parseFloat(2.0)
    )
      returnSignal.push(...newWindow);
  }

  // console.log({ returnSignal });
  return returnSignal;
};

const _sum = (a, b) => a + b;

const variance = (x, meanX) => {
  const xLength = x.length;
  let meanXPointer = -meanX;

  let subtraction = x.map((point) => point + meanXPointer);
  let powerResult = subtraction.map((el) => Math.pow(el, 2.0));

  const variance = powerResult.reduce(_sum, 0.0);
  return variance / asFloat(xLength);
};

const lerp = (x0, x1, t) =>
  Math.max(...[Math.min(...[x0 * (1 - t) + x1 * t, x1]), x0]);

const calcMedian = (arr) => {
  let arrCopy = [...arr];
  arrCopy.sort();
  return arrCopy.length % 2 === 0
    ? asFloat(arrCopy[arrCopy.length / 2] + arrCopy[arrCopy.length / 2 - 1]) / 2
    : asFloat(arrCopy[(arrCopy.length - 1) / 2]);
};

export const asFloat = (val) => parseFloat(parseFloat(val).toFixed(2));

export const RMSSD = (data) => {
  if (!data) return null;
  let newData = cleanHRV(data);
  if (newData.length > 1) {
    const calculatedSum = newData.reduce(
      (acc, current, idx, arr) =>
        idx !== 0 ? acc + Math.pow(current - arr[idx - 1], 2) : 0.0,
      0.0
    );
    // console.log("@RMSSD", { calculatedSum, cleanedSignal: newData });
    const finalSum = calculatedSum / asFloat(newData.length - 2);
    return Math.pow(finalSum, 0.5);
  } else return 0;
};

export const coherenceCalculation = (rawData) => {
  let completed = null;
  if (!rawData || rawData?.length < 20) return null;

  // const { dataBarIntro } = session;
  const data = cleanHRV(rawData);
  let remainder = 0.0;
  const samplingFreq = 2.0;
  const samplingPeriod = 1 / samplingFreq;
  let interpolatedPoints = [];
  const dataPoints = [];
  dataPoints.push({ x: 0, y: data[0] });

  data.forEach((point, idx) => {
    if (idx === 0) return null;
    dataPoints.push({ x: point / 1000.0 + dataPoints[idx - 1].x, y: point });
  });
  // console.log("@FLOW CALC", { dataPoints });

  let sum = 0.0;
  for (let i = 1; i < dataPoints.length - 2; i++) {
    let numInterpolatedPoints = Math.floor(
      (dataPoints[i + 1].x - dataPoints[i].x - remainder) / samplingPeriod
    );

    if (numInterpolatedPoints <= 0) continue;
    // console.log("@FLOW CALC", "Num interpolated", { numInterpolatedPoints });

    for (let j = 0; j < numInterpolatedPoints; j++) {
      let t = dataPoints[i].x + j * samplingPeriod + remainder;

      let value = catMullRom(
        normalize(t, dataPoints[i].x, dataPoints[i + 1].x, 0, 1),
        dataPoints[i - 1],
        dataPoints[i],
        dataPoints[i + 1],
        dataPoints[i + 2]
      );

      // console.log("@FLOW CALC", "Interpolated Value", { x: t, y: value });

      sum += value;

      interpolatedPoints.push({ x: t, y: value });
    }

    remainder =
      samplingPeriod -
      (dataPoints[i + 1].x -
        interpolatedPoints[interpolatedPoints.length - 1].x);
  }
  // console.log("@FLOW CALC", { interpolatedPoints });

  let interpolatedSum = interpolatedPoints.reduce(
    (acc, current) => acc + current.y,
    0.0
  );

  let avg = interpolatedSum / interpolatedPoints.length;

  // deduct avg, center points around 0
  interpolatedPoints = interpolatedPoints.map((point) => ({
    ...point,
    y: point.y - avg,
  }));
  // console.log("@FLOW CALC centered around 0", { interpolatedPoints });

  // trim if larger than 4096
  interpolatedPoints = interpolatedPoints.slice(0, 4096);

  const withHammingWindow = fftWindow.hamming(
    interpolatedPoints.map((p) => p.y)
  );
  // console.log("@FLOW CALC post hamming", { withHammingWindow });

  const prepForFFT = padArray(withHammingWindow, 4096, 0);
  // console.log("@FLOW CALC padded", { padded: prepForFFT });

  const f = new FFT(4096);
  const fftOutput = f.createComplexArray();

  f.realTransform(fftOutput, prepForFFT);

  // console.log("@FLOW CALC fft?", { fftOutput });

  // console.log("@FLOW CALC", { dataPoints });
  const powerSpectralDensity = fftOutput
    .map((p, idx) =>
      idx % 2 === 0 ? Math.pow(p, 2) + Math.pow(fftOutput[idx + 1], 2) : null
    )
    .filter((x) => !!x);

  //get a sub-array of PSD looking just in the frequency range of .04Hz to .26Hz
  let peakSearchBand = powerSpectralDensity.slice(
    freqToIdx(0.04, samplingFreq / 2, powerSpectralDensity.length),
    freqToIdx(0.26, samplingFreq / 2, powerSpectralDensity.length)
  );

  const peakMaxWithinBand = Math.max(...peakSearchBand);
  const peakIdxWithinBand = peakSearchBand.indexOf(peakMaxWithinBand);

  // console.log("@FLOW CALC peaks", {
  //   peakSearchBand,
  //   peakMaxWithinBand,
  //   peakIdxWithinBand,
  // });

  //get peak index with respect to whole psd instead of the sub-array search band
  const peakIdxEntire =
    freqToIdx(0.04, samplingFreq / 2, powerSpectralDensity.length) +
    parseInt(peakIdxWithinBand);

  const widePeakBand = powerSpectralDensity.slice(
    peakIdxEntire -
      freqToIdx(0.03, samplingFreq / 2, powerSpectralDensity.length),
    peakIdxEntire +
      freqToIdx(0.03, samplingFreq / 2, powerSpectralDensity.length)
  );

  const peakPower = widePeakBand.reduce((acc, current) => acc + current, 0.0);

  const totalBand = powerSpectralDensity.slice(
    freqToIdx(0.0033, samplingFreq / 2, powerSpectralDensity.length),
    freqToIdx(0.4, samplingFreq / 2, powerSpectralDensity.length)
  );

  const totalPower = totalBand.reduce((acc, current) => acc + current, 0.0);

  const coherence = peakPower / totalPower;
  // console.log("@FLOW CALC FINALLY FLOW SCORE", {
  //   coherence,
  // });

  return coherence;
};
