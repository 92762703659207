export const USER_TYPES = {
  EXISTING: "existingUser",
  CUSTOM: "customRecipient",
};

export const SHIPMENT_STATUSES = {
  WAITING: "WAITING",
  SHIPPED: "SHIPPED",
};
export const getStatusColor = (status) => {
  switch (status) {
    case SHIPMENT_STATUSES.WAITING:
      return "blue";
    case SHIPMENT_STATUSES.SHIPPED:
      return "green";
    default:
      return "gray";
  }
};

export const getStatusTitle = (status) => {
  switch (status) {
    case SHIPMENT_STATUSES.WAITING:
      return "Processing";
    case SHIPMENT_STATUSES.SHIPPED:
      return "Shipped";
    default:
      return "Pending";
  }
};
