import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
  Flex,
} from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { Tooltip } from "@chakra-ui/tooltip";
import { Link, useParams, useHistory } from "react-router-dom";
import { compareAsc, format, parseISO } from "date-fns/esm";
import { ErrorBoundary } from "react-error-boundary";
import "react-calendar-heatmap/dist/styles.css";

/* icon imports */
import { HiArrowLeft } from "react-icons/hi";
import { FaRegQuestionCircle } from "react-icons/fa";

/* local imports */
import UserProfileSummary from "./components/UserProfileSummary";
import WorldPreference from "./components/WorldPreference";
import ScoreTrajectory from "./components/ScoreTrajectory";
import SessionHeatmapWidget from "./components/SessionHeatmapWidget";
import FlowScoreGraph from "./components/FlowScoreGraph";
import RMSSDGraph from "./components/RMSSDGraph";
import TimeInFlowGraph from "./components/TimeInFlowGraph";
import HourlyPreference from "./components/HourlyPreference";

import { client } from "utils/awsConfig.js";
import { coherenceCalculation, RMSSD } from "utils/flow";
import Loading from "common/Loading";
import { PORTAL_ROOT } from "../../Routes";
import ErrorFallback from "common/ErrorFallback";

import { GET_USER, GET_USER_SESSIONS } from "graphql/queries";
import { useAuth } from "utils/AuthContext";
import getRoleRedirectPath from "utils/getRoleRedirectPath";

const TIME_FILTERS = [
  { weeks: 1, label: "1W" },
  { weeks: 4, label: "1M" },
  { weeks: 12, label: "3M" },
  { weeks: 52, label: "1Y" },
  { weeks: null, label: "Max" },
];

function UserView() {
  const history = useHistory();
  const [auth] = useAuth();
  const [unAuthorized, setUnAuthorized] = useState(false);
  const { userId } = useParams();
  console.log({ userId });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    streak: 0,
    lastSessionDate: "",
    sessions: [],
  });

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      console.log("getting user");
      try {
        const userData = await client.query({
          query: GET_USER,
          variables: {
            id: userId,
          },
        });
        const sessionData = await client.query({
          query: GET_USER_SESSIONS,
          variables: {
            id: userId,
            limit: 3650,
            fetchPolicy: "network-only",
          },
        });
        setLoading(false);
        const user = userData.data.getUser;
        const sessions = sessionData.data.getUser.sessions;
        setUser({ ...user, sessions: sessions.items });
      } catch (err) {
        console.log({ err });
        if (err.graphQLErrors[0]?.errorType === "Unauthorized") {
          alert(
            "Sorry, you don't have the right authorization level to access this resource."
          );
          setUnAuthorized(true);
          history.push(`${PORTAL_ROOT}`);
        } else {
          alert(err);
        }
        setLoading(false);
      }
    };
    fetchUser();
  }, [userId]);

  const engagementStats = useMemo(() => {
    if (!user.sessions.length)
      return {
        preSessionScores: [],
        postSessionScores: [],
        worlds: [],
        restingHRV: [],
        flow: [],
      };

    const formattedSurveys = user.sessions
      .sort((a, b) => compareAsc(new Date(a.createdAt), new Date(b.createdAt)))
      .map((s) => {
        let formattedSurvey = JSON.parse(s.survey);
        if (formattedSurvey && Object.keys(formattedSurvey).length) {
          const { relaxation } = formattedSurvey;
          if (typeof relaxation?.pre === "object") {
            relaxation.pre = relaxation.pre.relaxation ?? null;
          }
          if (typeof relaxation?.post === "object") {
            relaxation.post = relaxation.post.relaxation ?? null;
          }
        }

        // let formattedTimeInFlow = s.timeInFlow;
        // if (formattedTimeInFlow) {
        //   const d = new Date(1000 * Math.round(formattedTimeInFlow));
        //   formattedTimeInFlow = format(d, "m:ss");
        // }

        let sessionIntro = null;
        let sessionBody = null;
        if (s.data) {
          [sessionIntro, sessionBody] = s.data.split("_").map((part) => {
            try {
              return JSON.parse(part);
            } catch (error) {
              console.error(`[Session View]: Failed to parse HR Data`, part);
              return [];
            }
          });
        }

        return {
          ...s,
          survey: formattedSurvey,
          relaxationPre: formattedSurvey?.relaxation?.pre
            ? +formattedSurvey.relaxation.pre
            : null,
          relaxationPost: formattedSurvey?.relaxation?.post
            ? +formattedSurvey.relaxation.post
            : null,
          data: {
            intro: sessionIntro,
            body: sessionBody,
          },
        };
      });

    // console.log("@UserView transformed :>> ", formattedSurveys);
    const preSessionScores = [];
    const postSessionScores = [];
    const worlds = [];
    const restingHRV = [];
    const flow = [];
    const timeInFlow = [];
    const hourlyPreference = [];
    formattedSurveys.map((s) => {
      if (s.relaxationPre !== null)
        preSessionScores.push({
          id: s.id,
          fullDate: s.createdAt,
          date: s.createdAt.split("T")[0],
          value: s.relaxationPre,
        });

      if (s.relaxationPost !== null)
        postSessionScores.push({
          id: s.id,
          fullDate: s.createdAt,
          date: s.createdAt.split("T")[0],
          value: s.relaxationPost,
        });

      worlds.push({
        fullDate: s.createdAt,
        date: s.createdAt.split("T")[0],
        id: s.worldId,
      });

      const calcRMSSD = RMSSD(s.data.intro);
      if (!!calcRMSSD && isFinite(calcRMSSD) && !isNaN(calcRMSSD))
        restingHRV.push({
          id: s.id,
          fullDate: s.createdAt,
          date: s.createdAt.split("T")[0],
          value: calcRMSSD,
        });

      const calcFlow = coherenceCalculation(s.data.body);
      if (calcFlow)
        flow.push({
          id: s.id,
          fullDate: s.createdAt,
          date: s.createdAt.split("T")[0],
          value: calcFlow,
        });

      if (s.timeInFlow !== null)
        timeInFlow.push({
          id: s.id,
          fullDate: s.createdAt,
          date: s.createdAt.split("T")[0],
          value: s.timeInFlow,
        });

      hourlyPreference.push({
        id: s.id,
        fullDate: s.createdAt,
        date: s.createdAt.split("T")[0],
        hour: format(parseISO(s.createdAt), "H:00"),
        value: 1,
      });
      return null;
    });

    // console.log("@UserView finalObj :>> ", {
    //   preSessionScores,
    //   postSessionScores,
    //   worlds,
    //   restingHRV,
    //   flow,
    //   timeInFlow,
    //   hourlyPreference,
    // });
    return {
      preSessionScores,
      postSessionScores,
      worlds,
      restingHRV,
      flow,
      timeInFlow,
      hourlyPreference,
    };
  }, [user.sessions]);

  // console.log("@UserView", user);

  return (
    <Box
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 75vh)"
      width="100%"
      height="100%"
    >
      <Container maxW="8xl" pt="8">
        {loading ? <Loading /> : null}
        <Box as="section" py="12">
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(3, 1fr)" }}
            gap={6}
          >
            <GridItem colSpan={1}>
              <UserProfileSummary user={user} unAuthorized={unAuthorized} />
            </GridItem>
            <GridItem colSpan={2}>
              <Box mb={8}>
                <Button
                  as={Link}
                  to={`${PORTAL_ROOT}${getRoleRedirectPath(auth?.user?.role)}`}
                  colorScheme="gray"
                >
                  <Box mr="1.5">
                    <HiArrowLeft fontSize="12" />
                  </Box>
                  Dashboard
                </Button>
              </Box>
              <VStack align="flex-start" spacing={16} width="full">
                <SessionHeatmapWidget user={user} />
                <TabbedWidget
                  title="Flow Score"
                  explainer={`Your Flow Score is based on a metric we call 'coherence'. Coherence is a measure of how well your heart is able to follow your breath during slow, paced breathing. This score ranges from 0 to 100. The higher, the better.`}
                  defaultIndex={4}
                  tabs={TIME_FILTERS.map(({ label, weeks }) => ({
                    label,
                    content: (
                      <FlowScoreGraph
                        data={engagementStats.flow}
                        filterWeeks={weeks}
                      />
                    ),
                  }))}
                />
                <TabbedWidget
                  title="Time in Flow"
                  explainer={`Time in Flow is the cumulative time you spend "in Flow" in each session. You are considered "in Flow" when your Flow Score climbs above 70; which reflects a very high Flow Score and clear representation of your heart following your breath.`}
                  defaultIndex={4}
                  tabs={TIME_FILTERS.map(({ label, weeks }) => ({
                    label,
                    content: (
                      <TimeInFlowGraph
                        data={engagementStats.timeInFlow}
                        filterWeeks={weeks}
                      />
                    ),
                  }))}
                />
                <TabbedWidget
                  title="Resting HRV"
                  explainer={`Your Resting HRV represents the variation in time (milliseconds) between your heartbeats, when you are not doing slow, paced breathing. It is technically called RMSSD.`}
                  defaultIndex={4}
                  tabs={TIME_FILTERS.map(({ label, weeks }) => ({
                    label,
                    content: (
                      <RMSSDGraph
                        data={engagementStats.restingHRV}
                        filterWeeks={weeks}
                      />
                    ),
                  }))}
                />
                <TabbedWidget
                  title="Pre-session Score"
                  explainer={`This is the subjective score you input before and after each Flowly session. Flowly members typically report an average increase of 33% or more in relaxation after each session.`}
                  defaultIndex={4}
                  tabs={TIME_FILTERS.map(({ label, weeks }) => ({
                    label,
                    content: (
                      <ScoreTrajectory
                        data={engagementStats.preSessionScores}
                        filterWeeks={weeks}
                      />
                    ),
                  }))}
                />
                <TabbedWidget
                  title="Post-session Score"
                  explainer={`This is the subjective score you input before and after each Flowly session. Flowly members typically report an average increase of 33% or more in relaxation after each session.`}
                  defaultIndex={4}
                  tabs={TIME_FILTERS.map(({ label, weeks }) => ({
                    label,
                    content: (
                      <ScoreTrajectory
                        data={engagementStats.postSessionScores}
                        filterWeeks={weeks}
                      />
                    ),
                  }))}
                />
                <TabbedWidget
                  title="Time of Day Preference"
                  defaultIndex={4}
                  tabs={TIME_FILTERS.map(({ label, weeks }) => ({
                    label,
                    content: (
                      <HourlyPreference
                        data={engagementStats.hourlyPreference}
                        filterWeeks={weeks}
                      />
                    ),
                  }))}
                />
                <TabbedWidget
                  title="World Preference"
                  defaultIndex={4}
                  // data={engagementStats.postSessionScores}
                  tabs={TIME_FILTERS.map(({ label, weeks }) => ({
                    label,
                    content: (
                      <WorldPreference
                        data={engagementStats.worlds}
                        filterWeeks={weeks}
                      />
                    ),
                  }))}
                />
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default UserView;

const TabbedWidget = ({ title, explainer = null, tabs, ...rest }) => {
  return (
    <Tabs
      width="full"
      variant="soft-rounded"
      colorScheme="orange"
      size="sm"
      isLazy
      {...rest}
    >
      <VStack width="full" align="flex-start" spacing={3}>
        <HStack width="full" justifyContent="space-between">
          <Heading color="linkedin.500">
            <Flex alignItems="center">
              {title}
              {explainer ? (
                <Tooltip label={explainer} fontSize="sm" placement="right">
                  <Box as="span" ml="2">
                    <FaRegQuestionCircle fontSize="16" />
                  </Box>
                </Tooltip>
              ) : null}
            </Flex>
          </Heading>
          <TabList>
            {tabs.map((t, idx) => (
              <Tab
                fontSize="xs"
                _selected={{
                  // bg: "orange.100",
                  // color: "orange.600",
                  bg: "linkedin.500",
                  color: "gray.50",
                  // border: "2px",
                  // borderColor: "orange.200",
                }}
                key={idx}
              >
                {t.label}
              </Tab>
            ))}
          </TabList>
        </HStack>
        <TabPanels>
          {tabs.map((t, idx) => (
            <TabPanel
              key={idx}
              p={{ base: 4, lg: 8 }}
              bg="white"
              rounded="3xl"
              shadow="md"
              minHeight="md"
              width="full"
            >
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                {t.content}
              </ErrorBoundary>
            </TabPanel>
          ))}
        </TabPanels>
      </VStack>
    </Tabs>
  );
};
