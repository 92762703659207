import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useLocation,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { VStack, Grid, GridItem, Text, HStack, Box } from "@chakra-ui/layout";
import { Avatar } from "@chakra-ui/avatar";
import { Elements } from "@stripe/react-stripe-js";
import { get } from "lodash";
import qs from "qs";

/* local imports */
import PaymentDetailsForm from "./GiftCheckout_Payment";

import H1 from "common/H1";
import Loading from "common/Loading";
import vr from "assets/products/vr.png";
import subscription from "assets/products/subscription.svg";
import stripePromise from "utils/stripeConfig";
import { SUBSCRIPTION_TIME_UNITS } from "pages/Checkout/constants";
import { GET_SUBSCRIPTION_PRODUCT_BY_ID } from "graphql/queries";
import { GET_STRIPE_PAYMENT_INTENT } from "graphql/mutations";
import { publicClient } from "utils/awsConfig";

const STEPS = {
  checkout: "checkout",
  shipping: "shipping",
  payment: "payment",
  thankyou: "thankyou",
};

const GiftCheckout = () => {
  const history = useHistory();
  const { search, state: preload } = useLocation();
  const { url } = useRouteMatch();
  const { subscriptionType, subscriptionProductId } = useParams();

  const [loading, setLoading] = useState();
  const [selectedPlan, setSelectedPlan] = useState(
    () =>
      preload ?? {
        id: subscriptionProductId,
        subscriptionType,
        price: 0,
        timeUnits: SUBSCRIPTION_TIME_UNITS.MONTH,
        initialPrice: 0,
        duration: 1,
      }
  );

  const [options, setOptions] = useState({
    // fonts: ["Nunito"],
    appearance: {
      theme: "stripe",
      variables: {
        fontFamily:
          '"Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto","Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        colorPrimary: "#3182CE",
        colorDanger: "#9e2146",
      },
      // style: {
      //   base: {
      //     fontSize: "16px",
      //     color: "#252b40",
      //     "::placeholder": {
      //       color: "#8892ad",
      //     },
      //   },
      //   // invalid: {
      //   //   color: ,
      //   // },
      // },
    },
    loader: "auto",
    clientSecret: "",
  });

  useEffect(() => {
    const fetchClientSecret = async () => {
      // get client secret from API
      setLoading(true);
      const getSecret = await publicClient.mutate({
        mutation: GET_STRIPE_PAYMENT_INTENT,
        variables: {
          operation: "CREATE",
          input: {
            productId: subscriptionProductId,
          },
        },
      });
      setLoading(false);

      const clientSecret = getSecret.data.getStripePaymentSecret.result;

      if (clientSecret) {
        setOptions((prev) => ({ ...prev, clientSecret }));
      }

      if (getSecret.data.getStripePaymentSecret.error) {
        try {
          const parsedErr = JSON.parse(
            getSecret.data.getStripePaymentSecret.error
          );
          console.log(parsedErr);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchClientSecret();
  }, []);

  console.log(options);

  const [step, setStep] = useState(STEPS.shipping);
  const [formState, setFormState] = useState(() => ({
    name: "",
    email: "",
    password: "",
    confirmation_code: "",
    country: "United States",
    shippingPrice: 10.57,
    subscriptionType,
    step,
  }));

  useEffect(() => {
    const fetchProductInfo = async () => {
      setLoading(true);
      try {
        console.log("getting list of products");
        const subscriptionProduct = await publicClient.query({
          query: GET_SUBSCRIPTION_PRODUCT_BY_ID,
          variables: {
            id: subscriptionProductId,
          },
        });

        const productDetails = subscriptionProduct.data.getSubscriptionProduct;
        setSelectedPlan(productDetails);
      } catch (error) {
        console.error(
          `[SubscriptionProduct]: Fetch Product of ID "${subscriptionProductId}" failed`,
          error
        );
      } finally {
        setLoading(false);
      }
    };

    if (!preload) {
      fetchProductInfo();
    }
  }, []);
  const currentParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  const navigate = useCallback(
    (selectedStep) => {
      history.push({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: selectedStep }),
      });
      setStep(STEPS[selectedStep]);
    },
    [url, currentParams, history]
  );

  const currentStep = useMemo(() => currentParams.step, [currentParams]);

  useEffect(() => {
    if (!currentStep) {
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS.shipping }),
      });
    } else {
      navigate(currentStep);
      history.replace({
        pathname: url,
        search: qs.stringify({ ...currentParams, step: STEPS[currentStep] }),
      });
    }
  }, [currentStep]);

  const updateFormState = (property, value) =>
    setFormState((prev) => ({ ...prev, [property]: value }));

  const handleInputChange = (property) => (e) =>
    setFormState((prev) => ({
      ...prev,
      [property]: get(e, ["target", "value"], e),
    }));

  const next = () => navigate(STEPS.payment);
  const goBack = () => navigate(STEPS.shipping);
  const stepBack = () => history.replace(`../../gift`);
  const nextStep = () => history.replace(`../../gift/confirmation`);

  const durationInMonths = (duration, unit) => {
    switch (unit) {
      case SUBSCRIPTION_TIME_UNITS.LIFETIME:
        return `For life`;
      case SUBSCRIPTION_TIME_UNITS.YEAR:
        return `${duration * 12} months`;

      case SUBSCRIPTION_TIME_UNITS.MONTH:
      default:
        return `${duration} month${duration > 1 ? "s" : ""}`;
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      {loading && <Loading />}
      <Grid gridTemplateColumns="repeat(12, 1fr)" flexGrow="1">
        {/* Left */}
        <GridItem
          colSpan={[12, 12, 12, 12, 6]}
          pr="5vw"
          pl="10vw"
          pb="20"
          bg="white"
        >
          {loading || !options.clientSecret.length ? (
            <Loading />
          ) : (
            <Elements stripe={stripePromise} options={options}>
              <PaymentDetailsForm
                handleChange={handleInputChange}
                formState={formState}
                next={nextStep}
                goBack={stepBack}
                updateFormState={updateFormState}
              />
            </Elements>
          )}
          {/* {currentParams.step === STEPS.shipping ? (
            <ShippingDetailsForm
              handleChange={handleInputChange}
              formState={formState}
              next={next}
              goBack={stepBack}
              updateFormState={updateFormState}
            />
          ) : currentParams.step === STEPS.payment ? (
            loading || !options.clientSecret.length ? (
              <Loading />
            ) : (
              <Elements stripe={stripePromise} options={options}>
                <PaymentDetailsForm
                  handleChange={handleInputChange}
                  formState={formState}
                  next={nextStep}
                  goBack={goBack}
                  updateFormState={updateFormState}
                />
              </Elements>
            )
          ) : null} */}
        </GridItem>

        {/* Right */}
        <GridItem
          colSpan={[12, 12, 12, 12, 6]}
          display={{ lg: "block" }}
          width="full"
          py="20"
          pl="5vw"
          pr="10vw"
        >
          <VStack alignItems="flex-start" spacing={4}>
            <H1>Order Details</H1>
            <Text
              fontSize="md"
              color="gray.400"
              opacity={0.76}
              textTransform="uppercase"
              fontFamily="Tommy Soft"
              fontWeight="bold"
              letterSpacing="0.12rem"
            >
              Included in your order
            </Text>
            <HStack
              bg="white"
              shadow="md"
              rounded="lg"
              p={4}
              width="100%"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <HStack alignItems="flex-start">
                <Avatar
                  size="xl"
                  ml={0}
                  mr={2}
                  src={subscription}
                  bg="whiteAlpha.100"
                />
                <VStack alignItems="flex-start" spacing={0}>
                  <Text fontWeight="bold" fontSize="large">
                    Flowly Membership
                  </Text>
                  <Text color="gray.400">
                    {durationInMonths(
                      selectedPlan.duration,
                      selectedPlan.timeUnits
                    )}
                  </Text>
                </VStack>
              </HStack>
              <VStack alignItems="flex-end" spacing={0}>
                <Text fontWeight="bold" fontSize="large">
                  ${selectedPlan.price}
                </Text>
                <Text color="gray.400">Qty: 1</Text>
              </VStack>
            </HStack>
            <HStack
              bg="white"
              shadow="md"
              rounded="lg"
              p={4}
              width="100%"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <HStack alignItems="flex-start">
                <Avatar size="xl" ml={0} mr={2} src={vr} bg="white" />
                <VStack alignItems="flex-start" spacing={0}>
                  <Text fontWeight="bold" fontSize="large">
                    VR Headset and Biosensor
                  </Text>
                </VStack>
              </HStack>
              <VStack alignItems="flex-end" spacing={0}>
                <Text fontWeight="bold" fontSize="large">
                  Free
                </Text>
                <Text color="gray.400">Qty: 1</Text>
              </VStack>
            </HStack>
            <VStack width="full" borderTop="1px solid #c0cff9" py={8}>
              <HStack width="full" justifyContent="space-between">
                <Text>Subtotal</Text>
                <Text fontWeight="bold" fontSize="lg">
                  ${selectedPlan.price}
                </Text>
              </HStack>
              <HStack width="full" justifyContent="space-between">
                <Text>Shipping (Priority 2-3 days)</Text>
                <Text fontWeight="bold" fontSize="lg">
                  {formState?.shippingPrice
                    ? `$${formState.shippingPrice}`
                    : "..."}
                </Text>
              </HStack>
              <HStack width="full" justifyContent="space-between">
                <Text>Total Due Today</Text>
                <Text fontWeight="bold" fontSize="lg">
                  $
                  {(
                    selectedPlan.price +
                    (formState?.shippingPrice || 0) -
                    (formState?.discount || 0)
                  ).toFixed(2)}
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default GiftCheckout;
