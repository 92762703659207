import React, { useState } from "react";
import {
  Container,
  Grid,
  GridItem,
  VStack,
  HStack,
  Divider,
} from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import { Select } from "@chakra-ui/select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/input";
import { Textarea } from "@chakra-ui/textarea";
import { useRadioGroup } from "@chakra-ui/radio";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { add, format, parseISO, subDays } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

/** local imports */
import timezones from "utils/timezones";
import { EVENT_TYPES, PERMISSION_TIERS } from "./constants";
import EventTypeRadio from "./components/EventTypeRadio";
import { updateEvent } from "./services";

const EventView = () => {
  const { state: eventDetails } = useLocation();
  return (
    <Container maxW="8xl">
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
        gap={6}
        pt="12"
        pb="16"
      >
        <GridItem
          colSpan={1}
          bg="white"
          rounded="xl"
          overflow="hidden"
          shadow="md"
          width="full"
          order={{ base: 2, xl: 1 }}
        >
          <EventForm preload={eventDetails} />
        </GridItem>
        <GridItem
          h="96"
          colSpan={1}
          p="8"
          bgGradient="linear(to-tr, #95B2ED 5.04%, #8FD9FD 97.37%)"
          rounded="xl"
          position="relative"
          order={{ base: 1, xl: 2 }}
        >
          <Button position="absolute" bottom="4" left="4" shadow="md">
            Change cover photo
          </Button>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default EventView;

const EventForm = ({ preload }) => {
  const toast = useToast();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: "",
      description: "",
      ...preload,
      startDate: format(parseISO(preload.startDate), "yyyy-MM-dd"),
      startTime: format(parseISO(preload.startDate), "HH:mm"),
      durationHours: Math.floor(preload.duration / 60),
      durationMinutes: preload.duration % 60,
    },
  });

  const [customTimezone, setCustomTimezone] = useState(null);
  const displayTimezonePicker = () => {
    setCustomTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  };

  const preloadedEvent =
    EVENT_TYPES.find((type) => type.value === preload?.type)?.value ??
    "VIRTUAL";
  const [eventType, setEventType] = useState(() => preloadedEvent);

  const {
    getRootProps: getRootEvtTypeProps,
    getRadioProps: getEvtTypeRadioProps,
  } = useRadioGroup({
    name: "eventType",
    defaultValue: preloadedEvent,
    onChange: setEventType,
  });

  const preloadedPermTier =
    PERMISSION_TIERS.find((tier) => tier.value === preload?.permissionTier)
      ?.value ?? "FREE";

  const [eventPerms, setPermTier] = useState(() => preloadedPermTier);
  const {
    getRootProps: getRootPermissionProps,
    getRadioProps: getPermissionRadioProps,
  } = useRadioGroup({
    name: "eventPermissionTier",
    defaultValue: preloadedPermTier,
    onChange: setPermTier,
  });

  const onSubmit = async (data) => {
    const {
      title,
      description,
      startDate,
      startTime,
      durationHours,
      durationMinutes,
    } = data;

    const payload = {
      id: preload.id,
      title,
      description,
      duration: +durationHours * 60 + +durationMinutes,
      type: eventType,
      permissionTier: eventPerms,
      startDate: zonedTimeToUtc(
        `${startDate} ${startTime}`,
        customTimezone
          ? data.timeZone
          : Intl.DateTimeFormat().resolvedOptions().timeZone
      ).toISOString(),
      signupLink:
        data.signupLink && data.signupLink.length ? data.signupLink : null,
      joinLink: data.joinLink && data.joinLink.length ? data.joinLink : null,
    };

    try {
      await updateEvent(payload);
      toast({
        title: "Event updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log("[Update Event Failed] Error occurred", error);
      alert("An error occurred: Event not updated");
    }
  };

  const getEventSummaryString = () => {
    const evtDate =
      parseISO(`${getValues()?.startDate}T${getValues()?.startTime}`) ??
      new Date();

    const endDate = add(evtDate, {
      hours: +getValues()?.durationHours ?? 0,
      minutes: +getValues()?.durationMinutes ?? 0,
    });
    return `Event ends on ${format(evtDate, "eeee do")} at ${format(
      endDate,
      "hh:mm aa"
    )}`;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <VStack spacing={8} p={{ base: 4, lg: 8 }}>
        <FormControl id="title" isInvalid={errors?.title} isRequired>
          <FormLabel mb={1} fontWeight="semibold">
            Event name
          </FormLabel>
          <Input
            {...register("title", {
              required: {
                value: true,
                message: "Event title cannot be left empty",
              },
            })}
            type="text"
            size="lg"
            placeholder="Breathe along with Flowly"
          />
          {errors?.title && (
            <FormErrorMessage>{errors.title.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="description" isInvalid={errors?.description}>
          <FormLabel mb={1} fontWeight="semibold">
            Description
          </FormLabel>
          <Textarea
            {...register("description")}
            type="text"
            size="sm"
            rounded="md"
            placeholder="Who should come? What's the event about?"
          />
        </FormControl>

        <FormControl id="type" isInvalid={errors?.type} isRequired>
          <FormLabel mb={1} fontWeight="semibold">
            Where is the event taking place?
          </FormLabel>
          <HStack
            {...getRootEvtTypeProps()}
            spacing="0"
            borderColor="gray.200"
            borderWidth="1px"
            p="0.5"
            rounded="lg"
            w="fit-content"
          >
            {EVENT_TYPES.map(({ label, value }) => {
              const radio = getEvtTypeRadioProps({ value });
              return (
                <EventTypeRadio key={value} {...radio}>
                  {label}
                </EventTypeRadio>
              );
            })}
          </HStack>
        </FormControl>

        <FormControl id="startDate" isInvalid={errors?.startDate} isRequired>
          <FormLabel mb={1} fontWeight="semibold">
            When will it happen?
          </FormLabel>
          <HStack w="80%" spacing={4} alignItems="flex-start">
            <Input
              {...register("startDate", {
                required: {
                  value: true,
                  message: "Date cannot be left empty",
                },
                min: {
                  value: format(subDays(new Date(), 1), "yyyy-MM-dd"),
                  message: "Event date cannot be in the past",
                },
              })}
              w="150%"
              isRequired={true}
              type="date"
              min={format(new Date(), "yyyy-MM-dd")}
            />
            <Input
              {...register("startTime", {
                required: {
                  value: true,
                  message: "Time cannot be left empty",
                },
              })}
              type="time"
            />
          </HStack>
          {!customTimezone ? (
            <FormHelperText>
              {format(new Date(), "OOOO")} —{" "}
              {new Date()
                .toString()
                .match(/\((.*)\)/)
                .pop()}{" "}
              Zone •{" "}
              <Button
                variant="link"
                colorScheme="linkedin"
                size="sm"
                onClick={displayTimezonePicker}
              >
                Change
              </Button>
            </FormHelperText>
          ) : (
            <Controller
              control={control}
              name="timezone"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <FormControl py={4} isInvalid={!!error} id="timezone">
                  <FormLabel mb={1} fontWeight="semibold">
                    Timezone
                  </FormLabel>
                  <Select
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder={
                      timezones.find(
                        (tz) =>
                          tz.code ===
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                      ).city
                    }
                  >
                    {timezones.map((tz) => (
                      <option key={tz.code} value={tz.code}>
                        {tz.offset} {tz.name} — {tz.city}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{error && error.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          )}
        </FormControl>

        <FormControl
          id="duration"
          isInvalid={errors?.durationHours || errors?.durationMinutes}
          isRequired
        >
          <FormLabel mb={1} fontWeight="semibold">
            Duration
          </FormLabel>
          <HStack w="80%" spacing={4} alignItems="flex-start">
            <VStack alignItems="flex-start">
              <InputGroup>
                <Input
                  {...register("durationHours", {
                    required: {
                      value: true,
                      message: "Duration cannot be left empty",
                    },
                    max: {
                      value: 23,
                      message: "Hours cannot exceed 23",
                    },
                  })}
                  required="required"
                  type="number"
                  defaultValue="1"
                />
                <InputRightAddon>hour</InputRightAddon>
              </InputGroup>
              {errors?.durationHours && (
                <FormErrorMessage>
                  {errors.durationHours.message}
                </FormErrorMessage>
              )}
            </VStack>
            <VStack alignItems="flex-start">
              <InputGroup>
                <Input
                  {...register("durationMinutes", {
                    max: {
                      value: 59,
                      message: "Minutes cannot exceed 59",
                    },
                  })}
                  type="number"
                  defaultValue="0"
                />
                <InputRightAddon>minute</InputRightAddon>
              </InputGroup>
              {errors?.durationMinutes && (
                <FormErrorMessage>
                  {errors.durationMinutes.message}
                </FormErrorMessage>
              )}
            </VStack>
          </HStack>
          <FormHelperText>{getEventSummaryString()}</FormHelperText>
        </FormControl>

        <FormControl id="signupLink" isInvalid={errors?.signupLink}>
          <FormLabel mb={1} fontWeight="semibold">
            Sign up link
          </FormLabel>
          <Input
            {...register("signupLink")}
            type="text"
            placeholder="https://zoom.us/webinar/register/XXXXXX_XXXXXXXX"
          />
        </FormControl>
        <FormControl id="joinLink" isInvalid={errors?.joinLink}>
          <FormLabel mb={1} fontWeight="semibold">
            Join link
          </FormLabel>
          <Input
            {...register("joinLink")}
            type="text"
            placeholder="https://zoom.us/j/XXXXXXXXXX"
          />
        </FormControl>
        <FormControl id="type" isInvalid={errors?.type} isRequired>
          <FormLabel mb={1} fontWeight="semibold">
            Minimum hero level required?
          </FormLabel>
          <HStack
            {...getRootPermissionProps()}
            spacing="0"
            borderColor="gray.200"
            borderWidth="1px"
            p="0.5"
            rounded="lg"
            w="fit-content"
          >
            {PERMISSION_TIERS.map(({ label, value }) => {
              const radio = getPermissionRadioProps({
                value,
              });
              return (
                <EventTypeRadio key={value} {...radio}>
                  {label}
                </EventTypeRadio>
              );
            })}
          </HStack>
        </FormControl>
      </VStack>
      <Divider w="full" />
      <HStack p="4" justifyContent="flex-end">
        <Button type="submit" colorScheme="linkedin">
          Save changes
        </Button>
      </HStack>
    </form>
  );
};
