import React from "react";
import styled from "styled-components";

const H3Style = styled.h3`
  font-family: "Tommy Soft";
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #5072ec;
`;
function H3({ children }) {
  return <H3Style>{children}</H3Style>;
}

export default H3;
